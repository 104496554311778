import { makeStyles } from '@mui/styles';

const useEditSoftSkillModalStyle = makeStyles({
  modalPaper: {
    minWidth: 800,
    minHeight: 300,
    padding: 15,
    maxHeight: 600,
    overflow: 'scroll',
  },
});

export default useEditSoftSkillModalStyle;
