import SwitchFormField from 'components/molecules/SwitchFormField/SwitchFormField';
import { technicalSkillsRoutes } from 'constants/navigation';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@spotted-zebra-uk/ui-components';
import styles from './styles.module.scss';

interface ITechnicalSkillsHeader {
  isArchivedVisible: boolean;
  setIsArchivedVisible: (isArchivedVisible: boolean) => void;
}

const TechnicalSkillsHeader: FC<ITechnicalSkillsHeader> = ({
  isArchivedVisible,
  setIsArchivedVisible,
}) => {
  const toggleArchivedProjectsVisibility = () => {
    setIsArchivedVisible(!isArchivedVisible);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Technical Skills</div>
      <div className={styles.menu}>
        <div>
          <SwitchFormField
            id="toggle-display-archived-soft-skills"
            name="isArchivedVisible"
            color="primary"
            value={isArchivedVisible}
            label="Show archived technical skills"
            onChange={toggleArchivedProjectsVisibility}
          />
        </div>
        <div>
          <Link to={technicalSkillsRoutes.addNew.url()}>
            <Button>Add skill</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSkillsHeader;
