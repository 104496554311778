import Modal from 'components/molecules/Modal/Modal';
import { ModalTypes } from 'enums/modalTypes';
import {
  TraitsDocument,
  TraitsQuery,
  TraitsQueryVariables,
  useAddTraitMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TraitForm, { ITraitFieldFormValues } from '../TraitForm/TraitForm';
import useAddTraitModalStyle from './AddTraitModalStyle';

const AddTraitModal: FC = () => {
  const { handleMsgType } = useNotification();
  const classes = useAddTraitModalStyle();
  const [addTraitMutation] = useAddTraitMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, { data }) => {
      if (data?.Trait) {
        try {
          const cachedTraitsResult = cache.readQuery<
            TraitsQuery,
            TraitsQueryVariables
          >({
            query: TraitsDocument,
            variables: {
              isArchived: false,
            },
          });

          if (cachedTraitsResult?.Traits) {
            cache.writeQuery<TraitsQuery, TraitsQueryVariables>({
              query: TraitsDocument,
              variables: {
                isArchived: false,
              },
              data: {
                Traits: [...cachedTraitsResult.Traits, data.Trait],
              },
            });
          }
        } catch (error) {
          console.log(error);
        }

        try {
          const cachedTraitsResult = cache.readQuery<
            TraitsQuery,
            TraitsQueryVariables
          >({
            query: TraitsDocument,
          });

          if (cachedTraitsResult?.Traits) {
            cache.writeQuery<TraitsQuery, TraitsQueryVariables>({
              query: TraitsDocument,
              data: {
                Traits: [...cachedTraitsResult.Traits, data.Trait],
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const addTrait = (values: ITraitFieldFormValues) => {
    const name = values.name;
    const description = values.description;
    addTraitMutation({
      variables: {
        name,
        description,
      },
    });
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_TRAIT_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <TraitForm onSubmit={addTrait} />
    </Modal>
  );
};

export default AddTraitModal;
