import Button from 'components/atoms/Button/Button';
import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import ProjectJobRoleSelectFormField from 'components/molecules/SelectFormField/ProjectJobRoleSelectFormField/ProjectJobRoleSelectFormField';
import ReportNameSelectFormField from 'components/molecules/SelectFormField/ReportNameSelectFormField/ReportNameSelectFormField';
import {
  ReportRenderType,
  StageCandidateStatus,
  useGetProjectByIdQuery,
  useProjectJobRoleFindManyQuery,
  useStageResultsReportRenderCreateMutation,
} from 'generated/graphql';
import { usePollingReportRenderQuery } from 'helpers/reportRender';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { FC, useEffect, useRef, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useUserTableStyle from '../../../../../UserMain/UserTable/UserTableStyle';
import { INewReport } from '../../UserResets.interface';

const NewReport: FC<INewReport> = ({
  stageId,
  projectId,
  stageStatus,
  onReportLoaded,
  onSetReportType,
  stageCandidateId,
  activeReportType,
}) => {
  const { handleMsgType } = useNotification();
  const reportRenderSubIdRef = useRef<string | null>('new');

  const classes = useUserTableStyle();
  const [reportName, setReportName] = useState<TSelectFormFieldValue>('');
  const [selectedJobRoleId, setSelectedJobRoleId] = useState<
    TSelectFormFieldValue
  >('');

  const getProjectByIdResponse = useGetProjectByIdQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      id: projectId,
    },
  });

  const { data: projectJobRoles } = useProjectJobRoleFindManyQuery({
    variables: {
      id: projectId,
    },
    onCompleted: data => {
      if (data?.ProjectJobRoleFindMany) {
        setSelectedJobRoleId(data?.ProjectJobRoleFindMany[0]?.id);
      }
    },
  });

  const {
    startPolling,
    isPolling,
    reportRenderQueryResponse,
  } = usePollingReportRenderQuery(reportRenderSubIdRef.current, true);

  const [
    reportRenderCreateMutation,
  ] = useStageResultsReportRenderCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: data => {
      const report = data?.StageResultsReportRenderCreate;
      if (report) {
        reportRenderSubIdRef.current = report.subId;
        startPolling();
      }
    },
  });

  useEffect(() => {
    if (
      reportRenderQueryResponse.data?.ReportRender &&
      activeReportType === 'new'
    ) {
      onReportLoaded(reportRenderQueryResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportRenderQueryResponse, activeReportType]);

  const handleReportNameChange = (value: TSelectFormFieldValue) => {
    setReportName(value);
  };

  const handleSelectedJobRoleId = (value: TSelectFormFieldValue) => {
    setSelectedJobRoleId(value);
  };

  const handleGenerateReport = () => {
    onSetReportType('new');
    reportRenderCreateMutation({
      variables: {
        reportId: Number(reportName),
        dataSourceId: Number(stageCandidateId),
        reportRenderType: ReportRenderType.Pdf,
        projectJobRoleId: Number(selectedJobRoleId),
      },
    });
  };

  const isProjectMultimatch =
    (projectJobRoles?.ProjectJobRoleFindMany.length ?? 0) > 1;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <p>
        <strong>Create new report from existing soft skill scores</strong>
      </p>
      <div
        style={{
          width: isProjectMultimatch ? 700 : 350,
          display: 'flex',
          gap: 10,
        }}
      >
        {isProjectMultimatch && (
          <ProjectJobRoleSelectFormField
            id="projectJobRoleSelect"
            name="projectJobRole"
            label="Job role"
            onChange={handleSelectedJobRoleId}
            projectJobRoles={projectJobRoles?.ProjectJobRoleFindMany ?? []}
          />
        )}
        <ReportNameSelectFormField
          id="report"
          name="reportName"
          label="Report Name"
          value={reportName}
          onChange={handleReportNameChange}
          isDisabled={stageStatus !== StageCandidateStatus.Completed}
          stageId={stageId}
          projectId={Number(projectId)}
          companyId={Number(getProjectByIdResponse?.data?.project?.company?.id)}
        />
        <div style={{ marginLeft: 10 }}>
          {isPolling ? (
            <CircularLoader customStyle={classes.loaderWrapper} />
          ) : (
            <Button isDisabled={!reportName} onClick={handleGenerateReport}>
              New Report
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewReport;
