import {
  RelationDepth,
  useStageCandidateFindManyQuery,
} from 'generated/graphql';
import { compact } from 'lodash';
import { FC } from 'react';
import { Divider } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { IUserInfo } from '../UserDetails.interface';
import UserReset from './UserReset/UserResets';

const UserMainReset: FC<IUserInfo> = ({ userInfo }) => {
  const { handleMsgType } = useNotification();

  const stageCandidateFindManyResponse = useStageCandidateFindManyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      candidateId: userInfo?.id,
      relationDepth: RelationDepth.FullWithExternal,
    },
  });

  if (stageCandidateFindManyResponse?.data?.StageCandidates) {
    return (
      <div style={{ marginTop: 30 }}>
        {stageCandidateFindManyResponse.data.StageCandidates.map(
          ({
            id,
            stage,
            subId,
            lastDateOfCandidateAction,
            stageResult,
            status,
            doneBy,
            doneFor,
            rssAssessmentCandidates,
            formCandidates,
            deiStageActionCandidate,
          }) => (
            <div key={id} style={{ marginTop: 50 }}>
              <UserReset
                stageId={stage?.id || 0}
                stageName={stage?.name || ''}
                projectId={stage?.projectId || 0}
                lastDateOfCandidateAction={lastDateOfCandidateAction}
                stageStatus={status}
                scores={stageResult?.scores}
                totalScore={stageResult?.totalScore}
                userInfo={userInfo}
                stageCandidateId={id}
                stageCandidateSubId={subId}
                doneFor={doneFor}
                doneBy={doneBy}
                rssAssessmentCandidates={rssAssessmentCandidates || []}
                formCandidates={compact(formCandidates) || []}
                deiForm={deiStageActionCandidate}
              />
              <Divider style={{ marginTop: 50 }} />
            </div>
          )
        )}
      </div>
    );
  }
  return null;
};

export default UserMainReset;
