import styles from './styles.module.scss';
import TechnicalSkillsHeader from './TechnicalSkillsHeader/TechnicalSkillsHeader';
import TechnicalSkillsList from './TechnicalSkillsList/TechnicalSkillsList';

const TechnicalSkills = () => (
  <div className={styles.container}>
    <TechnicalSkillsHeader
      isArchivedVisible={false}
      setIsArchivedVisible={() => {
        console.log('archived');
      }}
    />
    <TechnicalSkillsList />
  </div>
);

export default TechnicalSkills;
