import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import WidgetTypeSelectFormField from '../../../components/molecules/SelectFormField/WidgetTypeSelectFormField/WidgetTypeSelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import {
  useWidgetUpdateMutation,
  WidgetType,
} from '../../../generated/graphql';
import validate from '../../../helpers/validate';
import {
  TSelectFormFieldValue,
  TTextFromFieldValue,
} from '../../../interfaces/forms/form';
import useWidgetStyles from '../WidgetStyles';

interface IEditWidgetPayload {
  widgetName: string;
  widgetTypeValue: TSelectFormFieldValue;
}

interface IEditWidget {
  id: number;
  name: string;
  type: string;
}

interface IEditWidgetError {
  widgetName: string;
  widgetTypeValue: string;
}

const EditWidget: FC<IEditWidget> = ({ id, name, type }) => {
  const { handleMsgType } = useNotification();
  const classes = useWidgetStyles();

  const [widgetInfo, setWidgetInfo] = useState<IEditWidgetPayload>({
    widgetName: name,
    widgetTypeValue: type,
  });

  const [errors, setErrors] = useState<IEditWidgetError>({
    widgetName: '',
    widgetTypeValue: '',
  });

  const [updateWidgetMutation] = useWidgetUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const getWidgetName = (value: TTextFromFieldValue) => {
    setWidgetInfo(prev => ({ ...prev, widgetName: value }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, widgetName: '' } : prevErrors
    );
  };

  const getWidgetTypeValue = (value: TSelectFormFieldValue) => {
    setWidgetInfo(prev => ({ ...prev, widgetTypeValue: value }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, widgetTypeValue: '' } : prevErrors
    );
  };

  const editWidgetValidation = (newValues: IEditWidgetPayload) => {
    return validate(newValues, {
      widgetName: {
        nonEmptyString: true,
      },
      widgetTypeValue: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
    });
  };

  const handleWidgetSave = () => {
    const reportErrors = editWidgetValidation(widgetInfo);
    if (!_.isEmpty(reportErrors)) {
      return setErrors(reportErrors);
    }
    const variables = {
      id,
      name: widgetInfo.widgetName,
      type: widgetInfo.widgetTypeValue as WidgetType,
    };
    updateWidgetMutation({ variables });
  };
  return (
    <>
      <Grid container direction="row" xs={12}>
        <Grid xs={6} pr={2}>
          <TextFormField
            id="widgetName"
            name="widgetName"
            value={widgetInfo.widgetName}
            label="Widget Name"
            onChange={getWidgetName}
            hasError={!!errors?.widgetName}
            errorText={errors?.widgetName}
          />
        </Grid>
        <Grid xs={6}>
          <WidgetTypeSelectFormField
            id="typeFormField"
            name="type"
            label="Type"
            value={widgetInfo.widgetTypeValue}
            onChange={getWidgetTypeValue}
            hasError={!!errors?.widgetTypeValue}
            errorText={errors?.widgetTypeValue}
          />
        </Grid>
      </Grid>
      <Grid>
        <div style={{ marginTop: '10px' }}>
          <Button
            className={classes.btn}
            onClick={handleWidgetSave}
            color="inherit"
          >
            SAVE
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default EditWidget;
