import { ApolloCache } from '@apollo/client';
import { Report, ReportsDocument } from '../generated/graphql';

export const addReportToCache = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  report: Report
) => {
  const data: { Reports: Report[] } | null = cache.readQuery({
    query: ReportsDocument,
  });
  if (data?.Reports && report) {
    cache.writeQuery({
      query: ReportsDocument,
      data: { Reports: [report, ...data.Reports] },
    });
  }
};
