export const headerData = [
  'Type',
  'Tp Project Id',
  'Tp Assessment Id',
  'Status',
  'Updated',
  '',
  '',
  '',
];
