import validate from 'validate.js';

const nonEmptyStringValidator = (
  value: string,
  options: {
    message?: string;
    allowEmpty?: boolean;
  }
): string | null => {
  const message = options.message || 'must be a non empty string';
  if (options.allowEmpty && (!validate.isDefined(value) || value === '')) {
    return null;
  }
  if (validate.isString(value) && !validate.isEmpty(value)) {
    return null;
  }

  return message;
};

const isNumber = (
  value: number,
  options: {
    message?: string;
  }
): string | null => {
  if (!validate.isNumber(+value)) {
    return 'must be number';
  }
  return null;
};

validate.validators.nonEmptyString = nonEmptyStringValidator;
validate.validators.isNumber = isNumber;

export default validate;
