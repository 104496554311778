import Button from 'components/atoms/Button/Button';
import { usersRoutes } from 'constants/navigation';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import useUserTableRowStyle from './UserTableRowStyle';

interface IUserTableRow {
  id: number;
  subId?: string;
  firstName: string;
  lastName: string;
  email: string;
}

const UserTableRow: FC<IUserTableRow> = ({
  id,
  subId,
  firstName,
  lastName,
  email,
}) => {
  const classes = useUserTableRowStyle();
  const navigate = useNavigate();

  const handleViewBtnClick = () => {
    navigate(usersRoutes.user.url(id));
  };
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{id}</TableCell>
      <TableCell className={classes.tableCell}>{subId}</TableCell>
      <TableCell className={classes.tableCell}>{firstName}</TableCell>
      <TableCell className={classes.tableCell}>{lastName}</TableCell>
      <TableCell className={classes.tableCell}>{email}</TableCell>
      <TableCell align="right">
        <Button className={classes.btn} onClick={handleViewBtnClick}>
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;
