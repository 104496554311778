import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import { FieldArray, useField, useFormikContext } from 'formik';
import {
  CompanyEmployeeModel,
  ProjectRequestUserType,
  useCompanyMaybeUserEmployeeFindManyLazyQuery,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC, Fragment, memo, useEffect, useMemo } from 'react';
import { EmployeeModalMode } from 'views/ModalView/CompanyEmployeeModal/CompanyEmployeeModal.enums';
import {
  TSelectOption,
  Button,
  TNotification,
  useNotification,
  FormikSelectFormField,
  FormikSelectWithMenuButton,
  TOption,
} from '@spotted-zebra-uk/ui-components';
import {
  ProjectRequestCreateFormContactValues,
  ProjectRequestCreateFormValues,
} from '../types';

export const roleOptions: TSelectOption[] = [
  {
    label: 'Additional contact',
    value: ProjectRequestUserType.AdditionalContact,
  },
  {
    label: 'Manager',
    value: ProjectRequestUserType.Manager,
  },
  {
    label: 'Recruiter',
    value: ProjectRequestUserType.Recruiter,
  },
];

interface ContactsSubFromRowProps {
  index: number;
  employeesOptions: TOption[];
  remove: (index: number) => void;
  hasDeleteButton: boolean;
  companyId: string | undefined;
}

const ContactsSubFromRow: FC<ContactsSubFromRowProps> = ({
  index,
  employeesOptions,
  remove,
  hasDeleteButton,
  companyId,
}) => {
  const { setFieldValue } = useFormikContext();

  const handleDelete = () => {
    remove(index);
  };

  const handleEmployeeCreated = (employee: CompanyEmployeeModel) => {
    setFieldValue(`contacts.${index}.employee`, {
      label: `${employee.firstName} ${employee.lastName}`,
      value: employee.email,
      labelNote: employee.email,
    });
  };

  const handleCreateEmployeeButtonClick = () => {
    if (companyId) {
      openModal(ModalTypes.COMPANY_EMPLOYEE_MODAL, {
        employee: {
          companyId: parseInt(companyId),
        },
        onEmployeeCreated: handleEmployeeCreated,
        mode: EmployeeModalMode.CREATE,
      });
    }
  };

  return (
    <div className="contacts-sub-from-row" key={index}>
      <FormikSelectFormField
        options={roleOptions}
        id={`contacts.${index}.role`}
        placeholder="Role"
        label="Role"
        className="contacts-sub-from-row__role-select"
        useFormikField={useField}
      />
      <FormikSelectWithMenuButton
        options={employeesOptions}
        id={`contacts.${index}.employee`}
        placeholder="Employee"
        label="Employee"
        className="contacts-sub-from-row__employee-select"
        hasMenuButton={!!companyId}
        maxMenuHeight={212}
        menuButtonProps={{
          children: '+ Create New Employee',
          onClick: handleCreateEmployeeButtonClick,
        }}
        useFormikField={useField}
      />
      {hasDeleteButton && (
        <Button
          onClick={handleDelete}
          size="small"
          variant="secondary"
          className="contacts-sub-from-row__delete-button"
          leftIcon={
            <Icon
              icon="delete_outline"
              className="contacts-sub-from-row__delete-icon"
            />
          }
        ></Button>
      )}
    </div>
  );
};

interface ContactsSubFormPresentationalProps {
  contacts: ProjectRequestCreateFormContactValues[];
  employeesOptions: TOption[];
  companyId: string | undefined;
}

const ContactsSubFormPresentational = memo<ContactsSubFormPresentationalProps>(
  ({ contacts, employeesOptions, companyId }) => (
    <FieldArray name="contacts">
      {({ remove, push }) => (
        <div className="contacts-sub-form">
          <div>
            {contacts.map((_contact, index) => (
              <Fragment key={index}>
                <ContactsSubFromRow
                  remove={remove}
                  index={index}
                  employeesOptions={employeesOptions}
                  hasDeleteButton={contacts.length > 1}
                  companyId={companyId}
                />
              </Fragment>
            ))}
          </div>
          <div>
            <Button
              className="contacts-sub-form__add-contact-button"
              size="medium"
              variant="secondary"
              onClick={() => push(emptyValue)}
              type="button"
              leftIcon={
                <Icon
                  icon="add_black"
                  className="contacts-sub-form__add-icon"
                />
              }
            >
              Add Contact
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
);

const emptyValue: ProjectRequestCreateFormContactValues = {
  role: { label: '', value: '' },
  employee: { label: '', value: '', labelNote: '' },
};

export const initialValues = [emptyValue];

const ContactsSubForm: FC = () => {
  const { handleMsgType } = useNotification();

  const { values, setFieldValue } = useFormikContext<
    ProjectRequestCreateFormValues
  >();
  const [
    companyEmployeesQuery,
    companyEmployeesQueryResponse,
  ] = useCompanyMaybeUserEmployeeFindManyLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  useEffect(() => {
    setFieldValue('contacts', initialValues);

    if (values.company.value) {
      companyEmployeesQuery({
        variables: {
          // TODO: Fix types in Select component.
          companyId: parseInt(values.company.value as string),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.company]);

  const employeesOptions = useMemo(
    () =>
      companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany?.map(
        employee => ({
          value: employee.email,
          label: `${employee.firstName} ${employee.lastName}`,
          labelNote: employee.email,
        })
      ) || [],
    [companyEmployeesQueryResponse.data]
  );

  return (
    <ContactsSubFormPresentational
      contacts={values.contacts}
      employeesOptions={employeesOptions}
      companyId={values.company.value as string}
    />
  );
};

export default ContactsSubForm;
