import styles from './ItemsCount.module.scss';

type ItemsCountProps = { count: number; ariaLabelSuffix?: string };

export const ItemsCount = ({ count, ariaLabelSuffix }: ItemsCountProps) => {
  return (
    <div
      className={styles.itemsCount}
      aria-label={`${count} ${ariaLabelSuffix}`}
    >
      {/* Revert the order to fix screen reader behaviour with JAWS */}
      <span className={styles.count} aria-label={String(count)}>
        {count}
      </span>
      <span className={styles.items} aria-label={ariaLabelSuffix}>
        Items
      </span>
    </div>
  );
};
