import { FormikCompanyMaybeUserEmployeesSelect } from 'components/organisms/CompanyMaybeUserEmployeesSelect';
import { ModalTypes } from 'enums/modalTypes';
import { useField, useFormikContext } from 'formik';
import { CompanyMaybeUserEmployeeFindManyQuery } from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { FC, useCallback } from 'react';
import {
  Button,
  FormikDatepicker,
  FormikSelectFormField,
  FormikTextInputField,
  ModalButtons,
  TSelectOption,
  TabListLegacy as TabList,
  TabPanelLegacy as TabPanel,
} from '@spotted-zebra-uk/ui-components';
import { EmployeeModalMode } from '../CompanyEmployeeModal.enums';

export const NO_EMPLOYEE_ID = 'no-employeeId';

enum EmployeeFormTabsValues {
  PERSONAL_INFO = 'PERSONAL_INFO',
  POSITION_INFO = 'POSITION_INFO',
}

const employeeFormTabsOptions = [
  {
    title: '1. Personal Info',
    value: EmployeeFormTabsValues.PERSONAL_INFO,
  },
  {
    title: '2. Position Info',
    value: EmployeeFormTabsValues.POSITION_INFO,
  },
];

export interface IEmployeeFormValues {
  firstName: string;
  lastName: string;
  email: string;
  department: string;
  startDate: string;
  endDate: string;
  employeeId: string;
  hrbp: TSelectOption;
  gender: string;
  jobTitle: string;
  division: string;
  function: string;
  groupFunction: string;
  office: string;
  officeCountry: string;
  level: string;
  companyId: TSelectOption;
}

interface IEmployeeForm {
  mode: EmployeeModalMode;
  companiesOptions: TSelectOption[];
}

export const EmployeeForm: FC<IEmployeeForm> = ({ mode, companiesOptions }) => {
  const disable = mode === EmployeeModalMode.DELETE;
  const {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
  } = useFormikContext<IEmployeeFormValues>();

  const isHRBPNoteVisible = Boolean(
    // TODO: Fix types in Select component.
    (values.hrbp.value as string).startsWith(NO_EMPLOYEE_ID) &&
      values.hrbp.label
  );

  const handleHRBPNoteClick = () => {
    openModal(ModalTypes.EDIT_HR_MANAGER_EMPLOYEE_ID_MODAL, {
      hrManagerEmail: values.hrbp.labelNote,
      hrManagerCompanyId: Number(values.companyId.value),
      onCompleted: hrManager =>
        setFieldValue('hrbp', {
          label: `${hrManager.firstName} ${hrManager.lastName}`,
          value: hrManager.employeeId,
          labelNote: hrManager.email,
        }),
    });
  };

  const getHRBPOptions = useCallback(
    (res: CompanyMaybeUserEmployeeFindManyQuery) => {
      // Remove employees that are already set as contacts to the project request.
      return (
        res?.CompanyMaybeUserEmployeeFindMany?.map(
          ({ email, employeeId, firstName, lastName }) => ({
            value: `${employeeId || `${NO_EMPLOYEE_ID}-${email}`}`,
            label: `${firstName} ${lastName}`,
            labelNote: email,
          })
        ) || []
      );
    },
    []
  );

  return (
    <div className="employee-form">
      <TabList
        initialValue={EmployeeFormTabsValues.PERSONAL_INFO}
        options={employeeFormTabsOptions}
      >
        <div className="employee-form__fields">
          <TabPanel value={EmployeeFormTabsValues.PERSONAL_INFO}>
            <div className="employee-form__flex-container">
              <FormikTextInputField
                label="Given Name"
                id="firstName"
                placeholder="Given Name"
                disabled={disable}
                useFormikField={useField}
              />
              <FormikTextInputField
                label="Family Name"
                id="lastName"
                placeholder="Family Name"
                disabled={disable}
                useFormikField={useField}
              />
            </div>
            <FormikTextInputField
              label="Email"
              id="email"
              placeholder="Email"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Gender"
              id="gender"
              placeholder="Gender"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Employee Id"
              id="employeeId"
              placeholder="Employee Id"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikSelectFormField
              maxMenuHeight={125}
              options={companiesOptions}
              label="Company"
              id="companyId"
              isDisabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Job title"
              id="jobTitle"
              placeholder="Job title"
              disabled={disable}
              useFormikField={useField}
            />
            <div className="employee-form__flex-container">
              <FormikDatepicker
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                isDisabled={disable}
                label="Start Date"
                useFormikField={useField}
              />
              <FormikDatepicker
                id="endDate"
                name="endDate"
                placeholder="End Date"
                isDisabled={disable}
                label="End Date"
                useFormikField={useField}
              />
            </div>
          </TabPanel>

          <TabPanel value={EmployeeFormTabsValues.POSITION_INFO}>
            <FormikTextInputField
              label="Function"
              id="function"
              placeholder="Function"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Department"
              id="department"
              placeholder="Department"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Division"
              id="division"
              placeholder="Division"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Level"
              id="level"
              placeholder="Level"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikCompanyMaybeUserEmployeesSelect
              maxMenuHeight={125}
              isDisabled={disable}
              label="HRBP"
              id="hrbp"
              placeholder="HRBP"
              hasMenuButton={false}
              getOptionsFromResponse={getHRBPOptions}
              companyId={Number(values.companyId.value)}
            />
            {isHRBPNoteVisible && (
              <div className="employee-form__htbp-note">
                EmployeeId needs setting. Click{' '}
                <Button
                  size="small"
                  variant="text"
                  onClick={handleHRBPNoteClick}
                  className="employee-form__htbp-note-button"
                >
                  here
                </Button>
              </div>
            )}
            <FormikTextInputField
              label="Office"
              id="office"
              placeholder="Office"
              disabled={disable}
              useFormikField={useField}
            />
            <FormikTextInputField
              label="Office country"
              id="officeCountry"
              placeholder="Office country"
              disabled={disable}
              useFormikField={useField}
            />
          </TabPanel>
        </div>
      </TabList>

      <div className="employee-form__actions">
        <ModalButtons
          onCancelButtonClick={() =>
            closeModal(ModalTypes.COMPANY_EMPLOYEE_MODAL)
          }
          onConfirmButtonClick={() => handleSubmit()}
          buttonsPosition="end"
          cancelButtonChildren="Cancel"
          confirmButtonChildren={
            mode === EmployeeModalMode.DELETE ? 'Delete Employee' : 'Submit'
          }
          cancelButtonProps={{
            size: 'medium',
          }}
          confirmButtonProps={{
            variant: mode === EmployeeModalMode.DELETE ? 'text' : 'primary',
            disabled: isSubmitting,
            type: 'button',
            size: 'medium',
          }}
        />
      </div>
    </div>
  );
};
