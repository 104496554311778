import { FC } from 'react';

interface ISkillsValidatedTableCell {
  text: string;
  isHeading?: boolean;
}

const SkillsValidatedTableCell: FC<ISkillsValidatedTableCell> = ({
  text,
  isHeading = false,
}) => {
  return (
    <div
      className="skills-validated-table-cell__item"
      is-heading={isHeading ? 'true' : ''}
    >
      {text}
    </div>
  );
};

export default SkillsValidatedTableCell;
