import { Trait } from 'generated/graphql';
import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import TraitsListItem from './TraitsListItem/TraitsListItem';
import useTraitsListStyle from './TraitsListStyle';

interface ITraitsList {
  traits: Trait[];
}

const TraitsList: FC<ITraitsList> = ({ traits }) => {
  const classes = useTraitsListStyle();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      pt={2}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Trait ID</Typography>
        </Grid>
        <Grid xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Name</Typography>
        </Grid>
        <Grid xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Description</Typography>
        </Grid>
        <Grid xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Type(s)</Typography>
        </Grid>
        <Grid xs={3} className={classes.listHeadingGridItemWrapper}></Grid>
      </Grid>
      {traits?.map(trait => (
        <TraitsListItem trait={trait} key={trait.id} />
      ))}
    </Grid>
  );
};

export default TraitsList;
