import history from 'config/history';
import {
  AUTH_LOGIN_ROUTE,
  AUTH_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'constants/authentication';

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
};

export const removeAccessToken = () => {
  localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
};

export const authCleanup = () => {
  removeAccessToken();
  removeRefreshToken();
};

export const logoutCleanup = () => {
  authCleanup();
  history.push(AUTH_LOGIN_ROUTE);
};
