import {
  RssAssessmentCreateManyMutation,
  RssAssessmentDeleteManyMutation,
  RssAssessmentFindManyDocument,
  RssAssessmentFindManyQuery,
} from 'generated/graphql';
import { ApolloCache } from '@apollo/client';

export const updateCacheAfterRSSAssessmentCreateMany = (
  cache: ApolloCache<{}>,
  stageId: number,
  data?: RssAssessmentCreateManyMutation | null
) => {
  if (!data) {
    return;
  }

  const cachedStageRssAssessments = cache.readQuery<RssAssessmentFindManyQuery>(
    {
      query: RssAssessmentFindManyDocument,
      variables: {
        stageId,
      },
    }
  );

  if (!cachedStageRssAssessments) {
    return;
  }

  cache.writeQuery<RssAssessmentFindManyQuery>({
    query: RssAssessmentFindManyDocument,
    variables: {
      stageId,
    },
    data: {
      RssAssessmentFindMany: [
        ...cachedStageRssAssessments.RssAssessmentFindMany,
        ...data.RssAssessmentCreateMany,
      ],
    },
  });
};

export const updateCacheAfterRSSAssessmentDeleteMany = (
  cache: ApolloCache<{}>,
  stageId: number,
  rssAssessmentIds: number[],
  data?: RssAssessmentDeleteManyMutation | null
) => {
  if (!data) {
    return;
  }

  const cachedStageRssAssessments = cache.readQuery<RssAssessmentFindManyQuery>(
    {
      query: RssAssessmentFindManyDocument,
      variables: {
        stageId,
      },
    }
  );

  if (!cachedStageRssAssessments) {
    return;
  }

  const newRssAssessmentArray = cachedStageRssAssessments.RssAssessmentFindMany.filter(
    cachedRssAssessment =>
      !rssAssessmentIds.some(rssaId => rssaId === cachedRssAssessment.id)
  );

  cache.writeQuery<RssAssessmentFindManyQuery>({
    query: RssAssessmentFindManyDocument,
    variables: {
      stageId,
    },
    data: {
      RssAssessmentFindMany: newRssAssessmentArray,
    },
  });
};

export const rssAlreadyExistsTooltipHtml = (type: string) =>
  `
    <div class="add-skills-self-assessment-action__tooltip-note">
      ${type} self-assessment isn't available,
    </div>
    <div class="add-skills-self-assessment-action__tooltip-note">
      because it already exists.
    </div>
  `;

export const rssNotAvailableTooltipHtml = (type: string) =>
  `
    <div class="add-skills-self-assessment-action__tooltip-note">
      ${type} self-assessment isn't available,
    </div>
    <div class="add-skills-self-assessment-action__tooltip-note">
      because no matching skills exist in the
    </div>
    <div class="add-skills-self-assessment-action__tooltip-note">
      skills profile.
    </div>
  `;
