import { GradeGQLInput } from '../enums/successProfile';

export const gradeOptions = [
  {
    value: GradeGQLInput['A+'],
    label: 'A+',
  },
  {
    value: GradeGQLInput.A,
    label: 'A',
  },
  {
    value: GradeGQLInput.B,
    label: 'B',
  },
  {
    value: GradeGQLInput.C,
    label: 'C',
  },
  {
    value: GradeGQLInput.D,
    label: 'D',
  },
  {
    value: GradeGQLInput.E,
    label: 'E',
  },
];
