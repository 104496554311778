import { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import SwitchFormField from '../../../../components/molecules/SwitchFormField/SwitchFormField';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import useCandidateFieldsHeaderStyle from './CandidateInfoFieldsHeaderStyle';

interface ICandidateInfoFieldsHeader {
  isArchivedVisible: boolean;
  setIsArchivedVisible: (isArchivedVisible: boolean) => void;
}

const CandidateInfoFieldsHeader: FC<ICandidateInfoFieldsHeader> = ({
  isArchivedVisible,
  setIsArchivedVisible,
}) => {
  const classes = useCandidateFieldsHeaderStyle();

  const toggleArchivedProjectsVisibility = () => {
    setIsArchivedVisible(!isArchivedVisible);
  };

  const handleOpenCreateCandidateInfoFieldModal = () => {
    openModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL, {});
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid xs={6}>
        <Typography variant="h5">Candidate information fields</Typography>
      </Grid>
      <Grid
        container
        direction="row"
        xs={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid>
          <SwitchFormField
            id="toggle-displey-archived-form-fields"
            name="isArchivedVisible"
            color="primary"
            value={isArchivedVisible}
            label="display / hide archived fields"
            onChange={toggleArchivedProjectsVisibility}
          />
        </Grid>
        <Grid>
          <Button
            onClick={handleOpenCreateCandidateInfoFieldModal}
            className={classes.button}
            variant="contained"
            color="inherit"
          >
            Add new
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CandidateInfoFieldsHeader;
