import Button from 'components/atoms/Button/Button';
import TestSubTypeSelectFormField from 'components/molecules/SelectFormField/TestSubTypeSelectFormField/TestSubTypeSelectFormField';
import TestTypeSelectFormField from 'components/molecules/SelectFormField/TestTypeSelectFormField/TestTypeSelectFormField';
import TextFormField from 'components/molecules/TextFormField/TextFormField';
import {
  TestSubType,
  TestType,
  Trait,
  TraitTestTypeModel,
} from 'generated/graphql';
import { duplicateTestTypeExist } from 'helpers/trait';
import validate from 'helpers/validate';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { FC, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import TraitFormHeader from './TraitFormHeader/TraitFormHeader';
import useTraitFormStyle from './TraitFormStyle';

export interface ITraitForm {
  trait?: Trait;
  onSubmit: (values: ITraitFieldFormValues) => void;
  isEditMode?: boolean;
}

export type ITraitTestType = Omit<TraitTestTypeModel, 'traitId'>;

export interface ITraitFieldFormValues {
  name: string;
  description: string;
  traitTestTypes: ITraitTestType[];
}

interface ITraitFieldFormErrors {
  nameError: string;
  descriptionError: string;
  typeError: string;
}

const TraitForm: FC<ITraitForm> = ({ trait, onSubmit, isEditMode = false }) => {
  const classes = useTraitFormStyle();
  const testTypeId = useRef<number>(0);
  const [values, setValues] = useState<ITraitFieldFormValues>({
    name: trait?.name ? trait.name : '',
    description: trait?.description ? trait.description : '',
    traitTestTypes: trait?.testTypes || [],
  });
  const [errors, setErrors] = useState<ITraitFieldFormErrors | undefined>();

  const handleChangeName = (value: string) => {
    setValues(prevValues => ({ ...prevValues, name: value }));
    if (errors?.nameError) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, nameError: '' } : undefined
      );
    }
  };

  const handleChangeDescription = (value: string) => {
    setValues(prevValues => ({ ...prevValues, description: value }));
    if (errors?.descriptionError) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, descriptionError: '' } : undefined
      );
    }
  };
  const changeTraitTestTypesAndRemoveErrors = (
    traitTestTypes: ITraitTestType[]
  ) => {
    setValues(prevValues => ({
      ...prevValues,
      traitTestTypes,
    }));
    if (errors?.typeError) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, typeError: '' } : undefined
      );
    }
  };

  const handleChangeTestType = (value: TSelectFormFieldValue, name: string) => {
    let traitTestTypeId = Number(name.replace(/[^0-9]/g, ''));

    const oldTraitTestTypes = [...values.traitTestTypes];
    const newTraitTestTypes = oldTraitTestTypes.map(traitTestType => {
      return {
        ...traitTestType,
        testType:
          traitTestType.id === traitTestTypeId
            ? (value as TestType)
            : traitTestType.testType,
        testSubType:
          traitTestType.id === traitTestTypeId && value === TestType.Cognitive
            ? TestSubType.Numerical
            : traitTestType.testSubType,
      };
    });
    changeTraitTestTypesAndRemoveErrors(newTraitTestTypes);
  };

  const handleChangeTestSubType = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    let traitTestTypeId = Number(name.replace(/[^0-9]/g, ''));

    const oldTraitTestTypes = [...values.traitTestTypes];
    const newTraitTestTypes = oldTraitTestTypes.map(traitTestType => {
      return {
        ...traitTestType,
        testSubType:
          traitTestType.id === traitTestTypeId
            ? (value as TestSubType)
            : traitTestType.testSubType,
      };
    });
    changeTraitTestTypesAndRemoveErrors(newTraitTestTypes);
  };

  const validateAddTraitForm = () => {
    return validate(values, {
      name: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
      description: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
    });
  };

  const handleSubmit = () => {
    const newErrors = validateAddTraitForm();

    if (!newErrors) {
      if (!duplicateTestTypeExist(values.traitTestTypes)) {
        onSubmit(values);
      } else {
        setErrors(prevErrors =>
          prevErrors
            ? { ...prevErrors, typeError: 'Test Types can not be duplicated' }
            : undefined
        );
      }
    } else {
      setErrors(newErrors);
    }
  };

  const addTraitTestType = () => {
    setValues(prevValues => ({
      ...prevValues,
      traitTestTypes: [
        ...prevValues.traitTestTypes,
        {
          id: testTypeId.current,
          testType: TestType.TalentReview,
        },
      ],
    }));
    testTypeId.current = testTypeId.current + 1;
  };

  const removeTraitTestType = (traitTestTypeId: number) => {
    setValues(prevValues => ({
      ...prevValues,
      traitTestTypes: prevValues.traitTestTypes.filter(
        traitTestType => traitTestType.id !== traitTestTypeId
      ),
    }));
  };

  const key = 'tf';
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.container}
    >
      <Grid item className={classes.titleWrapper}>
        <TraitFormHeader trait={trait} isEditMode={isEditMode} />
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-name`}
          name="name"
          label="Name"
          onChange={handleChangeName}
          value={values.name}
          errorText={errors?.nameError}
          hasError={!!errors?.nameError}
        />
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-description`}
          name="description"
          label="Description"
          onChange={handleChangeDescription}
          value={values.description}
          errorText={errors?.descriptionError}
          hasError={!!errors?.descriptionError}
        />
      </Grid>
      <Box m={2}>
        <Button onClick={addTraitTestType}>Add</Button>
      </Box>
      {values?.traitTestTypes?.map(traitTestType => (
        <Box m={1} key={traitTestType.id}>
          <Grid container>
            <Grid
              item
              key={traitTestType.id}
              xs={traitTestType.testType === TestType.Cognitive ? 4 : 8}
            >
              <TestTypeSelectFormField
                id={`${key}-type`}
                name={`testType-${traitTestType.id}`}
                label="Test Type"
                onChange={handleChangeTestType}
                value={traitTestType.testType}
                errorText={errors?.typeError}
                hasError={!!errors?.typeError}
              />
            </Grid>
            {traitTestType.testType === TestType.Cognitive &&
              traitTestType.testSubType && (
                <Grid item key={traitTestType.id} xs={4}>
                  <TestSubTypeSelectFormField
                    id={`${key}-type`}
                    name={`testSubType-${traitTestType.id}`}
                    label="Test SubType"
                    onChange={handleChangeTestSubType}
                    value={traitTestType.testSubType}
                    errorText={errors?.typeError}
                    hasError={!!errors?.typeError}
                    selectedTestType={traitTestType.testType}
                  />
                </Grid>
              )}
            <Grid item xs={4}>
              <Button onClick={() => removeTraitTestType(traitTestType.id)}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Grid className={classes.buttonWrapper} item>
        <Button onClick={handleSubmit}>Save</Button>
      </Grid>
    </Grid>
  );
};

export default TraitForm;
