// import DevelopmentCheck from 'components/organisms/DevelopmentCheck/DevelopmentCheck';
import AppBar from 'navigation/AppbarNavigation/AppBar/AppBar';
import RootSwitch from 'navigation/RootSwitch';
import { FC } from 'react';

interface IRoot {}

const Root: FC<IRoot> = () => {
  return (
    <>
      {/* <DevelopmentCheck /> */}
      <AppBar />
      <RootSwitch />
    </>
  );
};

export default Root;
