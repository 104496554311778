import Button from 'components/atoms/Button/Button';
import { useRespondantFormDeleteMutation } from 'generated/graphql';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export interface IResetForm {
  doneBy: number;
  stageCandidateId: number;
}

const ResetForm: FC<IResetForm> = ({ doneBy, stageCandidateId }) => {
  const { handleMsgType } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteRespondantForm] = useRespondantFormDeleteMutation({
    onCompleted: () => {
      setIsLoading(false);
      handleMsgType({
        type: TNotification.success,
        message: 'The form has been reset successfully!',
      });
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        message: 'Error while resetting the form!',
      });
      setIsLoading(false);
    },
  });

  const onReset = () => {
    setIsLoading(true);
    deleteRespondantForm({
      variables: {
        id: doneBy,
        associatedId: stageCandidateId,
      },
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#f2f2f2',
      }}
    >
      <p>
        <strong>Reset Form</strong>
      </p>
      <div style={{ width: 550, display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ marginLeft: 10 }}>
          <Button isDisabled={isLoading} onClick={onReset}>
            {isLoading ? 'Resetting' : 'Reset'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetForm;
