import {
  useWidgetOptionUploadBrandedSoftSkillImageMutation,
  useWidgetOptionUploadCoverPageImageMutation,
  WidgetType,
} from 'generated/graphql';
import { TWidgetOptions } from 'interfaces/widget';
import { cloneDeep } from 'lodash';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import WidgetConfigImageUploadField from '../WidgetConfigImageUploadField/WidgetConfigImageUploadField';
import {
  UploadFieldAsset,
  WidgetImageUploadFunction,
} from '../WidgetConfigModal.interfaces';
import styles from './WidgetUploadFieldDisplay.module.scss';

interface ImageUploadProps {
  uploadFieldAssets: UploadFieldAsset[];
  widget: TWidgetOptions & { type?: WidgetType };
  updateWidgetInfo: (
    newAssetValues: UploadFieldAsset[],
    newWidgetData: TWidgetOptions
  ) => void;
}

const WidgetUploadFieldDisplay = ({
  uploadFieldAssets,
  widget,
  updateWidgetInfo,
}: ImageUploadProps) => {
  const { handleMsgType } = useNotification();
  const [
    uploadBrandedCoverImageMutation,
    { loading: loadingCover },
  ] = useWidgetOptionUploadCoverPageImageMutation({
    context: {
      isUpload: true,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  //A Branded Cover image requires a specific mutation for an update and specific image dimensions. At the moment the only other image upload type is for the Merlin Skill Feedback so this check is enough to determine which mutation to use
  const isUploadBrandedCoverImageField =
    widget?.type === WidgetType.BrandedCoverPage;

  const [
    uploadBrandedSoftSkillImageMutation,
    { loading: loadingSoftSkill },
  ] = useWidgetOptionUploadBrandedSoftSkillImageMutation({
    context: {
      isUpload: true,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const assetIndex = uploadFieldAssets.findIndex(
    asset => asset.widgetId === widget.id
  );

  //Branded cover image dimensions are '1200 x 550'; Merlin Skill Feedback images are '190 x 190'
  const imageDimensions = isUploadBrandedCoverImageField
    ? '1200 x 550'
    : '190 x 190';

  const handleUploadImage: WidgetImageUploadFunction = file => {
    const updateWidget = (id: string | null, url: string | null) => {
      const tmp = cloneDeep(uploadFieldAssets);
      const newSettingsValue = id ? `{"blobId": ${id}}` : '';
      tmp[assetIndex] = { ...tmp[assetIndex], id: id, url: url };
      updateWidgetInfo(tmp, { ...widget, settings: newSettingsValue });
    };

    if (!file) {
      updateWidget(null, null);
      return;
    }

    if (isUploadBrandedCoverImageField) {
      //A Branded Cover image update
      uploadBrandedCoverImageMutation({
        variables: { file: file },
        onCompleted: data => {
          const { id, url } = data.WidgetOptionUploadCoverPageImage;
          updateWidget(id, url);
        },
      });
    } else {
      //A Skill Feedback image update
      uploadBrandedSoftSkillImageMutation({
        variables: { file: file },
        onCompleted: data => {
          const { id, url } = data.WidgetOptionUploadBrandedSoftSkillImage;
          updateWidget(id, url);
        },
      });
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>{widget.question}</p>
      {!(loadingCover || loadingSoftSkill) ? (
        <WidgetConfigImageUploadField
          imageDimensions={imageDimensions}
          onUpload={handleUploadImage}
          image={uploadFieldAssets[assetIndex].url}
          id={uploadFieldAssets[assetIndex].widgetId}
        />
      ) : (
        <Loader variant="bubbles" />
      )}
    </div>
  );
};

export default WidgetUploadFieldDisplay;
