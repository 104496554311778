import { FC, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { ModalTypes } from '../../enums/modalTypes';
import { IgCategory, useIgCategoriesQuery } from '../../generated/graphql';
import { openModal } from '../../graphql/modals';
import { TTextFromFieldValue } from '../../interfaces/forms/form';
import ReportHeader from '../Reports/ReportList/ReportHeader/ReportHeader';
import CategoriesTable from './CategoriesTable/CategoriesTable';

interface IInterviewGuidesCategories {}

const InterviewGuidesCategories: FC<IInterviewGuidesCategories> = () => {
  const headerData = ['Internal Name', 'No Interview Guides', 'Updated At', ''];
  const { data, error, loading } = useIgCategoriesQuery();

  const [search, setSearch] = useState<string>('');
  const [isArchivedVisible, setIsArchivedVisible] = useState<boolean>(false);
  const handleChangeSearch = (value: TTextFromFieldValue) => {
    setSearch(value);
  };

  const toggleArchivedProjectsVisibility = (value: boolean) => {
    setIsArchivedVisible(value);
  };

  const handleAddCategoryModal = () => {
    openModal(ModalTypes.ADD_CATEGORY_MODAL, {});
  };

  if (error) {
    return (
      <div>
        <label>{error.message}</label>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (data) {
    const { IgCategories } = data;
    let parsedIgCategories = [...(IgCategories as IgCategory[])];
    parsedIgCategories = parsedIgCategories.filter((category: IgCategory) => {
      if (!isArchivedVisible && category.isArchived) return false;
      if (search)
        return [category.internalName].some(internalName =>
          internalName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      return true;
    });
    return (
      <Grid container>
        <ReportHeader
          isArchivedVisible={isArchivedVisible}
          toggleArchivedProjectsVisibility={toggleArchivedProjectsVisibility}
          search={search}
          setSearch={handleChangeSearch}
          handleClick={handleAddCategoryModal}
          headerTitle="Categories list"
          buttonText="ADD Category"
        />
        <CategoriesTable
          headerData={headerData}
          tableBodyData={parsedIgCategories}
        />
      </Grid>
    );
  }
  return null;
};

export default InterviewGuidesCategories;
