import { ModalTypes } from 'enums/modalTypes';
import { Formik } from 'formik';
import {
  CompanyEmployeeModel,
  CompanyMaybeUserEmployeeFindManyDocument,
  CompanyMaybeUserEmployeeModel,
  useCompanyEmployeeCreateMutation,
  useCompanyEmployeeUpdateMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import * as yup from 'yup';
import { EditHRManagerEmployeeIDFormFields } from './EditHRManagerEmployeeIDFormFields';

interface IEditHRManagerEmployeeIDFromValues {
  firstName: string;
  lastName: string;
  email: string;
  employeeId: string;
}

const validationSchema = yup.object().shape({
  employeeId: yup.string().required().min(2, 'is too short'),
});

interface IEditHRManagerEmployeeIDForm {
  hrManagerEmployee: CompanyMaybeUserEmployeeModel;
  hrManagerCompanyId: number;
  onCompleted?: (hrManager: CompanyEmployeeModel) => void;
}

const EditHRManagerEmployeeIDForm: FC<IEditHRManagerEmployeeIDForm> = ({
  hrManagerEmployee: { firstName, lastName, email, isCompanyEmployee, userId },
  hrManagerCompanyId,
  onCompleted,
}) => {
  const [companyEmployeeCreate] = useCompanyEmployeeCreateMutation({
    refetchQueries: [
      {
        query: CompanyMaybeUserEmployeeFindManyDocument,
        variables: { companyId: hrManagerCompanyId },
      },
    ],
    onCompleted: data => {
      if (data) {
        onCompleted?.(data.CompanyEmployeeCreate);
      }
    },
  });

  const [companyEmployeeUpdate] = useCompanyEmployeeUpdateMutation({
    refetchQueries: [
      {
        query: CompanyMaybeUserEmployeeFindManyDocument,
        variables: { companyId: hrManagerCompanyId },
      },
    ],
    onCompleted: data => {
      if (data) {
        onCompleted?.(data.CompanyEmployeeUpdate);
      }
    },
  });

  const handleSubmit = async (values: IEditHRManagerEmployeeIDFromValues) => {
    try {
      if (isCompanyEmployee) {
        await companyEmployeeUpdate({
          variables: {
            id: userId as number,
            employeeId: values.employeeId,
          },
        });
      } else {
        await companyEmployeeCreate({
          variables: {
            firstName,
            lastName,
            companyId: hrManagerCompanyId,
            email,
            employeeId: values.employeeId,
          },
        });
      }
      closeModal(ModalTypes.EDIT_HR_MANAGER_EMPLOYEE_ID_MODAL);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik<IEditHRManagerEmployeeIDFromValues>
      onSubmit={handleSubmit}
      initialValues={{
        firstName,
        lastName,
        email,
        employeeId: '',
      }}
      validationSchema={validationSchema}
    >
      <EditHRManagerEmployeeIDFormFields />
    </Formik>
  );
};

export default EditHRManagerEmployeeIDForm;
