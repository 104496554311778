import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import CompanySelectFormField from '../../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import SwitchFormField from '../../../../components/molecules/SwitchFormField/SwitchFormField';
import { FormType } from '../../../../enums/forms/form';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

interface ICompanyRequirementsHeader {
  isArchivedVisible: boolean;
  companyIdFilter: string;
  formFilter: FormType;
  onToggleIsArchivedVisibility: () => void;
  onChangeCompanyIdFilter: (
    companyId: TSelectFormFieldValue,
    name: string
  ) => void;
  onFormFilterChange: (
    newFormFilter: TSelectFormFieldValue,
    name: string
  ) => void;
}

const formFilterOptions = [
  {
    label: FormType.CI_FORM,
    value: FormType.CI_FORM,
  },
  {
    label: FormType.CD_FORM,
    value: FormType.CD_FORM,
  },
  {
    label: FormType.TR_FORM,
    value: FormType.TR_FORM,
  },
  {
    label: FormType.RS_FORM,
    value: FormType.RS_FORM,
  },
  {
    label: FormType.APPRENTICE_FORM,
    value: FormType.APPRENTICE_FORM,
  },
  {
    label: FormType.GRAD_FORM,
    value: FormType.GRAD_FORM,
  },
  {
    label: FormType.UNDERGRAD_FORM,
    value: FormType.UNDERGRAD_FORM,
  },
];

const CompanyRequirementsHeader: FC<ICompanyRequirementsHeader> = ({
  isArchivedVisible,
  companyIdFilter,
  formFilter,
  onFormFilterChange,
  onToggleIsArchivedVisibility,
  onChangeCompanyIdFilter,
}) => {
  const handleOpenCreateCandidateInfoFieldModal = () => {
    openModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL, {
      companyId: +companyIdFilter,
      formFilter,
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        xs={6}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">Company requirements</Typography>
        </Grid>
        <Grid item xs={5}>
          <CompanySelectFormField
            id="crh-company"
            name="companyId"
            label="Company"
            value={companyIdFilter}
            onChange={onChangeCompanyIdFilter}
          />
        </Grid>
        <Grid item>
          <SelectFormField
            id="crh-form"
            name="form"
            label="Form"
            value={formFilter}
            onChange={onFormFilterChange}
            options={formFilterOptions}
            hasNullValue={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        xs={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <SwitchFormField
            id="toggle-displey-archived-form-fields"
            name="isArchivedVisible"
            color="primary"
            value={isArchivedVisible}
            label="display / hide archived fields"
            onChange={onToggleIsArchivedVisibility}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleOpenCreateCandidateInfoFieldModal}
            color="inherit"
          >
            Add new
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyRequirementsHeader;
