import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Snackbar from '../../../components/atoms/Snackbar/Snackbar';
import Modal from '../../../components/molecules/Modal/Modal';
import CompanySelectFormField from '../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import RoleSelectFormField from '../../../components/molecules/SelectFormField/RoleSelectFormField/RoleSelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IdentityPermissionRole,
  UserDocument,
  UserRole,
  useUserRoleCreateMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { TAddNewUserRoleModalProps } from '../../../interfaces/modal';
import { ISnackBar } from '../../../interfaces/snackBar';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';

interface IAddUserRolePayload {
  company?: TSelectFormFieldValue;
  role?: IdentityPermissionRole;
  externalId: string;
}
interface IAddUserRoleError {
  company: string | undefined;
  role: TSelectFormFieldValue;
  externalId: string;
}

const AddNewUserRoleModal: FC<TAddNewUserRoleModalProps> = ({
  userInfo,
  accessedFromCompany,
  currentCompanyId,
  isSuperAdmin,
}) => {
  const { handleMsgType } = useNotification();

  const classes = useReportModalStyle();
  const initialValue = {
    company: accessedFromCompany
      ? userInfo.roles[0].companyRole?.companyId.toString()
      : '',
    role: '',
    externalId: '',
  };
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const [roleErrors, setRoleErrors] = useState({
    company: '',
    role: '',
  });

  const [userRoleInfo, setUserRoleInfo] = useState<IAddUserRolePayload>({
    externalId: '',
    company: accessedFromCompany
      ? userInfo?.roles[0].companyRole?.companyId.toString()
      : '',
  });

  const [errors, setErrors] = useState<IAddUserRoleError>(initialValue);

  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_NEW_USER_ROLE_MODAL);
  };

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setUserRoleInfo(prev => ({ ...prev, [name]: value }));
    if (errors.company) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, company: '' } : prevErrors
      );
    }
  };

  const [userAddRoleMutation, { error }] = useUserRoleCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'role successfully added' });
      handleCloseModal();
    },
    refetchQueries: [
      {
        query: UserDocument,
        variables: {
          id: userInfo.id,
        },
      },
    ],
  });

  const addUserRoleFormValidation = (newValues: IAddUserRolePayload) =>
    validate(newValues, {
      company: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
      role: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
    });

  const onSubmitUserRole = () => {
    const roleError = addUserRoleFormValidation(userRoleInfo);
    if (!_.isEmpty(roleError)) return setRoleErrors;
    if (userRoleInfo?.role && userRoleInfo?.company) {
      const variables = {
        userId: userInfo.id,
        companyId: Number(userRoleInfo?.company),
        role: userRoleInfo?.role,
        externalId:
          userRoleInfo.externalId === '' ? null : userRoleInfo.externalId,
      };
      userAddRoleMutation({ variables });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  const filterCurrentCompanyRoles = (roles: UserRole[]): UserRole[] => {
    return roles.filter(
      role => role?.companyRole?.companyId !== currentCompanyId
    );
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            Add New User Role
          </Typography>
        </Grid>
        {!accessedFromCompany && (
          <Grid item className={classes.textfieldContainer}>
            <CompanySelectFormField
              id="companyFormField"
              name="company"
              label="Company"
              value={userRoleInfo.company}
              onChange={handleChange}
              className={classes.mb10}
              hasError={!!roleErrors?.company}
              errorText={roleErrors?.company}
            />
          </Grid>
        )}

        <Grid item className={classes.textfieldContainer}>
          <RoleSelectFormField
            value={userRoleInfo.role}
            onChange={handleChange}
            className={classes.mb10}
            hasError={!!roleErrors?.role}
            errorText={roleErrors?.role}
            existingRoles={
              accessedFromCompany
                ? filterCurrentCompanyRoles(userInfo.roles)
                : null
            }
            isSuperAdmin={isSuperAdmin}
            filterForIdentity={true}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="externalId"
            name="externalId"
            label="External Id"
            value={userRoleInfo.externalId}
            onChange={handleChange}
            className={classes.mb10}
          />
        </Grid>
        {error?.message && (
          <p style={{ color: 'red', marginBottom: '5px' }}>An error occured</p>
        )}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={onSubmitUserRole}
          >
            save
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </Modal>
  );
};

export default AddNewUserRoleModal;
