import { FC } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { Grid, Typography } from '@mui/material';
import { Report } from '../../../../../generated/graphql';
import { IReportWidget } from '../../../../../interfaces/report';
import { TWidgetOptions } from '../../../../../interfaces/widget';
import UsedReportWidget from './UsedReportWidget/UsedReportWidget';
import useReportStyles from './UsedReportWidgetStyles';

interface IReportWidgetDnD {
  usedWidgetItems?: IReportWidget[];
  availableWidget?: IReportWidget[];
  reportId?: number;
  report: Report | undefined;
  getModalWidgetInfo: (
    updatedWidgetOption: TWidgetOptions[],
    dndId?: number
  ) => void;
  reOrderWidget: (item: IReportWidget[]) => void;
  getNewReportConfig: (reportConfig: IReportWidget) => void;
  getNewPageBeforeValue: (
    value: boolean,
    reportConfig: IReportWidget,
    index: number
  ) => void;
  removeReportConfig: (reportConfig: IReportWidget, index: number) => void;
}

export const reorder = (
  list: IReportWidget[],
  startIndex: number,
  endIndex: number
): IReportWidget[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ReportDnD: FC<IReportWidgetDnD> = ({
  availableWidget,
  usedWidgetItems,
  report,
  reportId,
  getModalWidgetInfo,
  reOrderWidget,
  getNewReportConfig,
  removeReportConfig,
  getNewPageBeforeValue,
}) => {
  const classes = useReportStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (usedWidgetItems) {
      const tempItems = reorder(
        usedWidgetItems,
        result.source.index,
        result.destination.index
      );
      reOrderWidget(tempItems);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.container}>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            Widgets used
          </Typography>
        </Grid>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided) => (
            <div
              className={classes.droppableContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {usedWidgetItems?.map((item: IReportWidget, index: number) => (
                <Draggable
                  key={item.dndId}
                  draggableId={`${item.dndId}`}
                  index={index}
                >
                  {(providedDraggable: DraggableProvided) => (
                    <div>
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {item && (
                          <UsedReportWidget
                            item={item}
                            index={index}
                            isUsedWidget={true}
                            report={report}
                            reportId={reportId}
                            getNewPageBeforeValue={getNewPageBeforeValue}
                            getModalWidgetInfo={getModalWidgetInfo}
                            getNewReportConfig={getNewReportConfig}
                            removeReportConfig={removeReportConfig}
                            usedWidgetItems={usedWidgetItems}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            Widgets available
          </Typography>
        </Grid>
        {availableWidget?.map(
          (item, index) =>
            item && (
              <UsedReportWidget
                key={`${item.id}`}
                item={item}
                index={index}
                isUsedWidget={false}
                report={report}
                reportId={reportId}
                getModalWidgetInfo={getModalWidgetInfo}
                getNewPageBeforeValue={getNewPageBeforeValue}
                getNewReportConfig={getNewReportConfig}
                removeReportConfig={removeReportConfig}
              />
            )
        )}
      </div>
    </DragDropContext>
  );
};

export default ReportDnD;
