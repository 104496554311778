import { useRef, useState } from 'react';
import {
  BlobFindByNameQueryVariables,
  useBlobFindByNameLazyQuery,
} from 'generated/graphql';
import { usePolling } from 'helpers/polling';
import { Maybe } from 'interfaces/maybe';

const POLLING_TIMEOUR_ERROR_MESSAGE = 'Request timed out, please try again.';
const POLLING_FAILED_ERROR_MESSAGE = 'Request failed.';

type TUsePollingBlobFindByNameQueryInput = {
  pollingInterval?: number;
  pollingTimeoutInterval?: number;
};

export const usePollingBlobFindByNameQuery = (
  input?: Maybe<TUsePollingBlobFindByNameQueryInput>
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const variablesRef = useRef<null | BlobFindByNameQueryVariables>();

  const [
    blobFindByNameQuery,
    blobFindByNameQueryResponse,
  ] = useBlobFindByNameLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data.Blob) {
        if (data.Blob.url) {
          window.open(data.Blob.url);
        }
        endPolling();
      }
    },
    onError: error => {
      setErrorMessage(POLLING_FAILED_ERROR_MESSAGE);
      endPolling();
    },
  });

  const pollingFunction = () => {
    if (variablesRef.current) {
      blobFindByNameQuery({
        variables: variablesRef.current,
      });
    }
  };

  const onPollingTimeOut = () => {
    setErrorMessage(POLLING_TIMEOUR_ERROR_MESSAGE);
  };

  const { startPolling, endPolling, isPolling } = usePolling({
    func: pollingFunction,
    onTimeOut: onPollingTimeOut,
    pollingInterval: input?.pollingInterval,
    pollingTimeoutInterval: input?.pollingTimeoutInterval,
  });

  const onStartPolling = (variables: BlobFindByNameQueryVariables) => {
    variablesRef.current = variables;
    startPolling();
    setErrorMessage('');
  };

  return {
    startPolling: onStartPolling,
    response: blobFindByNameQueryResponse,
    isPolling,
    errorMessage,
  };
};
