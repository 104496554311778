import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CircularLoader from '../../../components/molecules/CircularLoader/CircularLoader';
import {
  ApiKeyOwnerType,
  useApiKeyFindAllQuery,
  useCompaniesQuery,
} from '../../../generated/graphql';
import ApiKeysCompanyList from './ApiKeysCompanyList/ApiKeysCompanyList';

interface IApiKeysCompany {}

const ApiKeysCompany: FC<IApiKeysCompany> = () => {
  const { handleMsgType } = useNotification();

  const apiKeysQueryResponse = useApiKeyFindAllQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  // This component requires companies in the cache.
  // (*check src/views/ApiKeys/ApiKeysCompany/ApiKeysCompanyList/ApiKeysCompanyListItem/ApiKeysCompanyListItem.tsx)
  const companiesQueryResponse = useCompaniesQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const renderContent = () => {
    if (apiKeysQueryResponse.error) {
      return <div>Error: {apiKeysQueryResponse.error.message}</div>;
    }

    if (companiesQueryResponse.error) {
      return <div>Error: {companiesQueryResponse.error.message}</div>;
    }

    if (apiKeysQueryResponse.data && companiesQueryResponse.data) {
      const companiesApiKeys = apiKeysQueryResponse.data.ApiKeyFindAll.filter(
        apiKey => apiKey.ownerType === ApiKeyOwnerType.Company
      );

      return <ApiKeysCompanyList apiKeys={companiesApiKeys} />;
    }

    return <CircularLoader />;
  };

  return (
    <div
      style={{
        padding: '30px 30px 30px 260px',
      }}
    >
      <h3 style={{ marginBottom: '30px' }}>Company api keys</h3>
      {renderContent()}
    </div>
  );
};

export default ApiKeysCompany;
