import Icon from 'components/atoms/Icon';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useField } from 'formik';
import { useTechnicalSkillSearchQuery } from 'generated/graphql';
import { pullAllBy, uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import {
  SelectFormField,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import styles from './SearchInputTechSkill.module.scss';

interface ISearchInputTechSkill {
  index: number;
}

// TODO: Refactor this component.
export const SearchInputTechSkill: FC<ISearchInputTechSkill> = ({ index }) => {
  const timeout = useRef<NodeJS.Timeout>();
  const firstLoad = useRef(true);

  const [options, setOptions] = useState<TSelectOption[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  const onInputChange = useCallback((text: string) => setInput(text), []);

  const { data } = useTechnicalSkillSearchQuery({
    fetchPolicy: 'network-only',
    variables: {
      searchText: searchText,
    },
  });

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon icon="search" className="search-icon" />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    setLoading(true);
    setOptions([]);

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(
      () => {
        setSearchText(input);
        firstLoad.current = false;
      },
      firstLoad.current ? 0 : 500
    );

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [input]);

  const [field, meta, helpers] = useField<TSelectOption>(
    `technicalSkills.${index}`
  );

  useEffect(() => {
    if (data) {
      const newOptions =
        data?.TechnicalSkillFreeTextSearchPaginated?.technicalSkills.map(o => ({
          value: `${o.id}`,
          label: o.name as string,
        })) || [];

      setLoading(false);
      // We need this field value to be able to display selected option if it is not in the list
      // We have to filter it to remove doubles
      // Have to remove default value
      setOptions(
        pullAllBy(
          uniqBy([field.value, ...newOptions], 'value'),
          [{ value: '0' }],
          'value'
        )
      );
    }
  }, [data, field.value]);

  const handleChange = (newValues: TSelectOption) => {
    helpers.setValue(newValues);
  };

  const hasError = Boolean(meta.error && meta.touched);
  const error = (meta.error as unknown) as
    | { label: string; value: string }
    | undefined;

  return (
    <div
      className={styles.searchInputTechSkill}
      data-testid="search-input-tech-skill"
    >
      {/* Temp styles have to add grid for the version 2 */}
      <SelectFormField
        value={field.value}
        onChange={handleChange}
        hasError={hasError}
        bottomText={hasError ? error?.value : ''}
        // @ts-ignore
        onInputChange={onInputChange}
        components={{ DropdownIndicator }}
        hasClearIndicator={false}
        placeholder="Options"
        options={options}
        isLoading={loading}
        id={`technicalSkills.${index}.value`}
        label="Name"
        maxMenuHeight={240}
      />
    </div>
  );
};
