import { CompanyProjectsFragment } from 'generated/graphql';
import { FC } from 'react';
import CandidateUsageTableCell from './CandidateUsageTableCell/CandidateUsageTableCell';

interface ICandidateUsageTable {
  companyProjects: CompanyProjectsFragment[];
}

const CandidateUsageTable: FC<ICandidateUsageTable> = ({ companyProjects }) => {
  return (
    <div className="candidate-usage-table" data-testid="candidate-usage-table">
      <div className="candidate-usage-table__description">
        * Please note that the set of new and completed candidates may not be
        the same (e.g. a candidate was created outside of this time range, but
        completed in the time range)
      </div>
      <div className="candidate-usage-table__row-container" key="header">
        <CandidateUsageTableCell text="Company" isHeading={true} />
        <CandidateUsageTableCell text="Project" isHeading={true} />
        <CandidateUsageTableCell text="New stage candidates" isHeading={true} />
        <CandidateUsageTableCell
          text="% completion of new stage candidates"
          isHeading={true}
        />
        <CandidateUsageTableCell
          text="Completed stage candidates*"
          isHeading={true}
        />
      </div>
      {companyProjects.map(companyProject => (
        <>
          {companyProject.projects.length > 0 && (
            <div
              className="candidate-usage-table__row-container"
              key={companyProject.id}
              data-testid="only-project-section"
            >
              {companyProject.projects.length === 1 ? (
                <>
                  <CandidateUsageTableCell
                    text={`${companyProject.company.name} (${companyProject.company.id})`}
                  />

                  <CandidateUsageTableCell
                    text={`${companyProject.projects[0].name} (${companyProject.projects[0].id})`}
                  />
                  <CandidateUsageTableCell
                    text={companyProject.projects[0].numberOfStageCandidates.toString()}
                  />
                  <CandidateUsageTableCell
                    text={`${companyProject.projects[0].percentageOfCompletedStageCandidates.toString()}%`}
                  />
                  <CandidateUsageTableCell
                    text={companyProject.projects[0].numberOfCompletedInTimeFrameStageCandidates.toString()}
                  />
                </>
              ) : (
                <>
                  <CandidateUsageTableCell
                    text={`${companyProject.company.name} (${companyProject.company.id})`}
                  />
                  <CandidateUsageTableCell text="All Projects" />
                  <CandidateUsageTableCell
                    text={companyProject.numberOfStageCandidates.toString()}
                  />
                  <CandidateUsageTableCell
                    text={`${companyProject.percentageOfCompletedStageCandidates.toString()}%`}
                  />
                  <CandidateUsageTableCell
                    text={companyProject.numberOfCompletedInTimeFrameStageCandidates.toString()}
                  />
                </>
              )}
            </div>
          )}
          {companyProject.projects.length > 1 &&
            companyProject.projects.map(project => {
              return (
                <div
                  className="candidate-usage-table__row-container"
                  key={project.id}
                  data-testid="project-row"
                >
                  <CandidateUsageTableCell text="" />

                  <CandidateUsageTableCell
                    text={`${project.name} (${project.id})`}
                  />
                  <CandidateUsageTableCell
                    text={project.numberOfStageCandidates.toString()}
                  />
                  <CandidateUsageTableCell
                    text={`${project.percentageOfCompletedStageCandidates.toString()}% `}
                  />
                  <CandidateUsageTableCell
                    text={project.numberOfCompletedInTimeFrameStageCandidates.toString()}
                  />
                </div>
              );
            })}
        </>
      ))}
    </div>
  );
};

export default CandidateUsageTable;
