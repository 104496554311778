import { FC } from 'react';
import { ProjectsDocument, ProjectsQuery } from '../../../../generated/graphql';
import {
  ISelectFormFieldOption,
  TSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import SelectWithQuery from '../SelectWithQuery/SelectWithQuery';

interface IProjectsSelectFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  isArchivedHidden?: boolean;
  className?: string;
}

const ProjectsSelectFormField: FC<IProjectsSelectFormField> = props => {
  const { isArchivedHidden, ...selectElementProps } = props;

  const getOptions = (result: ProjectsQuery) => {
    let parsedProjects = [...result.projects];

    if (!isArchivedHidden) {
      parsedProjects = parsedProjects.filter(project => !project.isArchived);
    }

    return parsedProjects
      .sort((a, b) =>
        a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      )
      .map(project => ({
        label: project.name,
        value: project.id,
      })) as ISelectFormFieldOption[];
  };

  return (
    <SelectWithQuery
      query={ProjectsDocument}
      selectElementProps={selectElementProps}
      getOptionsFromResponse={getOptions}
    />
  );
};

export default ProjectsSelectFormField;
