export const RemoveNonChangeInput = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any,
  defaultValue?: string[]
) => {
  for (let inputName in input) {
    if (option.hasOwnProperty(inputName)) {
      if (
        input[inputName] === option[inputName] &&
        !defaultValue?.includes(inputName)
      )
        delete input[inputName];
    }
  }
};
