import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

interface IContactsListItemField {
  title: string;
  content: string;
  id: string;
  note?: string;
  className?: string;
}

export const ContactsListItemField: FC<IContactsListItemField> = ({
  title,
  content,
  note,
  className,
  id,
}) => (
  <>
    <div
      className={`contacts-list-item-field${className ? ` ${className}` : ''}`}
    >
      <div className="contacts-list-item-field__title">{title}</div>
      <div
        className="contacts-list-item-field__content-wrapper"
        data-for={id}
        data-tip
      >
        <div className="contacts-list-item-field__content">{content}</div>
        {note && <div className="contacts-list-item-field__note">{note}</div>}
      </div>
    </div>
    <ReactTooltip id={id} place="top" effect="solid">
      <div>{content}</div>
      <div>{note}</div>
    </ReactTooltip>
  </>
);
