import { ModalTypes } from 'enums/modalTypes';
import { useMeQuery } from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import CompanyEmployeesList from './CompanyEmployeesList/CompanyEmployeesList';
import useCompanyEmployeesStyle from './CompanyEmployeesStyle';

interface ICompanyEmployees {
  companyId: number;
}

interface ICompany {
  companyId: string;
}

const CompanyEmployees: FC<ICompanyEmployees> = ({ companyId }) => {
  const companyIdParam = (useParams() as ICompany).companyId;
  const { data } = useMeQuery();
  const isSuperAdmin = data?.me?.roles?.some(
    item => item.companyRole.role.name === 'sz_super_admin'
  );
  const classes = useCompanyEmployeesStyle();
  const handleAddCompanyEmployee = () => {
    openModal(ModalTypes.ADD_NEW_USER_MODAL, {
      companyId: companyIdParam,
      isSuperAdmin,
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Typography variant="h5" className={classes.heading}>
          Users
        </Typography>
        <Grid item className={classes.buttonContainer}>
          <Button
            className={classes.button}
            onClick={handleAddCompanyEmployee}
            variant="contained"
            color="primary"
          >
            Add Employee
          </Button>
        </Grid>
      </Grid>

      <CompanyEmployeesList companyId={companyId} />
    </Grid>
  );
};

export default CompanyEmployees;
