import { FC } from 'react';
import SelectWithQuery from '../../../../../components/molecules/SelectFormField/SelectWithQuery/SelectWithQuery';
import {
  FormManagerType,
  FormTypesDocument,
  FormTypesQuery,
} from '../../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../../interfaces/forms/form';

export interface IFormTypesSelectFormFieldWrapper {
  id: string;
  name: string;
  label: string;
  hasError?: boolean;
  errorText?: string;
  value: TSelectFormFieldValue;
  selectedFormManagerType?: FormManagerType;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
}

const FormTypesSelectFormFieldWrapper: FC<IFormTypesSelectFormFieldWrapper> = ({
  selectedFormManagerType,
  ...restProps
}) => {
  const getOptionsFromResponse = (response: FormTypesQuery) => {
    return response?.FormReportFindFormTypes?.formTypes
      ? response.FormReportFindFormTypes?.formTypes.map(type => ({
          label: type,
          value: type,
        }))
      : [];
  };

  const isEmptySelectVisible = Boolean(selectedFormManagerType);

  return (
    <SelectWithQuery
      query={FormTypesDocument}
      queryOptions={{
        variables: {
          formManagerType: selectedFormManagerType,
        },
      }}
      getOptionsFromResponse={getOptionsFromResponse}
      isEmptySelectVisible={isEmptySelectVisible}
      selectElementProps={restProps}
    />
  );
};

export default FormTypesSelectFormFieldWrapper;
