import { ModalTypes } from 'enums/modalTypes';
import { useTraitSoftSkillContextCsvGenerateAndUploadQuery } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ExportContentModal from '../ExportContentModal/ExportContentModal';

export interface IBehaviourContentModal {}
const BehaviourContentModal: FC<IBehaviourContentModal> = () => {
  const { handleMsgType } = useNotification();

  const handleCloseModal = () => {
    closeModal(ModalTypes.BEHAVIOUR_CONTENT_MODAL);
  };

  const {
    data,
    loading,
    error,
    refetch,
  } = useTraitSoftSkillContextCsvGenerateAndUploadQuery({
    fetchPolicy: 'network-only',
    variables: {
      ids: [],
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error.message });
    },
  });

  const handleConfirmButtonClick = () => {
    if (error) {
      refetch({
        ids: [],
      });
    } else {
      handleCloseModal();
      window.open(
        data?.TraitSoftSkillContextCsvGenerateAndUpload?.url,
        '_self'
      );
      handleMsgType({
        type: TNotification.success,
        message: 'Download started',
      });
    }
  };

  return (
    <ExportContentModal
      handleCloseModal={handleCloseModal}
      error={error}
      handleConfirmButtonClick={handleConfirmButtonClick}
      loading={loading}
      url={data?.TraitSoftSkillContextCsvGenerateAndUpload?.url}
    />
  );
};

export default BehaviourContentModal;
