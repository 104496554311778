import { gql } from '@apollo/client';

export const FIELD_QUERY = gql`
  query field($id: Int!) {
    field: Field(id: $id) {
      id
      fieldType
      formManagerType
      fieldInstanceType
      name
      question
      ... on CompanyEmployeeSelectField {
        dynamicSelectOptions
      }
      ... on MultiSelectField {
        selectOptions
      }
      ... on SingleSelectField {
        selectOptions
      }
      isArchived
      txtData
      fieldOwnerId
      fieldOwnerType
      fieldOwnerName
      hint
      settings {
        allowFreeText
        searchable
      }
    }
  }
`;

export const FIELDS_QUERY = gql`
  query fields(
    $fieldType: FieldType
    $formManagerType: FormManagerType
    $name: String
    $question: String
    $isArchived: Boolean
  ) {
    fields: Fields(
      fieldType: $fieldType
      formManagerType: $formManagerType
      name: $name
      question: $question
      isArchived: $isArchived
    ) {
      id
      fieldType
      formManagerType
      fieldInstanceType
      name
      question
      ... on CompanyEmployeeSelectField {
        dynamicSelectOptions
      }
      ... on MultiSelectField {
        selectOptions
      }
      ... on SingleSelectField {
        selectOptions
      }
      isArchived
      txtData
      fieldOwnerId
      fieldOwnerType
      fieldOwnerName
    }
  }
`;

export const FIELD_CREATE_MUTATION = gql`
  mutation fieldCreate(
    $fieldType: FieldType!
    $formManagerType: FormManagerType!
    $fieldInstanceType: FieldInstanceType!
    $name: String!
    $question: String
    $selectOptions: String
    $isArchived: Boolean
    $fieldOwnerType: FieldOwnerType!
    $fieldOwnerId: Int
    $hint: String
    $settings: FieldSettingsArgs
  ) {
    field: FieldCreate(
      fieldType: $fieldType
      formManagerType: $formManagerType
      fieldInstanceType: $fieldInstanceType
      name: $name
      question: $question
      selectOptions: $selectOptions
      isArchived: $isArchived
      fieldOwnerType: $fieldOwnerType
      fieldOwnerId: $fieldOwnerId
      hint: $hint
      settings: $settings
    ) {
      id
      fieldType
      formManagerType
      fieldInstanceType
      name
      question
      ... on CompanyEmployeeSelectField {
        dynamicSelectOptions
      }
      ... on MultiSelectField {
        selectOptions
      }
      ... on SingleSelectField {
        selectOptions
      }
      isArchived
      txtData
      fieldOwnerId
      fieldOwnerType
      fieldOwnerName
      hint
      settings {
        allowFreeText
        searchable
      }
    }
  }
`;

export const FIELD_DELETE_MUTATION = gql`
  mutation fieldDelete($id: Int!) {
    fieldDelete: FieldDelete(id: $id) {
      affected
    }
  }
`;

export const FIELD_UPDATE_MUTATION = gql`
  mutation fieldUpdate(
    $id: Int!
    $name: String
    $question: String
    $selectOptions: String
    $isArchived: Boolean
    $hint: String
    $settings: FieldSettingsArgs
  ) {
    field: FieldUpdate(
      id: $id
      name: $name
      question: $question
      selectOptions: $selectOptions
      isArchived: $isArchived
      hint: $hint
      settings: $settings
    ) {
      id
      fieldType
      formManagerType
      fieldInstanceType
      name
      question
      ... on CompanyEmployeeSelectField {
        dynamicSelectOptions
      }
      ... on MultiSelectField {
        selectOptions
      }
      ... on SingleSelectField {
        selectOptions
      }
      isArchived
      txtData
      fieldOwnerId
      fieldOwnerType
      fieldOwnerName
      hint
      settings {
        allowFreeText
        searchable
      }
    }
  }
`;
