import {
  JobRoleOverviewFragment,
  ProjectJobRoleFragment,
} from 'generated/graphql';
import { TSelectOption } from '@spotted-zebra-uk/ui-components';

export function mapJobRolesForSelect(
  jobRole: JobRoleOverviewFragment
): TSelectOption {
  // here we're using latest based on createdAt
  // docs - https://miro.com/app/board/uXjVM1036WA=/?moveToWidget=3458764562485771251&cot=14
  const latestSuccessProfile = getLatestSuccessProfile(jobRole.skillsProfiles);

  return {
    label: jobRole.name,
    value: jobRole.id.toString(),
    labelNote: jobRole?.qualifier || undefined,
    additionalProps: {
      sucessProfileId: latestSuccessProfile.id,
    },
  };
}

export function filterJobRoles(
  jobRoleList: JobRoleOverviewFragment[],
  existingJobRoles: ProjectJobRoleFragment[]
) {
  const excludedJobRolesIds = new Set(
    existingJobRoles.map(jobRole => jobRole.jobRole.id)
  );

  // remove the duplicates (comming from existingJobRoles)
  return jobRoleList.filter(
    jobRole =>
      !excludedJobRolesIds.has(jobRole.id) &&
      // It is required that job role has at least one skills profile
      // because skills profile is required for creating project job role.
      jobRole.skillsProfiles.length
  );
}

type TSuccessProfileOverview = {
  id: number;
  createdAt: string;
};

function getLatestSuccessProfile(skillsProfiles: TSuccessProfileOverview[]) {
  return skillsProfiles.reduce((acc, curr) => {
    return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr;
  });
}
