import { interviewGuidesRoutes } from 'constants/navigation';

export const interviewGuidesSidebarConfig = [
  {
    title: 'Categories',
    path: interviewGuidesRoutes.categories.path,
  },
  {
    title: 'Interview guides',
    path: interviewGuidesRoutes.interviewGuides.path,
  },
];
