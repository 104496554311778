import './styles.scss';
import { FC } from 'react';

interface IResultsTable {
  rows: (string | number)[][];
  headers: string[];
  testKey: string;
}
const ResultsTable: FC<IResultsTable> = ({ rows, headers, testKey }) => {
  return (
    <table className="results-table" data-testid={`${testKey}-table`}>
      <tbody>
        <tr className="results-table__row" data-testid={`${testKey}-row`}>
          {headers.map((header, index) => (
            <th className="results-table__header" key={index}>
              {header}
            </th>
          ))}
        </tr>
        {rows.map((rowData, index) => {
          return (
            <tr
              className="results-table__row"
              key={index}
              data-testid={`${testKey}-row`}
            >
              {rowData.map((data, i) => (
                <td className="results-table__cell" key={i}>
                  {data}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ResultsTable;
