import { CSSProperties, FC } from 'react';
import { Modal as MUIModal } from '@mui/material';
import useModalStyle from './ModalStyle';

interface IModal {
  open?: boolean;
  onClose: () => void;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

const Modal: FC<IModal> = ({ onClose, children, className, style }) => {
  const classes = useModalStyle();

  const paperClassName = `${classes.paper}${className ? ` ${className}` : ''}`;
  return (
    <MUIModal className={classes.modal} open onClose={onClose}>
      <div style={style} className={paperClassName}>
        {children}
      </div>
    </MUIModal>
  );
};

export default Modal;
