import Button from 'components/atoms/Button/Button';
import FileUploadFormField from 'components/molecules/FileUploadFormField/FileUploadFormField';
import { WidgetImageUploadFunction } from '../WidgetConfigModal.interfaces';
import styles from './WidgetConfigImageUploadField.module.scss';

const WidgetConfigImageUploadField = ({
  onUpload,
  image,
  imageDimensions,
  id,
}: {
  onUpload: WidgetImageUploadFunction;
  image: string | null;
  imageDimensions: string;
  id: number;
}) => {
  return (
    <div className={styles.container}>
      {image ? (
        <div className={styles.imageContainer}>
          <img className={styles.image} src={image} alt="Asset" />
          <Button
            color="secondary"
            variant="contained"
            className={styles.btn}
            onClick={() => onUpload(null, id)}
          >
            Delete
          </Button>
        </div>
      ) : (
        <>
          <ul>
            <li>Maximum file size: 2MB</li>
            <li>{`Dimensions: ${imageDimensions}`}</li>
            <li>Accepted file types: .jpg or .png</li>
          </ul>
          <div className={styles.imageUploadContainer}>
            <FileUploadFormField
              id={`widget-asset-${id}`}
              name={`file-${id}`}
              value={null}
              onChange={(file: File) => onUpload(file, id)}
              isFileNameHidden
              label="Upload asset"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetConfigImageUploadField;
