import { makeStyles } from '@mui/styles';

const useFormManagerDownloadCSVStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
}));

export default useFormManagerDownloadCSVStyle;
