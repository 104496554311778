import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import EditHeader from '../../../components/organisms/EditHeader/EditHeader';
import { interviewGuidesRoutes } from '../../../constants/navigation';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IgCategoriesDocument,
  useIgCategoryDeleteOneMutation,
  useIgCategoryUpdateMutation,
} from '../../../generated/graphql';
import { closeModal, openModal } from '../../../graphql/modals';

interface ICategoryArchive {
  isArchived: boolean;
  id: number;
}

const CategoryArchive: FC<ICategoryArchive> = ({ isArchived, id }) => {
  const { handleMsgType } = useNotification();

  const navigate = useNavigate();
  const [deleteCategoryMutation] = useIgCategoryDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: IgCategoriesDocument,
      },
    ],
    onCompleted: () => {
      navigate(interviewGuidesRoutes.categories.url());
    },
  });

  const [categoryUpdateIsArchived] = useIgCategoryUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleCategoryDelete = () => {
    openModal(ModalTypes.CONFIRMATION_MODAL, {
      open: true,
      message: 'Are you sure you want to delete',
      onSave: () => {
        deleteCategoryMutation({ variables: { id } });
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      onClose: () => {
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      saveBtnText: 'Delete',
    });
  };

  const handleCategoryArchive = () => {
    categoryUpdateIsArchived({
      variables: {
        id,
        isArchived: !isArchived,
      },
    });
  };

  return (
    <EditHeader
      isArchived={isArchived}
      handleArchive={handleCategoryArchive}
      handleDelete={handleCategoryDelete}
      title="Edit Category"
    />
  );
};

export default CategoryArchive;
