import { FC } from 'react';
import Button from '../../../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../../../enums/modalTypes';
import { openModal } from '../../../../../../graphql/modals';
import { TAlertTypes } from '../../../../../../interfaces/snackBar';

interface IAddCompanySignature {
  companyId: number;
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  getNewDefaultId: (id: number) => void;
}

const AddCompanySignature: FC<IAddCompanySignature> = ({
  companyId,
  displaySnackbar,
  getNewDefaultId,
}) => {
  const handleAddCompanySignature = () => {
    openModal(ModalTypes.ADD_COMPANY_SIGNATURE_MODAL, {
      companyId,
      displaySnackbar,
      getNewDefaultId,
    });
  };

  return <Button onClick={handleAddCompanySignature}>Add</Button>;
};

export default AddCompanySignature;
