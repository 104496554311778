import { FC, useMemo } from 'react';
import { FormType } from '../../../../enums/forms/form';
import { TFormBuilderGQL } from '../../../../interfaces/forms/formGQL';
import CompanyRequirementsFormBuilder from './CompanyRequirementsFormBuilder/CompanyRequirementsFormBuilder';

interface ICompanyRequriementsContent {
  companyId: number;
  isArchivedVisible: boolean;
  companyRequirementsFormBuilder: TFormBuilderGQL;
  formFilter: FormType;
}

const CompanyRequriementsContent: FC<ICompanyRequriementsContent> = ({
  companyId,
  isArchivedVisible,
  companyRequirementsFormBuilder,
  formFilter,
}) => {
  const parsedFormOwnedFields = useMemo(
    () =>
      isArchivedVisible
        ? companyRequirementsFormBuilder.form.formFields
        : companyRequirementsFormBuilder.form.formFields.filter(
            formField => !formField.field.isArchived
          ),
    [isArchivedVisible, companyRequirementsFormBuilder.form.formFields]
  );

  const parsedAvailableFields = useMemo(
    () =>
      isArchivedVisible
        ? companyRequirementsFormBuilder.availableFields
        : companyRequirementsFormBuilder.availableFields.filter(
            field => !field.isArchived
          ),
    [companyRequirementsFormBuilder.availableFields, isArchivedVisible]
  );

  const archivedFields = useMemo(
    () => [
      ...companyRequirementsFormBuilder.form.formFields
        .filter(formField => formField.field.isArchived)
        .map(formField => ({
          id: formField.field.id,
          name: formField.field.name,
        })),
      ...companyRequirementsFormBuilder.availableFields
        .filter(field => field.isArchived)
        .map(field => ({ id: field.id, name: field.name })),
    ],
    [
      companyRequirementsFormBuilder.availableFields,
      companyRequirementsFormBuilder.form.formFields,
    ]
  );

  return (
    <CompanyRequirementsFormBuilder
      companyRequirementsFormId={companyRequirementsFormBuilder.form.id}
      formOwnedFields={parsedFormOwnedFields}
      availableFields={parsedAvailableFields}
      companyId={companyId}
      archivedFields={archivedFields}
      formFilter={formFilter}
    />
  );
};

export default CompanyRequriementsContent;
