import { ITraitTestType } from '../views/ModalView/TraitModals/TraitForm/TraitForm';

export const duplicateTestTypeExist = (traitTestTypes: ITraitTestType[]): boolean => {
    return traitTestTypes.some(
        (traitTestType, i) =>
            traitTestTypes.findIndex(
                otherTraitTestType =>
                    otherTraitTestType.testType === traitTestType.testType
            ) !== i
    );
};