import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from 'components/atoms/Button/Button';
import {
  MeasurementConstructType,
  UpdateSoftSkillMutationVariables,
} from 'generated/graphql';
import { TEditSoftSkillModal } from 'interfaces/modal';
import SoftSkillForm, {
  ISoftSkillFieldFormValues,
} from '../../SoftSkillForm/SoftSkillForm';
import ConnectedTraits from '../ConnectedTraits/ConnectedTraits';
import useEditSoftSkillModalPresentationalStyle from './EditSoftSkillModalPresentationalStyle';

export interface IEditSoftSkillModalPresentational extends TEditSoftSkillModal {
  handleSubmit: (
    variables: UpdateSoftSkillMutationVariables,
    softSkillIcon: File | null
  ) => void;
}

const EditSoftSkillModalPresentational: FC<IEditSoftSkillModalPresentational> = ({
  softSkill,
  updateFilters,
  handleSubmit,
}) => {
  const classes = useEditSoftSkillModalPresentationalStyle();
  const [isEditModeActive, setEditMode] = useState(false);

  const onSubmit = (values: ISoftSkillFieldFormValues) => {
    const id = softSkill.id;
    const name = values.name ? values.name : softSkill.name;
    const category = values.category ? values.category : softSkill.category;
    const companyId = values.companyId
      ? +values.companyId
      : softSkill.companyId;
    const measurementConstructType = values.measurementConstructType as MeasurementConstructType;

    handleSubmit(
      {
        id,
        name,
        category,
        companyId,
        measurementConstructType,
        description: values.description,
      },
      values.softSkillIcon
    );
  };

  const handleEditModeChange = () => {
    setEditMode(!isEditModeActive);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.container}
      style={{
        maxWidth: '825px',
      }}
    >
      <SoftSkillForm
        onSubmit={onSubmit}
        softSkill={softSkill}
        isEditMode={true}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.container}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid xs={9}>
            <Typography variant="h6">Traits connected to</Typography>
          </Grid>
          <Grid xs={2}>
            <Button onClick={handleEditModeChange}>Edit</Button>
          </Grid>
        </Grid>
        <ConnectedTraits
          softSkillId={softSkill.id}
          isEditModeActive={isEditModeActive}
          updateFilters={updateFilters}
        />
      </Grid>
    </Grid>
  );
};

export default EditSoftSkillModalPresentational;
