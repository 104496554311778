import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { CompanyEmployeeModel } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { Modal } from '@spotted-zebra-uk/ui-components';
import { EmployeeModalMode } from './CompanyEmployeeModal.enums';
import EmployeeForm from './EmployeeForm';

export type ICompanyEmployeeModal = {
  employee?: CompanyEmployeeModel;
  mode: EmployeeModalMode;
  onEmployeeCreated?: (employee: CompanyEmployeeModel) => void;
};

const headerObject = {
  [EmployeeModalMode.CREATE]: 'Create New Employee',
  [EmployeeModalMode.UPDATE]: 'Edit Employee',
  [EmployeeModalMode.DELETE]: 'Delete Employee',
};

const CompanyEmployeeModal: FC<ICompanyEmployeeModal> = ({
  employee,
  mode,
  onEmployeeCreated,
}) => {
  const handleCloseModal = () => {
    closeModal(ModalTypes.COMPANY_EMPLOYEE_MODAL);
  };

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen
      header={headerObject[mode]}
      className="employee-modal"
    >
      <EmployeeForm
        mode={mode}
        employee={employee}
        onEmployeeCreated={onEmployeeCreated}
      />
    </Modal>
  );
};

export default CompanyEmployeeModal;
