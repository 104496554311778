import { FC, useMemo } from 'react';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import { TestSubType, TestType } from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

const optionsObject: {
  [key in TestSubType]: {
    value: TestSubType;
    label: string;
    testType: TestType;
    order: number;
  };
} = {
  [TestSubType.Inductive]: {
    value: TestSubType.Inductive,
    label: 'Inductive',
    testType: TestType.Cognitive,
    order: 1,
  },
  [TestSubType.Numerical]: {
    value: TestSubType.Numerical,
    label: 'Numerical',
    testType: TestType.Cognitive,
    order: 2,
  },
  [TestSubType.Verbal]: {
    value: TestSubType.Verbal,
    label: 'Verbal',
    testType: TestType.Cognitive,
    order: 6,
  },
};

interface ITestSubTypeFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  isDisabled?: boolean;
  selectedTestType?: TestType | null;
}

const TestSubTypeSelectFormField: FC<ITestSubTypeFormField> = ({
  selectedTestType,
  ...selectElementProps
}) => {
  const options = useMemo(
    () =>
      Object.values(optionsObject)
        .filter(option => option.testType === selectedTestType)
        .sort((a, b) => (a.order > b.order ? 1 : 1))
        .map(option => ({ label: option.label, value: option.value })),
    [selectedTestType]
  );

  return <SelectFormField options={options} {...selectElementProps} />;
};

export default TestSubTypeSelectFormField;
