import { ModalTypes } from 'enums/modalTypes';
import {
  RAssignedType,
  ReportAssignmentFindStageReportQuery,
  ReportsFindByCompanyQuery,
  ReportSubType,
  ReportType,
  useReportAssignmentCreateMutation,
  useReportAssignmentDeleteMutation,
  useReportAssignmentFindStageReportQuery,
  useReportAssignmentUpdateMutation,
  useReportsFindByCompanyQuery,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  SelectFormField,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';

const stageAssignmentOption = (
  reportAssignment: ReportAssignmentFindStageReportQuery,
  companyReport: ReportsFindByCompanyQuery
) => {
  const reportAssigned = {
    value: `${reportAssignment?.reports?.id || -1}`,
    label: `**${reportAssignment?.reports?.report.name}`,
  };

  const sZReport =
    companyReport?.ReportsFindByCompany?.filter(
      item =>
        item.ownerId === 1 && item.id !== reportAssignment?.reports?.reportId
    ).map(item => ({ value: item.id.toString(), label: item.name })) || [];

  const companyReportArr =
    companyReport?.ReportsFindByCompany?.filter(
      item =>
        item.ownerId !== 1 && item.id !== reportAssignment?.reports?.reportId
    ).map(item => ({ value: item.id.toString(), label: item.name })) || [];

  let mergedReport: TSelectOption[] = [];
  if (sZReport?.length) {
    mergedReport = [reportAssigned, ...sZReport];
  }

  if (sZReport?.length && companyReportArr) {
    mergedReport = [reportAssigned, ...companyReportArr, ...sZReport];
  }

  return mergedReport;
};

interface ICandidateReportAssignment {
  companyId?: number;
  projectId?: number;
  stageId?: number;
  reportAssignedType: RAssignedType;
  subType: ReportSubType;
  label?: string;
  name: string;
  resetStageData?: boolean;
  checkBoxId?: string;
  selectFormId?: string;
}

const CandidateReportAssignment: FC<ICandidateReportAssignment> = ({
  companyId,
  projectId,
  stageId,
  reportAssignedType,
  subType,
  label,
  name,
  checkBoxId,
  selectFormId,
}) => {
  const [candidateReport, setCandidateReport] = useState<TSelectOption>({
    label: '',
    value: '',
  });

  const candidateReportsFindByCompanyResponse = useReportsFindByCompanyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      type: ReportType.StageResults,
      subType,
      ownerId: Number(companyId),
      includeSharedReports: true,
    },
  });

  const candidateReportAssignmentFindStageReportResponse = useReportAssignmentFindStageReportQuery(
    {
      fetchPolicy: 'no-cache',
      variables: {
        type: ReportType.StageResults,
        subType,
        projectId,
        companyId,
        stageId,
      },
    }
  );
  const candidateCheckValues = Boolean(
    candidateReportAssignmentFindStageReportResponse?.data?.reports
      ?.inheritsSetting
  );

  const candidateStageAssignmentOption = useMemo(() => {
    if (
      !candidateReportAssignmentFindStageReportResponse.data ||
      !candidateReportsFindByCompanyResponse.data
    ) {
      return [];
    }
    return stageAssignmentOption(
      candidateReportAssignmentFindStageReportResponse.data,
      candidateReportsFindByCompanyResponse.data
    );
  }, [
    candidateReportAssignmentFindStageReportResponse.data,
    candidateReportsFindByCompanyResponse.data,
  ]);

  const [reportAssignmentCreateMutation] = useReportAssignmentCreateMutation({
    onError: () => {},
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      candidateReportAssignmentFindStageReportResponse.refetch();
      candidateReportsFindByCompanyResponse.refetch();
      // TODO: Add notification on completed with text:
      // 'report assignment creation successful'
      closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
    },
  });

  const [reportAssignmentUpdateMutation] = useReportAssignmentUpdateMutation({
    onError: () => {},
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      candidateReportAssignmentFindStageReportResponse.refetch();
      candidateReportsFindByCompanyResponse.refetch();
      // TODO: Add notification on completed with text:
      // 'report assignment update successful'
    },
  });

  const [reportAssignmentDeleteMutation] = useReportAssignmentDeleteMutation({
    onError: () => {},
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      candidateReportAssignmentFindStageReportResponse.refetch();
      candidateReportsFindByCompanyResponse.refetch();
      // TODO: Add notification on completed with text:
      // 'report assignment deletion successful'
      closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
    },
  });

  useEffect(() => {
    if (candidateReportAssignmentFindStageReportResponse?.data?.reports) {
      const reports =
        candidateReportAssignmentFindStageReportResponse?.data?.reports;
      setCandidateReport({
        value: reports?.id.toString(),
        label: `**${reports?.report.name}`,
      });
    }
  }, [candidateReportAssignmentFindStageReportResponse?.data?.reports]);

  const handleCandidateChange = (value: TSelectOption) => {
    if (candidateReportAssignmentFindStageReportResponse?.data?.reports?.id) {
      reportAssignmentUpdateMutation({
        variables: {
          assignedId:
            reportAssignedType === 'COMPANY'
              ? Number(companyId)
              : reportAssignedType === 'PROJECT'
              ? Number(projectId)
              : Number(stageId),
          assignedType: reportAssignedType,
          reportId: Number(value.value),
          id:
            candidateReportAssignmentFindStageReportResponse?.data?.reports?.id,
        },
      });
      setCandidateReport(value);
    }
  };

  const handleCandidateCheckChange = () => {
    openModal(ModalTypes.STYLED_CONFIRMATION_MODAL, {
      open: true,
      message: 'Please confirm you wish to assign report',
      onSave: handleCandidateReportAssignment,
      saveBtnText: 'Assign',
    });
  };

  const handleCandidateReportAssignment = () => {
    if (!candidateCheckValues) {
      reportAssignmentDeleteMutation({
        variables: { id: Number(candidateReport.value) },
      });
    }
    if (candidateCheckValues) {
      reportAssignmentCreateMutation({
        variables: {
          assignedId:
            reportAssignedType === 'COMPANY'
              ? Number(companyId)
              : reportAssignedType === 'PROJECT'
              ? Number(projectId)
              : Number(stageId),
          assignedType: reportAssignedType,
          reportId: Number(
            candidateReportAssignmentFindStageReportResponse?.data?.reports
              ?.reportId
          ),
        },
      });
    }
  };

  return (
    <div className="report-assignment">
      <SelectFormField
        onChange={handleCandidateChange}
        label={label}
        name={name}
        value={candidateReport}
        id={selectFormId || 'selectFormId'}
        options={candidateStageAssignmentOption}
        isDisabled={
          candidateCheckValues ||
          !candidateReportAssignmentFindStageReportResponse?.data?.reports?.id
        }
        maxMenuHeight={200}
        className="report-assignment__select"
      />
      <Checkbox
        id={checkBoxId || 'checkBoxId'}
        name="candidateCheck"
        label="Use default"
        onChange={handleCandidateCheckChange}
        checked={candidateCheckValues}
        disabled={
          !candidateReportAssignmentFindStageReportResponse.data ||
          candidateReportAssignmentFindStageReportResponse.loading
        }
      />
    </div>
  );
};

export default CandidateReportAssignment;
