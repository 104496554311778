import { makeStyles } from '@mui/styles';

const useCompanyNameFormStyle = makeStyles(() => ({
  inputWrapper: {
    width: 300,
  },
  button: {
    width: 150,
    height: 48,
    marginTop: 4,
  },
}));

export default useCompanyNameFormStyle;
