import { WidgetOptionFieldType } from 'generated/graphql';

export const widgetOptionFieldType = [
  {
    value: WidgetOptionFieldType.FileUploadField,
    label: 'File upload field',
  },
  {
    value: WidgetOptionFieldType.MultipleSelectField,
    label: 'Multiple select field',
  },
  {
    value: WidgetOptionFieldType.ShortTextField,
    label: 'Short text field',
  },
  {
    value: WidgetOptionFieldType.SingleSelectField,
    label: 'Single select field',
  },
];
