import { projectRequestsRoutes } from 'constants/navigation';
import {
  ProjectRequestFragment,
  UserFragment,
  UserFragmentDoc,
} from 'generated/graphql';
import { formatDate } from 'helpers/date';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { ArchiveProjectRequestButton } from '../../components/ArchiveProjectRequestButton';
import CompanySign from '../../components/CompanySign';
import { DeleteProjectRequestButton } from '../../components/DeleteProjectRequestButton';
import { UnarchiveProjectRequestButton } from '../../components/UnarchiveProjectRequestButton';
import ProjectRequestStatusLabel from '../ProjectRequestStatusLabel';

interface ProjectRequestsListItemProps {
  projectRequest: ProjectRequestFragment;
}

const ProjectRequestsListItem: FC<ProjectRequestsListItemProps> = ({
  projectRequest,
}) => {
  const client = useApolloClient();

  const user = client.readFragment<UserFragment>({
    id: `User:${projectRequest.creatorId}`,
    fragment: UserFragmentDoc,
    fragmentName: 'User',
  });

  if (!user) {
    return null;
  }

  return (
    <Link
      to={projectRequestsRoutes.projectRequest.url(projectRequest.id)}
      key={projectRequest.id}
      className="project-requests-list-item"
    >
      <div className="project-requests-list-item__left">
        <div className="project-requests-list-item__left-item">
          <div className="project-requests-list-item__company-logo-wrapper">
            <CompanySign companyId={projectRequest.companyId} />
          </div>
          <div>{projectRequest.projectName}</div>
        </div>
        <div className="project-requests-list-item__left-item project-requests-list-item__left-item_position-center">
          {user && (
            <div>
              {user?.firstName} {user?.lastName}
            </div>
          )}
        </div>
        <div className="project-requests-list-item__left-item project-requests-list-item__left-item_updated-at">
          {formatDate(projectRequest.updatedAt, 'dd MMM yyyy')}
        </div>
        <div className="project-requests-list-item__left-item project-requests-list-item__left-item_position-center">
          <ProjectRequestStatusLabel status={projectRequest.status} />
        </div>
      </div>
      <div className="project-requests-list-item__right">
        {projectRequest.deletedAt ? (
          <>
            <UnarchiveProjectRequestButton
              projectRequestId={projectRequest.id}
            />
            <DeleteProjectRequestButton
              projectRequestId={projectRequest.id}
              projectRequestName={projectRequest.projectName}
            />
          </>
        ) : (
          <ArchiveProjectRequestButton
            projectRequestId={projectRequest.id}
            projectRequestName={projectRequest.projectName}
          />
        )}
      </div>
    </Link>
  );
};

export default ProjectRequestsListItem;
