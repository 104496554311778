import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import SelectFormField from '../../../components/molecules/SelectFormField/SelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import EditHeader from '../../../components/organisms/EditHeader/EditHeader';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IgCategoryColour,
  IgQuestionType,
  IgStep,
  IgStepFindManyDocument,
  IgStepFindManyQuery,
  IgStepFindManyQueryVariables,
  useIgStepArchiveMutation,
  useIgStepCreateMutation,
  useIgStepDeleteOneMutation,
  useIgStepUpdateMutation,
} from '../../../generated/graphql';
import { closeModal, openModal } from '../../../graphql/modals';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import {
  colours,
  questionTypes,
} from '../../InterviewGuide/InterviewGuide.constants';
import { updateIgSteps } from './IgStepConfigModal.helper';
import { useIgStepConfigModalStyle } from './IgStepConfigModalStyle';

// import WidgetConfigTextField from './WidgetConfigTextField/WidgetConfigTextField';
interface IIgStepError {
  name: string;
  internalName: string;
  durationInSeconds: string;
  action: string;
  staticQuestionName: string;
}
interface IIgStepPayload {
  name: string;
  internalName: string;
  durationInSeconds: number;
  action: string;
  staticQuestionName: string | null;
  colour: IgCategoryColour | null;
  questionType: IgQuestionType;
}
interface IIgStepChanged {
  name?: string;
  internalName?: string;
  durationInSeconds?: number;
  action?: string;
  staticQuestionName?: string | null;
  colour?: IgCategoryColour | null;
  questionType?: IgQuestionType | null;
}

interface IIgStepConfigModal {
  igStep?: IgStep;
  interviewGuideId: number;
  lastIgStepOrder?: number;
}

const IgStepConfigModal: FC<IIgStepConfigModal> = ({
  igStep,
  interviewGuideId,
  lastIgStepOrder,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useIgStepConfigModalStyle();
  const [changedVariables, setChangedVariables] = useState<IIgStepChanged>();
  const [_igStep, setIgStep] = useState<IIgStepPayload>({
    name: igStep?.name || '',
    internalName: igStep?.internalName || '',
    durationInSeconds: igStep?.durationInSeconds || 0,
    action: igStep?.action || '',
    colour: igStep?.colour || null,
    questionType: igStep?.questionType || IgQuestionType.None,
    staticQuestionName: igStep?.staticQuestionName || null,
  });

  const [_isArchived, setIsArchived] = useState<boolean>(
    igStep?.isArchived || false
  );
  const [errors, setErrors] = useState<IIgStepError>({
    name: '',
    internalName: '',
    durationInSeconds: '',
    action: '',
    staticQuestionName: '',
  });

  const [IgStepCreateMutation] = useIgStepCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const newIgStep = data?.IgStepCreate as IgStep;
      const igSteps = cache.readQuery<
        IgStepFindManyQuery,
        IgStepFindManyQueryVariables
      >({
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: false,
        },
      })?.IgStepFindMany as IgStep[];
      cache.writeQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>({
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: false,
        },
        data: {
          IgStepFindMany: [...igSteps, newIgStep],
        },
      });
    },
  });

  const [IgStepUpdateMutation] = useIgStepUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [IgStepArchiveMutation] = useIgStepArchiveMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: data => {
      if (data.IgStepUpdate) setIsArchived(data.IgStepUpdate?.isArchived);
    },
    update: (cache, { data }) => {
      const changedIgStep = data?.IgStepUpdate as IgStep;
      if (changedIgStep) {
        const orderedIgSteps = updateIgSteps(
          cache,
          changedIgStep,
          interviewGuideId
        );
        if (orderedIgSteps) {
          orderedIgSteps.map((igStep: IgStep) => {
            IgStepUpdateMutation({
              variables: {
                id: igStep.id,
                interviewGuideId,
                order: igStep.order,
              },
            });
            return null;
          });
        }
      }
    },
  });

  const [IgStepDeleteMutation] = useIgStepDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: false,
        },
      },
      {
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: true,
        },
      },
    ],
  });
  const handleCloseModal = () => {
    closeModal(ModalTypes.IG_STEP_CONFIG_MODAL);
  };

  const handleSubmit = () => {
    const reportErrors = addCompanySignatureFormValidation(_igStep);
    if (!_.isEmpty(reportErrors)) return setErrors(reportErrors);
    if (igStep) {
      const variables = {
        id: igStep.id,
        interviewGuideId,
        ...changedVariables,
      };
      IgStepUpdateMutation({ variables });
    } else {
      const {
        name,
        internalName,
        action,
        durationInSeconds,
        colour,
        questionType,
        staticQuestionName,
      } = _igStep;
      const variables = {
        interviewGuideId,
        name,
        internalName,
        action,
        durationInSeconds: +durationInSeconds as number,
        colour,
        questionType,
        order: lastIgStepOrder?.toString() ? lastIgStepOrder + 1 : 0,
        staticQuestionName,
      };
      IgStepCreateMutation({ variables });
    }

    handleCloseModal();
  };
  const handleChange = (value: string, name: string) => {
    setIgStep(prev => ({ ...prev, [name]: value }));
    let parsedValue: number | string | null = value;
    if (name === 'durationInSeconds') parsedValue = +value;
    if (
      (name === 'action' && value === '') ||
      (name === 'staticQuestionName' && value === '')
    )
      parsedValue = null;
    setChangedVariables(prev => ({ ...prev, [name]: parsedValue }));
    if (
      errors.name ||
      errors.durationInSeconds ||
      errors.internalName ||
      errors.staticQuestionName ||
      errors.action
    ) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, [name]: '' } : prevErrors
      );
    }
  };

  const addCompanySignatureFormValidation = (igStep: IIgStepPayload) =>
    validate(igStep, {
      name: {
        nonEmptyString: true,
      },
      internalName: {
        nonEmptyString: true,
      },
      durationInSeconds: {
        isNumber: true,
      },
    });

  const handleDropdownChange = (value: TSelectFormFieldValue, name: string) => {
    if (value) {
      setIgStep(prev => ({
        ...prev,
        [name]: value,
      }));
      setChangedVariables(prev => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setIgStep(prev => ({
        ...prev,
        [name]: IgQuestionType.None,
      }));
      setChangedVariables(prev => ({
        ...prev,
        [name]: IgQuestionType.None,
      }));
    }
    if (name === 'questionType' && value !== IgQuestionType.Static) {
      setIgStep(prev => ({ ...prev, staticQuestionName: null }));
      setChangedVariables(prev => ({ ...prev, staticQuestionName: null }));
    }
  };

  const handleArchive = () => {
    if (igStep) {
      const variables = {
        id: igStep.id,
        isArchived: !_isArchived,
      };
      IgStepArchiveMutation({ variables });
    }
  };
  const handleDelete = () => {
    if (igStep) {
      openModal(ModalTypes.CONFIRMATION_MODAL, {
        open: true,
        message: 'Are you sure you want to delete',
        onSave: () => {
          IgStepDeleteMutation({ variables: { id: igStep.id } });
          closeModal(ModalTypes.CONFIRMATION_MODAL);
          closeModal(ModalTypes.IG_STEP_CONFIG_MODAL);
        },
        onClose: () => {
          closeModal(ModalTypes.CONFIRMATION_MODAL);
        },
        saveBtnText: 'Delete',
      });
    }
  };
  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        {igStep && (
          <EditHeader
            title="Edit Step"
            handleArchive={handleArchive}
            handleDelete={handleDelete}
            isArchived={_isArchived}
          />
        )}
        <TextFormField
          id="name"
          name="name"
          label="Name"
          value={_igStep.name}
          onChange={handleChange}
          hasError={!!errors?.name}
          errorText={errors?.name}
        />
        <Grid style={{ marginTop: 10 }}>
          <TextFormField
            id="internalName"
            name="internalName"
            label="Internal Name"
            value={_igStep.internalName}
            //   classinternalName={classes.mb10}
            onChange={handleChange}
            hasError={!!errors?.internalName}
            errorText={errors?.internalName}
          />
        </Grid>
        <Grid style={{ marginTop: 10 }}>
          <TextFormField
            id="durationInSeconds"
            name="durationInSeconds"
            label="Duration In Seconds"
            value={`${_igStep.durationInSeconds}`}
            onChange={handleChange}
            hasError={!!errors?.durationInSeconds}
            errorText={errors?.durationInSeconds}
          />
        </Grid>
        <Grid style={{ marginTop: 10 }}>
          <TextFormField
            id="action"
            name="action"
            label="Action"
            value={_igStep.action}
            onChange={handleChange}
            hasError={!!errors?.action}
            errorText={errors?.action}
          />
        </Grid>

        <Grid xs={12} item style={{ marginTop: 10 }}>
          <SelectFormField
            value={_igStep?.questionType ?? ''}
            name="questionType"
            id="questionType"
            label="Question Type"
            options={questionTypes}
            onChange={handleDropdownChange}
          />
        </Grid>

        {_igStep?.questionType === IgQuestionType.Static && (
          <Grid style={{ marginTop: 10 }}>
            <TextFormField
              id="staticQuestionName"
              name="staticQuestionName"
              label="Static Question Name"
              value={_igStep.staticQuestionName ?? ''}
              onChange={handleChange}
              hasError={!!errors?.staticQuestionName}
              errorText={errors?.staticQuestionName}
            />
          </Grid>
        )}
        <Grid xs={12} item style={{ marginTop: 10 }}>
          <SelectFormField
            value={_igStep?.colour ?? ''}
            name="colour"
            id="colour"
            label="Colour"
            options={colours}
            onChange={handleDropdownChange}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default IgStepConfigModal;
