import { FC } from 'react';

interface ICandidateUsageTableCell {
  text: string;
  isHeading?: boolean;
}

const CandidateUsageTableCell: FC<ICandidateUsageTableCell> = ({
  text,
  isHeading,
}) => {
  return (
    <div
      className="candidate-usage-table-cell__item"
      is-heading={isHeading ? 'true' : ''}
    >
      {text}
    </div>
  );
};

export default CandidateUsageTableCell;
