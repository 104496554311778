import Icon from 'components/atoms/Icon';
import {
  ExternalAssessmentModel,
  TechnicalSkillFindManyQuery,
  useExternalAssessmentFindManyPaginatedQuery,
  useTechnicalSkillFindManyQuery,
} from 'generated/graphql';
import { useMemo, useState } from 'react';
import {
  Table,
  Link,
  Tag,
  TagColor,
  TagSize,
} from '@spotted-zebra-uk/ui-components';
import styles from './CodingAssessments.module.scss';
import TimeExtension from './components/TimeExtension';

const PAGINATION_PAGE_SIZE = 20;

const tableHeaders = [
  {
    key: 'company',
    header: 'Company',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'skill',
    header: 'Skill',
  },
  {
    key: 'time_limit',
    header: 'Time limit',
  },
  {
    key: 'actions',
    header: '',
  },
];

type TechnicalSkills = TechnicalSkillFindManyQuery['TechnicalSkillFindMany'];

function CodingAssessmentView() {
  const technicalSkillsResponse = useTechnicalSkillFindManyQuery();
  const technicalSkills =
    technicalSkillsResponse.data?.TechnicalSkillFindMany || [];
  const [page, setPage] = useState(1);

  const codingAssessmentsResponse = useExternalAssessmentFindManyPaginatedQuery(
    {
      variables: {
        skip: 0,
      },
    }
  );

  const codingAssessments =
    codingAssessmentsResponse.data?.ExternalAssessmentFindManyPaginated
      .externalAssessments;

  const totalNumberOfAssessments =
    codingAssessmentsResponse.data?.ExternalAssessmentFindManyPaginated.total;

  const tableRows = useMemo(() => {
    return codingAssessments?.map(codingAssessment => {
      const assessmentExternalLink = codingAssessment?.eaPlatformAssessments?.find(
        ea => ea.modifier === 'DEFAULT'
      )?.platformUrl;
      const technicalSkillName = findTechSkillName(
        technicalSkills,
        // @ts-ignore
        codingAssessment
      );
      return {
        id: codingAssessment.id,
        company: Boolean(codingAssessment.eaCompanyPlatformId) && (
          <Icon icon="tag" />
        ),
        name: codingAssessment.name,
        skill: technicalSkillName && (
          <Tag
            tagColor={TagColor.PURPLE}
            isDismissible={false}
            size={TagSize.LARGE}
          >
            {technicalSkillName}
          </Tag>
        ),
        time_limit: Boolean(codingAssessment.timeLimitInMinutes) && (
          <TimeExtension
            codingAssessment={codingAssessment as ExternalAssessmentModel}
          />
        ),
        actions: assessmentExternalLink && (
          <Link to={assessmentExternalLink} target="_blank">
            View in Coderbyte
          </Link>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codingAssessments]);

  function onPaginationChange({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) {
    const paginationOffset = (page - 1) * pageSize;
    codingAssessmentsResponse.refetch({ skip: paginationOffset });
    setPage(page);
  }

  return (
    <Table
      title="Coding Test"
      headerData={tableHeaders}
      rowData={tableRows}
      hasPagination={true}
      pagination={{
        currentPageSize: PAGINATION_PAGE_SIZE,
        onChange: onPaginationChange,
        totalNumberOfItems: totalNumberOfAssessments || 0,
        page,
      }}
      isSortable={false}
      className={styles.table}
    />
  );
}

/**
 * Here we're looping through the list of all technicalSkill and trying to find
 * matching id of technicalSkill with currently mapped externalAssessment's eaSkill technicalSkillId
 */
function findTechSkillName(
  technicalSkills: TechnicalSkills | undefined | null,
  codingAssessment: ExternalAssessmentModel | undefined | null
) {
  if (!technicalSkills || !codingAssessment) {
    return null;
  }
  return (
    technicalSkills?.find(
      techSkill =>
        techSkill.id ===
        codingAssessment.eaSkills?.find(
          externalAssessment =>
            externalAssessment.technicalSkillId === techSkill.id
        )?.technicalSkillId
    )?.name || ''
  );
}

export default CodingAssessmentView;
