import './styles.scss';
import Icon from 'components/atoms/Icon';
import { useDeauthenticateMutation } from 'generated/graphql';
import { getAccessToken, logoutCleanup } from 'helpers/auth';
import { FC } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

const Logout: FC = () => {
  const { handleMsgType } = useNotification();

  const [deauthenticate] = useDeauthenticateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleLogout = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      deauthenticate({
        variables: {
          accessToken,
        },
      });
    }
    logoutCleanup();
  };

  return (
    <div className="logout-button">
      <Button onClick={handleLogout} size="medium" variant="text">
        Logout <Icon icon="logout" className="logout-button__icon" />
      </Button>
    </div>
  );
};

export default Logout;
