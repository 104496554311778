import { makeStyles } from '@mui/styles';

const useTraitContentListItemStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
  gridItemWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  gridItemTextArea: {
    '@media (min-width: 1440px)': {
      minWidth: '220px',
    },
  },
}));

export default useTraitContentListItemStyle;
