import { FC, useMemo } from 'react';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import { TestType } from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

const optionsObject: {
  [key in TestType]: { value: TestType; label: string; order: number };
} = {
  [TestType.Cognitive]: {
    value: TestType.Cognitive,
    label: 'Cognitive',
    order: 1,
  },
  [TestType.TalentReview]: {
    value: TestType.TalentReview,
    label: 'Talent Review',
    order: 2,
  },
  [TestType.Personality]: {
    value: TestType.Personality,
    label: 'Personality',
    order: 3,
  },
};

interface ITestTypeSelectFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
}

const TestTypeSelectFormField: FC<ITestTypeSelectFormField> = props => {
  const options = useMemo(
    () =>
      Object.values(optionsObject)
        .sort((a, b) => (a.order > b.order ? 1 : 1))
        .map(option => ({ label: option.label, value: option.value })),
    []
  );

  return <SelectFormField options={options} {...props} />;
};

export default TestTypeSelectFormField;
