import { ModalTypes } from 'enums/modalTypes';
import { useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ExportContentModal from '../ExportContentModal/ExportContentModal';

export interface ICandidateUsageModal {
  companyIds: number[];
  createdAtFrom: string;
  createdAtTo: string;
}
const CandidateUsageModal: FC<ICandidateUsageModal> = ({
  companyIds,
  createdAtFrom,
  createdAtTo,
}) => {
  const { handleMsgType } = useNotification();

  const handleCloseModal = () => {
    closeModal(ModalTypes.CANDIDATE_USAGE_MODAL);
  };

  const {
    data,
    loading,
    error,
    refetch,
  } = useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery({
    variables: {
      companyIds,
      createdAtFrom,
      createdAtTo,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error.message });
    },
  });

  const handleConfirmButtonClick = () => {
    if (error) {
      refetch({
        ids: [],
      });
    } else {
      handleCloseModal();
      window.open(data?.projects?.url, '_self');
      handleMsgType({
        type: TNotification.success,
        message: 'Download started',
      });
    }
  };

  return (
    <ExportContentModal
      handleCloseModal={handleCloseModal}
      error={error}
      handleConfirmButtonClick={handleConfirmButtonClick}
      loading={loading}
      url={data?.projects?.url}
    />
  );
};

export default CandidateUsageModal;
