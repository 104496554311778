import { makeStyles } from '@mui/styles';

const useCompanyEmployeesStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
    marginRight: 5,
  },
  buttonContainer: {
    alignSelf: 'flex-end',
  },
  heading: {
    fontWeight: 500,
  },
}));

export default useCompanyEmployeesStyle;
