import ReportAccessModal, {
  IReportAccessModal,
} from 'components/feature/ReportsAccess/ReportsAccessModal';
import ConfirmationModal, {
  TConfirmationModal,
} from 'components/organisms/ConfirmationModal/ConfirmationModal';
import StyledConfirmationModal from 'components/organisms/StyledConfirmationModal';
import { ModalTypes } from 'enums/modalTypes';
import { GET_MODALS_QUERY } from 'graphql/modals';
import {
  IGetModalsQueryResponse,
  StyledConfirmationModalProps,
  TAddCategoryModalProps,
  TAddInterviewGuideModalProps,
  TAddNewCompanySignatureModalProps,
  TAddNewUserModalProps,
  TAddNewUserRoleModalProps,
  TAddSoftSkillModal,
  TAddTraitModal,
  TAddWidgetModalProps,
  TAddWidgetOptionModalProps,
  TCandidateInfoModal,
  TConfigModalProps,
  TEditSoftSkillModal,
  TEditTraitModal,
  TEditUserModalProps,
  TIgStepConfigModal,
  TInterviewGuideCategoryModalProps,
  TMitigateCircumstanceModal,
  TReportsModalProps,
  TRetrieveTpDetailsModalProps,
  TStageIndirectInvitationModal,
  TStaticContentFiltersModal,
  TTextFormFieldModal,
  TWarningModalProps,
} from 'interfaces/modal';
import { FC } from 'react';
import { useQuery } from '@apollo/client';
import EditDeiActionModal, {
  IEditDeiActionModal,
} from '../Project/components/Stages/StageActions/EditDeiActionModal';
import { IDeleteAssessmentProps } from '../User/UserDetails/UserMainResets/UserReset/StageReset/DeleteAssessment/DeleteAssessment';
import AddCategoryModal from './AddCategoryModal/AddCategoryModal';
import AddInterviewGuideModal from './AddInterviewGuideModal/AddInterviewGuideModal';
import AddNewCompanySignatureModal from './AddNewCompanySignatureModal/AddNewCompanySignatureModal';
import AddNewUserModal from './AddNewUserModal/AddNewUserModal';
import AddNewUserRoleModal from './AddNewUserRoleModal/AddNewUserRoleModal';
import AddProjectRequestDocumentModal, {
  AddProjectRequestDocumentModalProps,
} from './AddProjectRequestDocumentModal';
import AddWidgetModal from './AddWidgetModal/AddWidgetModal';
import AddWidgetOptionModal from './AddWidgetOptionModal/AddWidgetOptionModal';
import BehaviourContentModal, {
  IBehaviourContentModal,
} from './BehaviourContentModal/BehaviourContentModal';
import CandidateEditInfoFieldModal from './CandidateInfoFieldModal/CandidateInfoFieldModal';
import CandidateUsageModal, {
  ICandidateUsageModal,
} from './CandidateUsageModal/CandidateUsageModal';
import CompanyEmployeeModal, {
  ICompanyEmployeeModal,
} from './CompanyEmployeeModal';
import CreateProjectRequestContactsModal, {
  CreateProjectRequestContactsModalProps,
} from './CreateProjectRequestUserModal';
import DeleteAssessmentModal from './DeleteAssessmentModal/DeleteAssessmentModal';
import EditHRManagerEmployeeIDModal, {
  IEditHRManagerEmployeeIDModal,
} from './EditHRManagerEmployeeIDModal';
import EditUserModal from './EditUserModal/EditUserModal';
import IgStepConfigModal from './IgStepConfigModal/IgStepConfigModal';
import InterviewGuideCategoryConfigModal from './InterviewGuideCategoryConfigModal/InterviewGuideCategoryConfigModal';
import MitigateCircumstanceModal from './MitigateCircumstanceModal/MitigateCircumstanceModal';
import { renderModalObject } from './ModalView.constants';
import NotificationModal, { NotificationModalProps } from './NotificationModal';
import ProjectRequestApprovalModal, {
  IProjectRequestApprovalModal,
} from './ProjectRequestApprovalModal';
import ProjectRequestApprovalStatusModal, {
  IProjectRequestApprovalStatusModal,
} from './ProjectRequestApprovalStatusModal';
import ProjectRequestJobSpecificationModal, {
  IProjectRequestJobSpecificationModal,
} from './ProjectRequestJobSpecificationModal';
import ProjectRequestManagerSurveyModal, {
  IProjectRequestManagerSurveyModal,
} from './ProjectRequestManagerSurveyModal';
import ReportsModal from './ReportsModal/ReportsModal';
import RetrieveTpDetailsModal from './RetrieveTpDetailsModal/RetrieveTpDetailsModal';
import StageIndirectInvitationModal from './StageIndirectInvitationModal';
import WarningModal from './WarningModal/WarningModal';
import WidgetConfigModal from './WidgetConfigModal/WidgetConfigModal';

interface IModalView {}

const ModalView: FC<IModalView> = () => {
  const modalsQueryResponse = useQuery<IGetModalsQueryResponse>(
    GET_MODALS_QUERY
  );

  if (modalsQueryResponse.data?.modals.length) {
    const { modals } = modalsQueryResponse.data;

    return (
      <>
        {modals.map(modal => {
          if (modal.modalType === ModalTypes.CANDIDATE_INFO_FIELD_MODAL) {
            return (
              <CandidateEditInfoFieldModal
                key={modal.modalType}
                {...(modal.modalProps as TCandidateInfoModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.ADD_SOFT_SKILL_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TAddSoftSkillModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.EDIT_SOFT_SKILL_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TEditSoftSkillModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.ADD_TRAIT_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TAddTraitModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.EDIT_TRAIT_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TEditTraitModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.STATIC_CONTENT_FILTERS_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TStaticContentFiltersModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.VALIDATE_CONTENT_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TStaticContentFiltersModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.TEXT_FIELD_MODAL) {
            const ModalComponent = renderModalObject[modal.modalType];
            return (
              <ModalComponent
                key={modal.modalType}
                {...(modal.modalProps as TTextFormFieldModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.WARNING_MODAL) {
            return (
              <WarningModal
                key={modal.modalType}
                {...(modal.modalProps as TWarningModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.REPORTS_PAGE_MODAL) {
            return (
              <ReportsModal
                key={modal.modalType}
                {...(modal.modalProps as TReportsModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.ADD_WIDGET_OPTION_MODAL) {
            return (
              <AddWidgetOptionModal
                key={modal.modalType}
                {...(modal.modalProps as TAddWidgetOptionModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.ADD_WIDGET_MODAL) {
            return (
              <AddWidgetModal
                key={modal.modalType}
                {...(modal.modalProps as TAddWidgetModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.WIDGET_CONFIG_MODAL) {
            return (
              <WidgetConfigModal
                key={modal.modalType}
                {...(modal.modalProps as TConfigModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.ADD_NEW_USER_ROLE_MODAL) {
            return (
              <AddNewUserRoleModal
                key={modal.modalType}
                {...(modal.modalProps as TAddNewUserRoleModalProps)}
              />
            );
          }
          if (modal.modalType === ModalTypes.ADD_NEW_USER_MODAL) {
            return (
              <AddNewUserModal
                key={modal.modalType}
                {...(modal.modalProps as TAddNewUserModalProps)}
              />
            );
          }
          if (modal.modalType === ModalTypes.EDIT_USER_MODAL) {
            return (
              <EditUserModal
                key={modal.modalType}
                {...(modal.modalProps as TEditUserModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.RETRIEVE_TP_DETAILS_MODAL) {
            return (
              <RetrieveTpDetailsModal
                key={modal.modalType}
                {...(modal.modalProps as TRetrieveTpDetailsModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.CONFIRMATION_MODAL) {
            return (
              <ConfirmationModal
                key={modal.modalType}
                {...(modal.modalProps as TConfirmationModal)}
              />
            );
          }

          if (modal.modalType === ModalTypes.ADD_COMPANY_SIGNATURE_MODAL) {
            return (
              <AddNewCompanySignatureModal
                key={modal.modalType}
                {...(modal.modalProps as TAddNewCompanySignatureModalProps)}
              />
            );
          }

          if (modal.modalType === ModalTypes.STAGE_INDIRECT_INVITATION_MODAL) {
            return (
              <StageIndirectInvitationModal
                key={modal.modalType}
                {...(modal.modalProps as TStageIndirectInvitationModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.ADD_CATEGORY_MODAL) {
            return (
              <AddCategoryModal
                key={modal.modalType}
                {...(modal.modalProps as TAddCategoryModalProps)}
              />
            );
          }
          if (modal.modalType === ModalTypes.ADD_INTERVIEW_GUIDE_MODAL) {
            return (
              <AddInterviewGuideModal
                key={modal.modalType}
                {...(modal.modalProps as TAddInterviewGuideModalProps)}
              />
            );
          }
          if (
            modal.modalType === ModalTypes.INTERVIEW_GUIDE_CATEGORY_CONFIG_MODAL
          ) {
            return (
              <InterviewGuideCategoryConfigModal
                key={modal.modalType}
                {...(modal.modalProps as TInterviewGuideCategoryModalProps)}
              />
            );
          }
          if (modal.modalType === ModalTypes.IG_STEP_CONFIG_MODAL) {
            return (
              <IgStepConfigModal
                key={modal.modalType}
                {...(modal.modalProps as TIgStepConfigModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.MITIGATE_CIRCUMSTANCE_MODAL) {
            return (
              <MitigateCircumstanceModal
                key={modal.modalType}
                {...(modal.modalProps as TMitigateCircumstanceModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.STYLED_CONFIRMATION_MODAL) {
            return (
              <StyledConfirmationModal
                key={modal.modalType}
                {...(modal.modalProps as StyledConfirmationModalProps)}
              />
            );
          }
          if (modal.modalType === ModalTypes.NOTIFICATION_MODAL) {
            return (
              <NotificationModal
                key={modal.modalType}
                {...(modal.modalProps as NotificationModalProps)}
              />
            );
          }
          if (
            modal.modalType === ModalTypes.CREATE_PROJECT_REQUEST_CONTACT_MODAL
          ) {
            return (
              <CreateProjectRequestContactsModal
                key={modal.modalType}
                {...(modal.modalProps as CreateProjectRequestContactsModalProps)}
              />
            );
          }
          if (
            modal.modalType === ModalTypes.ADD_PROJECT_REQUEST_DOCUMENT_MODAL
          ) {
            return (
              <AddProjectRequestDocumentModal
                key={modal.modalType}
                {...(modal.modalProps as AddProjectRequestDocumentModalProps)}
              />
            );
          }
          if (
            modal.modalType ===
            ModalTypes.PROJECT_REQUEST_JOB_SPECIFICATION_MODAL
          ) {
            return (
              <ProjectRequestJobSpecificationModal
                key={modal.modalType}
                {...(modal.modalProps as IProjectRequestJobSpecificationModal)}
              />
            );
          }
          if (
            modal.modalType === ModalTypes.PROJECT_REQUEST_MANAGER_SURVEY_MODAL
          ) {
            return (
              <ProjectRequestManagerSurveyModal
                key={modal.modalType}
                {...(modal.modalProps as IProjectRequestManagerSurveyModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.PROJECT_REQUEST_APPROVAL_MODAL) {
            return (
              <ProjectRequestApprovalModal
                key={modal.modalType}
                {...(modal.modalProps as IProjectRequestApprovalModal)}
              />
            );
          }
          if (
            modal.modalType === ModalTypes.PROJECT_REQUEST_APPROVAL_STATUS_MODAL
          ) {
            return (
              <ProjectRequestApprovalStatusModal
                key={modal.modalType}
                {...(modal.modalProps as IProjectRequestApprovalStatusModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.COMPANY_EMPLOYEE_MODAL) {
            return (
              <CompanyEmployeeModal
                key={modal.modalType}
                {...(modal.modalProps as ICompanyEmployeeModal)}
              />
            );
          }
          if (
            modal.modalType === ModalTypes.EDIT_HR_MANAGER_EMPLOYEE_ID_MODAL
          ) {
            return (
              <EditHRManagerEmployeeIDModal
                key={modal.modalType}
                {...(modal.modalProps as IEditHRManagerEmployeeIDModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.BEHAVIOUR_CONTENT_MODAL) {
            return (
              <BehaviourContentModal
                key={modal.modalType}
                {...(modal.modalProps as IBehaviourContentModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.DELETE_ASSESSMENT_MODAL) {
            return (
              <DeleteAssessmentModal
                key={modal.modalType}
                {...(modal.modalProps as IDeleteAssessmentProps)}
              />
            );
          }
          if (modal.modalType === ModalTypes.EDIT_DEI_ACTION) {
            return (
              <EditDeiActionModal
                key={modal.modalType}
                {...(modal.modalProps as IEditDeiActionModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.REPORTS_ACCESS) {
            return (
              <ReportAccessModal
                key={modal.modalType}
                {...(modal.modalProps as IReportAccessModal)}
              />
            );
          }
          if (modal.modalType === ModalTypes.CANDIDATE_USAGE_MODAL) {
            return (
              <CandidateUsageModal
                key={modal.modalType}
                {...(modal.modalProps as ICandidateUsageModal)}
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  return null;
};

export default ModalView;
