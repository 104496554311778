import { Formik } from 'formik';
import { ProjectFragment, SupportedLocale } from 'generated/graphql';
import { FC } from 'react';

import LanguageForm from '../ProjectLanguageForm/LanguageForm/LanguageForm';
import { EditFormWrapper } from '@spotted-zebra-uk/ui-components';

export interface IProjectLanguageFormSubmitValues {
  locales: SupportedLocale[];
}

export interface IProjectFormValues {
  projectLocales: SupportedLocale[];
}

interface IProjectLanguageForm {
  project?: ProjectFragment;
  onProjectSave: (input: IProjectLanguageFormSubmitValues) => void;
  onCancel: () => void;
  saveLoading?: boolean;
}

const ProjectLanguageForm: FC<IProjectLanguageForm> = ({
  project,
  onProjectSave,
  onCancel,
}) => {
  const handleSubmit = (values: IProjectFormValues) => {
    onProjectSave({
      locales: values.projectLocales,
    });
  };

  const initialValues = getInitialValues(project);

  return (
    <EditFormWrapper
      onCancelClick={onCancel}
      title="Edit project languages"
      formId="project-language-form"
      className="project-language-form"
    >
      <Formik<IProjectFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <form id="project-language-form" onSubmit={handleSubmit}>
              {project && <LanguageForm title="Languages" />}
            </form>
          );
        }}
      </Formik>
    </EditFormWrapper>
  );
};

const getInitialValues = (project?: ProjectFragment): IProjectFormValues => {
  const projectLocales = project?.locales || [SupportedLocale.English];

  return {
    projectLocales: projectLocales,
  };
};

export default ProjectLanguageForm;
