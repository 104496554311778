import { reportsRoutes } from 'constants/navigation';

export const reportsSidebarConfig = [
  {
    title: 'Reports',
    path: reportsRoutes.reports.path,
  },
  {
    title: 'Widgets',
    path: reportsRoutes.widgets.path,
  },
];
