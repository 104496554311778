import { gql } from '@apollo/client';
import { TFieldGQL } from '../../interfaces/forms/formGQL';

export const FieldsFragments = {
  field: (field: TFieldGQL) => gql`
    fragment ${field.__typename} on ${field.__typename} {
      id
      fieldType
      formManagerType
      fieldInstanceType
      name
      question
      ... on CompanyEmployeeSelectField {
        dynamicSelectOptions
      }
      ... on MultiSelectField {
        selectOptions
      }
      ... on SingleSelectField {
        selectOptions
      }
      isArchived
      txtData
      fieldOwnerId
      fieldOwnerType
      fieldOwnerName
    }
  `,
};

export const FormsFragments = {
  form: gql`
    fragment Form on Form {
      id
      formType
      formManagerType
      name
      formOwner {
        id
        formOwnerId
      }
      formFields {
        id
        displayOrder
        isOptional
        field {
          id
          fieldType
          formManagerType
          fieldInstanceType
          name
          question
          ... on CompanyEmployeeSelectField {
            dynamicSelectOptions
          }
          ... on MultiSelectField {
            selectOptions
          }
          ... on SingleSelectField {
            selectOptions
          }
          isArchived
          txtData
        }
      }
      formOwnerName
    }
  `,
};

export const FormBuilderFragments = {
  formBuilder: gql`
    fragment FormBuilder on FormBuilder {
      form {
        ...Form
      }
      availableFields {
        id
        fieldType
        formManagerType
        fieldInstanceType
        name
        question
        ... on CompanyEmployeeSelectField {
          dynamicSelectOptions
        }
        ... on MultiSelectField {
          selectOptions
        }
        ... on SingleSelectField {
          selectOptions
        }
        isArchived
        txtData
        fieldOwnerId
        fieldOwnerType
        fieldOwnerName
      }
    }
    ${FormsFragments.form}
  `,
};

export const AnswersFragments = {
  answer: gql`
    fragment Answer on Answer {
      id
      response
      responseType
      field {
        id
        fieldType
        formManagerType
        fieldInstanceType
        name
        question
        selectOptions
        isArchived
        txtData
      }
      fieldId
      respondantId
      form {
        ...Form
      }
      fieldId
    }
    ${FormsFragments.form}
  `,
};
