import FileItem from 'components/atoms/FileItem';
import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestFileUploadFindManyQuery,
  useProjectRequestFileUploadDeleteOneMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { StyledConfirmationModalProps } from 'interfaces/modal';
import { ArrayElement } from 'interfaces/typeOfArrayElement';
import { FC, Fragment } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type TProjectRequestFileUpload = ArrayElement<
  ProjectRequestFileUploadFindManyQuery['ProjectRequestFileUploadFindMany']
>;

interface IDocumentsListItem {
  document: TProjectRequestFileUpload;
}

const DocumentsListItem: FC<IDocumentsListItem> = ({ document }) => {
  const { handleMsgType } = useNotification();
  const [
    projectRequestFileUploadDeleteOneMutation,
    projectRequestFileUploadDeleteOneMutationResponse,
  ] = useProjectRequestFileUploadDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      if (data?.ProjectRequestFileUploadDeleteOne) {
        cache.modify({
          fields: {
            ProjectRequestFileUploadFindMany: (
              existingProjectRequestFileUploads = [],
              { readField }
            ) =>
              existingProjectRequestFileUploads.filter(
                (existingProjectRequestFileUpload: TProjectRequestFileUpload) =>
                  document.id !==
                  readField('id', existingProjectRequestFileUpload)
              ),
          },
        });
        cache.gc();
      }
    },
  });

  const handleDelete = () => {
    const confirmationModalProps: StyledConfirmationModalProps = {
      message: `Do you want to delete ${document.fileName} file?`,
      saveBtnText: 'Yes, I am Sure',
      cancelBtnText: 'No, Keep It',
      saveBtnColor: 'purple',
      open: true,
      onSave: () => {
        projectRequestFileUploadDeleteOneMutation({
          variables: { id: document.id },
        });
        closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
      },
    };
    openModal(ModalTypes.STYLED_CONFIRMATION_MODAL, confirmationModalProps);
  };

  if (!document.blob) {
    return null;
  }

  return (
    <div className="documents-list-item">
      <FileItem
        fileName={document.fileName}
        url={document.blob.url}
        id={document.id}
      />
      <Button
        size="small"
        variant="text"
        className="documents-list-item__delete-button"
        onClick={handleDelete}
        disabled={projectRequestFileUploadDeleteOneMutationResponse.loading}
        leftIcon={
          <Icon
            icon="delete_outline"
            className="documents-list-item__delete-icon"
          />
        }
      ></Button>
    </div>
  );
};

interface IDocumentsList {
  documents: ProjectRequestFileUploadFindManyQuery['ProjectRequestFileUploadFindMany'];
}

const DocumentsList: FC<IDocumentsList> = ({ documents }) => (
  <div className="documents-list">
    {documents.map(document => (
      <Fragment key={document.id}>
        <DocumentsListItem document={document} />
      </Fragment>
    ))}
  </div>
);

export default DocumentsList;
