import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestUserApprovalStatus,
  useProjectRequestUserApprovalUpdateManyMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { formatISO } from 'helpers/date';
import { FC, useMemo } from 'react';
import { useGetProjectRequestUsers } from 'views/ProjectRequests/ProjectRequest/helpers';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ProjectRequestApprovalStatusFormPresentational } from './ProjectRequestApprovalStatusFormPresentational';
import { IProjectRequestApprovalStatusFormValues } from './ProjectRequestApprovalStatusModal.interfaces';

interface IProjectRequestApprovalStatusForm {
  projectRequestId: number;
  projectRequestCompanyId: number;
}

export const ProjectRequestApprovalStatusForm: FC<IProjectRequestApprovalStatusForm> = ({
  projectRequestId,
  projectRequestCompanyId,
}) => {
  const { handleMsgType } = useNotification();

  const { projectRequestUsers, loading, error } = useGetProjectRequestUsers(
    projectRequestId,
    projectRequestCompanyId
  );

  const [
    projectRequestUserApprovalUpdateManyMutation,
  ] = useProjectRequestUserApprovalUpdateManyMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const filteredProjectRequestUsers = useMemo(
    () => projectRequestUsers.filter(pr => pr.approval),
    [projectRequestUsers]
  );

  const initialValues = useMemo(() => {
    return {
      projectRequestUsersApprovals: filteredProjectRequestUsers.map(
        projectRequestUser => ({
          id: projectRequestUser.approval?.id || 0,
          approvalRequestDate:
            projectRequestUser.approval?.approvalRequestDate || '',
          hasApproved:
            projectRequestUser.approval?.approvalStatus ===
            ProjectRequestUserApprovalStatus.Approved,
        })
      ),
    };
  }, [filteredProjectRequestUsers]);

  const handleSubmit = async (
    values: IProjectRequestApprovalStatusFormValues
  ) => {
    try {
      await projectRequestUserApprovalUpdateManyMutation({
        variables: {
          dto: values.projectRequestUsersApprovals.map(value => {
            return {
              approvalStatus: value.hasApproved
                ? ProjectRequestUserApprovalStatus.Approved
                : ProjectRequestUserApprovalStatus.Sent,
              id: value.id,
              approvalRequestDate: formatISO(value.approvalRequestDate),
            };
          }),
        },
      });
      closeModal(ModalTypes.PROJECT_REQUEST_APPROVAL_STATUS_MODAL);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader variant="bubbles" />;
  }

  if (error) {
    return null;
  }

  return (
    <ProjectRequestApprovalStatusFormPresentational
      projectRequestUsers={filteredProjectRequestUsers}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  );
};
