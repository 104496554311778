import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  SoftSkillTrait,
  useFindSoftSkillTraitsBySsIdQuery,
} from '../../../../../generated/graphql';
import { IStaticContentFiltersFormValues } from '../../../StaticContentFiltersModal/StaticContentFiltersForm/StaticContentFiltersForm';
import ConnectedTraitsEditList from './ConnectedTraitsEditList/ConnectedTraitsEditList';
import ConnectedTraitsListHeader from './ConnectedTraitsHeader/ConnectedTraitsListHeader';
import ConnectedTraitsList from './ConnectedTraitsList/ConnectedTraitsList';
import useConnectedTraitsStyle from './ConnectedTraitsStyle';

interface IConnectedTraits {
  softSkillId: number;
  isEditModeActive: boolean;
  updateFilters: (values: IStaticContentFiltersFormValues) => void;
}

const ConnectedTraits: FC<IConnectedTraits> = ({
  softSkillId,
  isEditModeActive,
  updateFilters,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useConnectedTraitsStyle();
  const softSkillTraitsBySSIdQuery = useFindSoftSkillTraitsBySsIdQuery({
    variables: {
      id: softSkillId,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  if (softSkillTraitsBySSIdQuery.error) {
    return <Typography>Error</Typography>;
  }

  if (softSkillTraitsBySSIdQuery.loading) {
    return <Typography>Loading traits...</Typography>;
  }

  if (softSkillTraitsBySSIdQuery.data) {
    const softSkillTraits = softSkillTraitsBySSIdQuery.data
      .softSkillTraits as SoftSkillTrait[];
    return (
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <ConnectedTraitsListHeader isEditModeActive={isEditModeActive} />
        {isEditModeActive ? (
          <ConnectedTraitsEditList
            className={classes.connectedTraitsContainer}
            softSkillTraits={softSkillTraits}
            softSkillId={softSkillId}
          />
        ) : (
          <ConnectedTraitsList
            className={classes.connectedTraitsContainer}
            softSkillTraits={softSkillTraits}
            updateFilters={updateFilters}
          />
        )}
      </Grid>
    );
  }
  return null;
};

export default ConnectedTraits;
