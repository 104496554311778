import { FC, useCallback, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Snackbar from '../../../components/atoms/Snackbar/Snackbar';
import Modal from '../../../components/molecules/Modal/Modal';
import RoleMultiSelectFormField from '../../../components/molecules/MultiselectFormField/RoleMultiSelectFormField/RoleMultiSelectFormField';
import SelectFormField from '../../../components/molecules/SelectFormField/SelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  CompanyFindUsersDocument,
  IdentityPermissionRole,
  SupportedLocale,
  useCompanyCreateUserMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import validate from '../../../helpers/validate';
import {
  TMultiSelectFormFieldValue,
  TSelectFormFieldValue,
  TTextFromFieldValue,
} from '../../../interfaces/forms/form';
import { TAddNewUserModalProps } from '../../../interfaces/modal';
import { ISnackBar } from '../../../interfaces/snackBar';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';

interface IAddCompanyEmployeeFormValues {
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
  invitation: string;
  roles: TMultiSelectFormFieldValue;
}

interface IAddCompanyEmployeeFormErrors {
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
}
const AddNewUserModal: FC<TAddNewUserModalProps> = ({
  companyId,
  isSuperAdmin,
}) => {
  const { handleMsgType } = useNotification();

  const classes = useReportModalStyle();
  const initialValues = useCallback(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      externalId: '',
      invitation: 'Yes',
      roles: [],
    }),
    []
  );
  const [values, setValues] = useState<IAddCompanyEmployeeFormValues>(
    initialValues
  );
  const options = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const [errors, setErrors] = useState<IAddCompanyEmployeeFormErrors>();

  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_NEW_USER_MODAL);
  };

  const handleChange = (value: TTextFromFieldValue, name: string) => {
    setValues(prevValues => ({ ...prevValues, [name]: value }));
    if (errors) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, [name]: '' } : undefined
      );
    }
  };

  const handleSelectChange = (value: TSelectFormFieldValue, name: string) => {
    setValues(prev => ({
      ...prev,
      [name]: value !== undefined ? value : '',
    }));
  };

  const handleMultiSelectChange = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setValues(prev => ({
      ...prev,
      [name]: value !== undefined ? value : '',
    }));
  };

  const [addUserMutation, { error }] = useCompanyCreateUserMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'employee created successfully' });
      handleCloseModal();
    },
    refetchQueries: [
      {
        query: CompanyFindUsersDocument,
        variables: {
          id: +companyId,
        },
      },
    ],
  });

  const validateAddCompanyEmployeeForm = () => {
    return validate(values, {
      firstName: {
        nonEmptyString: true,
      },
      lastName: {
        nonEmptyString: true,
      },
      email: {
        email: {
          message: 'Invalid email.',
        },
      },
    });
  };
  const handleSave = async () => {
    const newErrors = validateAddCompanyEmployeeForm();
    if (!newErrors && companyId) {
      const result = await addUserMutation({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          externalId: values.externalId,
          companyId: +companyId,
          locale: SupportedLocale.English,
          roles: values.roles as IdentityPermissionRole[],
          sendInvite: values.invitation === 'Yes' ? true : false,
        },
      });
      if (result) {
        resetForm();
      }
    } else {
      setErrors(newErrors);
    }
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors(undefined);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            Add New User
          </Typography>
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`first-name`}
            name="firstName"
            label="Given name"
            onChange={handleChange}
            value={values.firstName}
            hasError={!!errors?.firstName}
            errorText={errors?.firstName ? 'This is a required field.' : ''}
          />
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`last-name`}
            name="lastName"
            label="Family name"
            onChange={handleChange}
            value={values.lastName}
            hasError={!!errors?.lastName}
            errorText={errors?.lastName ? 'This is a required field.' : ''}
          />
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`email`}
            name="email"
            label="Email"
            onChange={handleChange}
            value={values.email}
            hasError={!!errors?.email}
            errorText={errors?.email}
          />
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`external-id`}
            name="externalId"
            label="External code"
            onChange={handleChange}
            value={values.externalId}
            hasError={!!errors?.externalId}
            errorText={errors?.externalId}
          />
        </Grid>
        <Grid item xs>
          <RoleMultiSelectFormField
            value={values.roles}
            name="roles"
            onChange={handleMultiSelectChange}
            className={classes.mb10}
            hasError={false}
            errorText={'error text'}
            isSuperAdmin={isSuperAdmin}
          />
        </Grid>
        <Grid item xs>
          <SelectFormField
            id={`invitation`}
            name="invitation"
            label="Invitation"
            value={values.invitation}
            onChange={handleSelectChange}
            options={options}
          />
        </Grid>
        {error?.message && (
          <p style={{ color: 'red', marginBottom: '5px' }}>An error occured</p>
        )}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button color="primary" className={classes.btn} onClick={handleSave}>
            save
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </Modal>
  );
};

export default AddNewUserModal;
