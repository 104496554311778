import Icon from 'components/atoms/Icon';
import { Formik, useField } from 'formik';
import { isEqual } from 'lodash';
import { FC } from 'react';
import {
  FormikCheckboxFormField,
  Button,
  Tooltip,
} from '@spotted-zebra-uk/ui-components';

export type SkillsSelfAssessmentActionFormValues = {
  softSkillsChecked: boolean;
  technicalSkillsChecked: boolean;
};

interface ISkillsSelfAssessmentActionForm {
  initialValues: SkillsSelfAssessmentActionFormValues;
  softSkillsAssessmentAvailable: boolean;
  softSkillTooltipNote: string;
  technicalSkillsAssessmentAvailable: boolean;
  technicalSkillTooltipNote: string;
  title?: string;
  submitButtonText?: string;
  submitDisabled?: boolean;
  error?: string;
  onSoftSkillsCheckboxChange?: () => void;
  onTechnicalSkillsCheckboxChange?: () => void;
  onCancel: () => void;
  onSubmit: (
    values: SkillsSelfAssessmentActionFormValues
  ) => void | Promise<void>;
}

const SkillsSelfAssessmentActionForm: FC<ISkillsSelfAssessmentActionForm> = ({
  initialValues,
  softSkillsAssessmentAvailable,
  softSkillTooltipNote,
  technicalSkillsAssessmentAvailable,
  technicalSkillTooltipNote,
  submitDisabled,
  error,
  submitButtonText = 'Save',
  title,
  onSoftSkillsCheckboxChange,
  onTechnicalSkillsCheckboxChange,
  onCancel,
  onSubmit,
}) => (
  <Formik<SkillsSelfAssessmentActionFormValues>
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <div className="skills-self-assessment-action-form">
        {title && (
          <div className="skills-self-assessment-action-form__title">
            {title}
          </div>
        )}
        <div className="skills-self-assessment-action-form__note">
          Only skills in the 'Right for the role' are included in this
          self-assessment.
        </div>
        <div className="skills-self-assessment-action-form__form">
          <div className="skills-self-assessment-action-form__form-row">
            <FormikCheckboxFormField
              label="Soft skills"
              id="softSkillsChecked"
              disabled={!softSkillsAssessmentAvailable}
              onChange={onSoftSkillsCheckboxChange}
              useFormikField={useField}
            />
            {!softSkillsAssessmentAvailable && (
              <Tooltip html={softSkillTooltipNote} place="left">
                <div>
                  <Icon
                    icon="help_outline"
                    className="skills-self-assessment-action-form__help-icon"
                  />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="skills-self-assessment-action-form__form-row">
            <FormikCheckboxFormField
              label="Technical skills"
              id="technicalSkillsChecked"
              disabled={!technicalSkillsAssessmentAvailable}
              onChange={onTechnicalSkillsCheckboxChange}
              useFormikField={useField}
            />
            {!technicalSkillsAssessmentAvailable && (
              <Tooltip html={technicalSkillTooltipNote} place="left">
                <div>
                  <Icon
                    icon="help_outline"
                    className="skills-self-assessment-action-form__help-icon"
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="skills-self-assessment-action-form__error-wrapper">
          {error && (
            <div className="skills-self-assessment-action-form__error">
              <Icon icon="exclamation_mark_in_circle" /> {error}
            </div>
          )}
        </div>
        <div className="skills-self-assessment-action-form__actions">
          <Button onClick={onCancel} size="medium" variant="secondary">
            Cancel
          </Button>
          <Button
            size="medium"
            disabled={
              submitDisabled || isEqual(initialValues, values) || Boolean(error)
            }
            onClick={() => handleSubmit()}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
    )}
  </Formik>
);

export default SkillsSelfAssessmentActionForm;
