import { makeStyles } from '@mui/styles';

const useInterviewQuestionContentListItemStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
  gridItemWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export default useInterviewQuestionContentListItemStyle;
