import Icon from 'components/atoms/Icon';
import { FieldArray, Formik } from 'formik';
import { Fragment, memo } from 'react';
import {
  Button,
  TSelectOption,
  ModalButtons,
  TOption,
} from '@spotted-zebra-uk/ui-components';
import { CreateProjectRequestContactsFormRow } from './CreateProjectRequestContactsFormRow';

export type CreateProjectRequestContactValues = {
  role: TSelectOption;
  employee: TOption;
};

export type CreateProjectRequestContactsFormValues = {
  contacts: CreateProjectRequestContactValues[];
};

const emptyValue: CreateProjectRequestContactValues = {
  role: { label: '', value: '' },
  employee: { label: '', value: '', labelNote: '' },
};

export const initialValues = { contacts: [emptyValue] };

interface CreateProjectRequestContactsFormProps {
  employeesOptions: TOption[];
  companyId: number;
  onSubmit: (
    values: CreateProjectRequestContactsFormValues
  ) => void | Promise<void>;
  onCancel: () => void;
}

export const CreateProjectRequestContactsForm = memo<
  CreateProjectRequestContactsFormProps
>(({ employeesOptions, companyId, onSubmit, onCancel }) => (
  <Formik<CreateProjectRequestContactsFormValues>
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, isSubmitting }) => (
      <div className="create-project-request-contacts-form">
        <FieldArray name="contacts">
          {({ remove, push }) => (
            <div className="create-project-request-contacts-form__contacts-list">
              <div className="create-project-request-contacts-form__contacts-list-fields">
                {values.contacts.map((_contact, index) => (
                  <Fragment key={`contact_${index}`}>
                    <CreateProjectRequestContactsFormRow
                      remove={remove}
                      index={index}
                      employeesOptions={employeesOptions}
                      hasDeleteButton={values.contacts.length > 1}
                      companyId={companyId}
                    />
                  </Fragment>
                ))}
              </div>
              <div>
                <Button
                  className="create-project-request-contacts-form__add-contact-button"
                  size="medium"
                  variant="secondary"
                  onClick={() => push(emptyValue)}
                  type="button"
                  disabled={isSubmitting}
                  leftIcon={
                    <Icon
                      icon="add_black"
                      className="create-project-request-contacts-form__add-icon"
                    />
                  }
                >
                  Add Contact
                </Button>
              </div>
            </div>
          )}
        </FieldArray>
        <ModalButtons
          onConfirmButtonClick={() => onSubmit(values)}
          confirmButtonChildren="Add"
          onCancelButtonClick={onCancel}
          confirmButtonProps={{
            type: 'button',
          }}
          cancelButtonProps={{
            type: 'button',
          }}
        />
      </div>
    )}
  </Formik>
));
