import './style.scss';
import { FC } from 'react';
import ExternalLink from '../ExternalLink/ExternalLink';
import Icon from '../Icon';

interface FileItemProps {
  fileName: string;
  id: number;
  url: string;
}

const FileItem: FC<FileItemProps> = ({ fileName, url }) => {
  return (
    <ExternalLink url={url}>
      <div className="file-item">
        <Icon icon="note" className="file-item__file-icon" />
        <div className="file-item__file-name">{fileName}</div>
      </div>
    </ExternalLink>
  );
};

export default FileItem;
