import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  SoftSkillSuccessProfileContent,
  useSoftSkillSuccessProfileContentFindManyAndCountLazyQuery,
} from '../../../generated/graphql';
import { StaticContentContext } from '../StaticContentContext/StaticContentContext';
import SoftSkillSuccessProfileContentHeader from './SoftSkillSuccessProfileContentHeader/SoftSkillSuccessProfileContentHeader';
import SoftSkillSuccessProfileContentList from './SoftSkillSuccessProfileContentList/SoftSkillSuccessProfileContentList';
import useSoftSkillSuccessProfileContentStyle from './SoftSkillSuccessProfileContentStyle';

export interface ISoftSkillSuccessProfileContent {}

const PSoftSkillSuccessProfileContent: FC<ISoftSkillSuccessProfileContent> = () => {
  const { filters } = useContext(StaticContentContext);
  const classes = useSoftSkillSuccessProfileContentStyle();

  const [
    softSkillContentQuery,
    softSkillContentQueryResponse,
  ] = useSoftSkillSuccessProfileContentFindManyAndCountLazyQuery();

  useEffect(() => {
    const { softSkills, roleLevels, page, take, sortBy } = filters;
    softSkillContentQuery({
      variables: {
        softSkillIds: softSkills,
        roleLevels,
        page: page ? page : 1,
        take: take ? take : 50,
        order: sortBy ? sortBy : [],
      },
    });
  }, [filters, softSkillContentQuery]);

  if (softSkillContentQueryResponse.error) {
    return <Typography className={classes.container}>Error</Typography>;
  }

  if (softSkillContentQueryResponse.loading) {
    return (
      <Typography className={classes.container}>Loading content...</Typography>
    );
  }

  if (softSkillContentQueryResponse.data) {
    const softSkillContent =
      softSkillContentQueryResponse.data.softSkillContent;
    const count = softSkillContent.count;

    if (softSkillContent.softSkillContent) {
      return (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.container}
        >
          <SoftSkillSuccessProfileContentHeader />
          <SoftSkillSuccessProfileContentList
            softSkillContent={
              softSkillContent.softSkillContent as SoftSkillSuccessProfileContent[]
            }
            count={count as number}
          />
        </Grid>
      );
    }
  }

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
      className={classes.container}
    >
      <SoftSkillSuccessProfileContentHeader />
      <Typography>No content to display</Typography>
    </Grid>
  );
};

export default PSoftSkillSuccessProfileContent;
