import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import {
  TNotification,
  useNotification,
  FormikSelectFormField,
  IFormikSelectFormField,
} from '@spotted-zebra-uk/ui-components';
import { useField } from 'formik';

export interface IFormikSelectWithQuery<TQuery>
  extends Omit<IFormikSelectFormField, 'options'> {
  query: DocumentNode;
  queryOptions?: QueryHookOptions<TQuery, OperationVariables>;
  getOptionsFromResponse: (
    response: TQuery
  ) => { label: string; value: string }[];
  isEmptySelectVisible?: boolean;
}

export const FormikSelectWithQuery = <TQuery,>({
  query,
  queryOptions,
  getOptionsFromResponse,
  isEmptySelectVisible,
  ...selectElementProps
}: IFormikSelectWithQuery<TQuery>) => {
  const { handleMsgType } = useNotification();
  const queryResult = useQuery(query, {
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error.message,
      });
    },
    skip: isEmptySelectVisible,
    ...queryOptions,
  });

  const options =
    !queryResult.data || isEmptySelectVisible
      ? []
      : getOptionsFromResponse(queryResult.data);

  return (
    <FormikSelectFormField
      options={options}
      {...selectElementProps}
      isDisabled={Boolean(
        queryResult.error ||
          queryResult.loading ||
          selectElementProps.isDisabled ||
          selectElementProps.disabled
      )}
      useFormikField={useField}
    />
  );
};
