import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import CheckboxFormField from '../../../components/molecules/CheckboxFormField/CheckboxFormField';
import Modal from '../../../components/molecules/Modal/Modal';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  CompanySignatureFindManyDocument,
  CompanySignatureFindManyQuery,
  CompanySignatureFindManyQueryVariables,
  useCompanySignatureCreateOneMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { TAlertTypes } from '../../../interfaces/snackBar';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';

interface IAddNewCompanySignatureModal {
  companyId: number;
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  getNewDefaultId: (id: number) => void;
}

interface IAddCompanySignatureError {
  signature: string[];
  signatureEmail: string[];
}

interface ICompanyInfo {
  signature: string;
  signatureEmail: string;
  isDefault: boolean;
}

const AddNewCompanySignatureModal: FC<IAddNewCompanySignatureModal> = ({
  companyId,
  displaySnackbar,
  getNewDefaultId,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useReportModalStyle();

  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>({
    signature: '',
    signatureEmail: '',
    isDefault: false,
  });
  const [errors, setErrors] = useState<IAddCompanySignatureError>({
    signature: [],
    signatureEmail: [],
  });

  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_COMPANY_SIGNATURE_MODAL);
  };

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setCompanyInfo(prev => ({ ...prev, [name]: value }));
    if (errors) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, [name]: [] } : prevErrors
      );
    }
  };

  const handleCheckChange = (value: boolean) => {
    setCompanyInfo(prev => ({ ...prev, isDefault: value }));
  };

  const addCompanySignatureFormValidation = () =>
    validate(companyInfo, {
      signature: {
        nonEmptyString: true,
      },
      signatureEmail: {
        email: true,
      },
    });

  const handleSubmit = () => {
    const newErrors = addCompanySignatureFormValidation();
    if (newErrors) {
      setErrors(newErrors);
    } else {
      companySignatureCreateOneMutation({
        variables: {
          companyId,
          isDefault: companyInfo.isDefault,
          signature: companyInfo.signature,
          signatureEmail: companyInfo.signatureEmail,
        },
      });
    }
  };

  const [
    companySignatureCreateOneMutation,
  ] = useCompanySignatureCreateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      const companySignatureData = data?.CompanySignature;
      if (companySignatureData) {
        const cachedData = cache.readQuery<
          CompanySignatureFindManyQuery,
          CompanySignatureFindManyQueryVariables
        >({
          query: CompanySignatureFindManyDocument,
          variables: {
            companyId,
          },
        });
        if (cachedData?.CompanySignature) {
          cache.writeQuery({
            query: CompanySignatureFindManyDocument,
            variables: {
              companyId,
            },
            data: {
              CompanySignature: [
                companySignatureData,
                ...cachedData.CompanySignature,
              ],
            },
          });
        }
      }
    },
    onCompleted: data => {
      if (data?.CompanySignature) {
        if (data?.CompanySignature?.isDefault) {
          getNewDefaultId(data?.CompanySignature?.id);
        }
        displaySnackbar('company signature created successfully');
        handleCloseModal();
      }
    },
  });

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            Add New Company Signature
          </Typography>
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="signature"
            name="signature"
            label="Signature"
            value={companyInfo.signature}
            onChange={handleChange}
            className={classes.mb10}
            hasError={!!errors?.signature?.length}
            errorText={errors?.signature?.join('')}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="signatureEmail"
            name="signatureEmail"
            label="Email"
            value={companyInfo.signatureEmail}
            onChange={handleChange}
            className={classes.mb10}
            hasError={!!errors?.signatureEmail?.length}
            errorText={errors?.signatureEmail?.join('')}
          />
        </Grid>
        <Grid item className={classes.checkboxContainer}>
          <CheckboxFormField
            id="isDefault"
            name="isDefault"
            label="Set as default"
            onChange={handleCheckChange}
            value={companyInfo.isDefault}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddNewCompanySignatureModal;
