import EditHeader from 'components/organisms/EditHeader/EditHeader';
import { interviewGuidesRoutes } from 'constants/navigation';
import { ModalTypes } from 'enums/modalTypes';
import {
  IgInterviewGuide,
  IgInterviewGuidesDocument,
  useIgInterviewGuideDeleteOneMutation,
  useIgInterviewGuideUpdateMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IInterviewGuideHeader {
  interviewGuide: IgInterviewGuide;
}

const InterviewGuideHeader: FC<IInterviewGuideHeader> = ({
  interviewGuide,
}) => {
  const { handleMsgType } = useNotification();
  const navigate = useNavigate();

  const [interviewGuideUpdate] = useIgInterviewGuideUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [interviewGuideDelete] = useIgInterviewGuideDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: IgInterviewGuidesDocument,
      },
    ],
    onCompleted: () => {
      navigate(interviewGuidesRoutes.interviewGuides.url());
    },
  });
  const handleArchiveInterviewGuide = () => {
    const { id, isArchived } = interviewGuide;
    interviewGuideUpdate({
      variables: {
        id,
        isArchived: !isArchived,
      },
    });
  };

  const handleDeleteInterviewGuide = () => {
    openModal(ModalTypes.CONFIRMATION_MODAL, {
      open: true,
      message: 'Are you sure you want to delete',
      onSave: () => {
        interviewGuideDelete({
          variables: {
            id: interviewGuide.id,
          },
        });
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      onClose: () => {
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      saveBtnText: 'Delete',
    });
  };

  return (
    <EditHeader
      isArchived={interviewGuide.isArchived}
      handleArchive={handleArchiveInterviewGuide}
      handleDelete={handleDeleteInterviewGuide}
      title="Edit Interview Guide"
    />
  );
};

export default InterviewGuideHeader;
