import { FC } from 'react';
import styles from '../BehaviourUploadInlineNotification.module.scss';

interface INotStartedValidationStatus {
  sheetTemplate: string;
}

const NotStartedValidationStatus: FC<INotStartedValidationStatus> = ({
  sheetTemplate,
}) => {
  return (
    <div>
      You can
      <a href={sheetTemplate} className={styles.link} download>
        download a CSV template
      </a>
      for your behaviour content.
    </div>
  );
};

export default NotStartedValidationStatus;
