import './styles.scss';
import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestFindManyDocument,
  ProjectRequestFindOneDocument,
  ProjectRequestFragment,
  useProjectRequestSoftDeleteOneMutation,
  WithDeletedChoice,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { StyledConfirmationModalProps } from 'interfaces/modal';
import { FC, MouseEventHandler } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IArchiveProjectRequestButton {
  projectRequestId: number;
  projectRequestName: string;
}

export const ArchiveProjectRequestButton: FC<IArchiveProjectRequestButton> = ({
  projectRequestId,
  projectRequestName,
}) => {
  const { handleMsgType } = useNotification();

  const [
    projectRequestArchiveOneMutation,
    { loading },
  ] = useProjectRequestSoftDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      if (data) {
        // Updates the list of project requests that are not archived (removes archived project request),
        // on the '/project-requests' page. The list that is updated is visible if Display Archived switch is off.
        cache.modify({
          fields: {
            ProjectRequestFindMany(
              existingProjectRequests = [],
              { readField, storeFieldName }
            ) {
              if (
                storeFieldName.includes(
                  `"withDeleted":"${WithDeletedChoice.OnlyNotArchived}"`
                )
              ) {
                return existingProjectRequests.filter(
                  (projectRequestRef: ProjectRequestFragment) =>
                    projectRequestId !== readField('id', projectRequestRef)
                );
              }
            },
          },
        });
        cache.gc();
      }
    },
    // TODO: Remove refetch queries after backend updated to return the project request model in response.
    // After refetch queries are removed, implement required logic in "update" function.
    refetchQueries: [
      {
        query: ProjectRequestFindManyDocument,
        variables: { withDelete: WithDeletedChoice.All },
      },
      {
        query: ProjectRequestFindOneDocument,
        variables: { id: projectRequestId },
      },
    ],
  });

  const handleArchiveButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    const confirmationModalProps: StyledConfirmationModalProps = {
      message: `Do you want to archive ${projectRequestName} project request?`,
      saveBtnText: 'Yes, I am Sure',
      cancelBtnText: 'No, Keep It',
      saveBtnColor: 'purple',
      open: true,
      onSave: () => {
        projectRequestArchiveOneMutation({
          variables: { id: projectRequestId },
        });
        closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
      },
    };

    openModal(ModalTypes.STYLED_CONFIRMATION_MODAL, confirmationModalProps);
  };

  return (
    <Button
      disabled={loading}
      variant="text"
      size="small"
      onClick={handleArchiveButtonClick}
      className="archive-project-request-button"
      rightIcon={
        <Icon
          icon="delete_outline"
          className="archive-project-request-button__icon"
        />
      }
    >
      Archive
    </Button>
  );
};
