import { StageCandidateFindManyQueryVariables } from 'generated/graphql';
import { TModal } from 'interfaces/modal';
import generatedIntrospection from 'introspection-result.json';
import {
  InMemoryCache,
  makeVar,
  ReactiveVar,
  TypePolicies,
} from '@apollo/client';

const cacheTypePolicies: TypePolicies = {
  Project: {
    fields: {
      managers: {
        merge: false,
      },
    },
  },
  Form: {
    fields: {
      formFields: {
        merge: false,
      },
    },
  },
  SuccessProfileSoftSkill: {
    keyFields: ['id'],
    fields: {
      successProfileSoftSkillTraits: {
        merge: false,
      },
    },
  },
  SuccessProfileSoftSkillTrait: {
    keyFields: ['id'],
  },
  SoftSkillTrait: {
    keyFields: false,
  },
  SoftSkill: {
    fields: {
      softSkillTraits: {
        merge: false,
      },
    },
  },
  TraitFindMany: {
    keyFields: false,
  },
  SoftSkillRoleLevelContent: {
    keyFields: ['softSkillId', 'grade', 'roleLevel'],
  },
  BasicScoreModel: {
    keyFields: false,
  },
  ScoreModel: {
    keyFields: false,
  },
  SoftSkillSuccessProfileContent: {
    keyFields: ['softSkillId', 'roleLevel'],
  },
  TraitRoleLevelContent: {
    keyFields: ['softSkillId', 'traitId', 'roleLevel', 'classification'],
  },
  InterviewQuestionRoleLevel: {
    keyFields: ['softSkillId', 'traitId', 'roleLevel', 'classification'],
  },
  Query: {
    fields: {
      StageCandidateFindMany: {
        keyArgs: (args: StageCandidateFindManyQueryVariables | null) =>
          Object.keys(args ?? {}).filter(arg => arg !== 'relationDepth'),
      },
      modals: {
        read() {
          return modalsVar();
        },
      },
      StageFindMany: {
        merge: false,
      },
      Fields: {
        merge: false,
      },
      SoftSkillFindMany: {
        merge: false,
      },
      Traits: {
        merge: false,
      },
      RssAssessmentFindMany: {
        merge: false,
      },
      ProjectJobRoleFindMany: {
        merge: false,
      },
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: cacheTypePolicies,
});

export const modalsVar: ReactiveVar<TModal[]> = makeVar<TModal[]>([]);

export default cache;
