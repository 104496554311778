import { ResultValidatedSkillsModelFragment } from 'generated/graphql';
import { FC } from 'react';
import SkillsValidatedTableCell from './SkillsValidatedTableCell/SkillsValidatedTableCell';

interface ISkillsValidatedTable {
  validatedSkills: ResultValidatedSkillsModelFragment[];
}

const SkillsValidatedTable: FC<ISkillsValidatedTable> = ({
  validatedSkills,
}) => {
  return (
    <div
      className="skills-validated-table"
      data-testid="skills-validated-table"
    >
      <div className="skills-validated-table__row-container" key="header">
        <SkillsValidatedTableCell text="Company" isHeading={true} />
        <SkillsValidatedTableCell text="Skills Validated" isHeading={true} />
      </div>
      {validatedSkills.map(validatedSkill => (
        <div
          className="skills-validated-table__row-container"
          key={validatedSkill.company.id}
          data-testid="skills-validated-row"
        >
          <SkillsValidatedTableCell
            text={`${validatedSkill.company.name} (${validatedSkill.company.id})`}
          />
          <SkillsValidatedTableCell
            text={validatedSkill.totalValidateSkills.toString()}
          />
        </div>
      ))}
    </div>
  );
};

export default SkillsValidatedTable;
