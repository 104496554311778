import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { Button } from '@spotted-zebra-uk/ui-components';
import styles from './BehaviourContentHeader.module.scss';

const BehaviourContentHeader = () => {
  const handleExportClicked = () => {
    openModal(ModalTypes.BEHAVIOUR_CONTENT_MODAL, {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>Behaviour Content </div>
      <Button
        onClick={handleExportClicked}
        leftIcon={<Icon icon="export_icon" />}
      >
        Export content
      </Button>
    </div>
  );
};

export default BehaviourContentHeader;
