import SidebarNavigation from 'components/organisms/SidebarNavigation/SidebarNavigation';
import { FC } from 'react';
import { resultsSidebarConfig } from './ResultsNavigation.constants';

interface IResultsNavigation {}

const ResultsNavigation: FC<IResultsNavigation> = () => {
  return <SidebarNavigation routes={resultsSidebarConfig} />;
};

export default ResultsNavigation;
