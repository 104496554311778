import {
  DeiStageActionModel,
  StageActionAvailability,
  StageActionSubType,
  StageActionType,
  useStageQuery,
} from 'generated/graphql';
import { FC, useMemo, useState } from 'react';
import {
  Button,
  Loader,
  Modal,
  SelectFormField,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import AddSkillsSelfAssessmentAction from './AddSkillsSelfAssessmentAction';
import {
  deiInitialValues,
  getPrefilledDeiFields,
} from './DeiActionForm/dei.helpers';
import DeiActionForm from './DeiActionForm/DeiActionForm';

type TStageActionModalMode =
  | 'add-skills-self-assessment-action'
  | 'add-dei-action';

interface IStageActionModal {
  stageId: number;
  projectId: number;
  deiData: DeiStageActionModel;
  onClose: () => void;
}

const StageActionModal: FC<IStageActionModal> = ({
  onClose,
  stageId,
  deiData,
  projectId,
}) => {
  const [mode, setMode] = useState<TSelectOption | undefined>();
  const { handleMsgType } = useNotification();

  const handleModeChange = (newValue: TSelectOption) => {
    setMode(newValue);
  };

  const stageQueryResponse = useStageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: stageId,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const softSkillAssessmentAvailability = stageQueryResponse.data?.Stage?.availableActions.find(
    actionAvailability =>
      actionAvailability.type === StageActionType.RssAssessment &&
      actionAvailability.subType === StageActionSubType.RssAssessmentSoft
  );

  const technicalSkillAssessmentAvailability = stageQueryResponse.data?.Stage?.availableActions.find(
    actionAvailability =>
      actionAvailability.type === StageActionType.RssAssessment &&
      actionAvailability.subType === StageActionSubType.RssAssessmentTechnical
  );

  const hasDeiForm = deiData && !deiData.deletedAt;
  const hasArchivedDeiForm = Boolean(deiData?.deletedAt);

  const selectActionTypeOptions = [
    {
      label: 'Skill self-assessment',
      value: 'add-skills-self-assessment-action' as TStageActionModalMode,
      isDisabled:
        softSkillAssessmentAvailability?.availability !==
          StageActionAvailability.Available &&
        technicalSkillAssessmentAvailability?.availability !==
          StageActionAvailability.Available,
    },
    {
      label: 'Diversity, equity & inclusion',
      value: 'add-dei-action' as TStageActionModalMode,
      isDisabled: hasDeiForm,
    },
  ];

  const prefilledDeiData = useMemo(
    () => getPrefilledDeiFields(deiData?.fields || []),
    [deiData]
  );

  return (
    <Modal
      header="Add action"
      isOpen
      onClose={onClose}
      className="add-stage-action-modal"
    >
      {!stageQueryResponse.data && <Loader variant="bubbles" />}
      {stageQueryResponse.data && (
        <>
          <div className="add-stage-action-modal__header">
            <SelectFormField
              options={selectActionTypeOptions}
              onChange={handleModeChange}
              id="stage-action-modal-mode-select"
              value={mode}
              label="Action"
              placeholder="Action"
            />
          </div>
          {mode?.value === 'add-skills-self-assessment-action' && (
            <AddSkillsSelfAssessmentAction
              softSkillAssessmentAvailability={softSkillAssessmentAvailability}
              technicalSkillAssessmentAvailability={
                technicalSkillAssessmentAvailability
              }
              stageId={stageId}
              onClose={onClose}
            />
          )}
          {mode?.value === 'add-dei-action' && (
            <DeiActionForm
              stageId={stageId}
              projectId={projectId}
              onClose={onClose}
              type="create"
              initialValues={
                hasArchivedDeiForm
                  ? { deiValues: prefilledDeiData }
                  : deiInitialValues
              }
              hasArchivedDeiForm={hasArchivedDeiForm}
            />
          )}
          {!mode && (
            <div className="add-stage-action-modal__actions">
              <Button onClick={onClose} size="medium" variant="secondary">
                Cancel
              </Button>
              <Button size="medium" disabled>
                Add action
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default StageActionModal;
