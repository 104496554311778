import { AUTH_LOGIN_ROUTE } from 'constants/authentication';
import { getAccessToken } from 'helpers/auth';
import { FunctionComponent } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom';

interface IPrivateRoutes extends RouteProps {
  redirectUrl?: string;
  allowed?: boolean;
}

export interface IPrivateRouteRedirectLocationState {
  from: Location;
}

const PrivateRoutes: FunctionComponent<IPrivateRoutes> = ({
  redirectUrl = AUTH_LOGIN_ROUTE,
  allowed = true,
}) => {
  const accessToken = getAccessToken();
  const location = useLocation();

  if (!accessToken || !allowed) {
    return <Navigate to={redirectUrl} state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
