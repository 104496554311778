import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Tooltip } from '@spotted-zebra-uk/ui-components';
import styles from './TextWithTooltip.module.scss';

type TextWithTooltipProps = { text: string; className?: string };

function TextWithTooltip({ text, className }: TextWithTooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  // average width size of a letter (letter I takes less space then M)
  const AVG_FONT_SIZE_IN_PIXELS = 8;

  const ref = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        const cardContainerWidth = node.offsetWidth;
        const charsInOneLine = Math.floor(
          cardContainerWidth / AVG_FONT_SIZE_IN_PIXELS
        );
        const shouldShowTooltip = text.length > charsInOneLine;
        setShowTooltip(shouldShowTooltip);
      }
    },
    [text]
  );

  if (showTooltip) {
    return (
      <Tooltip content={text} className={styles.tooltipText}>
        <div ref={ref} className={clsx(styles.singleLineText, className)}>
          {text}
        </div>
      </Tooltip>
    );
  }

  return (
    <div ref={ref} className={className}>
      {text}
    </div>
  );
}

export default TextWithTooltip;
