import { ModalTypes } from 'enums/modalTypes';
import {
  RssAssessmentFragment,
  StageDocument,
  useRssAssessmentDeleteManyMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { FC, useState } from 'react';
import { NotificationModalProps } from 'views/ModalView/NotificationModal';
import {
  Button,
  TNotification,
  useNotification,
  NotificationModalType,
} from '@spotted-zebra-uk/ui-components';
import { updateCacheAfterRSSAssessmentDeleteMany } from './helpers';

interface IRemoveSkillsSelfAssessmentActionButton {
  rssAssessments: RssAssessmentFragment[];
  stageId: number;
}

const RemoveSkillsSelfAssessmentActionButton: FC<IRemoveSkillsSelfAssessmentActionButton> = ({
  rssAssessments,
  stageId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { handleMsgType } = useNotification();
  const [rssAssessmentDeleteManyMutation] = useRssAssessmentDeleteManyMutation({
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        message:
          'Ops, some error is ocurred during removing Skills self-assessment action!',
      });
      setSubmitting(false);
    },
    refetchQueries: [{ query: StageDocument, variables: { id: stageId } }],
    update: (cache, { data }) => {
      const rssAssessmentsIds = rssAssessments.map(rssa => rssa.id);

      updateCacheAfterRSSAssessmentDeleteMany(
        cache,
        stageId,
        rssAssessmentsIds,
        data
      );
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Skills self-assessment action removed!',
      });
      setSubmitting(false);
    },
  });

  const handleDelete = () => {
    const confirmationModalProps: NotificationModalProps = {
      message:
        'This action will remove "Skills self-assessment" from the stage',
      okButtonChildren: 'OK',
      type: NotificationModalType.WARNING,
      onOkClick: () => {
        setSubmitting(true);
        rssAssessmentDeleteManyMutation({
          variables: {
            ids: rssAssessments.map(rssAssessment => rssAssessment.id),
          },
        });
        closeModal(ModalTypes.NOTIFICATION_MODAL);
      },
    };

    openModal(ModalTypes.NOTIFICATION_MODAL, confirmationModalProps);
  };

  return (
    <Button
      variant="secondary"
      size="medium"
      className="remove-skills-self-assessment-action-button"
      onClick={handleDelete}
      disabled={submitting}
    >
      Remove
    </Button>
  );
};

export default RemoveSkillsSelfAssessmentActionButton;
