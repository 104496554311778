import { StageModes } from 'views/Project/components/Stages/types';
import { TagColor } from '@spotted-zebra-uk/ui-components';

export const TOOLTIP_CONTENT = {
  [StageModes.ASSIGNEE_BASED]:
    'Only assignees are notified when their candidates assessments are complete.',
  [StageModes.ACCESS_BASED]:
    'All project team members with access to a candidate are notified when their assessments are complete.',
  [StageModes.PROJECT_ROLE_BASED]:
    'Only project team members with specified project roles will be notified when candidates complete their assessments.',
  [StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED]:
    'Only specified project roles within the project team, assignees, or both will be notified when candidates complete their assessments.',
  [StageModes.DISABLED]:
    'No notifications will be sent when candidates complete their assessments.',
};

export const MODE_TAG_COLOR = {
  [StageModes.ASSIGNEE_BASED]: TagColor.BLUE,
  [StageModes.ACCESS_BASED]: TagColor.PURPLE,
  [StageModes.PROJECT_ROLE_BASED]: TagColor.GREEN,
  [StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED]: TagColor.RED,
  [StageModes.DISABLED]: TagColor.YELLOW,
};
