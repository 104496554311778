import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import CircularLoader from '../../../../components/molecules/CircularLoader/CircularLoader';
import TraitFiltersMultiselectFormField from '../../../../components/molecules/MultiselectFormField/TraitFiltersMultiselectFormField/TraitFiltersMultiselectFormField';
import SoftSkillSelectFormField from '../../../../components/molecules/SelectFormField/SoftSkillSelectFormField/SoftSkillSelectFormField';
import {
  useFixContentPieceLazyQuery,
  useValidateContentPieceLazyQuery,
} from '../../../../generated/graphql';
import { TFormFieldValue } from '../../../../interfaces/forms/form';
import { IValidateContentModal } from '../ValidateContentModal';
import useValidateContentFormStyle from './ValidateContentFormStyle';

export interface IValidateContentForm extends IValidateContentModal {}

export interface IValidateContentFormValues {
  softSkill: number;
  traitIds?: number[];
}

const ValidateContentForm: FC<IValidateContentForm> = () => {
  const classes = useValidateContentFormStyle();
  const [
    validateContent,
    validateContentResponse,
  ] = useValidateContentPieceLazyQuery();
  const [fixContentPiece, fixContentResponse] = useFixContentPieceLazyQuery();

  const checkValue = (values: IValidateContentFormValues): void => {
    validateContent({
      variables: {
        softSkillId: +values.softSkill,
        traitIds: values.traitIds,
      },
    });
  };

  const fixContent = (values: IValidateContentFormValues): void => {
    fixContentPiece({
      variables: {
        softSkillId: +values.softSkill,
        traitIds: values.traitIds,
      },
    });
  };

  const [values, setValues] = useState<IValidateContentFormValues>({
    softSkill: 1,
    traitIds: [],
  });

  const handleChange = (value: TFormFieldValue, name: string) => {
    /**
     * Reset the traits
     * If the softskill is set, then the traits need to be reset
     * If the traits are set, then they will be set regardless
     */
    setValues(prevValues => ({ ...prevValues, traitIds: [], [name]: value }));
  };

  const key = 'ff';
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.container}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h6">Validate content piece</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <SoftSkillSelectFormField
          id={`${key}-soft-skills`}
          name="softSkill"
          label="Soft skill filter"
          value={values.softSkill}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <TraitFiltersMultiselectFormField
          id={`${key}-traits`}
          name="traitIds"
          label="Traits filter"
          value={values.traitIds}
          onChange={handleChange}
          softSkillIds={[+values.softSkill]}
        />
      </Grid>
      <Grid className={classes.buttonWrapper} item>
        <Button onClick={() => checkValue(values)}>Check values</Button>
      </Grid>
      <Grid className={classes.buttonWrapper} item>
        <Button onClick={() => fixContent(values)}>Fix content</Button>
      </Grid>
      {validateContentResponse.loading || fixContentResponse.loading ? (
        <Grid item>
          <CircularLoader />
        </Grid>
      ) : (
        ''
      )}
      {validateContentResponse.error || fixContentResponse.error ? (
        <Grid item>
          <Typography>Error</Typography>
        </Grid>
      ) : (
        ''
      )}
      {validateContentResponse.data?.validateContent ? (
        <Grid container item justifyContent="center" alignItems="center">
          <Typography>
            {`${validateContentResponse.data.validateContent.numOfSsContent} / ${validateContentResponse.data.validateContent.numOfSsSpContent} / ${validateContentResponse.data.validateContent.numOfTraitContent} / ${validateContentResponse.data.validateContent.numOfIqContent}`}
          </Typography>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default ValidateContentForm;
