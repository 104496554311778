import Button from 'components/atoms/Button/Button';
import Editor from 'components/ui/Editor/Editor';
import { roleLevelOptions } from 'constants/roleLevel';
import {
  SoftSkillSuccessProfileContent,
  useSaveSoftSkillSuccessProfileContentMutation,
} from 'generated/graphql';
import { TFormFieldValue } from 'interfaces/forms/form';
import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useSoftSkillSuccessProfileContentListItemStyle from './SoftSkillSuccessProfileContentListItemStyle';

interface ISoftSkillSuccessProfileContentListItem {
  contentPiece: SoftSkillSuccessProfileContent;
}

interface ISoftSkillSuccessProfileContentFieldValues {
  description: string;
  shortDescription: string;
}

const SoftSkillSuccessProfileContentListItem: FC<ISoftSkillSuccessProfileContentListItem> = ({
  contentPiece,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useSoftSkillSuccessProfileContentListItemStyle();
  const [values, setValues] = useState<
    ISoftSkillSuccessProfileContentFieldValues
  >({
    description: contentPiece.description ? contentPiece.description : '',
    shortDescription: contentPiece.shortDescription
      ? contentPiece.shortDescription
      : '',
  });
  const [isSaving, setIsSaving] = useState(false);

  const [
    saveSoftSkillSuccessProfileContentMutation,
  ] = useSaveSoftSkillSuccessProfileContentMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setIsSaving(false);
    },
  });

  const handleChangeDescription = (id: string, value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, description: value as string }));
  };

  const handleChangeShortDescription = (id: string, value: TFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      shortDescription: value as string,
    }));
  };

  const handleSubmit = () => {
    saveSoftSkillSuccessProfileContent(values);
    setIsSaving(true);
  };

  const saveSoftSkillSuccessProfileContent = (
    values: ISoftSkillSuccessProfileContentFieldValues
  ) => {
    const id = contentPiece.id;
    const description = values.description
      ? values.description
      : contentPiece.description;
    const shortDescription = values.shortDescription
      ? values.shortDescription
      : contentPiece.shortDescription;
    saveSoftSkillSuccessProfileContentMutation({
      variables: {
        id,
        description,
        shortDescription,
      },
    });
  };

  const key = 'ssspcli';
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
    >
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.softSkill?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>
          {
            roleLevelOptions.filter(
              roleLevel => roleLevel.value === contentPiece.roleLevel
            )[0].label
          }
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Editor
          id={`${key}-shortDescription-${contentPiece.id}`}
          onChange={handleChangeShortDescription}
          value={values.shortDescription}
        />
      </Grid>
      <Grid item xs={4}>
        <Editor
          id={`${key}-description-${contentPiece.id}`}
          onChange={handleChangeDescription}
          value={values.description}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={handleSubmit}>{isSaving ? 'Saving..' : 'Save'}</Button>
      </Grid>
    </Grid>
  );
};

export default SoftSkillSuccessProfileContentListItem;
