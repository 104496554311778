import clsx from 'clsx';
import ProjectModeTag from 'components/feature/project/projectModeTag/ProjectModeTag';
import { ReportsAccessTypes } from 'components/feature/ReportsAccess/ReportsAccess';
import ProjectInfoItem from 'components/molecules/ProjectnfoItem/ProjectInfoItem';
import { SmStageModelFragment } from 'generated/graphql';
import { capitalize } from 'lodash';
import CandidateListButton from '../StageActionButtons/DirectInvitation';
import IndirectInvitationButton from '../StageActionButtons/IndirectInvitationButton';
import { getStageMode } from '../StageForm/StageForm.helper';
import {
  getActorRoles,
  getStageDate,
  getYesOrNo,
} from './StageOverview.helpers';
import styles from './StageOverview.module.scss';

interface IStageOverview {
  stage: SmStageModelFragment;
  projectId: number;
}

const StageOverview = ({ stage, projectId }: IStageOverview) => {
  return (
    <div className={styles.container}>
      <HeaderSection stage={stage} />
      <GeneralSection stage={stage} projectId={projectId} />
      <ProjectTeamEmailsSection stage={stage} />
    </div>
  );
};

export default StageOverview;

const HeaderSection = ({ stage }: Pick<IStageOverview, 'stage'>) => {
  const headerRows = [
    { label: 'Stage ID', value: stage.id },
    { label: 'Order', value: `Stage ${stage.order}` },
  ];
  return (
    <div className={clsx(styles.row, styles.firstRow)}>
      {headerRows.map(({ label, value }) => (
        <ProjectInfoItem key={label} label={label} value={value} />
      ))}
    </div>
  );
};

const GeneralSection = ({
  stage,
  projectId,
}: Pick<IStageOverview, 'stage' | 'projectId'>) => {
  const stageGeneralSectionRows = [
    {
      firstLabel: 'Name',
      firstValue: stage.name,
      secondLabel: 'Type',
      secondValue: capitalize(stage.type),
    },
    {
      firstLabel: 'Start at',
      firstValue: getStageDate(stage.startTime),
      secondLabel: 'End at',
      secondValue: stage.endTime ? getStageDate(stage.endTime) : '—',
    },
    {
      firstLabel: 'Email candidate the report',
      firstValue: getYesOrNo(stage.emailCandidateReport),
      secondLabel: 'Email SZ admin the reports',
      secondValue: getYesOrNo(stage.emailSzAdmin),
    },
    {
      firstLabel: 'Enable DIG',
      firstValue: getYesOrNo(stage.createInterviewGuide),
      secondLabel: 'DIG face-to-face interview recordings',
      secondValue: getYesOrNo(stage.enableF2fInterviews),
    },
  ];

  return (
    <div className={styles.section}>
      <div className={styles.row}>
        <h3 className={styles.sectionTitle}>General</h3>
        <div className={styles.invitationActions}>
          <IndirectInvitationButton projectId={projectId} stageId={stage.id} />
          <CandidateListButton projectId={projectId} stageId={stage.id} />
        </div>
      </div>
      {stageGeneralSectionRows.map(
        ({ firstLabel, secondLabel, firstValue, secondValue }, index) => (
          <div key={`row-${index}`} className={styles.row}>
            <ProjectInfoItem label={firstLabel} value={firstValue} />
            {secondLabel && secondValue && (
              <ProjectInfoItem label={secondLabel} value={secondValue} />
            )}
          </div>
        )
      )}
    </div>
  );
};

const ProjectTeamEmailsSection = ({ stage }: Pick<IStageOverview, 'stage'>) => {
  const stageMode = getStageMode(
    stage.emailManagerReport,
    stage.emailProjectTeam,
    stage.emailProjectTeamRoles
  );
  const projectTeamDetails = [
    {
      label: 'Mode',
      value: <ProjectModeTag mode={stageMode} />,
    },
    {
      label: 'Project role(s)',
      value: getActorRoles(stage.emailProjectTeamRoles),
    },
    {
      label: 'Reports accessible via',
      value: stage.managerReportEmailLinks
        ? ReportsAccessTypes.LINKS
        : ReportsAccessTypes.ATTACHMENTS,
    },
  ];

  return (
    <div className={styles.section}>
      <div className={styles.row}>
        <h3 className={styles.sectionTitle}>Project team emails</h3>
      </div>
      {projectTeamDetails.map(({ label, value }) => (
        <div key={label} className={styles.longRow}>
          <ProjectInfoItem label={label} value={value} />
        </div>
      ))}
    </div>
  );
};
