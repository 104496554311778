import clsx from 'clsx';
import {
  calculateSoftSkillWeightsSum,
  dataSourcesFieldsArray,
  getEmptyFormSoftSkillValues,
  ProfileSkillsTabsValues,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.helpers';
import { TJobRoleFormValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import FormSoftSkillFields from 'components/feature/jobRole/JobRoleFormSoftSkillFields/JobRoleFormSoftSkillFields';
import TechincalSkillsFields from 'components/feature/jobRole/TechincalSkillsFields/TechincalSkillsFields';
import { CompanyFormikSelectFormField } from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import { jobFamilyOptions, roleLevelOptions } from 'constants/roleLevel';
import Decimal from 'decimal.js';
import { Form, useField, useFormikContext } from 'formik';
import { SoftSkillFragment } from 'generated/graphql';
import { FC, useMemo } from 'react';
import {
  Button,
  TSelectOption,
  Tooltip,
  FormikCheckboxFormField,
  FormikSelectFormField,
  FormikTextInputField,
  TabListLegacy as TabList,
  TabPanelLegacy as TabPanel,
} from '@spotted-zebra-uk/ui-components';
import Icon from '../../../atoms/Icon';
import styles from './JobRoleFormFields.module.scss';
import { orientationOptions } from '../TraitsFormItem/TraitsFormItem';

interface IFormFields {
  dataSourceModalSoftSkillIndex?: number;
  traitsModalSoftSkillIndex?: number;
  softSkills: SoftSkillFragment[];
  useCustomWeightsVisible?: boolean;
  disableRoleLevelField?: boolean;
  onOpenTraitsModal: (traitsModalSoftSkillIndex: number) => void;
  onCloseTraitsModal: () => void;
  openDataSourcesModal: (softSkillIndex: number) => void;
  onCloseDataSourcesModal: () => void;
  onCancel: () => void;
}

const companyFieldTooltipContent = `
    <div style='text-align: center;'>
      Company owner cannot be
    </div>
    <div style='text-align: center;'>
      changed as this role is already
    </div>
    <div style='text-align: center;'>
      attached to a project.
    </div>
  `;

const FormFields: FC<IFormFields> = ({
  softSkills,
  traitsModalSoftSkillIndex,
  dataSourceModalSoftSkillIndex,
  useCustomWeightsVisible = true,
  disableRoleLevelField = false,
  onCloseTraitsModal,
  onCloseDataSourcesModal,
  onOpenTraitsModal,
  openDataSourcesModal,
  onCancel,
}) => {
  const { handleSubmit, values, setFieldValue } = useFormikContext<
    TJobRoleFormValues
  >();

  const weightSum = calculateSoftSkillWeightsSum(values.softSkills);

  const handleChangeSoftSkill = (
    newSoftSkillId: TSelectOption,
    index: number
  ) => {
    if (!newSoftSkillId.value) {
      return setFieldValue(
        `softSkills.${index}`,
        getEmptyFormSoftSkillValues()
      );
    }

    const softSkill = softSkills.find(
      softSkill => softSkill.id === Number(newSoftSkillId.value)
    );

    if (!softSkill) return;

    const softSkillValues = values.softSkills[index];
    const newSoftSkillValues = {
      softSkillId: newSoftSkillId,
      name: softSkill.name,
      type: {
        value: softSkillValues.type.value,
        label: softSkillValues.type.label,
      },
      weight: '',
      traitsValues: softSkill.softSkillTraits?.map(ssTrait => ({
        id: ssTrait.id || 0,
        traitId: ssTrait.traitId || 0,
        name: ssTrait.traitName || '',
        weight: new Decimal(ssTrait.weight).mul(100),
        orientation: orientationOptions[ssTrait.orientation || 0],
      })),
      dataSourceValues: dataSourcesFieldsArray,
      includeInCalibration: softSkillValues.includeInCalibration,
    };

    setFieldValue(`softSkills.${index}`, newSoftSkillValues);
  };

  const numberOfSoftSkills = useMemo(
    () => values.softSkills.filter(skill => skill.name).length,
    [values.softSkills]
  );
  const numberOfTechnicalSkills = useMemo(
    () => values.technicalSkills.filter(skill => skill.value).length,
    [values.technicalSkills]
  );
  const tabsOptions = [
    {
      title: 'Soft skills',
      value: ProfileSkillsTabsValues.SOFT_SKILLS,
      additionalInfo: numberOfSoftSkills,
    },
    {
      title: 'Technical skills',
      value: ProfileSkillsTabsValues.TECHNICAL_SKILLS,
      additionalInfo: numberOfTechnicalSkills,
    },
  ];

  const weightSumString = `Weights sum: ${weightSum.toLocaleString()}`;
  // TODO: Remove all form field wrappers after update dependencies.
  return (
    <Form noValidate onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.firstFieldsRow} data-testid="job-role-form-fields">
        <div className={styles.fieldWrapper}>
          <FormikTextInputField
            id="jobRoleName"
            label="Name"
            data-role="job-role-name-input"
            data-testid="job-role-name-input"
            placeholder="Name"
            useFormikField={useField}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <Tooltip html={companyFieldTooltipContent}>
            <div>
              <div data-testid="company-select-field-wrapper">
                <CompanyFormikSelectFormField
                  id="company"
                  label="Company"
                  placeholder="Company"
                  maxMenuHeight={240}
                  name="company"
                  isDisabled
                />
              </div>
            </div>
          </Tooltip>
        </div>

        <div className={styles.fieldWrapper}>
          <FormikTextInputField
            id="additionalInformation"
            label="Additional information"
            data-role="job-role-additional-information-input"
            placeholder="Additional information"
            data-testid="job-role-additional-information-input"
            useFormikField={useField}
          />
        </div>
      </div>
      <div className={styles.secondFieldsRow}>
        <div
          className={styles.fieldWrapper}
          data-testid="role-level-select-wrapper"
        >
          <FormikSelectFormField
            id="roleLevel"
            label="Role level"
            options={roleLevelOptions}
            data-role="role-level-select"
            placeholder="Role level"
            isDisabled={disableRoleLevelField}
            maxMenuHeight={240}
            selectProps={{
              'data-testid': 'role-level-select',
            }}
            useFormikField={useField}
            hasClearIndicator={false}
          />
        </div>
        <div
          className={styles.jobFamilyField}
          data-testid="job-family-select-wrapper"
        >
          <FormikSelectFormField
            id="family"
            label="Job Family (Right for role)"
            options={jobFamilyOptions}
            data-role="job-family-select"
            placeholder="Job Family"
            maxMenuHeight={240}
            selectProps={{
              'data-testid': 'job-family-select',
            }}
            useFormikField={useField}
            hasClearIndicator={false}
          />
          <Tooltip
            content="Skills for 'Right for us' and 'Right for the future' are benchmarked globally, not tailored to specific job families."
            id="tooltip-trigger"
          >
            <Icon
              icon="info"
              id="tooltip-trigger"
              className={styles.infoIcon}
            />
          </Tooltip>
        </div>
        {useCustomWeightsVisible && (
          <div className={styles.useCustomWeightsCheckboxWrapper}>
            <FormikCheckboxFormField
              id="customWeights"
              label="Use Custom Weights"
              data-role="custom-weights-check"
              data-testid="custom-weights-check"
              useFormikField={useField}
            />
          </div>
        )}
        <div
          className={clsx(
            styles.customWeightLabel,
            weightSum !== 100 && styles.customWeightLabel_error
          )}
        >
          {values.customWeights && <span>{weightSumString}</span>}
        </div>
      </div>

      <div className={styles.skillsWrapper}>
        <TabList
          initialValue={ProfileSkillsTabsValues.SOFT_SKILLS}
          options={tabsOptions}
        >
          <TabPanel value={ProfileSkillsTabsValues.SOFT_SKILLS}>
            <FormSoftSkillFields
              openTraitsModal={onOpenTraitsModal}
              onCloseTraitsModal={onCloseTraitsModal}
              traitsModalSoftSkillIndex={traitsModalSoftSkillIndex}
              onCloseDataSourcesModal={onCloseDataSourcesModal}
              dataSourceModalSoftSkillIndex={dataSourceModalSoftSkillIndex}
              openDataSourcesModal={openDataSourcesModal}
              onChangeSoftSkill={handleChangeSoftSkill}
            />
          </TabPanel>
          <TabPanel value={ProfileSkillsTabsValues.TECHNICAL_SKILLS}>
            <TechincalSkillsFields />
          </TabPanel>
        </TabList>
      </div>
      <div className={styles.actions}>
        <Button
          onClick={onCancel}
          type="button"
          variant="secondary"
          size="medium"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          data-role="save-skills-profile"
          data-testid="save-form"
          size="medium"
          disabled={!values.jobRoleName}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FormFields;
