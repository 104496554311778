import { ModalTypes } from 'enums/modalTypes';
import { useField } from 'formik';
import {
  CompanyEmployeeModel,
  CompanyMaybeUserEmployeeFindManyQuery,
  useCompanyMaybeUserEmployeeFindManyQuery,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { ComponentProps, FC, memo, useCallback } from 'react';
import { EmployeeModalMode } from 'views/ModalView/CompanyEmployeeModal/CompanyEmployeeModal.enums';
import {
  TNotification,
  useNotification,
  SelectWithMenuButtonField,
  TOption,
} from '@spotted-zebra-uk/ui-components';

type SelectWithMenuButtonFieldProps = ComponentProps<
  typeof SelectWithMenuButtonField
>;

interface ICompanyMaybeUserEmployeesSelect
  extends Omit<SelectWithMenuButtonFieldProps, 'options' | 'menuButtonProps'> {
  companyId?: number;
  getOptionsFromResponse?: (
    responseData: CompanyMaybeUserEmployeeFindManyQuery
  ) => TOption[];
  onEmployeeCreated?: (employee: CompanyEmployeeModel) => void;
}

const defaultOptionsParser = (
  responseData: CompanyMaybeUserEmployeeFindManyQuery
): TOption[] => {
  if (!responseData.CompanyMaybeUserEmployeeFindMany) {
    return [];
  }

  return responseData.CompanyMaybeUserEmployeeFindMany.map(
    ({ firstName, lastName, email }) => ({
      label: `${firstName} ${lastName}`,
      value: email,
      labelNote: email,
    })
  );
};

const CompanyMaybeUserEmployeesSelect: FC<ICompanyMaybeUserEmployeesSelect> = ({
  getOptionsFromResponse,
  onEmployeeCreated,
  companyId,
  hasMenuButton,
  ...selectElementProps
}) => {
  const { handleMsgType } = useNotification();

  const { data } = useCompanyMaybeUserEmployeeFindManyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    skip: !companyId,
    variables: {
      companyId: companyId as number,
    },
  });

  const handleCreateEmployeeButtonClick = () => {
    if (companyId) {
      openModal(ModalTypes.COMPANY_EMPLOYEE_MODAL, {
        mode: EmployeeModalMode.CREATE,
        employee: {
          companyId: companyId,
        },
        onEmployeeCreated,
      });
    }
  };

  if (!data) {
    return (
      <SelectWithMenuButtonField
        {...selectElementProps}
        options={[]}
        hasMenuButton={false}
        menuButtonProps={{
          children: '+ Create New Employee',
          onClick: handleCreateEmployeeButtonClick,
        }}
      />
    );
  }

  const options = getOptionsFromResponse?.(data) || defaultOptionsParser(data);

  return (
    <SelectWithMenuButtonField
      {...selectElementProps}
      options={options}
      hasMenuButton={data && hasMenuButton}
      menuButtonProps={{
        children: '+ Create New Employee',
        onClick: handleCreateEmployeeButtonClick,
      }}
    />
  );
};

const CompanyMaybeUserEmployeesSelectPure = memo(
  CompanyMaybeUserEmployeesSelect
);

interface IFormikCompanyMaybeUserEmployeesSelect
  extends Omit<
    ICompanyMaybeUserEmployeesSelect,
    'onChange' | 'value' | 'error' | 'hasError'
  > {}

export const FormikCompanyMaybeUserEmployeesSelect: FC<IFormikCompanyMaybeUserEmployeesSelect> = ({
  id,
  ...restProps
}) => {
  const [field, meta, helpers] = useField<TOption>(id);

  const handleChange = useCallback(
    (newValues: TOption) => helpers.setValue(newValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasError = Boolean(meta.error && meta.touched);
  const error = meta.error as
    | { label: string; value: string; labelNote?: string }
    | undefined;
  return (
    <CompanyMaybeUserEmployeesSelectPure
      id={id}
      value={field.value}
      onChange={handleChange}
      hasError={hasError}
      bottomText={hasError ? error?.value : ''}
      {...restProps}
    />
  );
};

export default CompanyMaybeUserEmployeesSelect;
