import Button from 'components/atoms/Button/Button';
import { reportsRoutes } from 'constants/navigation';
import { ReportConfig } from 'generated/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import useWidgetStyles from '../WidgetStyles';

interface IReportConfigs {
  id: number;
  newPageBefore: boolean;
  report: {
    id: number;
    name: string;
  };
  reportId: number;
}

interface IWidgetReports {
  reportConfigs: ReportConfig[];
}

const WidgetReports: FC<IWidgetReports> = ({ reportConfigs }) => {
  const navigate = useNavigate();
  const classes = useWidgetStyles();

  const handleViewReportBtnClick = (reportId: number) => {
    navigate(reportsRoutes.report.url(reportId));
  };

  return (
    <Grid mt={2}>
      <Grid xs={3} mb={2}>
        <Typography variant="h6" className={classes.title}>
          Used in Reports
        </Typography>
      </Grid>
      {reportConfigs?.map((item: IReportConfigs) => (
        <Grid key={item.id} className={classes.mb10}>
          <div className={classes.widgetOption}>
            <p style={{ width: '280px' }}>{item.report.name}</p>
            <div>
              <Button
                color="inherit"
                className={classes.firstBtn}
                onClick={() => handleViewReportBtnClick(item.reportId)}
              >
                VIEW
              </Button>
              <Button className={classes.btn} color="inherit">
                REMOVE
              </Button>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default WidgetReports;
