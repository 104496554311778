import { ApolloCache, InMemoryCache } from '@apollo/client';
import {
  IgStep,
  IgStepFindManyDocument,
  IgStepFindManyQuery,
  IgStepFindManyQueryVariables,
} from '../../../generated/graphql';

export const updateIgSteps = (
  cache: ApolloCache<InMemoryCache>,
  data: IgStep,
  interviewGuideId: number
): IgStep[] | null => {
  const changedIgStep = data;
  if (changedIgStep) {
    let newArchivedIgSteps, newIgSteps, orderedIgSteps: IgStep[];
    const previouslyArchived = !changedIgStep.isArchived;
    const archivedIgSteps = cache.readQuery<
      IgStepFindManyQuery,
      IgStepFindManyQueryVariables
    >({
      query: IgStepFindManyDocument,
      variables: {
        interviewGuideId: interviewGuideId,
        isArchived: true,
      },
    });
    const igSteps = cache.readQuery<
      IgStepFindManyQuery,
      IgStepFindManyQueryVariables
    >({
      query: IgStepFindManyDocument,
      variables: {
        interviewGuideId,
        isArchived: false,
      },
    });

    if (previouslyArchived) {
      newArchivedIgSteps = archivedIgSteps?.IgStepFindMany?.filter(
        igStep => igStep.id !== changedIgStep.id
      ) as IgStep[];
      cache.writeQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>({
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: true,
        },
        data: {
          IgStepFindMany: newArchivedIgSteps,
        },
      });
      newIgSteps = igSteps?.IgStepFindMany as IgStep[];
      orderedIgSteps = [...newIgSteps, changedIgStep];
      orderedIgSteps = orderedIgSteps.map((igStep: IgStep, index: number) => {
        return {
          ...igStep,
          order: index + 1,
        };
      });
      cache.writeQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>({
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: false,
        },
        data: {
          IgStepFindMany: orderedIgSteps,
        },
      });
    } else {
      newIgSteps = igSteps?.IgStepFindMany?.filter(
        igStep => igStep.id !== changedIgStep.id
      ) as IgStep[];
      cache.writeQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>({
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: true,
        },
        data: {
          IgStepFindMany: [
            ...(archivedIgSteps?.IgStepFindMany as IgStep[]),
            changedIgStep,
          ],
        },
      });
      orderedIgSteps = newIgSteps.map((igStep: IgStep, index: number) => {
        return {
          ...igStep,
          order: index + 1,
        };
      });
      cache.writeQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>({
        query: IgStepFindManyDocument,
        variables: {
          interviewGuideId,
          isArchived: false,
        },
        data: {
          IgStepFindMany: orderedIgSteps,
        },
      });
    }
    return orderedIgSteps;
  }
  return null;
};
