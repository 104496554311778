import { FC } from 'react';
import { classificationOptions } from '../../../../constants/classification';
import {
  TFormFieldValue,
  TMultiSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

export interface IClassificationFilterMultiselectFormField {
  id: string;
  name: string;
  value: TMultiSelectFormFieldValue;
  label: string;
  onChange: (value: TFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
}

const ClassificationFilterMultiselectFormField: FC<IClassificationFilterMultiselectFormField> = props => {
  return (
    <MultiselectFormField
      {...props}
      inputProps={{
        'data-role': 'classification-filter-select',
      }}
      options={classificationOptions}
    />
  );
};

export default ClassificationFilterMultiselectFormField;
