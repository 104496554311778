import { Formik, useField } from 'formik';
import {
  useGetProjectByIdQuery,
  useGetProjectCobrandingQuery,
  useSetIndirectInviteSsoMutation,
  useSetProjectCobrandingMutation,
} from 'generated/graphql';
import { useIsCompanySsoEnabled } from 'hooks/useIsCompanySsoEnabled';
import { useState } from 'react';
import {
  Button,
  EditFormWrapper,
  FormikCheckboxFormField,
  InlineNotification,
  NotificationType,
  TNotification,
  Tooltip,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './ProjectAuthenticationSettings.module.scss';
import {
  AuthSettingsFormProps,
  AuthSettingsFormValues,
  AuthSettingsPreviewProps,
  ProjectAuthenticationSettingsProps,
} from './ProjectAuthenticationSettings.types';

export function ProjectAuthenticationSettings({
  projectId,
  companyId,
}: ProjectAuthenticationSettingsProps) {
  const [formActive, setFormActive] = useState(false);
  const { handleMsgType } = useNotification();

  const { data: projectData, refetch: refetchProject } = useGetProjectByIdQuery(
    {
      variables: {
        id: projectId,
      },
    }
  );

  const subId = projectData?.project?.subId || '';

  const {
    data: projectCobrandingData,
    error: projectCobrandingGqlError,
    refetch: refetchProjectCobranding,
  } = useGetProjectCobrandingQuery({
    variables: {
      subId: subId,
    },
  });

  const isCompanySsoEnabled = useIsCompanySsoEnabled({ companyId });

  const cobrandingEnabled =
    Boolean(projectCobrandingData?.getProjectCobranding?.companyName) &&
    !projectCobrandingGqlError?.message;

  const ssoIndirectInviteEnabled = Boolean(
    projectData?.project?.indirectInviteSSOEnabled
  );

  const [setProjectCobranding] = useSetProjectCobrandingMutation();
  const [setIndirectInviteSSO] = useSetIndirectInviteSsoMutation();

  async function handleSaveAuthSettings(values: AuthSettingsFormValues) {
    const cobrandingMutation = setProjectCobranding({
      variables: {
        projectId: projectId,
        cobrandingEnabled: values.cobrandingEnabled,
      },
    });

    const ssoMutation = setIndirectInviteSSO({
      variables: {
        projectId: projectId,
        indirectInviteSSO: values.ssoIndirectInviteEnabled,
      },
    });

    try {
      await Promise.all([cobrandingMutation, ssoMutation]);
      handleSuccess();
    } catch (error) {
      handleError();
    }
  }

  function handleSuccess() {
    handleMsgType({
      type: TNotification.success,
      message: 'Successfully updated authentication',
    });
    refetchProject();
    refetchProjectCobranding();
    setFormActive(false);
  }

  function handleError() {
    handleMsgType({
      type: TNotification.error,
      title: 'Authentication could not be updated',
      message: 'Something went wrong. Please try again.',
    });
    setFormActive(false);
  }

  return (
    <section className={styles.projectSettingSection}>
      {formActive ? (
        <AuthSettingsForm
          ssoEnabled={isCompanySsoEnabled}
          cobrandingEnabled={cobrandingEnabled}
          ssoIndirectInviteEnabled={ssoIndirectInviteEnabled}
          onCancel={() => setFormActive(false)}
          onSubmit={handleSaveAuthSettings}
        />
      ) : (
        <AuthSettingsPreview
          ssoIndirectInviteEnabled={ssoIndirectInviteEnabled}
          cobrandingEnabled={cobrandingEnabled}
          activateForm={() => setFormActive(true)}
        />
      )}
    </section>
  );
}

function AuthSettingsPreview({
  ssoIndirectInviteEnabled,
  cobrandingEnabled,
  activateForm,
}: AuthSettingsPreviewProps) {
  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__sub-title">Authentication</div>
        </div>

        <div className="project-header__right">
          <Button onClick={activateForm} size="medium">
            Edit
          </Button>
        </div>
      </div>

      <div className="project-info">
        <div className="project-info__item">
          <div className="project-info__label">
            SSO sign up for indirect invites
          </div>

          <div className="project-info__value">
            {ssoIndirectInviteEnabled ? 'On' : 'Off'}
          </div>
        </div>

        <div className="project-info__item">
          <div>
            <Tooltip content="Display the company and project name on main sign-in screen.">
              <div className="project-info__label">Co-branding</div>
            </Tooltip>
          </div>
          <div className="project-info__value">
            {cobrandingEnabled ? 'On' : 'Off'}
          </div>
        </div>
      </div>
    </div>
  );
}

function AuthSettingsForm({
  onSubmit,
  onCancel,
  ssoEnabled,
  cobrandingEnabled,
  ssoIndirectInviteEnabled,
}: AuthSettingsFormProps) {
  function handleSubmit(values: AuthSettingsFormValues) {
    onSubmit(values);
  }
  return (
    <EditFormWrapper
      formId="project-authentication-settings-form"
      title="Edit authentication"
      hasResetAllButton={false}
      onCancelClick={onCancel}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          cobrandingEnabled: cobrandingEnabled,
          ssoIndirectInviteEnabled: ssoIndirectInviteEnabled,
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form
              id="project-authentication-settings-form"
              onSubmit={handleSubmit}
              className={styles.formFieldsWrapper}
            >
              {!ssoEnabled && (
                <InlineNotification
                  notificationType={NotificationType.NEUTRAL}
                  className={styles.ssoInlineNotif}
                >
                  To enable SSO sign-up (indirect invite only), please contact
                  PDE.
                </InlineNotification>
              )}
              <FormikCheckboxFormField
                id="ssoIndirectInviteEnabled"
                name="ssoIndirectInviteEnabled"
                label="SSO sign up for indirect invites"
                useFormikField={useField}
                disabled={!ssoEnabled}
              />
              <FormikCheckboxFormField
                id="cobrandingEnabled"
                name="cobrandingEnabled"
                label="Co-branding"
                useFormikField={useField}
              />
            </form>
          );
        }}
      </Formik>
    </EditFormWrapper>
  );
}
