import { FC } from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import { ModalTypes } from '../../../../enums/modalTypes';
import {
  IgInterviewGuide,
  useCompaniesQuery,
} from '../../../../generated/graphql';
import InterviewGuideTableRow from './InterviewGuideTableRow/InterviewGuideTableRow';
// import InterviewGuideTableRow from './InterviewGuideTableRow/InterviewGuideTableRow';
import useInterviewGuideStyle from './InterviewGuideTableStyle';

interface IInterviewGuideTable {
  headerData: string[];
  tableBodyData: IgInterviewGuide[];
}

const InterviewGuideTable: FC<IInterviewGuideTable> = ({
  headerData,
  tableBodyData,
}) => {
  const classes = useInterviewGuideStyle();
  const companiesResponse = useCompaniesQuery();

  // const handleDuplicateReportModal = (row: ITableReport) => {
  //   const modalProps = {
  //     name: row?.name,
  //     type: row?.type,
  //     subType: row?.subType,
  //     ownerId: Number(row?.ownerId),
  //     ownerType: row?.ownerType,
  //     copyReportId: row.id,
  //   };
  //   openModal(ModalTypes.REPORTS_PAGE_MODAL, modalProps);
  // };
  if (companiesResponse.loading) {
    return <CircularProgress />;
  }
  if (companiesResponse.error) {
    return <div>{companiesResponse.error.message}</div>;
  }
  if (companiesResponse.data?.Companies) {
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerData.map((item, index) => (
                <TableCell className={classes.tableHeader} key={`a${index}`}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyData?.map(row => {
              const company = companiesResponse?.data?.Companies?.find(
                company => company.id === row.ownerId
              );
              if (company?.name)
                return (
                  <InterviewGuideTableRow
                    key={row.id}
                    name={row.name}
                    id={row.id}
                    ownerId={row.ownerId}
                    // company={row.company.name}
                    ownerName={company.name}
                    updatedAt={row.updatedAt}
                    isArchived={row.isArchived}
                    //   onDuplicateBtnClick={() => handleDuplicateReportModal(row)}
                  />
                );
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};

export default InterviewGuideTable;
