import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { Modal } from '@spotted-zebra-uk/ui-components';
import ProjectRequestApprovalForm from './ProjectRequestApprovalForm';

export interface IProjectRequestApprovalModal {
  projectRequestId: number;
  projectRequestCompanyId: number;
}

const ProjectRequestApprovalModal: FC<IProjectRequestApprovalModal> = ({
  projectRequestCompanyId,
  projectRequestId,
}) => {
  const close = () => {
    closeModal(ModalTypes.PROJECT_REQUEST_APPROVAL_MODAL);
  };

  return (
    <Modal
      header="Request Approval"
      isOpen
      className="project-request-approval-modal"
      onClose={close}
    >
      <ProjectRequestApprovalForm
        projectRequestId={projectRequestId}
        projectRequestCompanyId={projectRequestCompanyId}
      />
    </Modal>
  );
};

export default ProjectRequestApprovalModal;
