import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import TextFormField from '../../../../../components/molecules/TextFormField/TextFormField';
// import { ReportType } from '../../../../../enums/report';
import { ReportType } from '../../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../../interfaces/forms/form';
import ReportSubTypeFormField from '../../../../ModalView/ReportsModal/ReportSubTypeFormField/ReportSubTypeFormField';
import ReportTypeFormField from '../../../../ModalView/ReportsModal/ReportTypeFormField/ReportTypeFormField';

interface IEditReportPayload {
  reportName: string;
  company: string;
  reportType: TSelectFormFieldValue;
  reportSubType?: TSelectFormFieldValue;
}
interface IReportError {
  reportName: string;
  reportType: string;
  reportSubType: string;
  company: string;
}
interface IEditReport {
  reportName: string | undefined;
  // company: TSelectFormFieldValue;
  company: string | undefined;
  reportId: number;
  reportType: TSelectFormFieldValue;
  reportSubType?: TSelectFormFieldValue;
  getReportDetails: (reportDetails: IEditReportPayload) => void;
}

const EditReport: FC<IEditReport> = ({
  reportName,
  company,
  reportId,
  reportType,
  reportSubType,
  getReportDetails,
}) => {
  const [reportDetails, setReportDetails] = useState<IEditReportPayload>({
    reportName: '',
    reportType: '',
    company: '',
    reportSubType: '',
  });
  const [errors, setErrors] = useState<IReportError>({
    reportName: '',
    reportType: '',
    company: '',
    reportSubType: '',
  });

  useEffect(() => {
    if (reportName) {
      setReportDetails(prev => ({ ...prev, reportName }));
    }
    if (company) {
      setReportDetails(prev => ({ ...prev, company }));
    }
    if (reportType) {
      setReportDetails(prev => ({ ...prev, reportType }));
    }
    if (reportSubType) {
      setReportDetails(prev => ({ ...prev, reportSubType }));
    }
  }, [reportName, reportType, company, reportSubType]);

  const getReportName = (value: string, name: string) => {
    setReportDetails(prev => ({ ...prev, reportName: value }));
    getReportDetails({ ...reportDetails, reportName: value });
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, [name]: '' } : prevErrors
    );
  };

  const getTypeValue = (value: TSelectFormFieldValue, name: string) => {
    setReportDetails(prev => ({ ...prev, reportType: value }));
    getReportDetails({ ...reportDetails, reportType: value });
  };

  const getCandidateSubType = (value: TSelectFormFieldValue, name: string) => {
    setReportDetails(prev => ({ ...prev, reportSubType: value }));
    getReportDetails({ ...reportDetails, reportSubType: value });
  };

  return (
    <div>
      <Grid container spacing={3} direction="row">
        <Grid sm={3} item>
          <TextFormField
            id="reportName"
            name="reportName"
            value={reportDetails.reportName}
            label="Report Name"
            onChange={getReportName}
            hasError={!!errors?.reportName}
            errorText={errors?.reportName}
          />
        </Grid>
        <Grid item sm={3}>
          <TextFormField
            id="reportName"
            name="company"
            value={reportDetails?.company}
            label="Company"
            isDisabled
            onChange={() => {}}
          />
        </Grid>
        <Grid item sm={3}>
          <ReportTypeFormField
            id="typeFormField"
            name="type"
            label="Type"
            value={reportDetails.reportType}
            onChange={getTypeValue}
            hasError={!!errors?.reportType}
            errorText={errors?.reportType}
          />
        </Grid>
        <Grid item sm={3}>
          <ReportSubTypeFormField
            id="subTypeFormField"
            name="subType"
            label="Sub type"
            value={reportDetails.reportSubType}
            onChange={getCandidateSubType}
            selectedReportType={
              reportDetails.reportType
                ? (reportDetails.reportType as ReportType)
                : null
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditReport;
