import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import ClassificationFilterMultiselectFormField from '../../../../components/molecules/MultiselectFormField/ClassificationFilterMultiselectFormField/ClassificationFilterMultiselectFormField';
import ContentSortMultiselectFormField from '../../../../components/molecules/MultiselectFormField/ContentSortMultiselectFormField/ContentSortMultiselectFormField';
import GradeFilterMultiselectFormField from '../../../../components/molecules/MultiselectFormField/GradeFilterMultiselectFormField/GradeFilterMultiselectFormField';
import RoleLevelFilterMultiselectFormField from '../../../../components/molecules/MultiselectFormField/RoleLevelFilterMultiselectFormField/RoleLevelFilterMultiselectFormField';
import SoftSkillFiltersMultiselectFormField from '../../../../components/molecules/MultiselectFormField/SoftSkillFiltersMultiselectFormField/SoftSkillFiltersMultiselectFormField';
import TraitFiltersMultiselectFormField from '../../../../components/molecules/MultiselectFormField/TraitFiltersMultiselectFormField/TraitFiltersMultiselectFormField';
import {
  ContentClassification,
  ContentRoleLevel,
  Grade,
} from '../../../../generated/graphql';
import { TFormFieldValue } from '../../../../interfaces/forms/form';
import { IStaticContentFiltersModal } from '../StaticContentFiltersModal';
import useStaticContentFiltersFormStyle from './StaticContentFiltersFormStyle';

export interface IStaticContentFiltersForm extends IStaticContentFiltersModal {}

export interface IStaticContentFiltersFormValues {
  softSkills?: number[];
  traits?: number[];
  grades?: Grade[];
  classifications?: ContentClassification[];
  roleLevels?: ContentRoleLevel[];
  page?: number;
  take?: number;
  sortBy?: string[];
}

const StaticContentFiltersForm: FC<IStaticContentFiltersForm> = ({
  isSoftSkillFilterIncluded,
  isTraitFilterIncluded,
  isGradeFilterIncluded,
  isClassificationFilterIncluded,
  isRoleLevelFilterIncluded,
  filters,
  onSubmit,
}) => {
  const classes = useStaticContentFiltersFormStyle();
  const [values, setValues] = useState<IStaticContentFiltersFormValues>({
    softSkills: filters?.softSkills ? filters.softSkills : [],
    traits: filters?.traits ? filters.traits : [],
    grades: filters?.grades ? filters.grades : [],
    classifications: filters?.classifications ? filters.classifications : [],
    roleLevels: filters?.roleLevels ? filters.roleLevels : [],
    page: filters?.page ? filters.page : 1,
    take: filters?.take ? filters.take : 50,
    sortBy: filters?.sortBy ? filters.sortBy : [],
  });

  const handleChange = (value: TFormFieldValue, name: string) => {
    setValues(prevValues => ({ ...prevValues, [name]: value }));
    setValues(prevValues => ({ ...prevValues, page: 1, take: 50 }));
  };

  const resetValues = () => {
    setValues({
      softSkills: [],
      traits: [],
      grades: [],
      classifications: [],
      roleLevels: [],
      page: 1,
      take: 50,
      sortBy: [],
    });
  };

  const key = 'ff';
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.container}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <Typography variant="h6">Filters</Typography>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={resetValues}>Reset</Button>
        </Grid>
      </Grid>
      {isSoftSkillFilterIncluded ? (
        <Grid item>
          <SoftSkillFiltersMultiselectFormField
            id={`${key}-soft-skills`}
            name="softSkills"
            label="Soft skill filter"
            value={values.softSkills}
            onChange={handleChange}
          />
        </Grid>
      ) : (
        ''
      )}
      {isTraitFilterIncluded ? (
        <Grid item>
          <TraitFiltersMultiselectFormField
            id={`${key}-traits`}
            name="traits"
            label="Traits filter"
            value={values.traits}
            onChange={handleChange}
            softSkillIds={values.softSkills}
          />
        </Grid>
      ) : (
        ''
      )}
      {isGradeFilterIncluded ? (
        <Grid item>
          <GradeFilterMultiselectFormField
            id={`${key}-grades`}
            name="grades"
            label="Grade filter"
            value={values.grades}
            onChange={handleChange}
          />
        </Grid>
      ) : (
        ''
      )}
      {isClassificationFilterIncluded ? (
        <Grid item>
          <ClassificationFilterMultiselectFormField
            id={`${key}-classification`}
            name="classifications"
            label="Classification filter"
            value={values.classifications}
            onChange={handleChange}
          />
        </Grid>
      ) : (
        ''
      )}
      {isRoleLevelFilterIncluded ? (
        <Grid item>
          <RoleLevelFilterMultiselectFormField
            id={`${key}-role-levels`}
            name="roleLevels"
            label="Role level filter"
            value={values.roleLevels}
            onChange={handleChange}
          />
        </Grid>
      ) : (
        ''
      )}
      <Grid item>
        <ContentSortMultiselectFormField
          id={`${key}-sort`}
          name="sortBy"
          label="Sort by"
          value={values.sortBy}
          onChange={handleChange}
          isSoftSkillFilterIncluded={isSoftSkillFilterIncluded}
          isGradeFilterIncluded={isGradeFilterIncluded}
          isRoleLevelFilterIncluded={isRoleLevelFilterIncluded}
          isClassificationFilterIncluded={isClassificationFilterIncluded}
          isTraitFilterIncluded={isTraitFilterIncluded}
        />
      </Grid>
      <Grid className={classes.buttonWrapper} item>
        <Button onClick={() => onSubmit(values)}>Save</Button>
      </Grid>
    </Grid>
  );
};

export default StaticContentFiltersForm;
