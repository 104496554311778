import { createStyles, makeStyles } from '@mui/styles';

const useModalStyle = makeStyles(theme =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      flexDirection: 'column',
      '&:focus': {
        outline: 'none',
      },
    },
  })
);

export default useModalStyle;
