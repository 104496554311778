import { makeStyles } from '@mui/styles';

const useTextFormFieldStyle = makeStyles(() => ({
  formField: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
}));

export default useTextFormFieldStyle;
