import { FC } from 'react';
import GridContainer from '../../../../components/atoms/GridContainer/GridContainer';
import UserApiKeysHeader from './UserApiKeysHeader/UserApiKeysHeader';
import UserApiKeysList from './UserApiKeysList/UserApiKeysList';

interface IUserApiKeys {
  userId: number;
}

const UserApiKeys: FC<IUserApiKeys> = ({ userId }) => {
  return (
    <GridContainer gridProps={{ direction: 'column', style: { padding: 0 } }}>
      <UserApiKeysHeader userId={userId} />
      <UserApiKeysList userId={userId} />
    </GridContainer>
  );
};

export default UserApiKeys;
