import { FC } from 'react';
import { useMutation } from '@apollo/client';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../components/atoms/Button/Button';
import CircularLoader from '../../../../components/molecules/CircularLoader/CircularLoader';
import { FormManagerType, FormType } from '../../../../enums/forms/form';
import { FORM_CREATE_MUTATION } from '../../../../graphql/form/form';
import {
  IFormCreateMutationInput,
  IFormCreateMutationResponse,
} from '../../../../interfaces/forms/formGQL';

interface ICompanyRequirementsCreateFormButton {
  companyId: number;
  formFilter: FormType;
  onCreateCompleted?: () => void;
}

const CompanyRequirementsCreateFormButton: FC<ICompanyRequirementsCreateFormButton> = ({
  companyId,
  formFilter,
  onCreateCompleted,
}) => {
  const { handleMsgType } = useNotification();

  const [
    companyRequirementsCreateFormMutation,
    companyRequirementsCreateFormMutationResponse,
  ] = useMutation<IFormCreateMutationResponse, IFormCreateMutationInput>(
    FORM_CREATE_MUTATION,
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      onCompleted: () => {
        if (onCreateCompleted) {
          onCreateCompleted();
        }
      },
    }
  );
  const getFormManagerType = (formType: FormType): FormManagerType => {
    switch (formType) {
      case FormType.TR_FORM:
        return FormManagerType.TR;
      case FormType.CI_FORM:
        return FormManagerType.CI;
      case FormType.RS_FORM:
        return FormManagerType.RS;
      case FormType.CD_FORM:
        return FormManagerType.CI;
      case FormType.APPRENTICE_FORM:
      case FormType.GRAD_FORM:
      case FormType.UNDERGRAD_FORM:
        return FormManagerType.APPRENTICE;
      default:
        return FormManagerType.GLOBAL;
    }
  };
  const handleCreateCompanyRequirementsForm = () => {
    const formManagerType: FormManagerType = getFormManagerType(formFilter);
    companyRequirementsCreateFormMutation({
      variables: {
        name: `company-requirements-form-${companyId}`,
        formType: formFilter,
        //Dynamic
        formManagerType,
        formOwnerId: companyId,
      },
    });
  };

  if (companyRequirementsCreateFormMutationResponse.loading) {
    return <CircularLoader />;
  }

  return (
    <Button
      isDisabled={companyRequirementsCreateFormMutationResponse.loading}
      onClick={handleCreateCompanyRequirementsForm}
    >
      Create form
    </Button>
  );
};

export default CompanyRequirementsCreateFormButton;
