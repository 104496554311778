import { makeStyles } from '@mui/styles';

const useUserTableStyle = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  duplicateBtn: {
    marginRight: '10px',
    width: 96,
  },
  tableHeader: {
    fontSize: '16px !important',
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: '16px !important',
  },
  btn: {
    width: 96,
  },
  loaderWrapper: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  archivedText: {
    color: '#f50057',
  },
}));

export default useUserTableStyle;
