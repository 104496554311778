import { appRoutes } from 'constants/navigation';
import {
  CompaniesDocument,
  CompaniesQuery,
  useDeleteCompanyMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import {
  IconType,
  Modal,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './DeleteCompany.module.scss';

interface IDeleteCompany {
  companyId: number;
}

const DeleteCompany: FC<IDeleteCompany> = ({ companyId }) => {
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();
  const [modalOpen, setModalOpen] = useState(false);

  const [deleteCompanyMutation] = useDeleteCompanyMutation({
    onCompleted: () => {
      navigate(appRoutes.companies.url());
    },
    update(cache) {
      const cacheData = cache.readQuery<CompaniesQuery>({
        query: CompaniesDocument,
      });
      if (cacheData?.Companies) {
        cache.writeQuery({
          query: CompaniesDocument,
          data: {
            companies: cacheData.Companies.filter(
              cacheCompany => cacheCompany.id !== companyId
            ),
          },
        });
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleDelete = () => {
    deleteCompanyMutation({
      variables: {
        id: companyId,
      },
    });
  };

  return (
    <>
      <Grid item>
        <Button
          className={styles.button}
          color="error"
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Delete
        </Button>
      </Grid>
      <Modal
        header="Are you sure?"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        headerIconType={IconType.WARNING}
        buttonsProps={{
          confirmButtonChildren: 'Delete',
          onConfirmButtonClick: handleDelete,
          confirmButtonProps: {
            className: styles.modalBtn,
          },
        }}
      >
        <p className={styles.modalContent}>
          You are about to delete this company and all associated projects.
        </p>
      </Modal>
    </>
  );
};

export default DeleteCompany;
