import { FC } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { TTextFromFieldValue } from '../../../interfaces/forms/form';
import useTextFormFieldStyle from './TextFormFieldStyle';

export interface ITextFormField {
  id: string;
  name: string;
  label?: string;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: (value: TTextFromFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  value: string;
  className?: string;
  isDisabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: string;
  min?: string;
  inputProps?: InputBaseComponentProps;
  variant?: 'standard' | 'filled' | 'outlined';
}

const TextFormField: FC<ITextFormField> = ({
  id,
  onChange,
  errorText = '',
  hasError = false,
  name,
  placeholder = '',
  isDisabled = false,
  onBlur,
  className,
  min,
  inputProps,
  variant = 'outlined',
  ...restProps
}) => {
  const classes = useTextFormFieldStyle();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value, name);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, name);
    }
  };

  return (
    <TextField
      className={`${classes.formField}${className ? ` ${className}` : ''}`}
      variant={variant}
      margin="normal"
      fullWidth
      placeholder={placeholder}
      name={name}
      onChange={handleChange}
      inputProps={{
        ...inputProps,
        onBlur: handleBlur,
        min,
      }}
      error={hasError}
      helperText={errorText}
      disabled={isDisabled}
      {...restProps}
    />
  );
};

export default TextFormField;
