import { FC } from 'react';
import {
  RoleType,
  useRoleFindManyQuery,
  UserRole,
} from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import SelectFormField from '../SelectFormField';

interface IRoleSelectFormField {
  className?: string;
  value: TSelectFormFieldValue;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  existingRoles?: UserRole[] | null;
  isSuperAdmin?: boolean;
  isDisabled?: boolean;
  filterForIdentity?: boolean;
}

interface IOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

const RoleSelectFormField: FC<IRoleSelectFormField> = ({
  value,
  onChange,
  className,
  hasError,
  errorText,
  existingRoles,
  isSuperAdmin = false,
  isDisabled = false,
  filterForIdentity = false,
}) => {
  const { data } = useRoleFindManyQuery({
    variables: {
      filterForIdentity,
    },
  });

  let adminRoleArray: IOptions[] | undefined;
  if (data?.RoleFindMany) {
    adminRoleArray = data?.RoleFindMany
      // .filter(
      //   r => r.type === RoleType.Company || r.type === RoleType.ModuleAccess
      // )
      .map(role => ({
        label: role.name,
        value: role.name.toUpperCase(),
        disabled:
          !isSuperAdmin && role.type === RoleType.SzProtected ? true : false,
      }));
  }

  if (data?.RoleFindMany.length) {
    return (
      <SelectFormField
        id="roleFormField"
        name="role"
        label="Role"
        onChange={onChange}
        className={className}
        value={value}
        options={adminRoleArray as IOptions[]}
        hasError={hasError}
        errorText={errorText}
        isDisabled={isDisabled}
      />
    );
  }
  return null;
};

export default RoleSelectFormField;
