import { initialLanguageOption } from 'constants/languages';
import { ModalTypes } from 'enums/modalTypes';
import {
  SupportedLocale as SupportedLocaleType,
  useGenerateStageIndirectInvitationUrlLazyQuery,
  useGetProjectByIdQuery,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Button,
  TNotification,
  useNotification,
  useLocales,
  SelectFormField,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';

interface ICopyStageIndirectInvitation {
  stageId: number;
  projectId: number;
}

const CopyStageIndirectInvitation: FC<ICopyStageIndirectInvitation> = ({
  stageId,
  projectId,
}) => {
  const { handleMsgType } = useNotification();
  const [language, setLanguage] = useState<
    TSelectOption<keyof typeof SupportedLocale>
  >(initialLanguageOption);
  const { getSupportedLocaleMetadata, SupportedLocale } = useLocales();

  const getProjectByIdResponse = useGetProjectByIdQuery({
    variables: { id: Number(projectId) },
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      }),
  });
  const projectLanguages = getProjectByIdResponse?.data?.project?.locales;

  const [
    indirectInviteUrlQuery,
    indirectInviteUrlQueryResponse,
  ] = useGenerateStageIndirectInvitationUrlLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
      closeModal(ModalTypes.STAGE_INDIRECT_INVITATION_MODAL);
    },
  });

  useEffect(() => {
    indirectInviteUrlQuery({
      variables: { id: stageId },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLanguage = (
    value: TSelectOption<keyof typeof SupportedLocale>
  ) => {
    setLanguage(value);
    if (value) {
      indirectInviteUrlQuery({
        variables: {
          id: stageId,
          locale: value.value as SupportedLocaleType,
        },
      });
    }
  };

  const url =
    indirectInviteUrlQueryResponse.data?.GenerateStageIndirectInvitationUrl.url;
  const copyToClipboardText = url || '';

  const handleCopy = () => {
    if (url) {
      handleMsgType({
        type: TNotification.success,
        message: `Successfuly copied stage indirect invitation!`,
      });
    }
  };

  const languageSelectFieldOptions = projectLanguages?.length
    ? projectLanguages.map(locale => {
        return {
          label: getSupportedLocaleMetadata(SupportedLocale[locale]).name
            .english,
          value: locale,
        };
      })
    : [initialLanguageOption];

  return (
    <div className="copy-stage-indirect-invitation">
      <SelectFormField
        value={language}
        onChange={handleChangeLanguage}
        id={`stage-indirect-invitation-language-select-${stageId}`}
        label="Language"
        options={languageSelectFieldOptions}
        placeholder="Language"
        data-testid="indirect-invite-candidate__language-select-form"
        hasClearIndicator={false}
      />
      <CopyToClipboard text={copyToClipboardText}>
        <Button
          size="medium"
          onClick={handleCopy}
          disabled={!url}
          data-testid="indirect-invite-candidate__copy-url-buttton"
        >
          Copy invite URL
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyStageIndirectInvitation;
