import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import SelectFormField from '../../../../../../components/molecules/SelectFormField/SelectFormField';
import {
  CompanySignatureFindManyDocument,
  CompanySignatureFindManyQuery,
  CompanySignatureFindManyQueryVariables,
  useCompanySignatureUpdateOneMutation,
} from '../../../../../../generated/graphql';
import {
  ISelectFormFieldOption,
  TSelectFormFieldValue,
} from '../../../../../../interfaces/forms/form';
import { TAlertTypes } from '../../../../../../interfaces/snackBar';

interface IDefaultCompanySignature {
  companySignatures: CompanySignatureFindManyQuery['CompanySignature'];
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  defaultId: number;
  companyId: number;
  getUpdatedDefaultId: (id: number) => void;
}

const DefaultCompanySignature: FC<IDefaultCompanySignature> = ({
  companySignatures,
  displaySnackbar,
  defaultId,
  getUpdatedDefaultId,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const options: ISelectFormFieldOption[] = companySignatures
    ? companySignatures.map(item => ({
        label: item.signature,
        value: item.id,
      }))
    : [];

  const [
    companySignatureUpdateOneMutation,
  ] = useCompanySignatureUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const companySignatureData = data?.CompanySignature;
      if (companySignatureData) {
        const cachedData = cache.readQuery<
          CompanySignatureFindManyQuery,
          CompanySignatureFindManyQueryVariables
        >({
          query: CompanySignatureFindManyDocument,
          variables: {
            companyId,
          },
        });
        if (cachedData?.CompanySignature) {
          const updatedCachedData = cachedData.CompanySignature?.map(item =>
            item.id === companySignatureData?.id ? companySignatureData : item
          );
          cache.writeQuery({
            query: CompanySignatureFindManyDocument,
            variables: {
              companyId,
            },
            data: {
              CompanySignature: updatedCachedData,
            },
          });
        }
      }
    },
    onCompleted: () => {
      displaySnackbar("default company's signature updated successfully");
    },
  });

  const handleChange = (value: TSelectFormFieldValue) => {
    getUpdatedDefaultId(Number(value));
    companySignatureUpdateOneMutation({
      variables: {
        id: Number(value),
        isDefault: true,
      },
    });
  };

  return (
    <div style={{ width: '350px' }}>
      <SelectFormField
        id="defaultSignature"
        name="isDefault"
        label="Default Signature"
        value={defaultId}
        onChange={handleChange}
        options={options}
        hasNullValue={false}
      />
    </div>
  );
};

export default DefaultCompanySignature;
