import './CompanyRolesList.scss';
import {
  Company,
  useCompanyRoleUpsertMutation,
  useRoleFindManyQuery,
} from 'generated/graphql';
import { FC, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IRole {
  id: number;
  description?: string | null | undefined;
  name: string;
  twoFactorEnabled?: boolean;
}

export interface CompanyRole {
  id: number;
  twoFactorEnabled: boolean;
  role: IRole;
  companyId: number;
}
interface ICompanyRolesList {
  companyRoles: CompanyRole[];
  company: Company;
}
const CompanyRolesList: FC<ICompanyRolesList> = ({ companyRoles, company }) => {
  const { handleMsgType } = useNotification();

  const [roles, setRoles] = useState<IRole[]>([]);
  const checkTwoFactorEnabledInCompany = (roleId: number) => {
    return companyRoles.some(
      companyRole =>
        companyRole.role.id === roleId && companyRole.twoFactorEnabled
    );
  };
  const filterDataRoles = (roles: IRole[]) => {
    roles.forEach(role => {
      setRoles(roles => [
        ...roles,
        {
          ...role,
          twoFactorEnabled: checkTwoFactorEnabledInCompany(role.id),
        },
      ]);
    });
  };
  useRoleFindManyQuery({
    variables: {
      filterForIdentity: true,
    },
    onCompleted: data => {
      filterDataRoles(data.RoleFindMany);
    },
  });
  const [companyRoleUpsertMutation] = useCompanyRoleUpsertMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleCheckClick = (value: boolean, id: string) => {
    const parsedId = +id;
    let temp = [...roles];
    setRoles(
      temp.map(role => {
        if (role.id === parsedId) {
          role.twoFactorEnabled = value;
        }
        return role;
      })
    );
    const companyId = company.id;
    companyRoleUpsertMutation({
      variables: {
        companyId,
        roleId: parsedId,
        twoFactorEnabled: value,
      },
    });
  };

  return (
    <div>
      <div className="header">
        <label>Require MFA?</label>
      </div>
      {roles.map(role => {
        return (
          <div className="CompanyRoleCard" key={role.id}>
            <label>{role.description}</label>
            <Checkbox
              id={role.id.toString()}
              checked={role.twoFactorEnabled}
              onChange={e => {
                handleCheckClick(e.target.checked, e.target.id);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CompanyRolesList;
