import {
  ProjectManagerFragment,
  ProjectModuleType,
  SmInviteHiringManagerDetailsArgs,
  SmInviteType,
  SupportedLocale,
  useGetProjectByIdQuery,
  useSmInviteHiringMutation,
  useSmInviteTrMutation,
} from 'generated/graphql';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import InviteNewCandidateForm from './InviteNewCandidateForm';

interface IInviteNewCandidate {
  stageId: number;
  type: ProjectModuleType;
  projectManagers: ProjectManagerFragment[];
  projectId: number;
  closeModal: () => void;
}

const InviteNewCandidate: FC<IInviteNewCandidate> = ({
  stageId,
  type,
  projectManagers,
  projectId,
  closeModal,
}) => {
  const { handleMsgType } = useNotification();

  const [inviteHiringMutation] = useSmInviteHiringMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Invitation sent!',
      });
      closeModal();
    },
  });
  const [inviteTRMutation] = useSmInviteTrMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Invitation sent!',
      });
      closeModal();
    },
  });

  const getProjectByIdResponse = useGetProjectByIdQuery({
    variables: { id: Number(projectId) },
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      }),
  });
  const projectLanguages = getProjectByIdResponse?.data?.project?.locales;

  const getManagersEmailByIds = (
    managers: number[]
  ): SmInviteHiringManagerDetailsArgs[] => {
    const result: SmInviteHiringManagerDetailsArgs[] = [];
    managers.forEach(manager => {
      const managerData = projectManagers.find(pm => pm.id === manager);
      if (managerData && managerData.email)
        result.push({ email: managerData.email });
    });
    return result;
  };

  const handleInviteCandidate = (
    givenName: string,
    familyName: string,
    markedAsInternalCandidate: boolean,
    email: string,
    managerEmail: string,
    selectedProjectManagers: number[],
    locale: SupportedLocale
  ) => {
    if (type === ProjectModuleType.Hiring) {
      const managerEmails: SmInviteHiringManagerDetailsArgs[] =
        getManagersEmailByIds(selectedProjectManagers);
      inviteHiringMutation({
        variables: {
          isCompanyCandidate: markedAsInternalCandidate,
          doneForsAndProjectManagers: [
            {
              doneForNewUser: {
                lastName: familyName,
                firstName: givenName,
                email,
              },
              projectManagerNewUsers: managerEmails,
            },
          ],
          stageId,
          locale,
          type: type as unknown as SmInviteType,
        },
      });
    } else {
      inviteTRMutation({
        variables: {
          isCompanyCandidate: markedAsInternalCandidate,
          doneForEmails: [email],
          doneByEmails: [managerEmail],
          stageId,
          locale,
          type: type as unknown as SmInviteType,
        },
      });
    }
  };
  return (
    <InviteNewCandidateForm
      projectManagers={projectManagers}
      projectLanguages={projectLanguages}
      projectId={projectId}
      type={type}
      onSubmit={handleInviteCandidate}
      onCancel={closeModal}
    />
  );
};

export default InviteNewCandidate;
