import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CircularLoader from '../../../components/molecules/CircularLoader/CircularLoader';
import {
  ApiKeyOwnerType,
  useApiKeyFindAllQuery,
  useUsersLazyQuery,
} from '../../../generated/graphql';
import ApiKeysUserList from './ApiKeysUserList/ApiKeysUserList';

interface IApiKeysUsers {}

const ApiKeysUsers: FC<IApiKeysUsers> = () => {
  const { handleMsgType } = useNotification();

  // This component requires users in cache.
  // (*check src/views/ApiKeys/ApiKeysUsers/ApiKeysUserList/ApiKeysUserListItem/ApiKeysUserListItem.tsx)
  const [usersQuery, usersQueryResponse] = useUsersLazyQuery();

  const apiKeysQueryResponse = useApiKeyFindAllQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: data => {
      const userApiKeys = data.ApiKeyFindAll.filter(
        apiKey => apiKey.ownerType === ApiKeyOwnerType.User
      );

      usersQuery({
        variables: {
          ids: userApiKeys.map(userApiKey => userApiKey.ownerId),
        },
      });
    },
  });

  const renderContent = () => {
    if (apiKeysQueryResponse.error) {
      return <div>Error: {apiKeysQueryResponse.error.message}</div>;
    }

    if (usersQueryResponse.error) {
      return <div>Error: {usersQueryResponse.error.message}</div>;
    }

    if (apiKeysQueryResponse.data && usersQueryResponse.data) {
      const userApiKeys = apiKeysQueryResponse.data.ApiKeyFindAll.filter(
        apiKey => apiKey.ownerType === ApiKeyOwnerType.User
      );

      return <ApiKeysUserList apiKeys={userApiKeys} />;
    }

    return <CircularLoader />;
  };

  return (
    <div
      style={{
        padding: '30px 30px 30px 260px',
      }}
    >
      <h3 style={{ marginBottom: '30px' }}>User api keys</h3>
      {renderContent()}
    </div>
  );
};

export default ApiKeysUsers;
