import Snackbar from 'components/atoms/Snackbar/Snackbar';
import {
  DeleteByIdArgs,
  ReportConfigCreateOneBatchedWithReportArgs,
  ReportConfigUpdateOneArgs,
  ReportDocument,
  ReportSubType,
  ReportType,
  useReportUpdateMutation,
  WidgetsDocument,
} from 'generated/graphql';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import {
  IReportConfigsToCreate,
  IReportConfigsToDelete,
  IReportConfigsToUpdate,
  IReportWidget,
} from 'interfaces/report';
import { ISnackBar } from 'interfaces/snackBar';
import { TWidgetOptions } from 'interfaces/widget';
import { FC, useEffect, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ReportForm from './ReportForm/ReportForm';
import {
  IReportDetails,
  IReportInfo,
  IReportMain,
} from './ReportMain.interface';
import SingleReportHeader from './SingleReportHeader/SingleReportHeader';

const initialPayload = {
  reportConfigsToCreate: [],
  reportConfigsToDelete: [],
  reportConfigsToUpdate: [],
};

const snackbarData = (snackbar: ISnackBar) => ({
  ...snackbar,
  show: true,
  msg: 'report update successful',
});

const ReportMain: FC<IReportMain> = ({
  report,
  reportId,
  usedWidget,
  availableWidget,
}) => {
  const { handleMsgType } = useNotification();

  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const [usedItem, setUsedItem] = useState<IReportWidget[] | undefined>([]);
  const [availableItem, setAvailableItem] = useState<
    IReportWidget[] | undefined
  >([]);
  const [reportInfo, setReportInfo] = useState<IReportInfo>(initialPayload);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  const getReportDetails = (reportDetails: IReportDetails) => {
    const { reportName, reportType, reportSubType } = reportDetails;
    setReportInfo({
      ...reportInfo,
      name: reportName,
      type: reportType,
      subType: reportSubType,
    });
  };

  useEffect(() => {
    if (usedWidget) setUsedItem(usedWidget);
    if (availableWidget) setAvailableItem(availableWidget);
  }, [usedWidget, availableWidget]);

  const [updateReportMutationResponse] = useReportUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: ReportDocument,
        variables: { id: reportId },
      },
      { query: WidgetsDocument },
    ],
    onCompleted: () => {
      runSnackFunc();
      setReportInfo(initialPayload);
    },
  });

  const runSnackFunc = () => setSnackbar(snackbarData(snackbar));

  const reOrderWidget = (reOrderedItems: IReportWidget[]) => {
    const updatedReOrderedItem = reOrderedItems.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setUsedItem(updatedReOrderedItem);
  };

  const getNewReportConfig = (reportConfig: IReportWidget) => {
    let maxNumber = 0;
    if (usedItem) {
      usedItem?.forEach(item => {
        if (Number(item?.dndId) > maxNumber) {
          maxNumber = Number(item?.dndId);
        }
      });
      const temp = [
        ...usedItem,
        {
          ...reportConfig,
          position: usedItem.length + 1,
          dndId: maxNumber + 1,
        },
      ];
      setUsedItem(temp);
    }
  };

  const removeReportConfig = (reportConfig: IReportWidget, index: number) => {
    let reportConfigsToDelete: IReportConfigsToDelete[] = reportInfo?.reportConfigsToDelete
      ? reportInfo?.reportConfigsToDelete
      : [];
    let temp = usedItem;
    temp?.splice(index, 1);
    temp = temp?.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setUsedItem(temp);
    const deletedConfig = { id: reportConfig.reportConfigId };
    if (reportInfo?.reportConfigsToDelete && reportConfig.usedWidget) {
      reportConfigsToDelete = [
        ...reportInfo.reportConfigsToDelete,
        deletedConfig,
      ];
    }

    return setReportInfo({
      ...reportInfo,
      reportConfigsToDelete,
    });
  };

  const getNewPageBeforeValue = (
    value: boolean,
    reportConfig: IReportWidget
  ) => {
    let createItems: IReportWidget[] = [];
    if (usedItem) {
      createItems = usedItem?.map(item => {
        if (item?.dndId === reportConfig?.dndId) {
          return {
            dndId: item.dndId,
            id: item.id,
            name: item.name,
            newPageBefore: value,
            position: item.position,
            reportConfigId: item.reportConfigId,
            type: item.type,
            usedWidget: item.usedWidget,
            widgetOptions: item.widgetOptions,
          };
        }
        return item;
      });
      setUsedItem(createItems);
    }
  };

  const getModalWidgetInfo = (
    updatedWidgetOption: TWidgetOptions[],
    dndId?: number
  ) => {
    let createItems: IReportWidget[] = [];
    if (usedItem) {
      createItems = usedItem?.map(item => {
        if (item?.dndId === dndId) {
          return {
            dndId: item.dndId,
            id: item.id,
            name: item.name,
            newPageBefore: item.newPageBefore,
            position: item.position,
            reportConfigId: item.reportConfigId,
            type: item.type,
            usedWidget: item.usedWidget,
            widgetOptions: updatedWidgetOption,
          };
        }
        return item;
      });
      setUsedItem(createItems);
    }
  };

  const onSaveReport = () => {
    let reportConfigsToCreate: IReportConfigsToCreate[] = reportInfo?.reportConfigsToCreate
      ? reportInfo?.reportConfigsToCreate
      : [];
    let reportConfigsToUpdate: IReportConfigsToUpdate[] = reportInfo?.reportConfigsToUpdate
      ? reportInfo?.reportConfigsToUpdate
      : [];
    if (usedItem) {
      reportConfigsToCreate = usedItem
        ?.filter(item => item.usedWidget === false)
        ?.map(item => ({
          position: item?.position,
          newPageBefore: item.newPageBefore,
          widgetId: item?.id,
          widgetConfigsToUpsert: item?.widgetOptions?.map(option => ({
            settings: option.settings,
            widgetOptionId: option.id,
          })),
        }));

      reportConfigsToUpdate = usedItem
        ?.filter(item => item.usedWidget === true)
        ?.map(item => ({
          position: item.position,
          newPageBefore: item.newPageBefore,
          id: item?.reportConfigId,
          widgetConfigsToUpsert: item?.widgetOptions?.map(option => ({
            settings: option.settings,
            widgetOptionId: option.id,
          })),
        }));
    }
    const variables = {
      ...reportInfo,
      id: reportId,
      name: reportInfo.name
        ? (reportInfo.name as string)
        : report
        ? (report?.name as string)
        : '',
      type: reportInfo.type
        ? (reportInfo.type as ReportType)
        : report
        ? (report?.type as ReportType)
        : ReportType.StageResults,
      subType: reportInfo.subType
        ? (reportInfo.subType as ReportSubType)
        : report
        ? (report?.subType as ReportSubType)
        : ReportSubType.StageResultsCandidate,
      reportConfigsToCreate: reportConfigsToCreate as ReportConfigCreateOneBatchedWithReportArgs[],
      reportConfigsToUpdate: reportConfigsToUpdate as ReportConfigUpdateOneArgs[],
      reportConfigsToDelete: reportInfo.reportConfigsToDelete as DeleteByIdArgs[],
    };
    updateReportMutationResponse({ variables });
  };

  return (
    <div>
      <SingleReportHeader reportId={reportId} report={report} />
      <ReportForm
        reportName={report && report?.name}
        reportId={reportId}
        company={report?.company?.name}
        reportType={report?.type}
        reportSubType={report?.subType as TSelectFormFieldValue}
        availableWidget={availableItem}
        usedWidget={usedItem}
        getModalWidgetInfo={getModalWidgetInfo}
        onSaveReport={onSaveReport}
        report={report}
        reOrderWidget={reOrderWidget}
        getNewReportConfig={getNewReportConfig}
        removeReportConfig={removeReportConfig}
        getReportDetails={getReportDetails}
        getNewPageBeforeValue={getNewPageBeforeValue}
      />
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default ReportMain;
