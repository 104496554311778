import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from 'components/atoms/Button/Button';
import MultiselectFormField from 'components/molecules/MultiselectFormField/MultiselectFormField';
import ProjectStagesSelectFormField from 'components/molecules/MultiselectFormField/ProjectStagesMultiSelectField/ProjectStagesMultiSelectField';
import CompanySelectFormField from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import SelectFormField from 'components/molecules/SelectFormField/SelectFormField';
import { StageCsvOptions } from 'generated/graphql';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import {
  BasicScoreTypeEnum,
  DisplayScoreTypeEnum,
  MeasurementConstructTypeEnum,
  SpSoftSkillSortEnum,
  StageCsvOptionsEnum,
} from './StageCSVForm.constant';
import {
  ICompanyProject,
  IStageCSVForm,
  IStageCSVFormValues,
} from './StageCSVForm.interface';
import useStageCSVFormStyle from './StageCSVFormStyle';

const StageCSVForm: FC<IStageCSVForm> = ({ onSubmit, isLoading }) => {
  const classes = useStageCSVFormStyle();
  const [companyProject, setCompanyProject] = useState<ICompanyProject>({
    company: 0,
  });
  const [values, setValues] = useState<IStageCSVFormValues>({
    stageIds: [],
    options: '' as StageCsvOptions,
    measurementConstructsToDisplay: [],
    scoreTypesToDisplay: [],
    BasicScoreType: [],
  });

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setValues(prev => ({
      ...prev,
      [name]: value !== undefined ? value : '',
    }));
  };

  const handleChangeCompany = (value: TSelectFormFieldValue, name: string) => {
    setCompanyProject(prev => ({
      ...prev,
      [name]: value !== undefined ? value : '',
    }));
  };

  const handleCreateCSV = () => {
    onSubmit(values);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.container}
      >
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item xs={3}>
            <CompanySelectFormField
              id="company-id"
              name="company"
              label="Company"
              value={companyProject.company}
              onChange={handleChangeCompany}
            />
          </Grid>
          <Grid item xs={6}>
            <ProjectStagesSelectFormField
              id="project-id"
              name="stageIds"
              label="project - Stage"
              value={values.stageIds}
              onChange={handleChange}
              companyId={+companyProject.company}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectFormField
              id="stageCsvOption-id"
              name="options"
              label="Report Options"
              value={values.options}
              onChange={handleChange}
              options={StageCsvOptionsEnum}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start">
          <p style={{ margin: '10px 0', paddingLeft: 10 }}>
            Select 'Custom' in report type to set these values. Leaving them
            blank will use the default. Some fields are multiselect e.g. Scores.
            can have percentile and grade.
          </p>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item xs={3}>
            <MultiselectFormField
              id="measurementConstruct-id"
              name="measurementConstructsToDisplay"
              label="Soft Skill Constructs To Include"
              value={values.measurementConstructsToDisplay}
              onChange={handleChange}
              options={MeasurementConstructTypeEnum}
              isDisabled={values.options !== StageCsvOptions.Custom}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiselectFormField
              id="scoreTypesToDisplay-id"
              name="scoreTypesToDisplay"
              label="Scores"
              value={values.scoreTypesToDisplay}
              onChange={handleChange}
              options={DisplayScoreTypeEnum}
              isDisabled={values.options !== StageCsvOptions.Custom}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiselectFormField
              id="BasicScoreType-id"
              name="BasicScoreType"
              label="Score Levels"
              value={values.BasicScoreType}
              onChange={handleChange}
              options={BasicScoreTypeEnum}
              isDisabled={values.options !== StageCsvOptions.Custom}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectFormField
              id="spSoftSkillSort-id"
              name="SpSoftSkillSort"
              label="Soft Skill Column Sort"
              value={values.SpSoftSkillSort}
              onChange={handleChange}
              options={SpSoftSkillSortEnum}
              isDisabled={values.options !== StageCsvOptions.Custom}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="center">
          <Grid className={classes.buttonContainer} item xs={3}>
            {!isLoading ? (
              <Button
                onClick={handleCreateCSV}
                className={classes.button}
                variant="contained"
                color="primary"
                isDisabled={!(values.stageIds?.length && values.options)}
              >
                Get CSV Report
              </Button>
            ) : (
              <Typography>...Request in progress please wait.</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StageCSVForm;
