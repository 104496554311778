import { makeStyles } from '@mui/styles';

const useSoftSkillsHeaderStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
}));

export default useSoftSkillsHeaderStyle;
