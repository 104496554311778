import { FC } from 'react';
import {
  ProjectFragment,
  RAssignedType,
  ReportSubType,
} from 'generated/graphql';
import ProjectCompanySignature from './ProjectCompanySignature';
import CandidateReportAssignment from './ReportAssignment';

interface IProjectReportsForm {
  project?: ProjectFragment;
}

const ProjectReportsForm: FC<IProjectReportsForm> = ({ project }) => {
  return (
    <div id="project-reports-form">
      <CandidateReportAssignment
        companyId={Number(project?.company?.id)}
        projectId={project?.id}
        reportAssignedType={RAssignedType.Project}
        subType={ReportSubType.StageResultsCandidate}
        label="Candidate report"
        name="candidateReport"
        checkBoxId="candidate-report-type-select-default-checkbox"
        selectFormId="candidate-report-type-select"
      />

      <CandidateReportAssignment
        companyId={Number(project?.company?.id)}
        projectId={project?.id}
        reportAssignedType={RAssignedType.Project}
        subType={ReportSubType.StageResultsManager}
        label="Manager report"
        name="managerReport"
        checkBoxId="manager-report-type-select-default-checkbox"
        selectFormId="manager-report-type-select"
      />
      <ProjectCompanySignature
        companyId={Number(project?.company?.id)}
        projectId={Number(project?.id)}
        projectCompanySignatureId={Number(project?.companySignatureId)}
      />
    </div>
  );
};

export default ProjectReportsForm;
