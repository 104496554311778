import './PaginationSection.scss';
import { FC, useState } from 'react';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import { Pageinfo } from '../../../../generated/graphql';
import B_ARROW_GREEN from '../../../../icons/arrow_backward_green.svg';
import B_ARROW_GREY from '../../../../icons/arrow_backward_grey.svg';
import F_ARROW_GREEN from '../../../../icons/arrow_forward_green.svg';
import F_ARROW_GREY from '../../../../icons/arrow_forward_grey.svg';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

interface IPaginationSection {
  pageInfo: Pageinfo;
  handlePaginationChange: (skip: number, take: number) => void;
}

interface IOptions {
  label: string;
  value: number;
}
const options: IOptions[] = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '30',
    value: 30,
  },
];

const PaginationSection: FC<IPaginationSection> = ({
  pageInfo,
  handlePaginationChange,
}) => {
  const { currentPage, pageTotal, itemsTotal, take, skip } = pageInfo;
  const [itemsPerPageValue, setItemsPerPageValue] = useState<number>(20);

  const handlePrevClicked = () => {
    if (skip !== 0)
      if (skip?.toString() && take?.toString()) {
        handlePaginationChange(skip - take, take);
      }
  };

  const handleNextClicked = () => {
    if (currentPage !== pageTotal)
      if (skip?.toString() && take?.toString()) {
        handlePaginationChange(skip + take, take);
      }
  };

  const handleSelectChange = (value: TSelectFormFieldValue, name: string) => {
    setItemsPerPageValue(value as number);
    handlePaginationChange(0, Number(value));
  };

  return (
    <div className="pagination-main-container">
      <div className="pagination-container">
        <div className="pagination-select">
          <SelectFormField
            id="select-form-field"
            name="itemsPerPage"
            label="itemsPerPage"
            value={itemsPerPageValue}
            onChange={handleSelectChange}
            options={options}
          />
        </div>
        {skip?.toString() && take?.toString() && (
          <div className="pagination-items-text">
            {skip + 1}- {skip + take > itemsTotal ? itemsTotal : skip + take} of{' '}
            {itemsTotal}
          </div>
        )}
        <img
          onClick={handlePrevClicked}
          src={skip !== 0 ? B_ARROW_GREEN : B_ARROW_GREY}
          className="pagination-icon"
          style={{ marginRight: 10 }}
          alt="arrow-icon"
        />
        <img
          onClick={handleNextClicked}
          src={currentPage !== pageTotal ? F_ARROW_GREEN : F_ARROW_GREY}
          style={{ marginRight: 10 }}
          className="pagination-icon"
          alt="arrow-icon"
        />
      </div>
    </div>
  );
};

export default PaginationSection;
