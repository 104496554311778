import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { StyledConfirmationModalProps } from 'interfaces/modal';
import { FC } from 'react';
import { Loader, Modal, ModalButtons } from '@spotted-zebra-uk/ui-components';

const StyledConfirmationModal: FC<StyledConfirmationModalProps> = ({
  message,
  onClose,
  onSave,
  saveBtnText,
  cancelBtnText,
  isLoading = false,
  open = false,
}) => {
  const handleClose = () => {
    closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
    onClose?.();
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      header={message}
      className="styled-confirmation-modal"
    >
      {isLoading ? (
        <Loader variant="bubbles" />
      ) : (
        <ModalButtons
          onConfirmButtonClick={onSave}
          confirmButtonChildren={saveBtnText}
          cancelButtonChildren={cancelBtnText}
          onCancelButtonClick={handleClose}
        />
      )}
    </Modal>
  );
};

export default StyledConfirmationModal;
