import TextFormField from 'components/molecules/TextFormField/TextFormField';
import DeleteApiKeyButton from 'components/organisms/DeleteApiKeyButton/DeleteApiKeyButton';
import { IdentityPermissionRole } from 'generated/graphql';
import { formatISO } from 'helpers/date';
import { Maybe } from 'interfaces/maybe';
import { FC } from 'react';
import { Grid } from '@mui/material';

interface IApiKeyListItem {
  apiKey: {
    id: number;
    key: string;
    createdAt: string;
    role: Maybe<IdentityPermissionRole>;
  };
}

const ApiKeyListItem: FC<IApiKeyListItem> = ({
  apiKey: { id, key, createdAt, role },
}) => {
  const formKey = 'aklif';
  return (
    <Grid style={{ padding: '8px 0' }} container item>
      <Grid item xs={3} style={{ paddingRight: '8px' }}>
        <TextFormField
          value={key}
          isDisabled
          id={`${formKey}-${id}-key`}
          name="Api key"
          label="Api key"
        />
      </Grid>
      <Grid item xs={3} style={{ paddingRight: '8px' }}>
        <TextFormField
          value={formatISO(createdAt)}
          isDisabled
          id={`${formKey}-${id}-createdAt`}
          name="Created at"
          label="Created at"
        />
      </Grid>
      <Grid item xs={3} style={{ paddingRight: '8px' }}>
        <TextFormField
          value={role || ''}
          isDisabled
          id={`${formKey}-${id}-role`}
          name="Role"
          label="Role"
        />
      </Grid>
      <Grid
        xs={3}
        item
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <DeleteApiKeyButton apiKey={key} apiKeyId={id} />
      </Grid>
    </Grid>
  );
};

export default ApiKeyListItem;
