import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import SwitchFormField from '../../../../components/molecules/SwitchFormField/SwitchFormField';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import { TTextFromFieldValue } from '../../../../interfaces/forms/form';
import useReportsHeaderStyles from './ReportHeaderStyles';

interface IReportsHeader {
  isArchivedVisible: boolean;
  toggleArchivedProjectsVisibility: (value: boolean) => void;
  search: TTextFromFieldValue;
  setSearch: (value: TTextFromFieldValue) => void;
  handleClick: () => void;
  headerTitle: string;
  buttonText: string;
  showSearchInput?: boolean;
}

const ReportHeader: FC<IReportsHeader> = ({
  isArchivedVisible,
  toggleArchivedProjectsVisibility,
  search,
  setSearch,
  handleClick,
  headerTitle,
  buttonText,
  showSearchInput = true,
}) => {
  const classes = useReportsHeaderStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid xs={3}>
        <Typography variant="h4" className={classes.title}>
          {headerTitle}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        xs={9}
        justifyContent="flex-end"
        alignItems="center"
      >
        {showSearchInput && (
          <Grid className={classes.searchInput}>
            <TextFormField
              id="search"
              name="Search"
              value={search}
              label="Search"
              placeholder="Type for search..."
              onChange={setSearch}
            />
          </Grid>
        )}
        <Grid>
          <SwitchFormField
            id="toggle-displey-archived-projects"
            name="isArchivedVisible"
            color="primary"
            value={isArchivedVisible}
            label="hide / display archived projects"
            onChange={toggleArchivedProjectsVisibility}
          />
        </Grid>
        <Grid>
          <Button
            color="inherit"
            className={classes.button}
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportHeader;
