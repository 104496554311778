import { makeStyles } from '@mui/styles';

const useSoftSkillFormStyle = makeStyles({
  container: {
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default useSoftSkillFormStyle;
