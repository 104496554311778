import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import {
  CompanyMaybeUserEmployeeFindManyDocument,
  ProjectRequestUserFindManyDocument,
  ProjectRequestUserFindManyQuery,
  ProjectRequestUserType,
  useCompanyMaybeUserEmployeeFindManyQuery,
  useProjectRequestUserCreateManyMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC, useCallback, useMemo } from 'react';
import {
  Loader,
  Modal,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  CreateProjectRequestContactsForm,
  CreateProjectRequestContactsFormValues,
} from './CreateProjectRequestContactsForm';

export interface CreateProjectRequestContactsModalProps {
  projectRequestId: number;
  companyId: number;
}

const CreateProjectRequestContactsModal: FC<CreateProjectRequestContactsModalProps> = ({
  projectRequestId,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const companyEmployeesQueryResponse = useCompanyMaybeUserEmployeeFindManyQuery(
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      variables: {
        companyId,
      },
    }
  );
  const [
    projectRequestUserCreateManyMutation,
  ] = useProjectRequestUserCreateManyMutation({
    refetchQueries: [
      {
        query: CompanyMaybeUserEmployeeFindManyDocument,
        variables: {
          companyId,
        },
      },
    ],
    update: (cache, { data }) => {
      if (data) {
        const projectRequestUsersCached = cache.readQuery<
          ProjectRequestUserFindManyQuery
        >({
          query: ProjectRequestUserFindManyDocument,
          variables: { projectRequestId },
        });

        cache.writeQuery<ProjectRequestUserFindManyQuery>({
          query: ProjectRequestUserFindManyDocument,
          variables: { projectRequestId },
          data: {
            ProjectRequestUserFindMany: projectRequestUsersCached
              ? [
                  ...projectRequestUsersCached.ProjectRequestUserFindMany,
                  ...data.ProjectRequestUserCreateMany,
                ]
              : data.ProjectRequestUserCreateMany,
          },
        });
      }
    },
  });

  const handleClose = useCallback(() => {
    closeModal(ModalTypes.CREATE_PROJECT_REQUEST_CONTACT_MODAL);
  }, []);

  const handleFormSubmit = async (
    values: CreateProjectRequestContactsFormValues
  ) => {
    try {
      const dto = values.contacts
        .filter(contact => contact.employee.value && contact.role.value)
        .map(contact => ({
          projectRequestId,
          // TODO: Fix select element types
          email: contact.employee.value as string,
          type: contact.role.value as ProjectRequestUserType,
        }));

      if (dto.length) {
        await projectRequestUserCreateManyMutation({
          variables: {
            dto,
          },
        });
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const employeesOptions = useMemo(
    () =>
      companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany?.map(
        employee => ({
          value: employee.email,
          label: `${employee.firstName} ${employee.lastName}`,
          labelNote: employee.email,
        })
      ) || [],
    [companyEmployeesQueryResponse.data]
  );

  return (
    <Modal
      isOpen
      className="create-project-request-user-modal"
      header="Add Contacts"
      onClose={handleClose}
    >
      {companyEmployeesQueryResponse.data && (
        <CreateProjectRequestContactsForm
          employeesOptions={employeesOptions}
          companyId={companyId}
          onSubmit={handleFormSubmit}
          onCancel={handleClose}
        />
      )}
      {companyEmployeesQueryResponse.loading && (
        <div className="create-project-request-user-modal__loader-wrapper">
          <Loader variant="bubbles" />
        </div>
      )}
    </Modal>
  );
};

export default CreateProjectRequestContactsModal;
