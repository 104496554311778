import { FC, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useCompaniesQuery } from '../../generated/graphql';
import { parseCompanies } from './Companies.helpers';
import CompaniesList from './CompaniesList/CompaniesList';
import CompaniesOptions from './CompaniesOptions/CompaniesOptions';
import useCompaniesStyle from './CompaniesStyle';

const Companies: FC = () => {
  const classes = useCompaniesStyle();
  const companiesResponse = useCompaniesQuery();
  const [search, setSearch] = useState<string>('');

  const handleSetSearch = (value: string) => {
    setSearch(value);
  };

  if (companiesResponse.data) {
    const parsedCompanies = parseCompanies(
      companiesResponse.data.Companies,
      search
    );
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={4}
        className={classes.container}
      >
        <CompaniesOptions search={search} setSearch={handleSetSearch} />
        {parsedCompanies ? <CompaniesList companies={parsedCompanies} /> : null}
      </Grid>
    );
  }

  return null;
};

export default Companies;
