import { FormType, TestSubType } from 'generated/graphql';
import { FC, useMemo } from 'react';
import useUserTableStyle from 'views/User/UserMain/UserTable/UserTableStyle';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { IActionsResultTable } from '../UserResets.interface';
import FormResultRow from './FormResultActionRow';
import { headerData } from './headerData.constant';
import RssAssessmentActionResultRow from './RssAssessmentActionResultRow';
import TestResultRow from './TestResultRow';

const ActionsResultTable: FC<IActionsResultTable> = ({
  userInfo,
  stageStatus,
  filteredTestCandidate,
  projectName,
  displaySnackbar,
  rssAssessmentCandidates,
  stageCandidateId,
  formCandidates,
}) => {
  const classes = useUserTableStyle();

  const sortedTestResults = filteredTestCandidate?.sort((a, b) =>
    (a?.test?.subType as TestSubType) < (b?.test?.subType as TestSubType)
      ? -1
      : 1
  );

  const rsFormResult = useMemo(() => {
    return formCandidates.find(fc => fc.form.formType === FormType.RsForm);
  }, [formCandidates]);
  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell className={classes.tableHeader} key={`a${index}`}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTestResults?.map(item => {
            return (
              <TestResultRow
                key={item.id}
                testCandidate={item}
                userInfo={userInfo}
                projectName={projectName}
                displaySnackbar={displaySnackbar}
              />
            );
          })}
          {Boolean(rssAssessmentCandidates.length) && (
            <RssAssessmentActionResultRow
              rssAssessmentCandidates={rssAssessmentCandidates}
              stageCandidateId={stageCandidateId}
            />
          )}
          {rsFormResult && (
            <FormResultRow
              formCandidate={rsFormResult}
              stageCandidateId={stageCandidateId}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ActionsResultTable;
