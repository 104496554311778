import { FC } from 'react';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import {
  IgInterviewGuideCategory,
  IgInterviewGuideCategoryFindManyDocument,
  useIgInterviewGuideCategoryDeleteMutation,
} from '../../../../generated/graphql';
import { openModal } from '../../../../graphql/modals';
import useInterviewGuideDndStyle from '../InterviewGuideDndStyle';

interface IInterviewGuideCategoryItem {
  interviewGuideCategory: IgInterviewGuideCategory;
  interviewGuideId: number;
}

const InterviewGuideCategoryItem: FC<IInterviewGuideCategoryItem> = ({
  interviewGuideCategory,
  interviewGuideId,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useInterviewGuideDndStyle();

  const [
    removeInterviewGuideCategory,
  ] = useIgInterviewGuideCategoryDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: IgInterviewGuideCategoryFindManyDocument,
        variables: {
          interviewGuideId,
        },
      },
    ],
  });
  const handleRemoveInterviewGuideCategory = (
    interviewGuideCategory: IgInterviewGuideCategory
  ) => {
    const variables = {
      id: interviewGuideCategory.id,
    };
    removeInterviewGuideCategory({ variables });
  };
  const openEditInterviewGuideCategoryModalConfigModal = () => {
    openModal(ModalTypes.INTERVIEW_GUIDE_CATEGORY_CONFIG_MODAL, {
      interviewGuideCategory,
    });
  };
  return (
    <>
      <div
        style={{
          border: 'solid 1px #757575',
          backgroundColor: '#fff',
          marginBottom: 10,
        }}
        className={classes.widgetOption2}
      >
        <p className={classes.text}>
          <RemoveCircleOutline
            className={classes.removeBtn}
            onClick={() =>
              handleRemoveInterviewGuideCategory(interviewGuideCategory)
            }
          />
          {interviewGuideCategory.category.name}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '420px',
            }}
          >
            <Button
              className={classes.viewBtn}
              color="inherit"
              onClick={() => openEditInterviewGuideCategoryModalConfigModal()}
            >
              VIEW
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewGuideCategoryItem;
