import Icon from 'components/atoms/Icon';
import { FC, MouseEventHandler } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import useFileUploadFormFieldStyle from './FileUploadFormFieldStyle';

interface IFileUploadFormField {
  id: string;
  name: string;
  onChange: (file: File, name: string) => void;
  value: File | null;
  errorText?: string | undefined;
  isFileNameHidden?: boolean;
  label?: string;
  onClearClick?: () => void;
}

const FileUploadFormField: FC<IFileUploadFormField> = ({
  onChange,
  id,
  name,
  label,
  value,
  errorText,
  isFileNameHidden = false,
  onClearClick,
}) => {
  const classes = useFileUploadFormFieldStyle();
  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target?.files[0]) {
      onChange(event.target?.files[0] as File, name);
    }
  };

  const handleClearClick: MouseEventHandler<HTMLAnchorElement> = event => {
    onClearClick && onClearClick();
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.input}
        id={id}
        name={name}
        type="file"
        onChange={handleUpload}
      />
      <label className={classes.label} htmlFor={id}>
        <TextField
          variant="standard"
          label={value?.name ?? label}
          InputProps={{ endAdornment: <Icon icon="upload" /> }}
          className={classes.inputButton}
        />
        {errorText ? <Typography color="error">{errorText}</Typography> : null}
      </label>
      {onClearClick ? (
        <Button
          className={classes.clearButton}
          color="primary"
          component="span"
          disabled={!value}
          onClick={handleClearClick}
        >
          X
        </Button>
      ) : null}
    </div>
  );
};

export default FileUploadFormField;
