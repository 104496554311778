import { ReportConfig, useWidgetQuery, WidgetOption } from 'generated/graphql';
import { IWidgetPath } from 'interfaces/widget';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import WidgetArchive from './WidgetArchive/WidgetArchive';
import EditWidget from './WidgetEdit/WidgetEdit';
import WidgetOptions from './WidgetOptions/WidgetOption';
import WidgetReports from './WidgetReports/WidgetReports';
import WidgetScreenshot from './WidgetScreenshot/WidgetScreenshot';
import useWidgetStyles from './WidgetStyles';

const Widget = () => {
  const classes = useWidgetStyles();

  const { widgetId } = useParams() as IWidgetPath;
  const parsedWidgetId: number = parseInt(widgetId, 10);
  const getWidgetByIdResponse = useWidgetQuery({
    variables: { id: parsedWidgetId },
  });
  if (getWidgetByIdResponse.data?.Widget) {
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid xs={6} className={classes.w100}>
            <WidgetArchive
              isArchived={getWidgetByIdResponse?.data?.Widget?.isArchived}
              id={parsedWidgetId}
            />
            {getWidgetByIdResponse?.data?.Widget && (
              <EditWidget
                id={parsedWidgetId}
                name={getWidgetByIdResponse?.data?.Widget?.name as string}
                type={getWidgetByIdResponse?.data?.Widget?.type}
              />
            )}
            <WidgetOptions
              widgetOptions={
                getWidgetByIdResponse?.data?.Widget
                  ?.widgetOptions as WidgetOption[]
              }
              id={parsedWidgetId}
            />
            <WidgetReports
              reportConfigs={
                getWidgetByIdResponse?.data?.Widget
                  ?.reportConfigs as ReportConfig[]
              }
            />
          </Grid>
          <Grid xs={6} pl={2}>
            <WidgetScreenshot
              screenShotUrl={
                getWidgetByIdResponse?.data?.Widget?.screenShotBlob
                  ?.url as string
              }
              id={parsedWidgetId}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
  return null;
};

export default Widget;
