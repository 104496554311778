import { makeStyles } from '@mui/styles';

const useCandidateInfoFieldsListItemStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
  gridItemWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useCandidateInfoFieldsListItemStyle;
