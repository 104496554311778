import { makeStyles } from '@mui/styles';

const useInterviewGuideStyles = makeStyles(theme => ({
  saveBtn: {
    marginRight: 10,
    marginBottom: 20,
  },
  container: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '22px',
  },
  button: {
    fontSize: '16px',
  },
  deleteBtn: {
    fontSize: '16px',
    backgroundColor: '#f50057',
    marginLeft: '10px',
    color: '#fff',
  },
}));
export default useInterviewGuideStyles;
