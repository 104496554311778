import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import FileUploadFormField from '../../../../../../components/molecules/FileUploadFormField/FileUploadFormField';

interface IUploadCompanyLogoForm {
  onSubmit: (file: File) => void;
}

const UploadCompanyLogoForm: FC<IUploadCompanyLogoForm> = ({ onSubmit }) => {
  const [values, setValues] = useState<{ file: File | null }>({
    file: null,
  });

  const handleChangeFile = (value: File, name: string) => {
    setValues({
      file: value,
    });

    if (value) {
      onSubmit(value);
    }
  };

  return (
    <>
      <Grid item>
        <FileUploadFormField
          id="company-file"
          name="file"
          value={values.file}
          onChange={handleChangeFile}
          isFileNameHidden
          label="Upload logo"
        />
      </Grid>
    </>
  );
};

export default UploadCompanyLogoForm;
