import { FC, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  CompanyFindUsersDocument,
  CompanyFindUsersQuery,
  CompanyFindUsersQueryVariables,
  User,
  UserFragment,
  useUserUpdateMutation,
} from 'generated/graphql';
import UserRoles from '../../../../../../User/UserDetails/UserRoles/UserRoles';
import CompanyUsersErrorMessages from '../../../../../CompanyUsersErrorMessage/CompanyUsersErrorMessage';
import EditCompanyEmployeeForm from './EditCompanyEmployeeForm/EditCompanyEmployeeForm';

interface ICompanyEmployeesListItem {
  companyId: number;
  companyEmployee: UserFragment;
}

const CompanyEmployeesListItem: FC<ICompanyEmployeesListItem> = ({
  companyId,
  companyEmployee,
}) => {
  const { handleMsgType } = useNotification();

  const [error, setError] = useState<ApolloError | undefined>();
  const [
    editCompanyEmployeeMutation,
    editCompanyEmployeeMutationResponse,
  ] = useUserUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });

      setError(error);
    },
    onCompleted: () => {
      setError(undefined);
    },
    update(cache, { data }) {
      // Add new company employee to cache.
      const companyEmployee = data?.User;
      if (companyEmployee) {
        const cacheData = cache.readQuery<
          CompanyFindUsersQuery,
          CompanyFindUsersQueryVariables
        >({
          query: CompanyFindUsersDocument,
          variables: {
            id: companyId,
          },
        });
        if (cacheData && companyEmployee) {
          cache.writeQuery({
            query: CompanyFindUsersDocument,
            variables: {
              companyId,
            },
            data: {
              companyEmployees: cacheData?.companyUsers?.map(
                companyEmployeeCache =>
                  companyEmployee.id === companyEmployeeCache?.id
                    ? companyEmployee
                    : companyEmployeeCache
              ),
            },
          });
        }
      }
    },
  });
  const handleCancel = () => {
    setError(undefined);
  };

  const handleSave = async (
    firstName: string,
    lastName: string,
    email: string
  ): Promise<boolean> => {
    const result = await editCompanyEmployeeMutation({
      variables: {
        id: companyEmployee.id,
        firstName,
        lastName,
        email,
      },
    });

    return !!result;
  };

  const filterCompanyRoles = (companyEmployee: User): User => {
    const filteredRoles = companyEmployee.roles.filter(
      role => role.companyRole.companyId === companyId
    );
    return { ...companyEmployee, roles: filteredRoles };
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ border: '1px solid black', marginTop: 20, padding: '16px' }}
    >
      <EditCompanyEmployeeForm
        onSave={handleSave}
        companyEmployee={companyEmployee}
        isLoading={editCompanyEmployeeMutationResponse.loading}
        onCancel={handleCancel}
      />
      <UserRoles
        userInfo={filterCompanyRoles(companyEmployee as User)}
        allowCompanyEditing={false}
      />
      <Grid item>
        <CompanyUsersErrorMessages error={error} />
      </Grid>
    </Grid>
  );
};

export default CompanyEmployeesListItem;
