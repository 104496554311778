import {
  CompanyMaybeUserEmployeeFindManyQuery,
  ProjectRequestUserFindManyQuery,
  useCompanyMaybeUserEmployeeFindManyQuery,
  useProjectRequestUserFindManyQuery,
} from 'generated/graphql';
import { Maybe } from 'interfaces/maybe';
import { ArrayElement } from 'interfaces/typeOfArrayElement';
import { useMemo } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type TProjectRequestUser = ArrayElement<
  ProjectRequestUserFindManyQuery['ProjectRequestUserFindMany']
>;
type TCompanyMaybeUserEmployee = ArrayElement<
  NonNullable<
    CompanyMaybeUserEmployeeFindManyQuery['CompanyMaybeUserEmployeeFindMany']
  >
>;

export type TProjectRequestUserWithCompanyMaybeEmployee = TProjectRequestUser & {
  companyEmployee: Maybe<TCompanyMaybeUserEmployee>;
};

export const useGetProjectRequestUsers = (
  projectRequestId: number,
  projectRequestCompanyId: number
): {
  projectRequestUsers: TProjectRequestUserWithCompanyMaybeEmployee[];
  loading: boolean;
  error: boolean;
} => {
  const { handleMsgType } = useNotification();

  const projectRequestUserFindManyQueryResult = useProjectRequestUserFindManyQuery(
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      variables: {
        projectRequestId,
      },
    }
  );
  const companyEmployeesQueryResponse = useCompanyMaybeUserEmployeeFindManyQuery(
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      variables: {
        companyId: projectRequestCompanyId,
      },
    }
  );

  const projectRequestUsers = useMemo(() => {
    const projectRequestUsers =
      projectRequestUserFindManyQueryResult.data?.ProjectRequestUserFindMany;
    const companyMaybeEmployees =
      companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany;
    if (!projectRequestUsers || !companyMaybeEmployees) {
      return [];
    }

    return projectRequestUsers.map(projectRequestUser => {
      const companyEmployee = companyMaybeEmployees.find(
        cme => cme.userId === projectRequestUser.userId
      );

      return {
        ...projectRequestUser,
        companyEmployee,
      };
    });
  }, [
    companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany,
    projectRequestUserFindManyQueryResult.data?.ProjectRequestUserFindMany,
  ]);

  return {
    projectRequestUsers,
    loading:
      companyEmployeesQueryResponse.loading ||
      projectRequestUserFindManyQueryResult.loading,
    error:
      Boolean(companyEmployeesQueryResponse.error) ||
      Boolean(projectRequestUserFindManyQueryResult.error),
  };
};
