import {
  InlineNotification,
  Modal,
  ModalSize,
  NotificationType,
} from '@spotted-zebra-uk/ui-components';
import { ProjectUser } from '../../UserProjectRoles.helpers';
import styles from '../../UserProjectRoles.module.scss';
import { ModalLabel } from '../ModalFieldLabel';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  selectedUserProject: ProjectUser;
  onConfirm: () => void;
};

export const DeleteModal = ({
  open,
  onClose,
  selectedUserProject,
  onConfirm,
}: ModalProps) => {
  return (
    <Modal
      header="Delete project role"
      isOpen={open}
      onClose={onClose}
      buttonsProps={{
        onConfirmButtonClick: onConfirm,
        confirmButtonChildren: 'Delete',
      }}
      modalSize={ModalSize.SMALL}
    >
      <ModalLabel
        label="Company"
        labelValue={selectedUserProject?.project?.company?.name || ''}
      />

      <ModalLabel
        label="Project"
        labelValue={selectedUserProject?.project?.name}
      />
      <InlineNotification
        notificationType={NotificationType.WARNING}
        className={styles.inlineNotification}
      >
        The user may lose access to the project mentioned above if you proceed
        to delete their project role.
      </InlineNotification>
    </Modal>
  );
};

export default DeleteModal;
