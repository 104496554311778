import ENVELOP from 'icons/envelop.svg';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { TextInputField } from '@spotted-zebra-uk/ui-components';
import styles from './ProjectJobRolesOverviewSkillsList.module.scss';
import { TSkillOverview } from './ProjectJobRolesOverviewSkillsList.types';

interface IProjectJobRolesOverviewSoftSkillsList {
  skills: TSkillOverview[];
  emptyViewText: string;
}
const ProjectJobRolesOverviewSoftSkillsList: FC<IProjectJobRolesOverviewSoftSkillsList> = ({
  skills,
  emptyViewText,
}) => {
  if (isEmpty(skills)) {
    return (
      <div
        className={styles.containerEmpty}
        data-testid="project-job-roles-overview-skills-empty-view"
      >
        <img src={ENVELOP} alt="Empty" />
        <p className={styles.containerEmptyText}>{emptyViewText}</p>
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      data-testid="project-job-roles-overview-skills-list"
    >
      {skills.map(skill => (
        <div
          key={`${skill.id}-${skill.name}`}
          className={styles.item}
          data-testid="project-job-roles-overview-skills-list-item"
        >
          <TextInputField
            id={`success-profile-skill-name-${skill.id}-${skill.name}`}
            onChange={() => {}}
            value={skill.name}
            label="Name"
            placeholder="Name"
            disabled
          />
        </div>
      ))}
    </div>
  );
};

export default ProjectJobRolesOverviewSoftSkillsList;
