import {
  ProjectJobRoleFragmentDoc,
  useJobRoleFindManyQuery,
  useProjectJobRoleCreateOneMutation,
  useProjectJobRoleFindManyQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalSize,
  TNotification,
  useNotification,
  Select,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import {
  filterJobRoles,
  mapJobRolesForSelect,
} from '../ProjectJobRolesOverviewPresentational/ProjectJobRolesOverviewPresentational.helpers';
import {
  TAddJobRoleModalProps,
  TSelectedJobRole,
} from '../ProjectJobRolesOverviewPresentational/ProjectJobRolesOverviewPresentational.types';
import styles from './AddJobRoleModal.module.scss';

export function AddJobRoleModal({
  open,
  onClose,
  projectId,
  companyId,
}: TAddJobRoleModalProps) {
  return (
    <Modal
      header="Add job role"
      modalSize={ModalSize.SMALL}
      isOpen={open}
      onClose={onClose}
    >
      {open && (
        <Content
          onClose={onClose}
          projectId={projectId}
          companyId={companyId}
        />
      )}
    </Modal>
  );
}

type ContentProps = {
  projectId: number;
  companyId: number;
  onClose: () => void;
};

const Content = ({ projectId, companyId, onClose }: ContentProps) => {
  const { handleMsgType } = useNotification();

  const [availableJobRoles, setAvailableJobRoles] = useState<TSelectOption[]>(
    []
  );
  const [selectedJobRole, setSelectedJobRole] = useState<TSelectedJobRole>();
  const {
    data: currentlyActiveJobRolesResponse,
  } = useProjectJobRoleFindManyQuery({
    variables: {
      id: projectId,
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
  });

  const currentlyActiveJobRolesLevel =
    currentlyActiveJobRolesResponse?.ProjectJobRoleFindMany?.[0]?.jobRole
      ?.roleLevel;

  const {
    data: availableJobRolesResponse,
    loading: loadingJobRoles,
  } = useJobRoleFindManyQuery({
    variables: {
      companyId: companyId,
      ...(currentlyActiveJobRolesLevel && {
        roleLevel: currentlyActiveJobRolesLevel,
      }),
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message:
          error?.message || 'Ops, some issue happened while getting job roles',
      });
    },
  });

  const [createJobRole] = useProjectJobRoleCreateOneMutation({
    onCompleted: data => {
      handleMsgType({
        type: TNotification.success,
        message: `Job role ${data?.ProjectJobRoleCreateOne?.jobRole?.name} added to project`,
      });
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message:
          error?.message || 'Ops, some issue happened while creating job role',
      });
    },
    update: (cache, { data }) => {
      const newProjectJobRole = data?.ProjectJobRoleCreateOne;

      if (newProjectJobRole) {
        cache.modify({
          fields: {
            ProjectJobRoleFindMany(existingProjectJobRoles = []) {
              const newProjectJobRoleRef = cache.writeFragment({
                data: newProjectJobRole,
                fragment: ProjectJobRoleFragmentDoc,
                fragmentName: 'ProjectJobRole',
              });

              return [...existingProjectJobRoles, newProjectJobRoleRef];
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    const availableJobRoles = availableJobRolesResponse?.JobRoleFindMany || [];
    const currentlyActiveJobRoles =
      currentlyActiveJobRolesResponse?.ProjectJobRoleFindMany || [];
    const filteredJobRoles = filterJobRoles(
      availableJobRoles,
      currentlyActiveJobRoles
    );

    const jobRolesForSelect = filteredJobRoles.map(mapJobRolesForSelect);

    setAvailableJobRoles(jobRolesForSelect);
    setSelectedJobRole(jobRolesForSelect[0]);
  }, [currentlyActiveJobRolesResponse, availableJobRolesResponse]);

  function handleSelectJobRole(value: TSelectOption) {
    setSelectedJobRole(value);
  }

  function handleConfirm() {
    createJobRole({
      variables: {
        jobRoleId: Number(selectedJobRole?.value),
        projectId: projectId,
        skillsProfileId: selectedJobRole?.additionalProps?.sucessProfileId,
      },
    });

    onClose();
  }

  const hasSelectedJobRole = Boolean(selectedJobRole?.value);

  return (
    <div>
      <Select
        id="sz-projectjobrolesoverview-add-job-role"
        onChange={handleSelectJobRole}
        placeholder="Job role"
        options={availableJobRoles}
        value={selectedJobRole}
        isLoading={loadingJobRoles}
        isSearchable
      />
      <div className={styles.modalButtons}>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!hasSelectedJobRole} onClick={handleConfirm}>
          Add
        </Button>
      </div>
    </div>
  );
};
