import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../../../components/atoms/Button/Button';

export interface ITraitFormHeaderPresentational {
  isArchived?: boolean;
  isEditMode: boolean;
  onDelete: () => void;
  onArchive: () => void;
  error?: string;
}

const TraitFormHeaderPresentational: FC<ITraitFormHeaderPresentational> = ({
  isArchived,
  isEditMode,
  onDelete,
  onArchive,
  error,
}) => {
  return (
    <Grid container item>
      {isEditMode ? (
        <Grid container item>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={10}
          >
            <Grid item>
              <Button onClick={onArchive}>
                {isArchived ? 'Unarchive' : 'Archive'}
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h6">Edit trait</Typography>
            </Grid>
            <Grid item>
              <Button onClick={onDelete}>Delete</Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {error ? <Typography color="error">{error}</Typography> : null}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">Add trait</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default TraitFormHeaderPresentational;
