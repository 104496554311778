import Button from 'components/atoms/Button/Button';
import { interviewGuidesRoutes } from 'constants/navigation';
import { formatDate } from 'helpers/date';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import useCategoriesTableRowStyle from './CategoriesTableRowStyle';

interface ICategoriesTableRow {
  name: string;
  id: number;
  isArchived: boolean;
  numberOfInterviewGuides: number;
  updatedAt: string;
}

const CategoriesTableRow: React.FC<ICategoriesTableRow> = ({
  name,
  id,
  isArchived,
  updatedAt,
  numberOfInterviewGuides,
}) => {
  const classes = useCategoriesTableRowStyle();
  const navigate = useNavigate();

  const handleViewBtnClick = () => {
    navigate(interviewGuidesRoutes.category.url(id), {
      state: {
        name,
        isArchived,
      },
    });
  };
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{name}</TableCell>
      <TableCell className={classes.tableCell}>
        {numberOfInterviewGuides}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {formatDate(updatedAt, 'dd MMM yyyy')}{' '}
        {isArchived && <span className={classes.archivedText}>Archived</span>}
      </TableCell>
      <TableCell align="right">
        <Button className={classes.btn} onClick={handleViewBtnClick}>
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default CategoriesTableRow;
