import { makeStyles } from '@mui/styles';

const useWidgetStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  w100: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'between',
    alignItem: 'center',
  },
  title: {
    fontSize: '16px !important',
    margin: '20px 0',
  },
  widgetOption: {
    borderRadius: '4px',
    border: 'solid 1px #757575',
    padding: '8px 8px 8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btn: {
    width: 96,
  },
  deleteBtn: {
    width: 196,
  },
  firstBtn: {
    width: 96,
    marginRight: '10px !important',
  },
  mb10: {
    marginBottom: 10,
  },
  archivedText: {
    color: '#f50057',
  },
  btnGroup: {
    width: '280px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 5,
  },
}));

export default useWidgetStyles;
