import { makeStyles } from '@mui/styles';

const useGridContainerStyle = makeStyles({
  container: {
    padding: '20px',
    width: '100%',
    margin: 0,
  },
});

export default useGridContainerStyle;
