import Button from 'components/atoms/Button/Button';
import DeleteApiKeyButton from 'components/organisms/DeleteApiKeyButton/DeleteApiKeyButton';
import { editCompanyRoutes } from 'constants/navigation';
import { CompanyFragment, CompanyFragmentDoc } from 'generated/graphql';
import { formatDate } from 'helpers/date';
import { TApiKey } from 'interfaces/apiKey';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { TableCell, TableRow } from '@mui/material';

interface IApiKeysCompanyListItem {
  apiKey: TApiKey;
}

const ApiKeysCompanyListItem: FC<IApiKeysCompanyListItem> = ({
  apiKey: { ownerId, key, createdAt, role, id },
}) => {
  const client = useApolloClient();

  const apiKeyCompany = client.readFragment<CompanyFragment>({
    id: `Company:${ownerId}`,
    fragment: CompanyFragmentDoc,
  });

  return (
    <TableRow>
      <TableCell>{key}</TableCell>
      <TableCell>
        {formatDate(new Date(createdAt).toISOString(), 'dd MMM yyyy')}
      </TableCell>
      <TableCell>{apiKeyCompany?.name}</TableCell>
      <TableCell>{role}</TableCell>
      <TableCell>
        <Link to={editCompanyRoutes.editCompanyApiKeys.url(ownerId)}>
          <Button>Edit</Button>
        </Link>
        <span style={{ marginLeft: 5 }}>
          <DeleteApiKeyButton apiKey={key} apiKeyId={id} />
        </span>
      </TableCell>
    </TableRow>
  );
};

export default ApiKeysCompanyListItem;
