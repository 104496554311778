import Icon from 'components/atoms/Icon';
import { appRoutes, projectRequestsRoutes } from 'constants/navigation';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectFragment,
  useEditProjectArchivingMutation,
  useEditProjectStateMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { StyledConfirmationModalProps } from 'interfaces/modal';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@spotted-zebra-uk/ui-components';

interface IProjectBar {
  project?: ProjectFragment;
  projectRequestId?: number;
}

const ProjectBar: FC<IProjectBar> = ({ project, projectRequestId }) => {
  const navigate = useNavigate();
  const [editProjectState] = useEditProjectStateMutation();
  const [archiveProject] = useEditProjectArchivingMutation();

  const handleChangeProjectState = () => {
    if (project) {
      editProjectState({
        variables: { id: project.id, state: !project.isStopped },
      });
    }
  };

  const handleArchiveProject = () => {
    if (project) {
      archiveProject({
        variables: { id: project.id, state: !project.isArchived },
      });
    }
  };

  const handleOpenDeleteModal = () => {
    if (project) {
      const confirmationModalProps: StyledConfirmationModalProps = {
        message: `Do you want to delete ${project.name} project?`,
        saveBtnText: 'Yes, I am Sure',
        cancelBtnText: 'No, Keep It',
        saveBtnColor: 'purple',
        open: true,
        onSave: () => closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL),
      };
      openModal(ModalTypes.STYLED_CONFIRMATION_MODAL, confirmationModalProps);
    }
  };

  const handleBackToProjectRequest = () => {
    if (projectRequestId) {
      navigate(projectRequestsRoutes.projectRequest.url(projectRequestId));
    }
  };

  const backToProjectsPage = () => {
    navigate(appRoutes.projects.url());
  };

  return (
    <div className="project-bar">
      <div className="project-bar__left">
        <Button
          onClick={backToProjectsPage}
          size="small"
          variant="text"
          className="project-bar__back-button"
          leftIcon={
            <Icon icon="left_arrow" className="project-bar__back-button-icon" />
          }
        >
          Projects
        </Button>
      </div>
      <div className="project-bar__right">
        {projectRequestId ? (
          <Button
            onClick={handleBackToProjectRequest}
            size="medium"
            variant="secondary"
          >
            Project Request
          </Button>
        ) : null}
        <Button
          onClick={handleArchiveProject}
          size="medium"
          variant="secondary"
          disabled={!project}
        >
          {project?.isArchived ? 'Unarchive' : 'Archive'}
        </Button>
        {!project?.isArchived ? (
          <Button
            onClick={handleChangeProjectState}
            size="medium"
            disabled={!project}
          >
            {project?.isStopped ? 'Start' : 'Stop'}
          </Button>
        ) : (
          <Button
            onClick={handleOpenDeleteModal}
            size="medium"
            variant="secondary"
            disabled={!project}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProjectBar;
