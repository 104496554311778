import { FC } from 'react';
import SelectWithQuery from '../../../../../components/molecules/SelectFormField/SelectWithQuery/SelectWithQuery';
import {
  FormManagerTypesDocument,
  FormManagerTypesQuery,
} from '../../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../../interfaces/forms/form';

interface IFormManagerTypesSelectFormField {
  id: string;
  name: string;
  label: string;
  hasError?: boolean;
  errorText?: string;
  value: TSelectFormFieldValue;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
}

const FormManagerTypesSelectFormField: FC<IFormManagerTypesSelectFormField> = props => {
  const getOptionsFromResponse = (response: FormManagerTypesQuery) => {
    return response?.FormReportFindFormManagerTypes?.formManagerTypes
      ? response.FormReportFindFormManagerTypes.formManagerTypes.map(type => ({
          label: type,
          value: type,
        }))
      : [];
  };

  return (
    <SelectWithQuery
      query={FormManagerTypesDocument}
      getOptionsFromResponse={getOptionsFromResponse}
      selectElementProps={props}
    />
  );
};

export default FormManagerTypesSelectFormField;
