import { ModalTypes } from 'enums/modalTypes';
import { useUpdateCompanyMutation } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import {
  Button,
  InlineNotification,
  Modal,
  ModalSize,
  NotificationType,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ReportsAccessTypes } from './ReportsAccess';
import styles from './ReportsAccess.module.scss';

export type IReportAccessModal = {
  id: number;
  name: string;
  accessType: ReportsAccessTypes;
  onSubmit: (value: boolean) => void;
};

const ReportAccessModal = ({
  accessType,
  id,
  name,
  onSubmit,
}: IReportAccessModal) => {
  const { handleMsgType } = useNotification();

  const handleCloseModal = () => {
    closeModal(ModalTypes.REPORTS_ACCESS);
  };

  const [editCompanyMutation, { loading }] = useUpdateCompanyMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Default report access successfully updated',
      });
      onSubmit(accessType === ReportsAccessTypes.LINKS);
      handleCloseModal();
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: 'Reports access method could not be updated',
        message: 'Something went wrong. Please try again.',
      });
      handleCloseModal();
    },
  });

  const handleSubmit = () => {
    editCompanyMutation({
      variables: {
        id,
        name,
        managerReportEmailLinks: accessType === ReportsAccessTypes.LINKS,
      },
    });
  };

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen
      modalSize={ModalSize.SMALL}
      className={styles.modalContainer}
      header="Confirm change to report access"
    >
      <p className={styles.label}>
        Reports accessible via
        <span className={styles.accessType}>{accessType}</span>
      </p>
      <InlineNotification
        notificationType={NotificationType.NEUTRAL}
        className={styles.reportsInlineNotification}
      >
        The change will only affect new projects. Existing projects will retain
        their current settings, but report access can be changed at the project
        level.
      </InlineNotification>
      <div className={styles.actionsContainer}>
        <Button
          onClick={handleCloseModal}
          size="medium"
          variant="secondary"
          data-testid="reports-access__cancel-button"
        >
          Cancel
        </Button>
        <Button
          size="medium"
          type="submit"
          loading={loading}
          onClick={handleSubmit}
          data-testid="reports-access__confirm-button"
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ReportAccessModal;
