import { ModalTypes } from 'enums/modalTypes';
import {
  StageCandidateFindManyDocument,
  StageCandidateFindManyQuery,
  StageCandidateFindManyQueryVariables,
  useStageCandidateDeleteOneMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { IconType } from 'interfaces/modal';
import {
  Modal,
  ModalSize,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { IDeleteAssessmentProps } from '../../User/UserDetails/UserMainResets/UserReset/StageReset/DeleteAssessment/DeleteAssessment';
import styles from './DeleteAssessmentModal.module.scss';

const DeleteAssessmentModal = ({
  candidateName,
  projectName,
  candidateId,
  stageCandidateSubId,
}: IDeleteAssessmentProps) => {
  const handleCloseModal = () => {
    closeModal(ModalTypes.DELETE_ASSESSMENT_MODAL);
  };
  const { handleMsgType } = useNotification();

  const [
    deleteAssessmentMutation,
    { loading },
  ] = useStageCandidateDeleteOneMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Successfully deleted assessment!',
      });
      handleCloseModal();
    },
    update(cache, { data }) {
      if (data?.StageCandidateDeleteOne.affected) {
        const existingStages = cache.readQuery<
          StageCandidateFindManyQuery,
          StageCandidateFindManyQueryVariables
        >({
          query: StageCandidateFindManyDocument,
          variables: { candidateId: candidateId },
        });

        const newStages = existingStages?.StageCandidates?.filter(
          stage => stage.subId !== stageCandidateSubId
        );
        if (existingStages?.StageCandidates) {
          cache.writeQuery({
            query: StageCandidateFindManyDocument,
            variables: {
              candidateId: candidateId,
            },
            data: {
              StageCandidates: newStages,
            },
          });
        }
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  return (
    <Modal
      header="Are you sure?"
      isOpen
      headerIconType={IconType.WARNING}
      onClose={handleCloseModal}
      modalSize={ModalSize.SMALL}
      buttonsProps={{
        onConfirmButtonClick: () => {
          deleteAssessmentMutation({
            variables: { subId: stageCandidateSubId },
          });
        },
        confirmButtonProps: { disabled: loading, loading: loading },
        confirmButtonChildren: 'Delete assessment',
        cancelButtonChildren: 'Cancel',
      }}
      className={styles.container}
    >
      You are about to remove{' '}
      <span className={styles.bold}>{candidateName}</span> from{' '}
      <span className={styles.bold}>{projectName}</span> and delete all
      associated results. This cannot be undone.
    </Modal>
  );
};

export default DeleteAssessmentModal;
