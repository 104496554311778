import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../../components/atoms/Button/Button';
import CircularLoader from '../../../../../../components/molecules/CircularLoader/CircularLoader';
import { ModalTypes } from '../../../../../../enums/modalTypes';
import {
  ApiKeyFindAllDocument,
  ApiKeyFindAllQuery,
  useApiKeyUserGenerateMutation,
} from '../../../../../../generated/graphql';
import { closeModal, openModal } from '../../../../../../graphql/modals';

interface IGenerateUserApiKeyButton {
  userId: number;
}

const GenerateUserApiKeyButton: FC<IGenerateUserApiKeyButton> = ({
  userId,
}) => {
  const { handleMsgType } = useNotification();

  const [
    apiKeyUserGenerateMutation,
    apiKeyGenerateMutationResponse,
  ] = useApiKeyUserGenerateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      closeModal(ModalTypes.CONFIRMATION_MODAL);
    },
    update: (cache, { data }) => {
      if (data) {
        const apiKeysFromCache = cache.readQuery<ApiKeyFindAllQuery>({
          query: ApiKeyFindAllDocument,
        });

        if (
          !apiKeysFromCache?.ApiKeyFindAll.find(
            apiKey => apiKey.id === data.ApiKey.id
          )
        ) {
          if (apiKeysFromCache) {
            cache.writeQuery<ApiKeyFindAllQuery>({
              query: ApiKeyFindAllDocument,
              data: {
                ApiKeyFindAll: [...apiKeysFromCache.ApiKeyFindAll, data.ApiKey],
              },
            });
          }
        }
      }
    },
  });

  const handleGenerateApiKeyClick = () => {
    openModal(ModalTypes.CONFIRMATION_MODAL, {
      open: true,
      message: `This action will create new API key if it does not exist or it will regenerate current API key.`,
      onSave: () => {
        apiKeyUserGenerateMutation({
          variables: {
            userId,
          },
        });
      },
      onClose: () => {
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      saveBtnText: 'Generate',
    });
  };

  if (apiKeyGenerateMutationResponse.loading) {
    return <CircularLoader />;
  }

  return <Button onClick={handleGenerateApiKeyClick}>Generate api key</Button>;
};

export default GenerateUserApiKeyButton;
