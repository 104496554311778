import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import CompanySelectFormField from '../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { IgInterviewGuide } from '../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { useInterviewGuideModalStyle } from '../../ModalView/AddInterviewGuideModal/AddInterviewGuideModalStyle';

export interface InterviewGuidePayload {
  id: number;
  name: string;
  company: string;
  ownerId: TSelectFormFieldValue;
}

interface IInterviewGuide {
  interviewGuide: IgInterviewGuide;
  getInterviewGuideName: (value: string) => void;
  getInterviewGuideOwnerId: (value: number) => void;
}
interface IInterviewGuideError {
  name: string;
  company: string;
}

const InterviewGuideForm: FC<IInterviewGuide> = props => {
  const classes = useInterviewGuideModalStyle();
  const { id, name, ownerId } = props.interviewGuide;
  const { getInterviewGuideName, getInterviewGuideOwnerId } = props;
  const [interviewGuideDetails, setInterviewGuideDetails] = useState<
    InterviewGuidePayload
  >({
    id: id,
    name: name,
    ownerId: ownerId,
    company: '',
  });
  const [errors, setErrors] = useState<IInterviewGuideError>({
    name: '',
    company: '',
  });

  const handleInterviewGuideName = (value: string, name: string) => {
    setInterviewGuideDetails(prev => ({ ...prev, name: value }));
    getInterviewGuideName(value);
  };

  const handleCompanyFormSelection = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setInterviewGuideDetails(prev => ({
      ...prev,
      ownerId: value,
      company: name,
    }));
    getInterviewGuideOwnerId(Number(value));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, [name]: '' } : prevErrors
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item className={classes.textfieldContainer} xs={6}>
        <TextFormField
          id="interviewGuide"
          name="name"
          value={interviewGuideDetails.name}
          label="Interview Guide Name"
          onChange={handleInterviewGuideName}
          hasError={!!errors?.name}
          errorText={errors?.name}
        />
      </Grid>
      <Grid item className={classes.textfieldContainer} xs={6}>
        <CompanySelectFormField
          id="companyFormField"
          name="company"
          label="Company"
          value={interviewGuideDetails.ownerId}
          onChange={handleCompanyFormSelection}
          hasError={!!errors?.company}
          errorText={errors?.company}
        />
      </Grid>
    </Grid>
  );
};

export default InterviewGuideForm;
