import TextFormFieldWithActionIcon from 'components/organisms/TextFormFieldWithActionIcon/TextFormFieldWithActionIcon';
import { ModalTypes } from 'enums/modalTypes';
import {
  MeasurementConstructType,
  SoftSkillFragment,
  useUpdateSoftSkillMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { TFormFieldValue } from 'interfaces/forms/form';
import { FC, useContext, useEffect, useState } from 'react';
import { StaticContentContext } from 'views/StaticContent/StaticContentContext/StaticContentContext';
import { Button, Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useSoftSkillsListItemStyle from './SoftSkillsListItemStyle';

interface ISoftSkillsListItem {
  softSkill: SoftSkillFragment;
}

const SoftSkillsListItem: FC<ISoftSkillsListItem> = ({ softSkill }) => {
  const { updateFilters } = useContext(StaticContentContext);
  const classes = useSoftSkillsListItemStyle();
  const { handleMsgType } = useNotification();
  const handleOpenCreateSoftSkillsModal = () => {
    openModal(ModalTypes.EDIT_SOFT_SKILL_MODAL, { softSkill, updateFilters });
  };
  const handleOnClickDescription = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `softskill-description-${softSkill.id}`,
      name: 'description',
      label: 'Description',
      initialValue: descriptionField,
      onChange: handleChangeDescription,
      onActionButtonClick: onUpdateDescription,
      showActionButton: true,
      actionButtonLabel: 'Save',
      showCancelButton: true,
      cancelButtonLabel: 'Cancel',
      changeValueOnClose: false,
    });
  };
  const [descriptionField, setDescriptionField] = useState<string>('');
  useEffect(() => {
    setDescriptionField(softSkill.description ?? '');
  }, [softSkill.description]);
  const handleChangeDescription = (value: TFormFieldValue) => {
    setDescriptionField(value as string);
  };

  const [editSoftSkillMutation] = useUpdateSoftSkillMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: data => {
      setDescriptionField(data.softSkill?.description ?? '');
      handleMsgType({
        type: TNotification.success,
        message: 'Description updated successfully!',
      });
      closeModal(ModalTypes.TEXT_FIELD_MODAL);
    },
  });
  const onUpdateDescription = (value: string) => {
    editSoftSkillMutation({
      variables: {
        id: softSkill.id,
        description: value,
        measurementConstructType:
          softSkill?.measurementConstructType ||
          MeasurementConstructType.Behavior,
      },
    });
  };

  //TO DO: Remove this after discovering and fixing why editing a cognitive skill causes Company Client to crash.
  const isEditDisabled = softSkill.name.toLowerCase().includes('cognitive');

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid xs={1} className={classes.gridItemWrapper}>
        <Typography>{softSkill.id}</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridItemWrapper}>
        <Typography>{softSkill.name}</Typography>
      </Grid>
      <Grid xs={3}>
        <TextFormFieldWithActionIcon
          id={`${softSkill.id}-description`}
          name="description"
          label="Description"
          onChange={() => {}} // not allowing user to update the description from the list
          value={descriptionField}
          onClick={handleOnClickDescription}
          isDisabled
          InputProps={{
            classes: {
              root: classes.inputRoot,
              disabled: classes.disabled,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputRoot,
              disabled: classes.disabled,
            },
          }}
        />
      </Grid>
      <Grid xs={2} className={classes.gridItemWrapper}>
        <Typography>{softSkill.category}</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridItemWrapper}>
        <Typography>{softSkill?.company?.name}</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridItemWrapper}>
        <Button
          onClick={handleOpenCreateSoftSkillsModal}
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isEditDisabled}
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};

export default SoftSkillsListItem;
