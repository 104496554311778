import Icon from 'components/atoms/Icon';
import { contentRoutes } from 'constants/navigation';
import { Link } from 'react-router-dom';
import AddTechnicalSkillsForm from './AddTechnicalSkills.form';
import styles from './styles.module.scss';

const AddTechnicalSkillsView = () => {
  return (
    <div className={styles.container}>
      <div className={styles.subpageContainer}>
        <div className={styles.subpageHeader}>
          <Link
            className={styles.subpageBackNavigationLink}
            to={contentRoutes.technicalSkills.url()}
          >
            <Icon icon="left_arrow" /> Technical Skills
          </Link>
        </div>

        <div className={styles.subpageBody}>
          <h2 className={styles.subpageTitle}>Add skill</h2>
          <AddTechnicalSkillsForm />
        </div>
      </div>
    </div>
  );
};

export default AddTechnicalSkillsView;
