import CheckboxFormField from 'components/molecules/CheckboxFormField/CheckboxFormField';
import SelectFormField from 'components/molecules/SelectFormField/SelectFormField';
import {
  ISelectFormFieldOption,
  TSelectFormFieldValue,
} from 'interfaces/forms/form';
import { FC } from 'react';

interface ICandidateReportAssignmentPresentational {
  candidateStageAssignmentOption: ISelectFormFieldOption[];
  handleCandidateChange: (value: TSelectFormFieldValue) => void;
  candidateReport: TSelectFormFieldValue;
  candidateCheckValues: boolean;
  label?: string;
  name: string;
  checkBoxId?: string;
  selectFormId?: string;
  handleCandidateCheckChange: (value: boolean) => void;
}

const CandidateReportAssignmentPresentational: FC<ICandidateReportAssignmentPresentational> = ({
  candidateStageAssignmentOption,
  handleCandidateChange,
  candidateReport,
  candidateCheckValues,
  handleCandidateCheckChange,
  label,
  name,
  checkBoxId = 'checkbox',
  selectFormId = 'selectFormForm',
}) => {
  return (
    <>
      <div style={{ display: 'flex', width: '350px' }}>
        <SelectFormField
          onChange={handleCandidateChange}
          label={label}
          name={name}
          value={candidateReport}
          id={selectFormId}
          options={candidateStageAssignmentOption}
          isDisabled={candidateCheckValues}
        />
      </div>
      <div style={{ marginLeft: 20, width: 200 }}>
        <CheckboxFormField
          id={checkBoxId}
          name="candidateCheck"
          label="Use default"
          onChange={handleCandidateCheckChange}
          value={candidateCheckValues}
        />
      </div>
    </>
  );
};

export default CandidateReportAssignmentPresentational;
