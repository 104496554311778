import { createStyles, makeStyles } from '@mui/styles';

const useConfirmationModalStyle = makeStyles(theme =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '&:focus': {
        outline: 'none',
      },
    },
    title: {
      fontSize: 18,
      marginBottom: 20,
      width: 350,
    },
    actions: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    loaderWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    button: {
      height: 48,
      width: 150,
      margin: 5,
    },
  })
);

export default useConfirmationModalStyle;
