import Button from 'components/atoms/Button/Button';
import {
  StageCandidateStatus,
  useSrEmailDispatchEmailsMutation,
} from 'generated/graphql';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useState } from 'react';

type DispatchEmailReportProps = {
  stageStatus?: StageCandidateStatus;
  stageCandidateId: number;
};

export const DispatchEmailReport = ({
  stageStatus,
  stageCandidateId,
}: DispatchEmailReportProps) => {
  const [emailsDispatched, setEmailsDispatched] = useState(false);
  const { handleMsgType } = useNotification();

  const [dispatchEmail, { loading }] = useSrEmailDispatchEmailsMutation({
    variables: {
      stageCandidateId: stageCandidateId,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Emails dispatched successfully!',
      });
      setEmailsDispatched(true);
    },
  });

  const handleDispatchEmail = () => {
    dispatchEmail();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#f2f2f2',
      }}
    >
      <p>
        <strong>Dispatch all report emails based on the stage settings</strong>
      </p>
      <Button
        isDisabled={
          stageStatus !== StageCandidateStatus.Completed ||
          emailsDispatched ||
          loading
        }
        onClick={handleDispatchEmail}
      >
        DISPATCH EMAILS
      </Button>
    </div>
  );
};
