import { FC } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import { ModalTypes } from '../../../enums/modalTypes';
import { closeModal } from '../../../graphql/modals';
import { TWarningModalProps } from '../../../interfaces/modal';

const useWarningModalStyle = makeStyles({
  modalPaper: {
    minWidth: 300,
    minHeight: 200,
  },
  typography: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const WarningModal: FC<TWarningModalProps> = props => {
  const classes = useWarningModalStyle();
  const handleCloseModal = () => {
    closeModal(ModalTypes.WARNING_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Typography className={classes.typography}>{props.message}</Typography>
      <Button onClick={handleCloseModal}>Ok</Button>
    </Modal>
  );
};

export default WarningModal;
