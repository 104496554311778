import Button from 'components/atoms/Button/Button';
import { reportsRoutes } from 'constants/navigation';
import {
  useWidgetDeleteMutation,
  useWidgetUpdateIsArchivedMutation,
  WidgetsDocument,
} from 'generated/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useWidgetStyles from '../WidgetStyles';

interface IWidgetArchive {
  isArchived: boolean;
  id: number;
}

const WidgetArchive: FC<IWidgetArchive> = ({ isArchived, id }) => {
  const { handleMsgType } = useNotification();
  const classes = useWidgetStyles();
  const navigate = useNavigate();

  const [deleteWidgetMutation] = useWidgetDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: WidgetsDocument,
      },
    ],
    onCompleted: () => {
      navigate(reportsRoutes.widgets.url());
    },
  });

  const [widgetUpdateIsArchived] = useWidgetUpdateIsArchivedMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleWidgetDelete = () => {
    deleteWidgetMutation({ variables: { id } });
  };

  const handleWidgetArchive = () => {
    if (isArchived) {
      widgetUpdateIsArchived({
        variables: {
          id,
          isArchived: false,
        },
      });
    } else {
      widgetUpdateIsArchived({
        variables: {
          id,
          isArchived: true,
        },
      });
    }
  };

  return (
    <>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        style={{ marginBottom: '35px' }}
        className={classes.w100}
      >
        <p>Edit widget</p>
        {isArchived ? (
          <div>
            <Button
              color="inherit"
              className={classes.firstBtn}
              onClick={handleWidgetArchive}
            >
              UNARCHIVE
            </Button>
            <Button
              className={classes.btn}
              color="inherit"
              onClick={handleWidgetDelete}
            >
              DELETE
            </Button>
          </div>
        ) : (
          <Button color="inherit" onClick={handleWidgetArchive}>
            ARCHIVE
          </Button>
        )}
      </Grid>
    </>
  );
};

export default WidgetArchive;
