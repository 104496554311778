import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  INotificationModal,
  NotificationModal as SZNotificationModal,
} from '@spotted-zebra-uk/ui-components';

export interface NotificationModalProps
  extends Omit<INotificationModal, 'isOpen' | 'onClose'> {}

const NotificationModal: FC<NotificationModalProps> = props => {
  const handleClose = () => {
    closeModal(ModalTypes.NOTIFICATION_MODAL);
  };

  return <SZNotificationModal {...props} onClose={handleClose} isOpen />;
};

export default NotificationModal;
