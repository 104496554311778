import { DataSourceType, SoftSkillType } from 'generated/graphql';
import { sumOfArrayField } from 'helpers/calculations';
import { SOFT_SKILLS_CORE } from '../DataSourcesFormItem/DataSourcesFormItem';
import { TJobRoleFormSoftSkillValues } from './JobRoleForm.types';

export type TDataSourceField = {
  isIncluded: boolean;
  softSkillType: {
    value: SoftSkillType;
  };
  type: DataSourceType;
  name: string;
};

export const dataSourcesFieldsArray: TDataSourceField[] = [
  {
    isIncluded: false,
    softSkillType: SOFT_SKILLS_CORE,
    type: DataSourceType.EmployeeAssessment,
    name: 'Employee Assessment',
  },
  {
    isIncluded: false,
    softSkillType: SOFT_SKILLS_CORE,
    type: DataSourceType.EmployeeSurvey,
    name: 'Employee Survey',
  },
  {
    isIncluded: false,
    softSkillType: SOFT_SKILLS_CORE,
    type: DataSourceType.Industry,
    name: 'Industry',
  },
  {
    isIncluded: false,
    softSkillType: SOFT_SKILLS_CORE,
    type: DataSourceType.JdReview,
    name: 'JD Review',
  },
  {
    isIncluded: false,
    softSkillType: SOFT_SKILLS_CORE,
    type: DataSourceType.ManagerSurvey,
    name: 'Manager Survey',
  },
];

export const getEmptyFormSoftSkillValues = (): TJobRoleFormSoftSkillValues => ({
  softSkillId: { label: '', value: '' },
  name: '',
  type: SOFT_SKILLS_CORE,
  weight: '',
  traitsValues: [],
  dataSourceValues: [],
  includeInCalibration: { label: 'No', value: 'no' },
});

export const calculateSoftSkillWeightsSum = (
  value: TJobRoleFormSoftSkillValues[]
) => {
  const parsedValues = value.map(value => ({
    ...value,
    weight: Number(value.weight),
  }));
  return sumOfArrayField(parsedValues, 'weight');
};

export enum ProfileSkillsTabsValues {
  SOFT_SKILLS = 'SOFT_SKILLS',
  TECHNICAL_SKILLS = 'TECHNICAL_SKILLS',
}
