import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  Pageinfo,
  User,
  useUserFreeTextSearchPaginateLazyQuery,
} from '../../../generated/graphql';
import FreeTextSearchUsers from './FreeTextSearchUsers/FreeTextSearchUsers';
import PaginationSection from './PaginationSection/PaginationSection';
import UserTable from './UserTable/UserTable';

const UserMain: FC = () => {
  const { handleMsgType } = useNotification();

  const [freeUserSearchInput, setFreeUserSearchInput] = useState<string>('');
  const [freeUsers, setFreeUsers] = useState<User[]>([]);
  const [showFreeUsersData, setShowFreeUsersData] = useState<boolean>();

  const [
    userFreeTextSearchQuery,
    userFreeTextSearchQueryResult,
  ] = useUserFreeTextSearchPaginateLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: data => {
      setFreeUsers(data?.Users?.data as User[]);
    },
    nextFetchPolicy: 'network-only',
  });

  const handleSearch = (value: string) => {
    setShowFreeUsersData(true);
    setFreeUserSearchInput(value);
    userFreeTextSearchQuery({
      variables: {
        freetext: value,
        skip: 0,
        take: 20,
      },
    });
  };

  const handlePaginationChange = (skip: number, take: number) => {
    userFreeTextSearchQuery({
      variables: {
        freetext: freeUserSearchInput,
        skip,
        take,
      },
    });
  };

  const pageInfo = userFreeTextSearchQueryResult.data?.Users
    ?.pageInfo as Pageinfo;
  return (
    <div>
      <FreeTextSearchUsers setSearchText={handleSearch} />
      {showFreeUsersData && (
        <div style={{ marginTop: 50 }}>
          {pageInfo &&
            pageInfo.take?.toString() &&
            pageInfo.skip?.toString() && (
              <PaginationSection
                pageInfo={pageInfo}
                handlePaginationChange={handlePaginationChange}
              />
            )}
          {freeUsers && <UserTable userData={freeUsers} />}
        </div>
      )}
    </div>
  );
};

export default UserMain;
