export enum ErrorMessages {
  NOT_FOUND = 'Not Found',
  EXISTING_USER = 'EXISTING_USER',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_COGNITIVE_COMBINED_TRAIT_TYPES = 'INVALID_COGNITIVE_COMBINED_TRAIT_TYPES',
  SOFT_SKILL_THRESHOLD_INVALID = 'SOFT_SKILL_THRESHOLD_INVALID',
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
  MFA_REQUIRED = 'MFA_REQUIRED',
  INVALID_MFA_CODE = 'INVALID_MFA_CODE',
  EXPIRED_MFA_TOKEN = 'EXPIRED_MFA_TOKEN',
  EXCEEDED_NUMBER_OF_ATTEMPTS = 'EXCEEDED_NUMBER_OF_ATTEMPTS',
}

export enum CompanyEmployeesErrorMessages {
  EXISTING_USER = 'EXISTING_USER',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}

export enum CreateSuccessProfileErrors {
  INVALID_COGNITIVE_COMBINED_TRAIT_TYPES = 'INVALID_COGNITIVE_COMBINED_TRAIT_TYPES',
  SOFT_SKILL_THRESHOLD_INVALID = 'SOFT_SKILL_THRESHOLD_INVALID',
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
}
