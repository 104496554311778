import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ModalTypes } from '../../../../../../../enums/modalTypes';
import {
  CompanySignatureFindManyDocument,
  CompanySignatureFindManyQuery,
  CompanySignatureFindManyQueryVariables,
  useCompanySignatureDeleteOneMutation,
  useCompanySignatureUpdateOneMutation,
} from '../../../../../../../generated/graphql';
import { closeModal, openModal } from '../../../../../../../graphql/modals';
import { TAlertTypes } from '../../../../../../../interfaces/snackBar';
import EditCompanySignatureForm from './EditCompanySignatureForm/EditCompanySignatureForm';

interface ISignatureListItem {
  id: number;
  companyId: number;
  signature: string;
  signatureEmail: string;
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  defaultId: number;
  companySignatureCount: number;
}

const confirmationModalMessage = (deleteItem: string) =>
  `Please confirm you wish to delete ${deleteItem}'s signature`;

const SignatureListItem: FC<ISignatureListItem> = ({
  id,
  signature,
  signatureEmail,
  companyId,
  displaySnackbar,
  defaultId,
  companySignatureCount,
}) => {
  const { handleMsgType } = useNotification();

  const [confirmEditMode, setConfirmEditMode] = useState<boolean>(false);

  const [
    companySignatureUpdateOneMutation,
  ] = useCompanySignatureUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      resetShowConfirmEdit();
      displaySnackbar("company's signature updated successfully");
    },
  });

  const [
    companySignatureDeleteOneMutation,
  ] = useCompanySignatureDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      const deletedData = data?.CompanySignature;
      if (deletedData) {
        const cachedData = cache.readQuery<
          CompanySignatureFindManyQuery,
          CompanySignatureFindManyQueryVariables
        >({
          query: CompanySignatureFindManyDocument,
          variables: {
            companyId,
          },
        });
        if (cachedData) {
          const updatedCompanySignature = cachedData.CompanySignature?.filter(
            item => item.id !== id
          );
          cache.writeQuery({
            query: CompanySignatureFindManyDocument,
            variables: {
              companyId,
            },
            data: {
              CompanySignature: updatedCompanySignature,
            },
          });
        }
      }
    },
    onCompleted: () => {
      displaySnackbar("company's signature deleted successfully");
      closeModal(ModalTypes.CONFIRMATION_MODAL);
    },
  });

  const resetShowConfirmEdit = () => {
    setConfirmEditMode(false);
  };

  const toggleEditConfirmationMode = () => {
    setConfirmEditMode(true);
  };

  const showConfirmationDialog = (id: number, signature: string) => {
    openModal(ModalTypes.CONFIRMATION_MODAL, {
      open: true,
      message: confirmationModalMessage(signature),
      onSave: () => {
        companySignatureDeleteOneMutation({
          variables: {
            id,
          },
        });
      },
      onClose: () => {
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      saveBtnText: 'Delete',
    });
  };

  const getEditSignatureData = (
    id: number,
    signature: string,
    signatureEmail: string
  ) => {
    companySignatureUpdateOneMutation({
      variables: {
        id,
        signature,
        signatureEmail,
      },
    });
  };

  return (
    <div style={{ marginTop: 20, width: '100%' }}>
      <EditCompanySignatureForm
        id={id}
        signature={signature}
        signatureEmail={signatureEmail}
        getEditSignatureData={getEditSignatureData}
        resetShowConfirmEdit={resetShowConfirmEdit}
        confirmEditMode={confirmEditMode}
        toggleEditConfirmationMode={toggleEditConfirmationMode}
        showConfirmationDialog={showConfirmationDialog}
        displaySnackbar={displaySnackbar}
        defaultId={defaultId}
        companySignatureCount={companySignatureCount}
      />
    </div>
  );
};

export default SignatureListItem;
