import { makeStyles } from '@mui/styles';

const useCompaniesStyle = makeStyles(() => ({
  container: {
    padding: 30,
    width: '100%',
  },
  createCompanyPaper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: 200,
  },
  paper: {
    height: 200,
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10,
    boxSizing: 'border-box',
  },
  companyLogoWrapper: {
    height: 80,
    width: 130,
    marginTop: 30,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  companyLogo: {
    display: 'block',
    maxHeight: 80,
    maxWidth: 130,
    margin: 'auto',
  },
  companyName: {
    marginTop: 10,
    textAlign: 'center',
  },
}));

export default useCompaniesStyle;
