import { IgCategoryColour, IgQuestionType } from '../../generated/graphql';

export const colours = [
  {
    value: IgCategoryColour.Colour1,
    label: 'COLOUR1',
  },
  {
    value: IgCategoryColour.Colour2,
    label: 'COLOUR2',
  },
  {
    value: IgCategoryColour.Colour3,
    label: 'COLOUR3',
  },
  {
    value: IgCategoryColour.Colour4,
    label: 'COLOUR4',
  },
];

export const questionTypes = [
  {
    value: IgQuestionType.Dynamic,
    label: 'Dynamic',
  },
  {
    value: IgQuestionType.None,
    label: 'None',
  },
  {
    value: IgQuestionType.Static,
    label: 'Static',
  },
];
