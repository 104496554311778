import { gql } from '@apollo/client';

export const FORM_QUERY = gql`
  query form($id: Int!) {
    form: Form(id: $Id) {
      id
      formType
      formManagerType
      name
      formOwner {
        id
        formOwnerId
      }
      formFields {
        id
        displayOrder
        isOptional
        field {
          id
          fieldType
          formManagerType
          fieldInstanceType
          name
          question
          ... on CompanyEmployeeSelectField {
            dynamicSelectOptions
          }
          ... on MultiSelectField {
            selectOptions
          }
          ... on SingleSelectField {
            selectOptions
          }
          isArchived
          txtData
        }
      }
      formOwnerName
    }
  }
`;

export const FORMS_QUERY = gql`
  query forms(
    $name: String
    $formType: FormType
    $formManagerType: FormManagerType
    $formOwnerId: Int
  ) {
    forms: Forms(
      name: $name
      formType: $formType
      formManagerType: $formManagerType
      formOwnerId: $formOwnerId
    ) {
      id
      formType
      formManagerType
      name
      formOwner {
        id
        formOwnerId
      }
      formFields {
        id
        displayOrder
        isOptional
        field {
          id
          fieldType
          formManagerType
          fieldInstanceType
          name
          question
          ... on CompanyEmployeeSelectField {
            dynamicSelectOptions
          }
          ... on MultiSelectField {
            selectOptions
          }
          ... on SingleSelectField {
            selectOptions
          }
          isArchived
          txtData
        }
      }
      formOwnerName
    }
  }
`;

export const FORM_CREATE_MUTATION = gql`
  mutation fromCreate(
    $name: String!
    $formType: FormType!
    $formManagerType: FormManagerType!
    $formOwnerId: Int!
  ) {
    form: FormCreate(
      name: $name
      formType: $formType
      formManagerType: $formManagerType
      formOwnerId: $formOwnerId
    ) {
      id
      formType
      formManagerType
      name
      formOwner {
        id
        formOwnerId
      }
      formFields {
        id
        displayOrder
        isOptional
        field {
          id
          fieldType
          formManagerType
          fieldInstanceType
          name
          question
          ... on CompanyEmployeeSelectField {
            dynamicSelectOptions
          }
          ... on MultiSelectField {
            selectOptions
          }
          ... on SingleSelectField {
            selectOptions
          }
          isArchived
          txtData
        }
      }
      formOwnerName
    }
  }
`;

export const FORM_DELETE_MUTATION = gql`
  mutation formDelete($id: Int!) {
    formDelete: FormDelete(id: $id) {
      affected
    }
  }
`;

export const FORM_UPDATE_MUTATION = gql`
  mutation formUpdate($id: Int!, $name: String!) {
    form: formUpdate(id: $id, name: $name) {
      id
      formType
      formManagerType
      name
      formOwner {
        id
        formOwnerId
      }
      formFields {
        id
        displayOrder
        isOptional
        field {
          id
          fieldType
          formManagerType
          fieldInstanceType
          name
          question
          ... on CompanyEmployeeSelectField {
            dynamicSelectOptions
          }
          ... on MultiSelectField {
            selectOptions
          }
          ... on SingleSelectField {
            selectOptions
          }
          isArchived
          txtData
        }
      }
      formOwnerName
    }
  }
`;

export const FORM_ADD_FIELD_MUTATION = gql`
  mutation formAddField(
    $displayOrder: Int
    $isOptional: Boolean!
    $formId: Int!
    $fieldId: Int!
  ) {
    form: FormAddField {
      id
      formType
      formManagerType
      name
      formOwner {
        id
        formOwnerId
      }
      formFields {
        id
        displayOrder
        isOptional
        field {
          id
          fieldType
          formManagerType
          fieldInstanceType
          name
          question
          ... on CompanyEmployeeSelectField {
            dynamicSelectOptions
          }
          ... on MultiSelectField {
            selectOptions
          }
          ... on SingleSelectField {
            selectOptions
          }
          isArchived
          txtData
        }
      }
      formOwnerName
    }
  }
`;
