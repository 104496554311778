import Icon from 'components/atoms/Icon';
import { ProjectJobRoleFragment, StageFindManyQuery } from 'generated/graphql';
import { useMemo } from 'react';
import { Tooltip } from '@spotted-zebra-uk/ui-components';
import styles from './BenchmarkGroup.module.scss';

type BenchmarkGroupProps = {
  projectJobRoles: ProjectJobRoleFragment[];
  stagesData: StageFindManyQuery | undefined;
};

const BenchmarkGroup = ({
  projectJobRoles,
  stagesData,
}: BenchmarkGroupProps) => {
  const benchmarkValue = useMemo(() => {
    let dataSetType = '-';

    //Check the data set type of the job roles
    const areAllJobRolesLocalized = projectJobRoles
      .map(({ isLocalizedBenchmarkGroup }) => isLocalizedBenchmarkGroup)
      .every(_ => _);
    const areAllJobRolesNotLocalized = projectJobRoles
      .map(({ isLocalizedBenchmarkGroup }) => !isLocalizedBenchmarkGroup)
      .every(_ => _);
    const isMixedDataSet =
      !areAllJobRolesLocalized && !areAllJobRolesNotLocalized;

    if (areAllJobRolesLocalized) {
      dataSetType = 'Localised';
    } else if (areAllJobRolesNotLocalized) {
      dataSetType = 'Test Partnership';
    } else {
      dataSetType = 'Mixed';
    }
    return { isMixedDataSet, dataSetType };
  }, [projectJobRoles]);

  return (
    <div
      className={styles.container}
      data-testid="project-edit__benchmark-group"
    >
      <span className={styles.label}>Benchmark group</span>
      {!stagesData?.Stages?.length ? (
        <Tooltip
          content={'Insert a stage to enable the benchmark group.'}
          children={<span className={styles.value}>-</span>}
        />
      ) : (
        <BenchmarkValue
          dataSetType={benchmarkValue.dataSetType}
          isMixedDataSet={benchmarkValue.isMixedDataSet}
          projectJobRoles={projectJobRoles}
        />
      )}
    </div>
  );
};

export default BenchmarkGroup;

const BenchmarkValue = ({
  dataSetType,
  isMixedDataSet,
  projectJobRoles,
}: {
  dataSetType: string;
  isMixedDataSet: boolean;
  projectJobRoles: ProjectJobRoleFragment[];
}) => {
  return (
    <div className={styles.valueContainer}>
      <span className={styles.value}>{`${dataSetType} data set`}</span>
      {isMixedDataSet && (
        <Tooltip
          id="tooltip-trigger"
          content={projectJobRoles
            .map(
              ({ jobRole, isLocalizedBenchmarkGroup }) =>
                `"${jobRole.name}" uses the ${
                  isLocalizedBenchmarkGroup ? 'localised' : 'test partnership'
                } data set. `
            )
            .join('')}
          children={<Icon icon="info" id="tooltip-trigger" />}
        />
      )}
    </div>
  );
};
