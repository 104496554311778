import { makeStyles } from '@mui/styles';

const useUserStyle = makeStyles(() => ({
  container: {
    padding: '30px 30px 30px 260px',
  },
  w100: {
    width: '100%',
  },
  mr10: { marginRight: 10 },
  mb10: { marginBottom: 10 },
  externalId: {
    margin: '0 10px 0 0 !important',
  },
  loaderWrapper: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    height: 55,
  },
  error: {
    color: 'red',
    marginBottom: '20px',
  },
}));

export default useUserStyle;
