export enum ReportType {
  STAGE_RESULTS = 'STAGE_RESULTS',
  SUCCESS_PROFILE = 'SUCCESS_PROFILE',
}

export enum ReportSubType {
  SUCCESS_PROFILE_FULL = 'SUCCESS_PROFILE_FULL',
  SUCCESS_PROFILE_PARTIAL = 'SUCCESS_PROFILE_PARTIAL',
  STAGE_RESULTS_CANDIDATE = 'STAGE_RESULTS_CANDIDATE',
  STAGE_RESULTS_MANAGER = 'STAGE_RESULTS_MANAGER',
}

export enum ReportOwnerType {
  COMPANY = 'COMPANY',
  USER = 'USER',
}

export enum ReportRenderStatus {
  INITIALISATION = 'INITIALISATION',
  COLLECTING_DATA = 'COLLECTING_DATA',
  RENDERING = 'RENDERING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  COMPLETE_EMAILING = 'COMPLETE_EMAILING',
  COMPLETE_EMAILED = 'COMPLETE_EMAILED',
}

export enum ReportRenderType {
  PDF = 'PDF',
  DATA = 'DATA',
  CSV = 'CSV',
}
