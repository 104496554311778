import { TTextFromFieldValue } from 'interfaces/forms/form';
import { FC } from 'react';
import {
  InputAdornment,
  InputBaseComponentProps,
  InputLabelProps,
  OutlinedInputProps,
  TextField,
} from '@mui/material';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import useExpandableTextFormFieldStyle from './TextFormFieldWithActionIconStyle';

export interface ITextFormFieldWithActionIcon {
  id: string;
  name: string;
  label?: string;
  autoFocus?: boolean;
  onChange: (value: TTextFromFieldValue, name: string) => void;
  onClick:
    | ((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void)
    | undefined;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  value: string;
  className?: string;
  isDisabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: string;
  min?: string;
  inputProps?: InputBaseComponentProps;
  ActionIcon?: JSX.Element;
  InputProps?: Partial<OutlinedInputProps>;
  InputLabelProps?: Partial<InputLabelProps>;
}

const TextFormFieldWithActionIcon: FC<ITextFormFieldWithActionIcon> = ({
  id,
  onChange,
  onClick,
  errorText = '',
  hasError = false,
  name,
  isDisabled = false,
  onBlur,
  className,
  min,
  inputProps,
  multiline = true,
  rows = 2,
  ActionIcon = <FullscreenOutlinedIcon />,
  InputProps,
  InputLabelProps,
  ...restProps
}) => {
  const classes = useExpandableTextFormFieldStyle();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value, name);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, name);
    }
  };

  return (
    <TextField
      className={`${classes.formField}${className ? ` ${className}` : ''}`}
      variant="outlined"
      margin="normal"
      fullWidth
      name={name}
      onChange={handleChange}
      inputProps={{
        ...inputProps,
        onBlur: handleBlur,
        min,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" onClick={onClick}>
            <>{ActionIcon}</>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      error={hasError}
      helperText={errorText}
      disabled={isDisabled}
      InputLabelProps={InputLabelProps}
      {...restProps}
    />
  );
};

export default TextFormFieldWithActionIcon;
