import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../../components/atoms/Button/Button';
import CircularLoader from '../../../../../../components/molecules/CircularLoader/CircularLoader';
import SelectFormField from '../../../../../../components/molecules/SelectFormField/SelectFormField';
import { ModalTypes } from '../../../../../../enums/modalTypes';
import {
  ApiKeyFindAllDocument,
  ApiKeyFindAllQuery,
  IdentityPermissionRole,
  useApiKeyCompanyGenerateMutation,
  useMeQuery,
} from '../../../../../../generated/graphql';
import { closeModal, openModal } from '../../../../../../graphql/modals';
import { TSelectFormFieldValue } from '../../../../../../interfaces/forms/form';
import { getIdentityPermissionRoleOptions } from './GenerateCompanyApiKey.constants';

interface IGenerateCompanyApiKey {
  companyId: number;
}

const getConfirmationModalMessage = (permission: IdentityPermissionRole) =>
  `This action will create new API key for the ${permission} role if api key for that role does not exist or it will regenerate current API key for that role.`;

const GenerateCompanyApiKey: FC<IGenerateCompanyApiKey> = ({ companyId }) => {
  const { handleMsgType } = useNotification();

  const [values, setValues] = useState({
    permission: IdentityPermissionRole.Admin,
  });

  const meQueryResponse = useMeQuery();

  const [
    generateCompanyApiKeyMutation,
    generateCompanyApiKeyMutationResponse,
  ] = useApiKeyCompanyGenerateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      closeModal(ModalTypes.CONFIRMATION_MODAL);
    },
    update: (cache, { data }) => {
      if (data) {
        const apiKeysFromCache = cache.readQuery<ApiKeyFindAllQuery>({
          query: ApiKeyFindAllDocument,
        });

        if (
          !apiKeysFromCache?.ApiKeyFindAll.find(
            apiKey => apiKey.id === data.ApiKey.id
          )
        ) {
          if (apiKeysFromCache) {
            cache.writeQuery<ApiKeyFindAllQuery>({
              query: ApiKeyFindAllDocument,
              data: {
                ApiKeyFindAll: [...apiKeysFromCache.ApiKeyFindAll, data.ApiKey],
              },
            });
          }
        }
      }
    },
  });

  const handleChangePermission = (
    value: TSelectFormFieldValue,
    _name: string
  ) => {
    setValues(prevValues => ({
      ...prevValues,
      permission: value as IdentityPermissionRole,
    }));
  };

  const handleSubmit = () => {
    openModal(ModalTypes.CONFIRMATION_MODAL, {
      open: true,
      message: getConfirmationModalMessage(values.permission),
      onSave: () => {
        generateCompanyApiKeyMutation({
          variables: {
            companyId,
            permission: values.permission,
          },
        });
      },
      onClose: () => {
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      saveBtnText: 'Generate',
    });
  };

  if (meQueryResponse.error) {
    return <div>Error: {meQueryResponse.error.message}</div>;
  }

  if (meQueryResponse.data) {
    const formKey = 'gcakf';

    const identityPermissionRoleOptions = getIdentityPermissionRoleOptions(
      meQueryResponse.data.me
    );

    return (
      <Grid container item xs={6} justifyContent="flex-end">
        <Grid item>
          <SelectFormField
            options={identityPermissionRoleOptions}
            value={values.permission}
            id={`${formKey}-permission`}
            name="permission"
            onChange={handleChangePermission}
          />
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          {generateCompanyApiKeyMutationResponse.loading ? null : (
            <Button onClick={handleSubmit}>Generate api key</Button>
          )}
        </Grid>
      </Grid>
    );
  }

  return <CircularLoader />;
};

export default GenerateCompanyApiKey;
