export const setDocumentHeadLinks = () => {
  const appleLink = document.getElementById(
    'sz-apple-touch-icon'
  ) as HTMLLinkElement;
  const smallLogoLink = document.getElementById(
    'sz-logo-16x16'
  ) as HTMLLinkElement;
  const mediumLogoLink = document.getElementById(
    'sz-logo-32x32'
  ) as HTMLLinkElement;
  const faviconLink = document.getElementById('sz-favicon') as HTMLLinkElement;
  const manifestLink = document.getElementById(
    'sz-manifest'
  ) as HTMLLinkElement;
  const server = process.env.REACT_APP_SERVER;
  if (server === 'dev' || server === 'dev2' || server === 'stg') {
    appleLink.href = `${process.env.PUBLIC_URL}/dev/sz-apple-touch-icon.png`;
    smallLogoLink.href = `${process.env.PUBLIC_URL}/dev/sz-logo-16x16.png`;
    mediumLogoLink.href = `${process.env.PUBLIC_URL}/dev/sz-logo-32x32.png`;
    faviconLink.href = `${process.env.PUBLIC_URL}/dev/sz-favicon.ico`;
    manifestLink.href = `${process.env.PUBLIC_URL}/dev/manifest.json`;
  }
};
