import { useField, useFormikContext } from 'formik';
import { SoftSkillType } from 'generated/graphql';
import { FC } from 'react';
import {
  FormikCheckboxFormField,
  FormikSelectFormField,
} from '@spotted-zebra-uk/ui-components';
import { TJobRoleFormValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import styles from './DataSourcesFormItem.module.scss';

export const SOFT_SKILLS_CORE = {
  value: SoftSkillType.Core,
  label: 'CORE',
};

const softSkillTypeOptions = [
  {
    value: SoftSkillType.Differentiator,
    label: 'DIFFERENTIATOR',
  },
  SOFT_SKILLS_CORE,
  {
    value: SoftSkillType.Diversity,
    label: 'DIVERSITY',
  },
];

interface IDataSourcesFormItem {
  softSkillIndex: number;
  index: number;
}

const DataSourcesFormItem: FC<IDataSourcesFormItem> = ({
  index,
  softSkillIndex,
}) => {
  const { values } = useFormikContext<TJobRoleFormValues>();
  const { name } = values.softSkills[softSkillIndex].dataSourceValues[index];
  const fieldsIdPrefix = `softSkills.${softSkillIndex}.dataSourceValues.${index}`;

  return (
    <div className={styles.container}>
      <div className={styles.label}>{name}</div>
      <FormikSelectFormField
        id={`${fieldsIdPrefix}.softSkillType`}
        options={softSkillTypeOptions}
        label="Soft skill type"
        placeholder="Soft skill type"
        className={styles.typeSelectField}
        useFormikField={useField}
        hasClearIndicator={false}
      />
      <div className={styles.checkboxWrapper}>
        <FormikCheckboxFormField
          id={`${fieldsIdPrefix}.${index}.isIncluded`}
          label="Is included"
          useFormikField={useField}
        />
      </div>
    </div>
  );
};

export default DataSourcesFormItem;
