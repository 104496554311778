import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { Formik, useField } from 'formik';
import {
  ProjectRequestFileUploadFindManyDocument,
  useProjectRequestFileUploadMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  Modal,
  TNotification,
  useNotification,
  FormikFileInput,
  FormikTextInputField,
  ModalButtons,
} from '@spotted-zebra-uk/ui-components';

type AddProjectRequestDocumentFormSubmitValues = {
  file: File;
  fileName: string;
};

type AddProjectRequestDocumentFormValues = {
  file: File | null;
  fileName: string;
};

interface AddProjectRequestDocumentFormProps {
  onSubmit: (values: AddProjectRequestDocumentFormSubmitValues) => void;
  onCancelClick: () => void;
  fixedFileName?: string;
}

const AddProjectRequestDocumentForm: FC<AddProjectRequestDocumentFormProps> = ({
  onCancelClick,
  onSubmit,
  fixedFileName,
}) => (
  <Formik<AddProjectRequestDocumentFormValues>
    initialValues={{ file: null, fileName: fixedFileName ? fixedFileName : '' }}
    onSubmit={values =>
      onSubmit(values as AddProjectRequestDocumentFormSubmitValues)
    }
  >
    {({ values, handleSubmit, setFieldValue }) => (
      <div className="add-project-request-document-modal-form">
        <FormikFileInput
          id="file"
          placeholder="Select Document"
          label="Select Document"
          onChange={(file: File | null) => {
            if (!fixedFileName && file) {
              setFieldValue('fileName', file.name);
            }
          }}
          useFormikField={useField}
        />
        {values.file && (
          <FormikTextInputField
            id="fileName"
            label="File Name"
            placeholder="File Name"
            disabled={Boolean(fixedFileName)}
            useFormikField={useField}
          />
        )}
        <ModalButtons
          onConfirmButtonClick={() => handleSubmit()}
          onCancelButtonClick={onCancelClick}
          confirmButtonChildren="Upload"
          confirmButtonProps={{
            size: 'medium',
            disabled: !values.fileName,
          }}
          cancelButtonProps={{
            size: 'medium',
          }}
        />
      </div>
    )}
  </Formik>
);

export interface AddProjectRequestDocumentModalProps {
  projectRequestId: number;
  fixedFileName?: string;
}

const AddProjectRequestDocumentModal: FC<AddProjectRequestDocumentModalProps> = ({
  projectRequestId,
  fixedFileName,
}) => {
  const { handleMsgType } = useNotification();
  const [
    projectRequestFileUplaodMutation,
  ] = useProjectRequestFileUploadMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    context: {
      isUpload: true,
    },
    refetchQueries: [
      {
        query: ProjectRequestFileUploadFindManyDocument,
        variables: {
          projectRequestId,
        },
      },
    ],
  });

  const handleClose = () => {
    closeModal(ModalTypes.ADD_PROJECT_REQUEST_DOCUMENT_MODAL);
  };

  const handleSubmit = async (
    values: AddProjectRequestDocumentFormSubmitValues
  ) => {
    await projectRequestFileUplaodMutation({
      variables: {
        args: {
          projectRequestId,
          fileName: values.fileName,
        },
        file: values.file,
      },
    });
    closeModal(ModalTypes.ADD_PROJECT_REQUEST_DOCUMENT_MODAL);
  };

  return (
    <Modal
      isOpen
      className="add-project-request-document-modal"
      onClose={handleClose}
      header="Add Document"
    >
      <AddProjectRequestDocumentForm
        onCancelClick={handleClose}
        onSubmit={handleSubmit}
        fixedFileName={fixedFileName}
      />
    </Modal>
  );
};

export default AddProjectRequestDocumentModal;
