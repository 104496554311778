import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { IconType, Modal, ModalSize } from '@spotted-zebra-uk/ui-components';
import styles from './ExportContentModal.module.scss';

interface IExportContentModal {
  handleCloseModal: () => void;
  error?: ApolloError;
  handleConfirmButtonClick: () => void;
  loading: boolean;
  url?: string;
}
const ExportContentModal: FC<IExportContentModal> = ({
  handleCloseModal,
  error,
  handleConfirmButtonClick,
  loading,
  url,
}) => {
  const getConfirmButtonProps = (): string => {
    if (loading) return 'Loading...';
    if (error) return 'Try again';
    return 'Download';
  };

  return (
    <Modal
      header={error ? 'Export failed' : 'Export content'}
      headerIconType={error && IconType.ERROR}
      isOpen
      onClose={handleCloseModal}
      modalSize={ModalSize.SMALL}
      buttonsProps={{
        onConfirmButtonClick: handleConfirmButtonClick,
        confirmButtonProps: { disabled: loading },
        confirmButtonChildren: getConfirmButtonProps(),
        cancelButtonChildren: error ? 'Close' : 'Cancel',
      }}
    >
      <div className={styles.body}>
        {url && (
          <p className={styles.successBody}>
            Your CSV file is ready for download.
          </p>
        )}
        {error && (
          <>
            <p className={styles.errorBodyFirstPara}>
              Failed to export behaviour content.
            </p>
            <p className={styles.errorBodySecondPara}>
              Please try again. If this problem persists, please contact the
              Spotted Zebra technical team.
            </p>
          </>
        )}
        {loading && (
          <p className={styles.successBody}>
            Your export is being prepared. Please wait.
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ExportContentModal;
