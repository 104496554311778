import _ from 'lodash';
import { FC, useMemo } from 'react';
import {
  TFormFieldValue,
  TMultiSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

export interface IContentSortMultiselectFormField {
  id: string;
  name: string;
  value: TMultiSelectFormFieldValue;
  label: string;
  onChange: (value: TFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  isSoftSkillFilterIncluded?: boolean;
  isTraitFilterIncluded?: boolean;
  isGradeFilterIncluded?: boolean;
  isClassificationFilterIncluded?: boolean;
  isRoleLevelFilterIncluded?: boolean;
}

const ContentSortMultiselectFormField: FC<IContentSortMultiselectFormField> = props => {
  const sortingOptions: string[] = useMemo(() => {
    let options: string[] = [];

    //if (props.isSoftSkillFilterIncluded) options.push('softSkillName');
    //if (props.isTraitFilterIncluded) options.push('traitName');
    if (props.isGradeFilterIncluded) options.push('grade');
    if (props.isClassificationFilterIncluded) options.push('classification');
    if (props.isRoleLevelFilterIncluded) options.push('roleLevel');

    return options;
  }, [
    //props.isSoftSkillFilterIncluded,
    //props.isTraitFilterIncluded,
    props.isGradeFilterIncluded,
    props.isClassificationFilterIncluded,
    props.isRoleLevelFilterIncluded,
  ]);

  return (
    <MultiselectFormField
      {...props}
      inputProps={{
        'data-role': 'content-sort-select',
      }}
      options={sortingOptions.map(option => ({
        label: option,
        value: option,
      }))}
      isDisabled={_.isEmpty(sortingOptions)}
    />
  );
};

export default ContentSortMultiselectFormField;
