import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import SelectFormField from '../../../components/molecules/SelectFormField/SelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  useWidgetOptionCreateMutation,
  useWidgetOptionUpdateMutation,
  WidgetDocument,
  WidgetOptionFieldType,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import { RemoveNonChangeInput } from '../../../helpers/RemoveNonChanges';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { TAddWidgetOptionModalProps } from '../../../interfaces/modal';
import {
  IAddWidgetOptionPayload,
  IWidgetOptionError,
  IWidgetOptionVariables,
} from '../../../interfaces/widget';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';
import { widgetOptionFieldType } from './AddWidgetOptionModal.constants';

const AddWidgetOptionModal: FC<TAddWidgetOptionModalProps> = ({
  widgetId,
  widgetOptionItem,
}) => {
  const { handleMsgType } = useNotification();

  const classes = useReportModalStyle();
  const refetchQueries = [
    {
      query: WidgetDocument,
      variables: { id: widgetId },
    },
  ];

  const [createWidgetOptionMutation] = useWidgetOptionCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries,
    onCompleted: () => {
      closeModal(ModalTypes.ADD_WIDGET_OPTION_MODAL);
    },
  });

  const [updateWidgetOptionMutation] = useWidgetOptionUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries,
    onCompleted: () => {
      closeModal(ModalTypes.ADD_WIDGET_OPTION_MODAL);
    },
  });

  const [optionDetails, setOptionDetails] = useState<IAddWidgetOptionPayload>({
    optionName: '',
    question: (widgetOptionItem && widgetOptionItem?.question) || '',
    defaultValue: (widgetOptionItem && widgetOptionItem?.defaultValue) || '',
    fieldType: widgetOptionItem ? widgetOptionItem?.fieldType : '',
  });

  const [errors, setErrors] = useState<IWidgetOptionError>({
    optionName: '',
    fieldType: '',
  });
  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_WIDGET_OPTION_MODAL);
  };

  const getOptionName = (value: string) => {
    setOptionDetails(prev => ({ ...prev, optionName: value }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, optionName: '' } : prevErrors
    );
  };

  const getDefaultValue = (value: string) => {
    setOptionDetails(prev => ({ ...prev, defaultValue: value }));
  };

  const getQuestion = (value: string) => {
    setOptionDetails(prev => ({ ...prev, question: value }));
  };

  const getFieldTypeValue = (value: TSelectFormFieldValue) => {
    setOptionDetails(prev => ({ ...prev, fieldType: value }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, fieldType: '' } : prevErrors
    );
  };

  const widgetOptionFormValidation = (newValues: IAddWidgetOptionPayload) => {
    const fieldType = {
      nonEmptyString: {
        message: 'must be selected',
      },
    };
    if (widgetOptionItem) return validate(newValues, { fieldType });
    return validate(newValues, {
      optionName: {
        nonEmptyString: true,
      },
      fieldType,
    });
  };

  const handleSubmitWidgetOption = () => {
    const reportErrors = widgetOptionFormValidation(optionDetails);
    if (!_.isEmpty(reportErrors)) return setErrors(reportErrors);
    const id = widgetOptionItem?.id;

    const variables: IWidgetOptionVariables = {
      question: optionDetails.question,
      defaultValue: optionDetails.defaultValue,
      fieldType: optionDetails.fieldType as WidgetOptionFieldType,
    };
    if (widgetOptionItem?.name && id) {
      RemoveNonChangeInput(variables, widgetOptionItem, ['fieldType']);
      updateWidgetOptionMutation({
        variables: {
          ...variables,
          id: id,
          name: widgetOptionItem.name,
        },
      });
    } else {
      RemoveNonChangeInput(variables, optionDetails, ['fieldType']);
      createWidgetOptionMutation({
        variables: { ...variables, widgetId, name: optionDetails.optionName },
      });
    }
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            {widgetOptionItem ? 'Edit Option' : 'New Option'}
          </Typography>
        </Grid>
        {widgetOptionItem ? (
          <Typography className={classes.p} mt={2}>
            {widgetOptionItem.name}
          </Typography>
        ) : (
          <Grid className={classes.textfieldContainer}>
            <TextFormField
              id="optionName"
              name="optionName"
              value={optionDetails.optionName}
              label="Option Name"
              onChange={getOptionName}
              hasError={!!errors?.optionName}
              errorText={errors?.optionName}
            />
          </Grid>
        )}
        <Grid className={classes.textfieldContainer} mt={2}>
          <TextFormField
            id="question"
            name="question"
            value={optionDetails && optionDetails?.question}
            label="Question"
            onChange={getQuestion}
          />
        </Grid>
        <Grid className={classes.textfieldContainer}>
          <TextFormField
            id="defaultValue"
            name="defaultValue"
            value={optionDetails?.defaultValue}
            label="Default Value"
            onChange={getDefaultValue}
          />
        </Grid>
        <Grid className={classes.textfieldContainer}>
          <SelectFormField
            id="fieldType"
            name="fieldType"
            label="Field Type"
            value={optionDetails.fieldType}
            onChange={getFieldTypeValue}
            options={widgetOptionFieldType}
            hasError={!!errors?.fieldType}
            errorText={errors?.fieldType}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmitWidgetOption}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddWidgetOptionModal;
