import {
  Drawer,
  useNotification,
  TNotification,
} from '@spotted-zebra-uk/ui-components';
import { Formik } from 'formik';
import {
  useCompanyAllowedEmailDomainsUpdateMutation,
  CompanyDocument,
  CompanyQuery,
} from 'generated/graphql';
import {
  CompanyEmailDomainsDrawerProps,
  EmailDomainsFormValues,
} from './CompanyEmailDomainsDrawer.types';
import { CompanyEmailDomainsForm } from './CompanyEmailDomainsDrawer.form';
import { validateExisting } from './CompanyEmailDomainsDrawer.schema';

export function CompanyEmailDomainsDrawer({
  isOpen,
  onClose,
  companyId,
  existingEmailDomains,
}: CompanyEmailDomainsDrawerProps) {
  const { handleMsgType } = useNotification();

  const [
    companyAllowedEmailDomainsUpdate,
  ] = useCompanyAllowedEmailDomainsUpdateMutation();

  const validationSchema = validateExisting(existingEmailDomains);

  const handleSubmit = async (values: EmailDomainsFormValues) => {
    await companyAllowedEmailDomainsUpdate({
      variables: {
        id: companyId,
        allowedEmailDomains: [...existingEmailDomains, ...values.emailDomains],
      },
      onError: error =>
        handleMsgType({ type: TNotification.error, message: error?.message }),
      onCompleted: () =>
        handleMsgType({
          type: TNotification.success,
          message: `Email domain${
            values.emailDomains.length > 1 ? 's' : ''
          } added`,
        }),
      update: (cache, results) => {
        const query: CompanyQuery | null = cache.readQuery({
          query: CompanyDocument,
          variables: { id: companyId },
        });
        if (!query || !query.company) return;
        cache.writeQuery({
          query: CompanyDocument,
          variables: { id: companyId },
          data: {
            company: {
              ...query.company,
              allowedEmailDomains:
                results.data?.CompanyAllowedEmailDomainsUpdate,
            },
          },
        });
      },
    });
    onClose();
  };

  return (
    <Drawer open={isOpen} title="Add email domains" onClose={onClose}>
      <Formik
        initialValues={{ emailDomains: [''] }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <CompanyEmailDomainsForm />
      </Formik>
    </Drawer>
  );
}

export default CompanyEmailDomainsDrawer;
