import { makeStyles } from '@mui/styles';

const useSoftSkillsListStyle = makeStyles(() => ({
  listHeadingGridItemWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useSoftSkillsListStyle;
