import Decimal from 'decimal.js';

export const sumOfArrayField = <T>(array: T[], field: keyof T): number => {
  return array
    .reduce(
      (acc: Decimal, curr: T) =>
        curr && curr[field] ? acc.add(Number(curr[field])) : acc,
      new Decimal(0)
    )
    .toDP(7)
    .toNumber();
};
