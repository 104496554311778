import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import SuccessMessage from '../../../components/atoms/SuccessMessage/SuccessMessage';
import ErrorMessage from '../../../components/organisms/ErrorMessage/ErrorMessage';
import { useRetrieveTpCandidatesMutation } from '../../../generated/graphql';
import RetriveTpCandidatesForm, {
  TRetrieveTpCandidatesFormValues,
} from './RetrieveTpCandidatesForm/RetrieveTpCandidatesForm';
import useRetriveTpCandidatesStyle from './RetrieveTpCandidatesStyle';

interface IRetriveTpCandidates {}

const RetriveTpCandidates: FC<IRetriveTpCandidates> = () => {
  const { handleMsgType } = useNotification();

  const classes = useRetriveTpCandidatesStyle();
  const [
    retriveTpCandidatesMutation,
    retriveTpCandidatesMutationResponse,
  ] = useRetrieveTpCandidatesMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleRetriveTpCandidates = (
    values: TRetrieveTpCandidatesFormValues
  ) => {
    retriveTpCandidatesMutation({
      variables: {
        candidateEmail: values.candidateEmail,
        firstName: values.firstName,
        lastName: values.lastName,
        stageId: values.stageId ? parseInt(values.stageId) : 0,
        personalityTpAssessmentId: parseInt(values.personalityTpAssessmentId),
        inductiveTpAssessmentId: parseInt(values.inductiveTpAssessmentId),
        numericalTpAssessmentId: parseInt(values.numericalTpAssessmentId),
        verbalTpAssessmentId: parseInt(values.verbalTpAssessmentId),
      },
    });
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Typography variant="h4">Retieve tp candidates</Typography>
      </Grid>
      <RetriveTpCandidatesForm onSubmit={handleRetriveTpCandidates} />
      {retriveTpCandidatesMutationResponse.data ? (
        <SuccessMessage message="Success! Tp candidate retrieved!" />
      ) : null}
      {retriveTpCandidatesMutationResponse.error ? (
        <ErrorMessage error={retriveTpCandidatesMutationResponse.error} />
      ) : null}
    </Grid>
  );
};

export default RetriveTpCandidates;
