import { FC, useState } from 'react';
import Snackbar from '../../../../../components/atoms/Snackbar/Snackbar';
import { useCompanySignatureFindManyQuery } from '../../../../../generated/graphql';
import { ISnackBar, TAlertTypes } from '../../../../../interfaces/snackBar';
import AddCompanySignature from './AddCompanySignature/AddCompanySignature';
import DefaultCompanySignature from './DefaultCompanySignature/DefaultCompanySignature';
import EditCompanySignature from './EditCompanySignature/EditCompanySignature';

interface ICompanySignature {
  companyId: number;
}

const CompanySignature: FC<ICompanySignature> = ({ companyId }) => {
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
    alertType: 'success',
  });

  const [defaultId, setDefaultId] = useState<number>(-1);
  const companySignatureQueryResponse = useCompanySignatureFindManyQuery({
    variables: {
      companyId,
    },
    onCompleted: data => {
      if (data.CompanySignature) {
        const defaultCompanySignature = data.CompanySignature.find(
          companySignature => companySignature.isDefault
        );

        if (defaultCompanySignature) {
          setDefaultId(defaultCompanySignature.id);
        }
      }
    },
  });

  const getNewDefaultId = (id: number) => {
    setDefaultId(id);
  };

  const getUpdatedDefaultId = (id: number) => {
    setDefaultId(id);
  };

  const displaySnackbar = (
    msg: string = '',
    alertType: TAlertTypes = 'success'
  ) => {
    setSnackbar({
      ...snackbar,
      show: true,
      alertType,
      msg,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  return (
    <>
      <div style={{ margin: '50px 0 30px' }}>Company Signature</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 20,
        }}
      >
        {companySignatureQueryResponse?.data && (
          <DefaultCompanySignature
            companySignatures={
              companySignatureQueryResponse.data.CompanySignature
            }
            displaySnackbar={displaySnackbar}
            defaultId={Number(defaultId)}
            getUpdatedDefaultId={getUpdatedDefaultId}
            companyId={companyId}
          />
        )}
        <AddCompanySignature
          companyId={companyId}
          displaySnackbar={displaySnackbar}
          getNewDefaultId={getNewDefaultId}
        />
      </div>
      {companySignatureQueryResponse?.data && (
        <EditCompanySignature
          companySignatureQueryResponse={companySignatureQueryResponse.data}
          companyId={companyId}
          displaySnackbar={displaySnackbar}
          defaultId={Number(defaultId)}
        />
      )}
      <Snackbar
        open={snackbar.show}
        alertType={snackbar?.alertType || 'success'}
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default CompanySignature;
