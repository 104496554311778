import Icon, { IconType } from 'components/atoms/Icon';
import { Formik, useField } from 'formik';
import { TestOrigin, TestSubType, TestType } from 'generated/graphql';
import { capitalize } from 'lodash';
import { FC } from 'react';
import * as yup from 'yup';
import {
  Button,
  Tooltip,
  FormikSelectFormField,
  FormikTextInputField,
  TSelectOption,
  TextInputField,
} from '@spotted-zebra-uk/ui-components';

export interface IStageTestActionFormValues {
  origin: TSelectOption;
  tpProjectId: string;
  questionNumbers: string;
}

interface IStageTestActionForm {
  tpProjectId?: string | null;
  testId: number;
  subType?: TestSubType | null;
  type: TestType;
  testOrigin: TestOrigin;
  testQuestionNumbers?: number | null;
  onSave: (values: IStageTestActionFormValues) => void;
}

const validationSchema = yup.object().shape({
  questionNumbers: yup.number().required().min(1),
  tpProjectId: yup
    .string()
    .test(
      'tpProjectId',
      'tpProjectId is a required field',
      (value, context) => {
        if (context.parent.origin.value === TestOrigin.TestPartnership) {
          return Boolean(value);
        }

        return true;
      }
    ),
});

const getInitialValues = ({
  testQuestionNumbers,
  testOrigin,
  type,
  subType,
  tpProjectId,
}: IStageTestActionForm) => ({
  questionNumbers: testQuestionNumbers
    ? testQuestionNumbers?.toString()
    : testOrigin === TestOrigin.SpottedZebra && type === TestType.Personality
    ? '4'
    : testOrigin === TestOrigin.SpottedZebra &&
      subType === TestSubType.Inductive
    ? '15'
    : testOrigin === TestOrigin.SpottedZebra &&
      (subType === TestSubType.Verbal || subType === TestSubType.Numerical)
    ? '5'
    : '1',
  origin: {
    value: testOrigin,
  },
  tpProjectId: tpProjectId || '',
});

const originOptions = [
  { label: TestOrigin.TestPartnership, value: TestOrigin.TestPartnership },
  { label: TestOrigin.SpottedZebra, value: TestOrigin.SpottedZebra },
];

const StageTestActionForm: FC<IStageTestActionForm> = props => {
  const questionLabel =
    props.subType === TestSubType.Numerical ||
    props.subType === TestSubType.Verbal
      ? 'Number of testlets'
      : props.type === TestType.Personality
      ? 'Number of questions/trait'
      : 'Number of questions';
  const initialValues = getInitialValues(props);
  const typeValue = capitalize(
    `${props.type === 'PERSONALITY' ? props.type : props.subType}`
  );

  return (
    <Formik<IStageTestActionFormValues>
      initialValues={initialValues}
      onSubmit={props.onSave}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit }) => {
        const isDirty =
          values.origin.value !== initialValues.origin.value ||
          values.questionNumbers !== initialValues.questionNumbers ||
          values.tpProjectId !== initialValues.tpProjectId;

        return (
          <div
            className="stage-test-action-form"
            data-role="stage-test-form-div"
          >
            <Icon
              icon={`${typeValue.toLowerCase()}InCircleColored` as IconType}
              className="stage-test-action-form__test-icon"
            />
            <Tooltip content={`${props.testId}`} place="bottom">
              <div>
                <TextInputField
                  id={`stage-test-type-label-${props.testId}`}
                  name="type"
                  value={typeValue}
                  onChange={() => {}}
                  disabled
                  className="stage-test-action-form__type-label"
                />
              </div>
            </Tooltip>
            <FormikSelectFormField
              label="Origin"
              name={`stage-test-type-${props.testId}`}
              id="origin"
              options={originOptions}
              isDisabled={false}
              hasNullValue={false}
              data-role="stage-test-form-select-origin"
              placeholder="type"
              className="stage-test-action-form__origin-select"
              useFormikField={useField}
            />
            <FormikTextInputField
              id="questionNumbers"
              name={`stage-test-id-label-${props.testId}`}
              placeholder={questionLabel}
              label={questionLabel}
              data-role="stage-test-form-question-number"
              className="stage-test-action-form__question-numbers-input"
              useFormikField={useField}
            />
            {values.origin.value === TestOrigin.TestPartnership && (
              <FormikTextInputField
                id="tpProjectId"
                name={`stage-test-${props.testId}`}
                label="Tp test id"
                placeholder="Tp test id"
                data-role="stage-test-form-test-id"
                className="stage-test-action-form__tp-project-id"
                useFormikField={useField}
              />
            )}
            <Button
              variant="primary"
              disabled={!isDirty}
              onClick={() => handleSubmit()}
              data-role="stage-test-form-save"
              type="button"
              size="small"
            >
              Save
            </Button>
          </div>
        );
      }}
    </Formik>
  );
};

export default StageTestActionForm;
