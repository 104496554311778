import { FC, useEffect, useState } from 'react';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import { TWidgetOptions } from '../../../../interfaces/widget';

interface IWidgetConfigTextField {
  item: TWidgetOptions;
  getWidgetInfo: (value: TWidgetOptions) => void;
  isAvailableWidget: boolean;
  // settings: TWidgetOptions[];
  index: number;
}

interface IAddWidgetPayload {
  widgetName: string;
}

interface IAddWidgetError {
  widgetName: string;
}

const WidgetConfigTextField: FC<IWidgetConfigTextField> = ({
  item,
  getWidgetInfo,
  isAvailableWidget,
  index,
}) => {
  const [widgetInfo, setWidgetInfo] = useState<IAddWidgetPayload>({
    widgetName: '',
  });
  const [errors, setErrors] = useState<IAddWidgetError>({
    widgetName: '',
  });

  const getWidgetName = (value: string, name: string) => {
    setWidgetInfo(prev => ({ ...prev, widgetName: value }));

    getWidgetInfo({ ...item, settings: value });
    if (errors.widgetName) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, widgetName: '' } : prevErrors
      );
    }
  };

  useEffect(() => {
    if (item?.settings !== undefined) {
      return setWidgetInfo({ ...widgetInfo, widgetName: item?.settings });
    }
    if (item.defaultValue) {
      return setWidgetInfo({ ...widgetInfo, widgetName: item.defaultValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <TextFormField
      id="widgetName"
      name="widgetName"
      value={widgetInfo.widgetName}
      isDisabled={isAvailableWidget}
      label="Value"
      onChange={getWidgetName}
      hasError={!!errors?.widgetName}
      errorText={errors?.widgetName}
    />
  );
};

export default WidgetConfigTextField;
