import {
  ContentClassification,
  ContentRoleLevel,
  Grade,
} from '../enums/successProfile';

const grades = Object.values(Grade);
const roleLevels = Object.values(ContentRoleLevel);
const classifications = Object.values(ContentClassification);
export const maxNumOfSsContent = grades.length * roleLevels.length;
export const maxNumOfSpContent = roleLevels.length;
export const maxNumOfTraitContent = classifications.length * roleLevels.length;
export const maxNumOfIqContent = classifications.length * roleLevels.length;
