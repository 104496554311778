import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import { CompanyEmployeesErrorMessages } from '../../../enums/error';

interface ICompanyUsersErrorMessages {
  error?: ApolloError;
}

const ErrorMessagesStrings = {
  [CompanyEmployeesErrorMessages.EXISTING_USER]:
    'User with that email already exists.',
  [CompanyEmployeesErrorMessages.USER_NOT_FOUND]:
    'User with provided id does not exists.',
};

const CompanyUsersErrorMessages: FC<ICompanyUsersErrorMessages> = ({
  error,
}) => {
  if (!error) {
    return null;
  }

  const errorMessageString =
    ErrorMessagesStrings[error.message as CompanyEmployeesErrorMessages];

  return <Typography color="error">{errorMessageString}</Typography>;
};

export default CompanyUsersErrorMessages;
