import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { Modal } from '@spotted-zebra-uk/ui-components';
import ProjectRequestManagerSurveyForm from './ProjectRequestManagerSurveyForm/ProjectRequestManagerSurveyForm';

export interface IProjectRequestManagerSurveyModal {
  projectRequestId: number;
  projectRequestCompanyId: number;
}

const ProjectRequestManagerSurveyModal: FC<IProjectRequestManagerSurveyModal> = ({
  projectRequestId,
}) => {
  const close = () => {
    closeModal(ModalTypes.PROJECT_REQUEST_MANAGER_SURVEY_MODAL);
  };

  return (
    <Modal
      className="project-request-manager-survey-modal"
      isOpen
      onClose={close}
      header="Add survey"
    >
      <ProjectRequestManagerSurveyForm
        closeModal={close}
        projectRequestId={projectRequestId}
      />
    </Modal>
  );
};

export default ProjectRequestManagerSurveyModal;
