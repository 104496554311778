import { FC } from 'react';
import { CompanySignatureFindManyQuery } from '../../../../../../generated/graphql';
import { TAlertTypes } from '../../../../../../interfaces/snackBar';
import SignatureListItem from './SignatureListItem/SignatureListItem';

interface IEditCompanySignature {
  companyId: number;
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  companySignatureQueryResponse: CompanySignatureFindManyQuery;
  defaultId: number;
}

const EditCompanySignature: FC<IEditCompanySignature> = ({
  companyId,
  displaySnackbar,
  defaultId,
  companySignatureQueryResponse,
}) => {
  return (
    <div style={{ marginTop: 20, width: '100%' }}>
      {companySignatureQueryResponse?.CompanySignature?.map(item => (
        <SignatureListItem
          key={item.id}
          id={item.id}
          companyId={companyId}
          signature={item.signature}
          signatureEmail={item.signatureEmail}
          displaySnackbar={displaySnackbar}
          defaultId={defaultId}
          companySignatureCount={
            companySignatureQueryResponse?.CompanySignature?.length || 0
          }
        />
      ))}
    </div>
  );
};

export default EditCompanySignature;
