import { Language } from 'generated/graphql';
import { Maybe } from 'interfaces/maybe';
import {
  TSelectOption,
  getSupportedLocaleMetadata,
  SupportedLocale,
} from '@spotted-zebra-uk/ui-components';

export type TAvailableLanguage =
  | Language.English
  | Language.SpanishCastilian
  | Language.German
  | Language.Turkish
  | Language.Indonesian
  | Language.French
  | Language.DutchFlemish
  | Language.Danish
  | Language.Italian
  | Language.Korean
  | Language.Malay
  | Language.Zulu //TODO - will be updated
  | Language.Chinese;

const languageLocalizationFileNames: { [key in TAvailableLanguage]: string } = {
  [Language.English]: 'en',
  [Language.SpanishCastilian]: 'es',
  [Language.German]: 'de',
  [Language.Indonesian]: 'id',
  [Language.Turkish]: 'tr',
  [Language.French]: 'fr',
  [Language.Danish]: 'da',
  [Language.DutchFlemish]: 'nl',
  [Language.Italian]: 'it',
  [Language.Korean]: 'ko',
  [Language.Malay]: 'ms',
  [Language.Zulu]: 'zu', //TODO - will be updated
  [Language.Chinese]: 'zh',
};

const languageLocalizationShortNames: {
  [key in string]: TAvailableLanguage;
} = {
  en: Language.English,
  es: Language.SpanishCastilian,
  de: Language.German,
  id: Language.Indonesian,
  tr: Language.Turkish,
  fr: Language.French,
  ko: Language.Korean,
  ms: Language.Malay,
  zu: Language.Zulu, //TODO - will be updated
  zh: Language.Chinese,
};

export const getLanguageFromShortName = (lngShort: Maybe<string>) =>
  lngShort && languageLocalizationShortNames[lngShort]
    ? languageLocalizationShortNames[lngShort]
    : Language.English;

export const getLanguageLocalizationFileName = (
  language: TAvailableLanguage
): string => languageLocalizationFileNames[language] || '';

export const availableLanguages: TAvailableLanguage[] = [
  Language.English,
  Language.SpanishCastilian,
  Language.German,
  Language.Turkish,
  Language.Indonesian,
  Language.French,
  Language.Danish,
  Language.DutchFlemish,
  Language.Italian,
  Language.Korean,
  Language.Malay,
  Language.Zulu, //TODO - will be updated with complex lang code when available
  Language.Chinese,
];

export const initialLanguageOption: TSelectOption<
  keyof typeof SupportedLocale
> = {
  label: getSupportedLocaleMetadata(SupportedLocale.ENGLISH).name.english,
  value: getSupportedLocaleMetadata(
    SupportedLocale.ENGLISH
  ).name.english.toUpperCase() as keyof typeof SupportedLocale,
};
