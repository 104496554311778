import Button from 'components/atoms/Button/Button';
import { ModalTypes } from 'enums/modalTypes';
import { useDeiStageActionCandidateReopenMutation } from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './ResetDeiForm.module.scss';

const ResetDeiForm = ({ stageCandidateId }: { stageCandidateId: number }) => {
  const { handleMsgType } = useNotification();
  const [reopenForm, { loading }] = useDeiStageActionCandidateReopenMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title:
          'Diversity, equity & inclusion questionnaire successfully reopened',
      });
      closeModal(ModalTypes.NOTIFICATION_MODAL);
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title:
          'Diversity, equity & inclusion questionnaire could not be reopened',
        message: 'Something went wrong. Please try again.',
      });
      closeModal(ModalTypes.NOTIFICATION_MODAL);
    },
  });
  const handleResetDeiModal = () => {
    const modalProps = {
      title: 'Are you sure?',
      message:
        'This action will reopen the questionnaire to the user, without deleting previous responses.',
      confirmButtonProps: { loading: loading },
      onOkClick: () => {
        reopenForm({ variables: { stageCandidateId } });
      },
    };
    openModal(ModalTypes.NOTIFICATION_MODAL, modalProps);
  };
  return (
    <div
      className={styles.container}
      data-testid="user-details__reset-dei-form"
    >
      <p>Reopen user's Diversity, equity & inclusion questionnaire?</p>
      <Button onClick={handleResetDeiModal}>Reopen</Button>
    </div>
  );
};

export default ResetDeiForm;
