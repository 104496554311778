import { makeStyles } from '@mui/styles';

const useValidateContentModalStyle = makeStyles({
  modalPaper: {
    minWidth: 600,
    minHeight: 300,
  },
});

export default useValidateContentModalStyle;
