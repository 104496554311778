import { FC, useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';
import useSoftSkillContentListItemStyle from '../SoftSkillContentList/SoftSkillContentListItem/SoftSkillContentListItemStyle';

export interface ISoftSkillContentHeader {}

export interface ISoftSkillContentFormValues {
  softSkills: number[];
}

const SoftSkillContentHeader: FC<ISoftSkillContentHeader> = () => {
  const { filters, updateFilters } = useContext(StaticContentContext);
  const classes = useSoftSkillContentListItemStyle();

  const openStaticContentFiltersModal = () => {
    openModal(ModalTypes.STATIC_CONTENT_FILTERS_MODAL, {
      isSoftSkillFilterIncluded: true,
      isGradeFilterIncluded: true,
      isRoleLevelFilterIncluded: true,
      filters: filters,
      onSubmit: updateFilters,
    });
  };

  return (
    <Grid container direction="row" alignItems="center">
      <Grid xs={1}>
        <Typography variant="h6">Soft Skill</Typography>
      </Grid>
      <Grid xs={1}>
        <Typography variant="h6">Grade</Typography>
      </Grid>
      <Grid xs={1}>
        <Typography variant="h6">Role level</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridTextArea}>
        <Typography variant="h6">Description</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridTextArea}>
        <Typography variant="h6">HM feedback</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridTextArea}>
        <Typography variant="h6">Candidate feedback</Typography>
      </Grid>
      <Grid xs={2} className={classes.gridTextArea}>
        <Typography variant="h6">Development feedback</Typography>
      </Grid>
      <Grid xs={1}>
        <Button onClick={openStaticContentFiltersModal}>Filter/Sort</Button>
      </Grid>
    </Grid>
  );
};

export default SoftSkillContentHeader;
