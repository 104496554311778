import {
  getAccessToken,
  getRefreshToken,
  logoutCleanup,
  setAccessToken,
} from 'helpers/auth';
import {
  IRequestTokenRefreshInput,
  IRequestTokenRefreshResponse,
} from 'interfaces/authentication';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

export const REQUEST_TOKEN_REFRESH = gql`
  mutation RequestTokenRefresh($accessToken: String!, $refreshToken: String!) {
    requestTokenRefresh(
      accessToken: $accessToken
      refreshToken: $refreshToken
    ) {
      accessToken
      refreshToken
    }
  }
`;

const requestTokenRefreshMutation = async (
  client: ApolloClient<NormalizedCacheObject>
): Promise<boolean> => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  if (accessToken && refreshToken) {
    const { data } = await client.mutate<
      IRequestTokenRefreshResponse,
      IRequestTokenRefreshInput
    >({
      mutation: REQUEST_TOKEN_REFRESH,
      variables: {
        accessToken,
        refreshToken,
      },
    });

    if (data) {
      setAccessToken(data.requestTokenRefresh.accessToken);
      setAccessToken(data.requestTokenRefresh.refreshToken);

      return true;
    }
  }

  if (accessToken && !refreshToken) {
    logoutCleanup();
  }

  return false;
};

export const requestTokenRefresh = async (
  client: ApolloClient<NormalizedCacheObject>
): Promise<boolean> => {
  try {
    const result = await requestTokenRefreshMutation(client);
    return result;
  } catch (error) {
    logoutCleanup();
    return false;
  }
};
