import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';

interface ICandidateInfoFieldMessage {
  error?: ApolloError;
  isSuccess: boolean;
  successMessage: string;
}

const CandidateInfoFieldMessage: FC<ICandidateInfoFieldMessage> = ({
  error,
  isSuccess,
  successMessage,
}) => {
  if (error) {
    return <Typography color="error">Some error occured!</Typography>;
  }

  if (isSuccess) {
    return <Typography color="primary">{successMessage}</Typography>;
  }

  return null;
};

export default CandidateInfoFieldMessage;
