import { parse, set } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
import {
  ActorRole,
  SmStageModelFragment,
  StageFindManyDocument,
  StageFindManyQuery,
  StageFindManyQueryVariables,
} from 'generated/graphql';
import { actorRoleLabels } from 'helpers/actorRoles';
import * as yup from 'yup';
import { ApolloCache } from '@apollo/client';
import { TSelectOption } from '@spotted-zebra-uk/ui-components';
import { STAGE_TYPE_FULL, stageModes, stageTypeOptions } from '../helpers';
import { StageModes } from '../types';
import { IStageFormValues } from './StageForm.interfaces';

export const getStageMode = (
  emailManagerReport: boolean,
  emailProjectTeam: boolean,
  emailProjectTeamRoles: ActorRole[]
): StageModes => {
  if (emailManagerReport) {
    if (emailProjectTeam) {
      return emailProjectTeamRoles.length > 0
        ? StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED
        : StageModes.ACCESS_BASED;
    }
    return StageModes.ASSIGNEE_BASED;
  }

  if (emailProjectTeam && emailProjectTeamRoles.length > 0) {
    return StageModes.PROJECT_ROLE_BASED;
  }

  return StageModes.DISABLED;
};

export const createValidationSchema = (projectRolesLength: number) => {
  const validationSchema = yup.object().shape({
    stageName: yup.string().required(),
    stageStartTime: yup.string().required(),
    managerReportEmailLinks: yup.boolean(),
    createInterviewGuide: yup.boolean(),
    stageEndTime: yup
      .string()
      .test(
        'stageEndTimeRequirement',
        'Stage end time is required',
        (value, { parent }) => {
          if (parent.stageIsEndTimeNull) {
            return true;
          }

          return Boolean(value);
        }
      ),

    stageProjectRoles: yup.array().when('stageMode.value', {
      is: (value: StageModes) =>
        value === StageModes.PROJECT_ROLE_BASED ||
        value === StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED,
      then: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
          })
        )
        .test('is-not-empty', 'This is a required field.', value =>
          Boolean(value && value.length > 0)
        )
        .test(
          'is-not-all-roles',
          'You cannot select all project roles. Remove some project roles or choose another setting.',
          value => Boolean(value && value.length < projectRolesLength)
        ),
    }),
  });

  return validationSchema;
};

export const initialValues = {
  stageName: 'Assessment',
  stageType: STAGE_TYPE_FULL,
  stageEmailCandidateReport: false,
  stageEmailSzAdmin: false,
  stageStartTime: new Date().toISOString(),
  stageEndTime: '',
  stageIsEndTimeNull: true,
  stageMode: stageModes[StageModes.ASSIGNEE_BASED],
  stageProjectRoles: [],
  createInterviewGuide: false,
  // managerReportEmailLinks initial value will be overwritten by the default set at the company level
  managerReportEmailLinks: false,
  enableF2fInterviews: false,
};

export const initializeValues = (
  stage: SmStageModelFragment
): IStageFormValues => {
  const stageMode = getStageMode(
    stage.emailManagerReport,
    stage.emailProjectTeam,
    stage.emailProjectTeamRoles
  );

  return {
    stageName: stage?.name,
    stageType:
      stageTypeOptions.find(option => option.value === stage.type) ||
      STAGE_TYPE_FULL,
    stageStartTime: stage.startTime
      ? new Date(stage.startTime).toISOString()
      : convertToUTC(new Date().toDateString()),
    // Set stage end time to 00:00:00 instead of 23:59:59 because the datepicker defaults to the next day
    // This will not affect sending 23:59 as the correct end time in the API request
    stageEndTime: stage.endTime ? convertToUTC(stage.endTime) : '',
    stageIsEndTimeNull: !stage.endTime,
    stageEmailCandidateReport: stage.emailCandidateReport,
    stageEmailSzAdmin: stage.emailSzAdmin,
    createInterviewGuide: stage.createInterviewGuide,
    stageMode: stageModes[stageMode] as TSelectOption<StageModes>,
    managerReportEmailLinks: stage.managerReportEmailLinks,
    stageProjectRoles: stage.emailProjectTeamRoles.map(role => ({
      value: role,
      label: actorRoleLabels[role],
    })),
    enableF2fInterviews: stage.enableF2fInterviews,
  };
};

export const updateCacheHelper = (
  cache: ApolloCache<SmStageModelFragment>,
  data: SmStageModelFragment,
  projectId: number
) => {
  const existingStages = cache.readQuery<
    StageFindManyQuery,
    StageFindManyQueryVariables
  >({
    query: StageFindManyDocument,
    variables: { projectId },
  });

  if (existingStages?.Stages) {
    cache.writeQuery({
      query: StageFindManyDocument,
      variables: { projectId },
      data: { Stages: [data] },
    });
  }
};

export const convertToUTC = (
  dateInput: string,
  isEndOfDay: boolean = false
) => {
  let parsedDate;
  // Check if the input is an ISO string by looking for the 'T' and 'Z'
  if (dateInput.includes('T') && dateInput.includes('Z')) {
    parsedDate = toZonedTime(dateInput, 'UTC');
  } else {
    // Assume it is in the format 'EEE MMM dd yyyy' (e.g., 'Thu Sep 26 2024')
    parsedDate = parse(dateInput, 'EEE MMM dd yyyy', new Date());
  }

  let utcDate;
  // Set desired time
  if (isEndOfDay) {
    utcDate = set(parsedDate, { hours: 23, minutes: 59, seconds: 59 });
  } else {
    utcDate = set(parsedDate, { hours: 0, minutes: 0, seconds: 0 });
  }

  return format(utcDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};
