import { CSSProperties, FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useCircularLoaderStyle from './CircularLoaderStyle';

interface ICircularLoader {
  customStyle?: string;
  size?: number;
  style?: CSSProperties;
}

const CircularLoader: FC<ICircularLoader> = ({
  customStyle,
  size = 40,
  style,
}) => {
  const defaultStyle = useCircularLoaderStyle();

  return (
    <div
      className={customStyle ? customStyle : defaultStyle.loaderWrapper}
      style={style}
    >
      <CircularProgress size={size} />
    </div>
  );
};

export default CircularLoader;
