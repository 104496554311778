import { UserFragment } from 'generated/graphql';
import { FC, Fragment } from 'react';
import ContactsListItem from '../ContactsListItem';
import { CreatorContactsListItem } from '../CreatorContactsListItem';
import { TContactData } from '../types';

interface ContactsListProps {
  contacts: TContactData[];
  creator?: UserFragment | null;
}

const ContactsList: FC<ContactsListProps> = ({ contacts, creator }) => (
  <div className="contacts-list">
    {creator && <CreatorContactsListItem creator={creator} />}
    {contacts.map(contact => (
      <Fragment key={contact.projectRequestUserId}>
        <ContactsListItem contact={contact} />
      </Fragment>
    ))}
  </div>
);

export default ContactsList;
