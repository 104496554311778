import Icon, { IconType } from 'components/atoms/Icon';
import { ProjectRequestStatus } from 'generated/graphql';
import { FC } from 'react';

interface ProjectRequestStatusLabelProps {
  status: ProjectRequestStatus;
}

const projectRequestStatusColorMap = {
  [ProjectRequestStatus.ConfirmMatch]: {
    title: 'Confirm Match',
    color: 'green',
    icon: 'check_circle',
  },
  [ProjectRequestStatus.JobSpecsRecieved]: {
    title: 'Job Specification Received',
    color: 'gray',
    icon: 'playlist_add_check',
  },
  [ProjectRequestStatus.RequiresProject]: {
    title: 'Requires Project',
    color: 'purple',
    icon: 'timelapse',
  },
  [ProjectRequestStatus.ProjectCreated]: {
    title: 'Project Created',
    color: 'blue',
    icon: 'done_all',
  },
};

const ProjectRequestStatusLabel: FC<ProjectRequestStatusLabelProps> = ({
  status,
}) => {
  const { title, color, icon } = projectRequestStatusColorMap[status];

  return (
    <div
      className={`project-request-status-label project-request-status-label_color_${color}`}
    >
      <Icon icon={icon as IconType} />
      {title}
    </div>
  );
};

export default ProjectRequestStatusLabel;
