import { FC, useMemo } from 'react';
import { WidgetType } from '../../../../generated/graphql';
import { getSelectElementOptionsFromEnumObject } from '../../../../helpers/getSelectElementOptionsFromEnum';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import SelectFormField from '../SelectFormField';

interface IWidgetTypeSelectFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
}

const WidgetTypeSelectFormField: FC<IWidgetTypeSelectFormField> = props => {
  const options = useMemo(
    () =>
      getSelectElementOptionsFromEnumObject(Object(WidgetType)).sort((a, b) =>
        a.value > b.value ? 1 : -1
      ),
    []
  );

  return <SelectFormField {...props} options={options} />;
};

export default WidgetTypeSelectFormField;
