import { FC, useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';

export interface IInterviewQuestionContentHeader {}

const InterviewQuestionContentHeader: FC<IInterviewQuestionContentHeader> = () => {
  const { filters, updateFilters } = useContext(StaticContentContext);

  const openStaticContentFiltersModal = () => {
    openModal(ModalTypes.STATIC_CONTENT_FILTERS_MODAL, {
      isSoftSkillFilterIncluded: true,
      isTraitFilterIncluded: true,
      isClassificationFilterIncluded: true,
      isRoleLevelFilterIncluded: true,
      filters: filters,
      onSubmit: updateFilters,
    });
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={5}
    >
      <Grid item xs={1}>
        <Typography variant="h6">Soft Skill</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">Trait</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">Classification</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">Role level</Typography>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={2}>
        <Button onClick={openStaticContentFiltersModal}>Filter/Sort</Button>
      </Grid>
    </Grid>
  );
};

export default InterviewQuestionContentHeader;
