import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import { ErrorMessages } from '../../../enums/error';

interface IErrorMessage {
  error?: ApolloError | undefined;
}

const ErrorMessagesStrings = {
  [ErrorMessages.NOT_FOUND]: 'Element not found.',
  [ErrorMessages.INVALID_COGNITIVE_COMBINED_TRAIT_TYPES]:
    'Invalid cognitive combined traits provided.',
  [ErrorMessages.PROJECT_NOT_FOUND]: 'Invalid project provided',
  [ErrorMessages.SOFT_SKILL_THRESHOLD_INVALID]:
    'Soft skill trashold must be 100.',
  [ErrorMessages.EXISTING_USER]: 'User with that email already exists.',
  [ErrorMessages.USER_NOT_FOUND]: 'User with provided id does not exists.',
  [ErrorMessages.MFA_REQUIRED]: 'User has two factor authentication enabled.',
  [ErrorMessages.INVALID_MFA_CODE]: 'Incorrect two factor authentication code',
  [ErrorMessages.EXPIRED_MFA_TOKEN]: 'Expired token. Re-enter credentials.',
  [ErrorMessages.EXCEEDED_NUMBER_OF_ATTEMPTS]:
    'User has exceeded number of login attempts.',
};

const ErrorMessage: FC<IErrorMessage> = ({ error }) => {
  if (!error) {
    return null;
  }

  const errorMessageString =
    ErrorMessagesStrings[error.message as ErrorMessages] ||
    'Some error occured!';

  return <Typography color="error">{errorMessageString}</Typography>;
};

export default ErrorMessage;
