import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import ProjectsSelectFormField from '../../../../components/molecules/SelectFormField/ProjectsSelectFormField/ProjectsSelectFormField';
import StagesSelectFormField from '../../../../components/molecules/SelectFormField/StagesSelectFormField/StagesSelectFormField';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import validate from '../../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import { UserReponse } from '../../UserDetails/UserDetails.interface';
import useRetrieveTpCandidatesFormStyle from './RetrieveTpCandidatesFormStyle';

export type TRetrieveTpCandidatesFormValues = {
  candidateEmail: string;
  firstName: string;
  lastName: string;
  projectId: string;
  stageId: string;
  personalityTpAssessmentId: string;
  inductiveTpAssessmentId: string;
  numericalTpAssessmentId: string;
  verbalTpAssessmentId: string;
};

type TRetrieveTpCandidatesFormErrors = {
  candidateEmail: string;
  projectId: string;
  stageId: string;
};

interface IRetrieveTpCandidatesForm {
  stageId?: number;
  projectId?: number;
  userInfo?: UserReponse | undefined;
  onSubmit: (values: TRetrieveTpCandidatesFormValues) => void;
}

const RetriveTpCandidatesForm: FC<IRetrieveTpCandidatesForm> = ({
  stageId,
  projectId,
  userInfo,
  onSubmit,
}) => {
  const classes = useRetrieveTpCandidatesFormStyle();
  const [values, setValues] = useState<TRetrieveTpCandidatesFormValues>({
    candidateEmail: userInfo?.email || '',
    firstName: userInfo?.firstName || '',
    lastName: userInfo?.lastName || '',
    projectId: `${projectId}` || '',
    stageId: `${stageId}` || '',
    personalityTpAssessmentId: '',
    inductiveTpAssessmentId: '',
    numericalTpAssessmentId: '',
    verbalTpAssessmentId: '',
  });
  const [errors, setErrors] = useState<
    TRetrieveTpCandidatesFormErrors | undefined
  >();

  const handleChange = (value: string, name: string) => {
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));

    if (errors && errors[name as keyof TRetrieveTpCandidatesFormErrors]) {
      setErrors(prevErrors =>
        prevErrors
          ? {
              ...prevErrors,
              [name]: '',
            }
          : undefined
      );
    }
  };

  const validateRetriveTpCandidatesForm = () => {
    return validate(values, {
      candidateEmail: {
        email: true,
      },
      projectId: {
        numericality: { notEqualTo: 0, message: '^Project must be selected.' },
      },
      stageId: {
        numericality: { notEqualTo: 0, message: '^Stage must be selected.' },
      },
    });
  };

  const handleChangeProject = (value: TSelectFormFieldValue, _name: string) => {
    setValues(prevValues => ({
      ...prevValues,
      stageId: '',
      projectId: value as string,
    }));

    if (errors && errors.projectId) {
      setErrors(prevErrors =>
        prevErrors
          ? {
              ...prevErrors,
              projectId: '',
            }
          : undefined
      );
    }
  };

  const handleChangeStage = (value: TSelectFormFieldValue, _name: string) => {
    setValues(prevValues => ({
      ...prevValues,
      stageId: value as string,
    }));

    if (errors && errors.stageId) {
      setErrors(prevErrors =>
        prevErrors
          ? {
              ...prevErrors,
              stageId: '',
            }
          : undefined
      );
    }
  };

  const handleSubmit = () => {
    const newErrors = validateRetriveTpCandidatesForm();

    if (!newErrors) {
      onSubmit(values);
    } else {
      setErrors(newErrors);
    }
  };

  const formKey = 'rtcf';
  return (
    <Grid
      item
      container
      direction="column"
      className="RetriveTpCandidatesForm"
      alignItems="center"
    >
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-candidateEmail`}
          name="candidateEmail"
          onChange={handleChange}
          value={values.candidateEmail}
          label="Candidate email"
          hasError={!!errors?.candidateEmail}
          errorText={errors?.candidateEmail}
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-firstName`}
          name="firstName"
          onChange={handleChange}
          value={values.firstName}
          label="Given name"
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-lastName`}
          name="lastName"
          onChange={handleChange}
          value={values.lastName}
          label="Family name"
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <ProjectsSelectFormField
          id={`${formKey}-projectId`}
          name="projectId"
          onChange={handleChangeProject}
          value={values.projectId}
          label="Project"
          hasError={!!errors?.projectId}
          errorText={errors?.projectId}
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <StagesSelectFormField
          id={`${formKey}-stageId`}
          name="stageId"
          onChange={handleChangeStage}
          value={values.stageId}
          selectedProjectId={values.projectId ? +values.projectId : 0}
          label="Stage"
          hasError={!!errors?.stageId}
          errorText={errors?.stageId}
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-personalityTpAssessmentId`}
          name="personalityTpAssessmentId"
          onChange={handleChange}
          value={values.personalityTpAssessmentId}
          label="Personality tp assessment id"
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-inductiveTpAssessmentId`}
          name="inductiveTpAssessmentId"
          onChange={handleChange}
          value={values.inductiveTpAssessmentId}
          label="Inductive tp assessment id"
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-numericalTpAssessmentId`}
          name="numericalTpAssessmentId"
          onChange={handleChange}
          value={values.numericalTpAssessmentId}
          label="Numerical tp assessment id"
        />
      </Grid>
      <Grid item className={classes.formFieldWrapper}>
        <TextFormField
          id={`${formKey}-verbalTpAssessmentId`}
          name="verbalTpAssessmentId"
          onChange={handleChange}
          value={values.verbalTpAssessmentId}
          label="Verbal tp assessment id"
        />
      </Grid>
      <Grid item>
        <Button onClick={handleSubmit}>Submit</Button>
      </Grid>
    </Grid>
  );
};

export default RetriveTpCandidatesForm;
