export default function TimeLimitIcon({
  color = '#006FBF',
}: {
  color?: string;
}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_89_1810)">
        <path
          d="M8.33301 6.6665V11.6665L12.2497 14.0832L12.9163 13.0832L9.58301 11.0832V6.6665H8.33301Z"
          fill={color}
        />
        <path
          d="M14.9332 9.99967C14.9748 10.2747 14.9998 10.5497 14.9998 10.833C14.9998 14.083 12.4165 16.6663 9.1665 16.6663C5.9165 16.6663 3.33317 14.083 3.33317 10.833C3.33317 7.58301 5.9165 4.99967 9.1665 4.99967C9.74984 4.99967 10.3082 5.08301 10.8332 5.24134V3.52467C10.2998 3.39967 9.7415 3.33301 9.1665 3.33301C4.99984 3.33301 1.6665 6.66634 1.6665 10.833C1.6665 14.9997 4.99984 18.333 9.1665 18.333C13.3332 18.333 16.6665 14.9997 16.6665 10.833C16.6665 10.5497 16.6498 10.2747 16.6165 9.99967H14.9332Z"
          fill={color}
        />
        <path
          d="M16.6667 4.1665V1.6665H15V4.1665H12.5V5.83317H15V8.33317H16.6667V5.83317H19.1667V4.1665H16.6667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_89_1810">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
