import Icon from 'components/atoms/Icon';
import { TTechnicalSkillValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import { SearchInputTechSkill } from 'components/feature/jobRole/SearchInputTechSkill/SearchInputTechSkill';
import { FieldArray, useFormikContext } from 'formik';
import { FC } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';
import styles from './TechnicalSkillsFields.module.scss';

const getEmptyFormTechnicalSkillValues = () => ({
  value: '0',
  label: '',
});

const TechincalSkillsFields: FC = () => {
  const { values } = useFormikContext<TTechnicalSkillValues>();
  return (
    <div data-testid="technical-skills-fields">
      <FieldArray
        name="technicalSkills"
        render={fieldArrayHelpers => (
          <>
            <div className={styles.fieldsList}>
              <br />
              {values?.technicalSkills?.map(({ value }, index) => {
                return (
                  <div
                    key={`${value}_${index}`}
                    className={styles.fieldsListItem}
                  >
                    <SearchInputTechSkill index={index} />
                    <Button
                      onClick={() => fieldArrayHelpers.remove(index)}
                      size="medium"
                      variant="text"
                      type="button"
                      className={styles.smallButton}
                      leftIcon={
                        <Icon icon="delete_outline" className={styles.icon} />
                      }
                    >
                      Delete
                    </Button>
                  </div>
                );
              })}

              <div className={styles.actions}>
                <Button
                  onClick={() =>
                    fieldArrayHelpers.push(getEmptyFormTechnicalSkillValues())
                  }
                  size="medium"
                  variant="secondary"
                  type="button"
                  className={styles.smallButton}
                  leftIcon={<Icon icon="add_black" className={styles.icon} />}
                >
                  Add technical skill
                </Button>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default TechincalSkillsFields;
