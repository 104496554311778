import { FC, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../../../components/atoms/Button/Button';
import { companyRequirementsFormBuilderFormColumns } from '../../../../../../enums/forms/CandidateInformationFieldRequirementsForm';
import {
  ICandidateInformationFieldRequirementsFormSubmitValues,
  ICompanyRequirementsFormBuilderFormValues,
} from '../../../../../../interfaces/forms/companyRequirementsFormBuilderForm';
import CompanyRequirementsFormBuilderFormOrderColumn from '../CompanyRequirementsFormBuilderFormOrderColumn/CompanyRequirementsFormBuilderFormOrderColumn';
import useCompanyRequirementsFormBuilderFormStyle from './CompanyRequirementsFormBuilderFormStyle';

interface ICompanyRequirementsFormBuilderForm {
  initialValues: ICompanyRequirementsFormBuilderFormValues;
  onSubmit: (
    formValues: ICandidateInformationFieldRequirementsFormSubmitValues
  ) => void;
  companyId: number;
}

const CompanyRequirementsFormBuilderForm: FC<ICompanyRequirementsFormBuilderForm> = ({
  initialValues,
  onSubmit,
  companyId,
}) => {
  const classes = useCompanyRequirementsFormBuilderFormStyle();
  const [values, setValues] = useState<
    ICompanyRequirementsFormBuilderFormValues
  >(initialValues);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const onChangeFieldRequirement = (value: boolean, id: number) => {
    setValues({
      ...values,
      fieldValues: {
        ...values.fieldValues,
        [id]: {
          ...values.fieldValues[id],
          isOptional: value,
        },
      },
    });

    setIsDirty(true);
  };

  const onChangeOrder = ({ destination, source, draggableId }: DropResult) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      const orderColumn =
        values.order[
          source.droppableId as companyRequirementsFormBuilderFormColumns
        ];
      const newOrderColumn = [...orderColumn];
      newOrderColumn.splice(source.index, 1);
      newOrderColumn.splice(destination.index, 0, +draggableId);

      setValues({
        ...values,
        order: {
          ...values.order,
          [source.droppableId as companyRequirementsFormBuilderFormColumns]: newOrderColumn,
        },
      });
    } else {
      const orderSourceColumn =
        values.order[
          source.droppableId as companyRequirementsFormBuilderFormColumns
        ];
      const orderDestinationColumn =
        values.order[
          destination.droppableId as companyRequirementsFormBuilderFormColumns
        ];
      const newSourceColumnOrder = [...orderSourceColumn];
      const newDestinationColumnOrder = [...orderDestinationColumn];
      newSourceColumnOrder.splice(source.index, 1);
      newDestinationColumnOrder.splice(destination.index, 0, +draggableId);

      setValues({
        ...values,
        order: {
          ...values.order,
          [source.droppableId as companyRequirementsFormBuilderFormColumns]: newSourceColumnOrder,
          [destination.droppableId as companyRequirementsFormBuilderFormColumns]: newDestinationColumnOrder,
        },
      });
    }
    setIsDirty(true);
  };

  const handleSubmit = () => {
    onSubmit(values);
  };

  const formId = 'crfbf';
  return (
    <DragDropContext onDragEnd={result => onChangeOrder(result)}>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Optional?</Typography>
          </Grid>
          <Grid item xs={2} className={classes.actionsWrapper}>
            <Button isDisabled={!isDirty} onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
        <CompanyRequirementsFormBuilderFormOrderColumn
          id={companyRequirementsFormBuilderFormColumns.USED}
          title="Used"
          orderValues={values.order.used}
          fieldValues={values.fieldValues}
          companyId={companyId}
          formId={formId}
          onChangeFieldRequirement={onChangeFieldRequirement}
        />
        <CompanyRequirementsFormBuilderFormOrderColumn
          id={companyRequirementsFormBuilderFormColumns.NOT_USED}
          title="Not used"
          orderValues={values.order.notUsed}
          fieldValues={values.fieldValues}
          companyId={companyId}
          formId={formId}
          onChangeFieldRequirement={onChangeFieldRequirement}
        />
      </Grid>
    </DragDropContext>
  );
};

export default CompanyRequirementsFormBuilderForm;
