import './styles.scss';
import Icon from 'components/atoms/Icon';
import {
  ProjectRequestFragmentDoc,
  useProjectRequestRestoreOneMutation,
  WithDeletedChoice,
} from 'generated/graphql';
import { FC, MouseEventHandler } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IUnarchiveProjectRequestButton {
  projectRequestId: number;
}

export const UnarchiveProjectRequestButton: FC<IUnarchiveProjectRequestButton> = ({
  projectRequestId,
}) => {
  const { handleMsgType } = useNotification();

  const [
    projectRequestRestoreOneMutation,
    { loading },
  ] = useProjectRequestRestoreOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      if (data?.ProjectRequestRestoreOne) {
        const newProjectRequestRef = cache.writeFragment({
          id: `ProjectRequestModel:${data.ProjectRequestRestoreOne.id}`,
          fragment: ProjectRequestFragmentDoc,
          data: data.ProjectRequestRestoreOne,
        });

        // Updates the list of project requests that are not archived (adds restored project request),
        // on the '/project-requests' page. The list that is updated is visible if Display Archived switch is off.
        cache.modify({
          fields: {
            ProjectRequestFindMany(
              existingProjectRequests = [],
              { storeFieldName }
            ) {
              if (
                storeFieldName.includes(
                  `"withDeleted":"${WithDeletedChoice.OnlyNotArchived}"`
                )
              ) {
                return [...existingProjectRequests, newProjectRequestRef];
              }
            },
          },
        });
      }
    },
  });

  const handleUnarchiveButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    projectRequestRestoreOneMutation({
      variables: { id: projectRequestId },
    });
  };

  return (
    <Button
      disabled={loading}
      variant="text"
      size="small"
      onClick={handleUnarchiveButtonClick}
      className="unarchive-project-request-button"
    >
      Unarchive
      <Icon icon="replay" className="unarchive-project-request-button__icon" />
    </Button>
  );
};
