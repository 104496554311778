import { ProjectRequestsViewContext } from 'contexts/ProjectRequestContext';
import {
  ProjectRequestFragment,
  useCompanyFindManyByIdsLazyQuery,
  useProjectRequestFindManyQuery,
  useUsersLazyQuery,
  WithDeletedChoice,
} from 'generated/graphql';
import { isBefore } from 'helpers/date';
import uniq from 'lodash/uniq';
import { FC, Fragment, memo, useContext, useMemo } from 'react';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ProjectRequestsListItem from '../ProjectRequestsListItem';

interface ProjectRequestsListPresentationalProps {
  projectRequests: ProjectRequestFragment[];
}

const ProjectRequestsListPresentational: FC<ProjectRequestsListPresentationalProps> = ({
  projectRequests,
}) => (
  <div className="project-requests-list">
    {projectRequests.map(projectRequest => (
      <Fragment key={projectRequest.id}>
        <ProjectRequestsListItem projectRequest={projectRequest} />
      </Fragment>
    ))}
  </div>
);

const ProjectRequestsListPresentationalPure = memo(
  ProjectRequestsListPresentational
);

const ProjectRequestsList: FC = () => {
  const { handleMsgType } = useNotification();

  const { displayArchivedFilter } = useContext(ProjectRequestsViewContext);
  const [
    companiesQuery,
    companiesQueryResponse,
  ] = useCompanyFindManyByIdsLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [usersQuery, usersQueryResponse] = useUsersLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const projectRequestQueryResponse = useProjectRequestFindManyQuery({
    variables: {
      withDeleted: displayArchivedFilter
        ? WithDeletedChoice.All
        : WithDeletedChoice.OnlyNotArchived,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted(data) {
      if (data.ProjectRequestFindMany?.length) {
        const companyIds = uniq(
          data.ProjectRequestFindMany.map(
            projectRequest => projectRequest.companyId
          )
        );

        if (companyIds.length) {
          companiesQuery({ variables: { ids: companyIds } });
        }

        const userIds = uniq(
          data.ProjectRequestFindMany.map(
            projectRequest => projectRequest.creatorId
          )
        );

        if (userIds.length) {
          usersQuery({ variables: { ids: userIds } });
        }
      }
    },
  });

  const sortedProjectRequests = useMemo(
    () =>
      projectRequestQueryResponse.data?.ProjectRequestFindMany
        ? [
            ...projectRequestQueryResponse.data.ProjectRequestFindMany,
          ].sort((pr1, pr2) =>
            isBefore(pr1.updatedAt, pr2.updatedAt) ? 1 : -1
          )
        : [],
    [projectRequestQueryResponse.data?.ProjectRequestFindMany]
  );

  const loading =
    projectRequestQueryResponse.loading ||
    companiesQueryResponse.loading ||
    usersQueryResponse.loading;

  if (
    projectRequestQueryResponse.data?.ProjectRequestFindMany &&
    companiesQueryResponse.data &&
    usersQueryResponse.data
  ) {
    return (
      <ProjectRequestsListPresentationalPure
        projectRequests={sortedProjectRequests}
      />
    );
  }

  if (loading) {
    return (
      <div className="project-requests-list-loader">
        <Loader variant="bubbles" />
      </div>
    );
  }

  return null;
};

export default ProjectRequestsList;
