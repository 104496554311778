import { Form, Formik, useField } from 'formik';
import {
  IdentityPermissionRole,
  useCompaniesQuery,
  useCompanyFindManyUsersWithRolesLazyQuery,
} from 'generated/graphql';
import { FC, useMemo, useState } from 'react';
import { Pagination } from '@mui/lab';
import {
  InlineNotification,
  NotificationType,
  Button,
  Loader,
  TNotification,
  useNotification,
  FormikMultiselectFormField,
  TMultiselectOption,
} from '@spotted-zebra-uk/ui-components';
import ResultsTable from '../ResultTable/ResultsTable';

export const errorMessage =
  'Oops, something went wrong during the fetch of validated skills';
const notes = [
  'The total number of users across all these roles may be less than the sum of the number against each role, due to users with multiple roles for the same company',
  'The number in brackets refers to the number of users who have a password set (having a password set is an indication of engagement with the SZ system, but they may not have actually interacted with the system once logged in and this doesn’t account for users with access via SSO)',
];
const requiredUserRoles = [
  IdentityPermissionRole.Admin,
  IdentityPermissionRole.CompanyAdmin,
  IdentityPermissionRole.CompanyHr,
  IdentityPermissionRole.CompanyTalentTeam,
  IdentityPermissionRole.CompanyHiringManager,
  IdentityPermissionRole.CompanyMember,
  IdentityPermissionRole.Candidate,
];
const initialFormValues: { companies: TMultiselectOption[] } = {
  companies: [],
};
type TFormValues = {
  companies: TMultiselectOption[];
};
const Users: FC = () => {
  const { handleMsgType } = useNotification();
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<number[] | null>(
    null
  );
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [tableRows, setTableRows] = useState<(string | number)[][]>([]);
  const itemsPerPage = 10;

  const tableHeaders = ['Company', 'Users across all roles'];

  requiredUserRoles.forEach(role => tableHeaders.push(role.toLowerCase()));

  const companiesQueryResponse = useCompaniesQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${errorMessage}: ${error.message}`,
      });
    },
  });

  const [
    fetchCompanyUsersWithRoles,
    {
      data: companyUsersWithRoles,
      loading: companyUsersLoading,
      called: queryWasCalled,
    },
  ] = useCompanyFindManyUsersWithRolesLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const rows = data.CompanyFindManyUsersWithRoles.companyUserInfo.map(
        cui => {
          const rowData = [];
          const userRoleMap = new Map(
            cui.companyUserAcrossEachRole.map(userInfo => [
              userInfo.roleName,
              userInfo,
            ])
          );
          rowData.push(`${cui.company.name} (${cui.company.id})`);
          rowData.push(
            `${cui.companyUserAcrossAllRoles.totalUsers} (${cui.companyUserAcrossAllRoles.totalUsersWithPassword})`
          );
          requiredUserRoles.forEach(role => {
            const userRoleData = userRoleMap.get(role);
            rowData.push(
              !userRoleData
                ? '0 (0)'
                : `${userRoleData.totalUsers} (${userRoleData.totalUsersWithPassword})`
            );
          });
          return rowData;
        }
      );
      setTableRows(rows);
    },
    onError: err => {
      handleMsgType({
        type: TNotification.error,
        message: `${errorMessage}: ${err.message}`,
      });
    },
  });

  const refetchCompanyValidatedSkills = async (
    pageNumber: number,
    companyIds?: number[]
  ) => {
    await fetchCompanyUsersWithRoles({
      variables: {
        companyIds: companyIds ?? selectedCompanyIds ?? [],
        take: itemsPerPage,
        skip: (pageNumber - 1) * itemsPerPage,
        userRoles: requiredUserRoles,
      },
    });
  };

  const handleFormSubmit = (formValues: TFormValues) => {
    const { companies } = formValues;
    const companyIds = companies.map(company => parseInt(company.value ?? ''));
    setSelectedCompanyIds(companyIds);
    refetchCompanyValidatedSkills(selectedPage, companyIds);
  };

  const handlePageChange = (value: number) => {
    setSelectedPage(value);
    refetchCompanyValidatedSkills(value);
  };

  const companyUserInfoPaginated =
    companyUsersWithRoles?.CompanyFindManyUsersWithRoles;

  const companyMultiselectOptions: TMultiselectOption[] = useMemo(
    () =>
      companiesQueryResponse.data?.Companies?.map(c => ({
        label: c.name,
        value: c.id.toString(),
      })) || [],
    [companiesQueryResponse.data?.Companies]
  );

  const renderCompanyUsersTable = () => {
    if (companyUsersLoading) {
      return (
        <div data-testid="users-loader">
          <Loader variant="bubbles" />
        </div>
      );
    }

    if (!queryWasCalled) {
      return (
        <InlineNotification
          title={'Apply filters to see the summary'}
          notificationType={NotificationType.INFORMATION}
        />
      );
    }

    if (companyUserInfoPaginated) {
      return (
        <>
          <ResultsTable
            testKey="users"
            rows={tableRows}
            headers={tableHeaders}
          />

          <div className="users__pages">
            <Pagination
              count={Math.ceil(companyUserInfoPaginated?.total / itemsPerPage)}
              page={selectedPage}
              onChange={(_event, value) => handlePageChange(value)}
              size="large"
            />
          </div>
        </>
      );
    }

    return (
      <InlineNotification
        title={'Apply filters to see the summary'}
        notificationType={NotificationType.INFORMATION}
      />
    );
  };

  return (
    <div className="users">
      <Formik initialValues={initialFormValues} onSubmit={handleFormSubmit}>
        <Form className="users__input-container">
          <div className="users__input-item" data-testid="companies-dropdown">
            <FormikMultiselectFormField
              id="companies"
              name="companies"
              placeholder="Companies"
              label="Companies"
              options={companyMultiselectOptions}
              useFormikField={useField}
            />
          </div>
          <div className="users__input-item">
            <Button
              size="large"
              variant="primary"
              disabled={companyUsersLoading}
              data-testid="users-button"
              type="submit"
            >
              View Users
            </Button>
          </div>
        </Form>
      </Formik>
      <div className="users__description">
        <b>Calculation notes:</b>
        <ul className="users__description__notes">
          {notes.map((note, index) => {
            return <li key={index}>{note}</li>;
          })}
        </ul>
      </div>
      {renderCompanyUsersTable()}
    </div>
  );
};

export default Users;
