import { useCompanyAllowedEmailDomainsUpdateMutation } from 'generated/graphql';
import {
  InlineNotification,
  Modal,
  ModalSize,
  NotificationType,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './DeleteDomainModal.module.scss';

export function DeleteDomainModal({
  domain,
  existingEmailDomains,
  companyId,
  isOpen,
  onCloseModal,
}: {
  domain: string;
  existingEmailDomains: string[];
  companyId: number;
  isOpen: boolean;
  onCloseModal: () => void;
}) {
  const [
    updateEmailDomains,
    { loading },
  ] = useCompanyAllowedEmailDomainsUpdateMutation();

  const { handleMsgType } = useNotification();

  function handleConfirmDeletion() {
    const finalEmailDomains = existingEmailDomains.filter(
      emailDomain => emailDomain !== domain
    );

    updateEmailDomains({
      variables: {
        allowedEmailDomains: finalEmailDomains,
        id: companyId,
      },
      onCompleted: () => {
        onCloseModal();
        handleMsgType({
          type: TNotification.success,
          message: 'Email domain deleted',
        });
      },
      onError: () => {
        onCloseModal();
        handleMsgType({
          type: TNotification.error,
          title: 'Email domain could not be deleted.',
          message: 'Please try again',
        });
      },
      update: cache => {
        cache.modify({
          id: `Company:${companyId}`,
          fields: {
            allowedEmailDomains() {
              return finalEmailDomains;
            },
          },
        });
      },
    });
  }

  return (
    <Modal
      header="Delete email domain"
      onClose={onCloseModal}
      isOpen={isOpen}
      modalSize={ModalSize.SMALL}
      buttonsProps={{
        onConfirmButtonClick: !loading ? handleConfirmDeletion : () => {},
        confirmButtonChildren: 'Delete',
        confirmButtonProps: {
          loading: loading,
        },
      }}
    >
      <section className={styles.modalContainer}>
        <div className={styles.domainInfo}>
          <span className={styles.domainTitle}>Email domain:</span>
          <span>{domain}</span>
        </div>
        <InlineNotification
          notificationType={NotificationType.WARNING}
          className={styles.notification}
        >
          <p>
            Deleting this email domain will prevent clients from inviting new
            company users with this domain.
          </p>
        </InlineNotification>
      </section>
    </Modal>
  );
}
