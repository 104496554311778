import { makeStyles } from '@mui/styles';

const useCompanyRequirementsFormBuilderFormOrderColumnStyle = makeStyles(
  () => ({
    droppable: {
      border: '1px solid black',
      minHeight: 200,
      margin: '0 6px',
      width: '100%',
      borderRadius: 7,
    },
    titleRow: {
      marginBottom: 10,
      marginTop: 5,
    },
  })
);

export default useCompanyRequirementsFormBuilderFormOrderColumnStyle;
