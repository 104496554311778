import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import {
  CompanyEmployeeModel,
  useCompanyMaybeUserEmployeeFindManyQuery,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  Loader,
  Modal,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import EditHRManagerEmployeeIDForm from './EditHRManagerEmployeeIDForm';

export interface IEditHRManagerEmployeeIDModal {
  hrManagerEmail: string;
  hrManagerCompanyId: number;
  onCompleted?: (hrManager: CompanyEmployeeModel) => void;
}

const EditHRManagerEmployeeIDModal: FC<IEditHRManagerEmployeeIDModal> = ({
  hrManagerEmail,
  hrManagerCompanyId,
  onCompleted,
}) => {
  const { handleMsgType } = useNotification();
  const companyMaybeUserEmployeeFindManyQueryResult = useCompanyMaybeUserEmployeeFindManyQuery(
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      variables: {
        companyId: hrManagerCompanyId,
      },
    }
  );

  const hrManagerEmployee = companyMaybeUserEmployeeFindManyQueryResult.data?.CompanyMaybeUserEmployeeFindMany?.find(
    employee => employee.email === hrManagerEmail
  );

  return (
    <Modal
      isOpen
      onClose={() => closeModal(ModalTypes.EDIT_HR_MANAGER_EMPLOYEE_ID_MODAL)}
      className="edit-hr-manager-employee-id-modal"
      header="Update manager employeeId"
    >
      {hrManagerEmployee ? (
        <EditHRManagerEmployeeIDForm
          hrManagerEmployee={hrManagerEmployee}
          hrManagerCompanyId={hrManagerCompanyId}
          onCompleted={onCompleted}
        />
      ) : (
        <div className="edit-hr-manager-employee-id-modal__loader-wrapper">
          <Loader variant="bubbles" />
        </div>
      )}
    </Modal>
  );
};

export default EditHRManagerEmployeeIDModal;
