import { FC } from 'react';
import { useMutation } from '@apollo/client';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../../components/atoms/Button/Button';
import {
  FIELD_UPDATE_MUTATION,
  FIELDS_QUERY,
} from '../../../../../../graphql/form/field';
import { FieldsFragments } from '../../../../../../graphql/form/fragments';
import {
  IFieldsQueryInput,
  IFieldsQueryResponse,
  IFieldUpdateMutationInput,
  IFieldUpdateMutationResponse,
  TFieldGQL,
} from '../../../../../../interfaces/forms/formGQL';

interface IUnarchiveCandidateInfoFieldButton {
  candidateInformationFieldId: number;
  companyId?: number;
}

const UnarchiveCandidateInfoFieldButton: FC<IUnarchiveCandidateInfoFieldButton> = ({
  candidateInformationFieldId,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const [updateCandidateInformationFieldMutation] = useMutation<
    IFieldUpdateMutationResponse,
    IFieldUpdateMutationInput
  >(FIELD_UPDATE_MUTATION, {
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      if (data?.field) {
        try {
          const { field } = data;
          const notArchivedFieldsCacheData = cache.readQuery<
            IFieldsQueryResponse,
            IFieldsQueryInput
          >({
            query: FIELDS_QUERY,
            variables: {
              isArchived: false,
            },
          });
          if (notArchivedFieldsCacheData) {
            cache.writeQuery<IFieldsQueryResponse, IFieldsQueryInput>({
              query: FIELDS_QUERY,
              data: {
                fields: [...notArchivedFieldsCacheData.fields, field],
              },
              variables: {
                isArchived: false,
              },
            });
          }
        } catch (error) {}

        try {
          const cacheFieldData = cache.readFragment<TFieldGQL>({
            id: cache.identify(data.field),
            fragment: FieldsFragments.field(data.field),
          });

          if (cacheFieldData) {
            cache.writeFragment<TFieldGQL>({
              id: cache.identify(data.field),
              fragment: FieldsFragments.field(data.field),
              data: {
                ...cacheFieldData,
                ...data.field,
              },
            });
          }
        } catch (error) {}
      }
    },
  });

  const handleUnarchiveCandidateInfoField = () => {
    updateCandidateInformationFieldMutation({
      variables: {
        id: candidateInformationFieldId,
        isArchived: false,
      },
    });
  };

  return <Button onClick={handleUnarchiveCandidateInfoField}>Unarchive</Button>;
};

export default UnarchiveCandidateInfoFieldButton;
