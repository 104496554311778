import Icon from 'components/atoms/Icon';
import {
  ProjectRequestStatus,
  useProjectRequestUpdateOneMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import {
  components,
  GroupBase,
  OnChangeValue,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import {
  TNotification,
  useNotification,
  Select,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<TSelectOption, false, GroupBase<TSelectOption>>) => {
  const { additionalProps, label } = props.data;

  return (
    <components.SingleValue {...props}>
      <div
        className={`project-request-status-select__selected-value-label project-request-status-select__selected-value-label_color_${additionalProps?.color}`}
      >
        {additionalProps?.icon && <Icon icon={additionalProps.icon} />} {label}
      </div>
    </components.SingleValue>
  );
};

const Option = (
  props: OptionProps<TSelectOption, false, GroupBase<TSelectOption>>
) => {
  if (!props.data) {
    return null;
  }

  const { additionalProps, label } = props.data;

  return (
    <components.Option {...props}>
      <div
        className={`project-request-status-select__option-label project-request-status-select__option-label_color_${additionalProps?.color}`}
      >
        {additionalProps?.icon && <Icon icon={additionalProps.icon} />}
        {label}
      </div>
    </components.Option>
  );
};

export const options: TSelectOption[] = [
  {
    value: ProjectRequestStatus.RequiresProject,
    label: 'Requires Project',
    additionalProps: {
      color: 'purple',
      icon: 'timelapse',
    },
  },
  {
    value: ProjectRequestStatus.JobSpecsRecieved,
    label: 'Job Specification Received',
    additionalProps: {
      color: 'grey',
      icon: 'playlist_add_check',
    },
  },
  {
    value: ProjectRequestStatus.ProjectCreated,
    label: 'Project Created',
    additionalProps: {
      color: 'blue',
      icon: 'done_all',
    },
  },
  {
    value: ProjectRequestStatus.ConfirmMatch,
    label: 'Confirm Match',
    additionalProps: {
      color: 'green',
      icon: 'check_circle',
    },
  },
];

interface ProjectRequestStatusSelectProps {
  projectRequestId: number;
  projectRequestStatus: ProjectRequestStatus;
}

const ProjectRequestStatusSelect: FC<ProjectRequestStatusSelectProps> = ({
  projectRequestId,
  projectRequestStatus,
}) => {
  const { handleMsgType } = useNotification();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(
    options.find(option => option.value === projectRequestStatus)
  );

  const [projectRequestUpdateOne] = useProjectRequestUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleChange = (newValue: OnChangeValue<TSelectOption, false>) => {
    if (newValue) {
      setValue(newValue);
      projectRequestUpdateOne({
        variables: {
          status: newValue.value as ProjectRequestStatus,
          id: projectRequestId,
        },
      });
    }
  };

  return (
    <Select
      className="project-request-status-select"
      components={{ Option, SingleValue }}
      options={options}
      onChange={handleChange}
      value={value}
      id="status"
      hasIndicator={false}
      maxMenuHeight={300}
    />
  );
};

export default ProjectRequestStatusSelect;
