import Icon from 'components/atoms/Icon';
import { urlTypeformLinkValidationRegex } from 'constants/validation';
import { Form, Formik } from 'formik';
import {
  useProjectRequestFindOneQuery,
  useProjectRequestUpdateManagerSurveyMutation,
} from 'generated/graphql';
import { copyTextToClipboard } from 'helpers/clipboard';
import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  InlineNotification,
  NotificationType,
  Button,
  ModalButtons,
  TextInputField,
} from '@spotted-zebra-uk/ui-components';
import styles from './ProjectRequestManagerSurveyForm.module.scss';

interface IProjectRequestManagerSurveyFormPresentational {
  onSubmit: (values: { url: string }) => void | Promise<void>;
  onCancel: () => void;
  createLinkLoading?: boolean;
  surveyLinkTypeform?: string;
  surveyLinkCompany?: string;
}

interface FormValues {
  url: string;
}

const COMPANY_APP_URL =
  process.env.REACT_APP_COMPANY_APP_BASE_URL ||
  'https://dev2-company.spottedzebra.co.uk';

const TYPEFORM_URL = 'https://spottedzebra.typeform.com/to';

const ProjectRequestManagerSurveyFormPresentational: FC<IProjectRequestManagerSurveyFormPresentational> = ({
  onSubmit,
  onCancel,
  createLinkLoading,
  surveyLinkTypeform = '',
  surveyLinkCompany,
}) => {
  const [linkFreshlyCopied, setLinkFreshlyCopied] = useState(false);

  function handleCopyLink() {
    !linkFreshlyCopied &&
      surveyLinkCompany &&
      copyTextToClipboard(surveyLinkCompany);
    setLinkFreshlyCopied(true);

    setTimeout(() => {
      setLinkFreshlyCopied(false);
    }, 3000);
  }

  return (
    <Formik
      initialValues={{ url: surveyLinkTypeform }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={true}
    >
      {formik => (
        <div className="project-request-manager-survey-form">
          <div className="project-request-manager-survey-form__fields">
            <Form>
              <label htmlFor="url" className={styles.formFieldTitle}>
                Form to be shared
              </label>
              <div className={styles.inlineFormFields}>
                <TextInputField
                  id="url"
                  name="url"
                  label="Typeform link"
                  placeholder="Typeform link"
                  data-testid="admin-hm-form-link"
                  hasError={Boolean(formik.errors.url)}
                  bottomText={formik.errors.url}
                  value={formik.getFieldProps('url').value}
                  // explicitly setting touched for auto-validation
                  onChange={(value: string) => {
                    formik.setTouched({ url: true }, true);
                    formik.setFieldValue('url', value, true);
                  }}
                />

                <Button
                  variant="tertiary"
                  type="submit"
                  loading={createLinkLoading}
                >
                  {createLinkLoading ? 'Creating link' : 'Create link'}
                </Button>
              </div>
            </Form>
            {surveyLinkCompany ? (
              <div>
                <p className={styles.shareLinkTitle}>Share this link</p>
                <div className={styles.shareLinkWrapper}>
                  <p
                    className={styles.sharableLink}
                    data-testid="admin-hm-form-link-generated"
                  >
                    {surveyLinkCompany}
                  </p>

                  <Button
                    leftIcon={<Icon icon="copy" />}
                    variant="tertiary"
                    onClick={handleCopyLink}
                    data-testid="admin-hm-form-copy-button"
                  >
                    {linkFreshlyCopied ? 'Link copied' : 'Copy'}
                  </Button>
                </div>
              </div>
            ) : (
              <InlineNotification
                notificationType={NotificationType.NEUTRAL}
                className={styles.inlineNotification}
              >
                <p>
                  Add a Typeform survey to make it available in the company app.
                </p>
              </InlineNotification>
            )}
          </div>
          <ModalButtons
            onConfirmButtonClick={onCancel}
            confirmButtonChildren="Done"
            confirmButtonProps={{
              type: 'button',
              disabled: createLinkLoading,
              size: 'large',
            }}
            hasCancelButton={false}
          />
        </div>
      )}
    </Formik>
  );
};

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .required('Please add a Typeform link to generate a shareable survey.')
    .matches(
      urlTypeformLinkValidationRegex,
      'Please add a valid Typeform link.'
    ),
});

interface IProjectRequestManagerSurveyForm {
  closeModal: () => void;
  projectRequestId: number;
}

const ProjectRequestManagerSurveyForm: FC<IProjectRequestManagerSurveyForm> = ({
  closeModal,
  projectRequestId,
}) => {
  const [companyAppSurveyUrl, setCompanyAppSurveyUrl] = useState('');
  const [typeformSurveyUrl, setTypeformSurveyUrl] = useState('');
  const [
    updateManagerSurvey,
    { loading: updateManagerSurveyLoading },
  ] = useProjectRequestUpdateManagerSurveyMutation();

  const { data: projectRequestData } = useProjectRequestFindOneQuery({
    variables: {
      id: projectRequestId,
    },
  });

  const surveyId = projectRequestData?.ProjectRequestFindOne?.managerSurveyId;

  useEffect(() => {
    if (surveyId) {
      handleCompanyAppUrl(surveyId);
      handleTypeformUrl(surveyId);
    }
  }, [surveyId]);

  function handleCompanyAppUrl(surveyId: string) {
    setCompanyAppSurveyUrl(`${COMPANY_APP_URL}/survey?id=${surveyId}`);
  }

  function handleTypeformUrl(surveyId: string) {
    setTypeformSurveyUrl(`${TYPEFORM_URL}/${surveyId}`);
  }

  async function handleSubmit(values: FormValues) {
    const surveyId = values.url.split('/').pop();

    if (surveyId) {
      try {
        await updateManagerSurvey({
          variables: {
            id: projectRequestId,
            managerSurveyId: surveyId,
          },
        });

        handleCompanyAppUrl(surveyId);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <ProjectRequestManagerSurveyFormPresentational
      onSubmit={handleSubmit}
      onCancel={closeModal}
      surveyLinkTypeform={typeformSurveyUrl}
      surveyLinkCompany={companyAppSurveyUrl}
      createLinkLoading={updateManagerSurveyLoading}
    />
  );
};

export default ProjectRequestManagerSurveyForm;
