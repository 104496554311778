import { SoftSkill } from 'generated/graphql';
import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import SoftSkillsListItem from './SoftSkillsListItem/SoftSkillsListItem';
import useSoftSkillsListStyle from './SoftSkillsListStyle';

interface ISoftSkillsList {
  softSkills: SoftSkill[];
}

const SoftSkillsList: FC<ISoftSkillsList> = ({ softSkills }) => {
  const classes = useSoftSkillsListStyle();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        pt={2}
      >
        <Grid xs={1} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Soft Skill ID</Typography>
        </Grid>
        <Grid xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Name</Typography>
        </Grid>
        <Grid xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Description</Typography>
        </Grid>
        <Grid xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Category</Typography>
        </Grid>
        <Grid xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Owned by Company</Typography>
        </Grid>
      </Grid>

      {softSkills.map(softSkill => (
        <SoftSkillsListItem softSkill={softSkill} key={softSkill.id} />
      ))}
    </Grid>
  );
};

export default SoftSkillsList;
