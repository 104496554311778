import Icon from 'components/atoms/Icon';
import { CompanyUsersFindManyFormikMultiselectFormField } from 'components/molecules/MultiselectFormField/CompanyUsersFindManyFormikMultiselectFormField';
import { CompanyFormikSelectFormField } from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import { Formik, useField } from 'formik';
import {
  ActorRole,
  CompanyUser,
  ProductSolution,
  ProjectCollaborator,
  ProjectFragment,
  ProjectModuleType,
  SupportedLocale,
} from 'generated/graphql';
import {
  getSelectElementLabelFromEnumValue,
  getSelectElementOptionsFromEnumObject,
} from 'helpers/getSelectElementOptionsFromEnum';
import { collaboratorsByRole, humanizeActorRole } from 'helpers/project';
import { FC, useMemo } from 'react';
import * as yup from 'yup';
import {
  EditFormWrapper,
  FormikSelectFormField,
  FormikTextInputField,
  InlineNotification,
  NotificationType,
  TMultiselectOption,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';

const validationSchema = yup.object().shape({
  projectName: yup.string().required('Project is a required field'),
  projectCompany: yup.object().shape({
    label: yup.string(),
    value: yup.string().required('Company is a required field'),
  }),
  projectHiringManagers: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  projectRecruiter: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  projectInterviewer: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  projectReskillingTeamMember: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  projectLineManager: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  projectEarlyCareersTeamMember: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  projectHeadOfPeopleTalent: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
});

export enum ProjectFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export interface IProjectOverviewFormSubmitValues {
  name: string;
  companyId: string;
  collaborators: Array<{
    userId?: number;
    groupId?: number;
    role: ActorRole | null;
  }>;
  removedCollaborators: Array<{
    userId?: number;
    groupId?: number;
    role: ActorRole | null;
  }>;
  productSolution: ProductSolution;
  projectModuleType: ProjectModuleType;
  requireSheetsExport: boolean;
}

export interface IProjectFormValues {
  projectName: string;
  projectCompany: TSelectOption;
  productSolution: TSelectOption;
  projectModuleType: TSelectOption;
  projectLocales: SupportedLocale[];
  projectUnassigned: TMultiselectOption[];
  projectHiringManagers: TMultiselectOption[];
  projectRecruiter: TMultiselectOption[];
  projectInterviewer: TMultiselectOption[];
  projectReskillingTeamMember: TMultiselectOption[];
  projectLineManager: TMultiselectOption[];
  projectEarlyCareersTeamMember: TMultiselectOption[];
  projectHeadOfPeopleTalent: TMultiselectOption[];
}

interface IPredefineCreateProjectData {
  companyId?: number;
  name?: string;
}

interface IProjectForm {
  project?: ProjectFragment;
  collaborators?: Array<ProjectCollaborator>;
  predefinedCreateProjectData?: IPredefineCreateProjectData;
  onProjectSave: (input: IProjectOverviewFormSubmitValues) => void;
  onCancel: () => void;
  saveLoading?: boolean;
  mode?: ProjectFormMode;
}

const productSolutionsOptions: TSelectOption[] = [
  { label: 'Early career hiring', value: ProductSolution.EarlyCareerHiring },
  { label: 'Learning', value: ProductSolution.Learning },
  { label: 'Professional hiring', value: ProductSolution.ProfessionalHiring },
  { label: 'Reskilling', value: ProductSolution.Reskilling },
  { label: 'Succession planning', value: ProductSolution.SuccessionPlanning },
  { label: 'Volume hiring', value: ProductSolution.VolumeHiring },
];

export const prepareCollaborators = (
  values: IProjectFormValues
): Record<string, ActorRole> => {
  return Object.fromEntries([
    ...values.projectUnassigned.map(option => [option.value, null]),
    ...values.projectHiringManagers.map(option => [
      option.value,
      ActorRole.HiringManager,
    ]),
    ...values.projectRecruiter.map(option => [
      option.value,
      ActorRole.RecruiterOrTalentAcquisitionPartner,
    ]),
    ...values.projectInterviewer.map(option => [
      option.value,
      ActorRole.Interviewer,
    ]),
    ...values.projectReskillingTeamMember.map(option => [
      option.value,
      ActorRole.ReskillingTeamMember,
    ]),
    ...values.projectLineManager.map(option => [
      option.value,
      ActorRole.LineManager,
    ]),
    ...values.projectEarlyCareersTeamMember.map(option => [
      option.value,
      ActorRole.EarlyCareerTeamMember,
    ]),
    ...values.projectHeadOfPeopleTalent.map(option => [
      option.value,
      ActorRole.HeadOfPeopleOrTalent,
    ]),
  ]);
};

export const prepareProjectFormValues = (
  values: IProjectFormValues,
  initialValues: IProjectFormValues
) => {
  const collaborators = prepareCollaborators(values);
  const previousCollaborators = prepareCollaborators(initialValues);
  return {
    name: values.projectName,
    // TODO: Fix types in Select component.
    companyId: values.projectCompany.value as string,
    collaborators: Object.entries(collaborators)
      .filter(([id, role]) => previousCollaborators[id] !== role)
      .map(([id, role]) => ({
        [`${id.split(':')[0]}Id`]: Number(id.split(':')[1]),
        role: role,
      })),
    removedCollaborators: Object.entries(previousCollaborators)
      .filter(([id]) => collaborators[id] === undefined)
      .map(([id, role]) => ({
        [`${id.split(':')[0]}Id`]: Number(id.split(':')[1]),
        role: role,
      })),
    productSolution: values.productSolution.value as ProductSolution,
    projectModuleType: values.projectModuleType.value as ProjectModuleType,
    requireSheetsExport: false,
  };
};

const getOptionForUser = (
  user: CompanyUser,
  collaborators: Record<string, ActorRole>
) => {
  const type = user.user ? 'user' : user.group ? 'group' : 'unknown';
  const id = user.user ? user.user.id : user.group ? user.group.id : '';
  const value = `${type}:${id}`;
  return {
    label: user.user
      ? `${user.user.firstName} ${user.user.lastName}`
      : user.group
      ? `${user.group.name}`
      : '',
    value,
    labelNote: user.user
      ? `${user.user.email}`
      : user.group && user.group.membersPreview
      ? `${
          user.group.membersPreview.length > 0
            ? user.group.membersPreview
                .map(member => `${member.firstName} ${member.lastName}`)
                .join(', ')
            : `${user.group.description}`
        }`
      : '',
    labelDescription:
      user && collaborators[value]
        ? `tagged as ${humanizeActorRole(collaborators[value])}`
        : user.inProjectTeam?.asGroupMember
        ? 'added to project team via groups'
        : '',
    labelCounter: user.group
      ? {
          icon: <Icon icon="groups" />,
          content: `${user.group?.membersCount || 0}`,
        }
      : undefined,
    isDisabled: user && collaborators[value],
  };
};

const ProjectOverviewForm: FC<IProjectForm> = ({
  project,
  collaborators,
  predefinedCreateProjectData,
  onProjectSave,
  onCancel,
  mode = ProjectFormMode.EDIT,
}) => {
  const projectModuleTypeOptions = useMemo(
    () =>
      getSelectElementOptionsFromEnumObject(Object(ProjectModuleType)).sort(
        (a, b) => (a.value > b.value ? 1 : -1)
      ),
    []
  );
  const initialValues = getInitialValues(
    project,
    collaborators,
    predefinedCreateProjectData
  );

  const handleSubmit = (values: IProjectFormValues) => {
    onProjectSave(prepareProjectFormValues(values, initialValues));
  };

  return (
    <EditFormWrapper
      onCancelClick={onCancel}
      title="Edit project overview"
      formId="project-overview-form"
      className="project-form"
    >
      <Formik<IProjectFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit }) => {
          const collaborators = prepareCollaborators(values);
          const companyId = Number(values.projectCompany.value);

          values.projectUnassigned = values.projectUnassigned.filter(option =>
            option.value ? !collaborators[option.value] : false
          );

          const getOptions = (users: Array<CompanyUser>) => {
            return users.map(user => getOptionForUser(user, collaborators));
          };

          return (
            <form
              id="project-overview-form"
              data-testid="project-overview-form"
              onSubmit={handleSubmit}
            >
              <h3 className="project-form__title">General</h3>
              <FormikTextInputField
                id="projectName"
                label="Project name"
                placeholder="Project name"
                name="projectName"
                data-testid="new-project--project-name"
                useFormikField={useField}
              />
              <div className="project-form__fields-row">
                <CompanyFormikSelectFormField
                  id="projectCompany"
                  label="Company"
                  placeholder="Company"
                  maxMenuHeight={200}
                  name="projectCompany"
                  data-testid="new-project--project-company"
                />
                <FormikSelectFormField
                  options={productSolutionsOptions}
                  id="productSolution"
                  label="Product solution"
                  placeholder="Product solution"
                  name="productSolution"
                  data-testid="new-project--product-solution"
                  useFormikField={useField}
                />
              </div>
              <div className="project-form__advanced-settings">
                <FormikSelectFormField
                  id="projectModuleType"
                  label="Project module type"
                  placeholder="Project module type"
                  options={projectModuleTypeOptions}
                  maxMenuHeight={200}
                  isDisabled={mode === ProjectFormMode.EDIT}
                  useFormikField={useField}
                />
              </div>

              <h3 className="project-form__title">Project team</h3>
              {mode === ProjectFormMode.EDIT &&
              values.projectUnassigned.length ? (
                <CompanyUsersFindManyFormikMultiselectFormField
                  id="projectUnassigned"
                  label="Project team members without roles"
                  placeholder="Project team members without roles"
                  companyId={companyId}
                  maxMenuHeight={200}
                  queryArgs={{ projectId: project?.id }}
                  maxSelectedElementsToShow={3}
                  name="projectUnassigned"
                  data-testid="new-project--unassigned"
                  getOptions={() => []}
                  isSearchable={false}
                />
              ) : null}
              <InlineNotification
                notificationType={NotificationType.NEUTRAL}
                className="project-form__notification"
              >
                <p>
                  Users and groups can only be tagged to one role per project.
                </p>
              </InlineNotification>
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectHiringManagers"
                label="Hiring manager"
                placeholder="Hiring manager"
                companyId={companyId}
                maxMenuHeight={200}
                queryArgs={{ projectId: project?.id }}
                maxSelectedElementsToShow={3}
                name="projectHiringManagers"
                data-testid="new-project--hiring-managers"
                getOptions={getOptions}
              />
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectRecruiter"
                label="Recruiter"
                placeholder="Recruiter"
                companyId={companyId}
                queryArgs={{ projectId: project?.id }}
                maxMenuHeight={200}
                maxSelectedElementsToShow={3}
                name="projectRecruiter"
                data-testid="new-project--recruiter"
                getOptions={getOptions}
              />
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectInterviewer"
                label="Interviewer"
                placeholder="Interviewer"
                companyId={companyId}
                queryArgs={{ projectId: project?.id }}
                maxMenuHeight={200}
                maxSelectedElementsToShow={3}
                name="projectInterviewer"
                data-testid="new-project--interviewer"
                getOptions={getOptions}
              />
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectReskillingTeamMember"
                label="Reskilling team member"
                placeholder="Reskilling team member"
                companyId={companyId}
                queryArgs={{ projectId: project?.id }}
                maxMenuHeight={200}
                maxSelectedElementsToShow={3}
                name="projectReskillingTeamMember"
                data-testid="new-project--project-reskilling-team-member"
                getOptions={getOptions}
              />
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectLineManager"
                label="Line manager"
                placeholder="Line manager"
                companyId={companyId}
                queryArgs={{ projectId: project?.id }}
                maxMenuHeight={200}
                maxSelectedElementsToShow={3}
                name="projectLineManager"
                data-testid="new-project--project-reskilling-team-member"
                getOptions={getOptions}
              />
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectEarlyCareersTeamMember"
                label="Early careers team member"
                placeholder="Early careers team member"
                companyId={companyId}
                queryArgs={{ projectId: project?.id }}
                maxMenuHeight={200}
                maxSelectedElementsToShow={3}
                name="projectEarlyCareersTeamMember"
                data-testid="new-project--project-early-careers-team-member"
                getOptions={getOptions}
              />
              <CompanyUsersFindManyFormikMultiselectFormField
                id="projectHeadOfPeopleTalent"
                label="Head of People/Talent"
                placeholder="Head of People/Talent"
                companyId={companyId}
                queryArgs={{ projectId: project?.id }}
                maxMenuHeight={200}
                maxSelectedElementsToShow={3}
                name="projectHeadOfPeopleTalent"
                data-testid="new-project--head-of-people-talent"
                getOptions={getOptions}
              />
            </form>
          );
        }}
      </Formik>
    </EditFormWrapper>
  );
};

export const getInitialValues = (
  project?: ProjectFragment,
  collaborators?: Array<ProjectCollaborator>,
  predefinedCreateProjectData?: IPredefineCreateProjectData
): IProjectFormValues => {
  let companyValue: TSelectOption = { label: '', value: '' };
  let productSolutionValue: TSelectOption = { label: '', value: '' };

  if (project?.company) {
    companyValue = {
      label: project.company.name,
      value: project.company.id.toString(),
    };
  }

  if (predefinedCreateProjectData?.companyId) {
    companyValue = { value: predefinedCreateProjectData?.companyId.toString() };
  }

  if (project?.productSolution) {
    productSolutionValue = productSolutionsOptions.find(
      option => option.value === project.productSolution
    ) as TSelectOption;
  }

  const projectLocales = project?.locales || [SupportedLocale.English];

  const collaboratorToSelectOption = (collaborator: ProjectCollaborator) => ({
    label: collaborator.user
      ? `${collaborator.user.firstName} ${collaborator.user.lastName}`
      : collaborator.group
      ? `${collaborator.group.name}`
      : '',
    value: collaborator.user
      ? `user:${collaborator.user.id}`
      : collaborator.group
      ? `group:${collaborator.group.id}`
      : '',
    labelNote: collaborator.user
      ? `${collaborator.user.email}`
      : collaborator.group
      ? `${
          collaborator.group.membersPreview.length > 0
            ? collaborator.group.membersPreview
                .map(member => `${member.firstName} ${member.lastName}`)
                .join(', ')
            : `${collaborator.group.description}`
        }`
      : '',
    labelCounter: collaborator.group
      ? {
          icon: <Icon icon="groups" />,
          content: `${collaborator.group?.membersCount || '0'}`,
        }
      : undefined,
    labelDescription: collaborator.actorRole
      ? `tagged as ${humanizeActorRole(collaborator.actorRole)}`
      : '',
  });

  const byRole = collaboratorsByRole(collaborators || []);

  return {
    projectName: project?.name || predefinedCreateProjectData?.name || '',
    projectCompany: companyValue,
    productSolution: productSolutionValue,
    projectModuleType: project?.moduleType
      ? {
          value: project?.moduleType,
          label: getSelectElementLabelFromEnumValue(project?.moduleType),
        }
      : {
          value: ProjectModuleType.Hiring,
          label: getSelectElementLabelFromEnumValue(ProjectModuleType.Hiring),
        },
    projectLocales: projectLocales,
    projectUnassigned:
      collaborators
        ?.filter(collaborators => !collaborators.actorRole)
        .map(collaboratorToSelectOption) || [],
    projectHiringManagers: (byRole[ActorRole.HiringManager] || []).map(
      collaboratorToSelectOption
    ),
    projectInterviewer: (byRole[ActorRole.Interviewer] || []).map(
      collaboratorToSelectOption
    ),
    projectReskillingTeamMember: (
      byRole[ActorRole.ReskillingTeamMember] || []
    ).map(collaboratorToSelectOption),
    projectEarlyCareersTeamMember: (
      byRole[ActorRole.EarlyCareerTeamMember] || []
    ).map(collaboratorToSelectOption),
    projectLineManager: (byRole[ActorRole.LineManager] || []).map(
      collaboratorToSelectOption
    ),
    projectHeadOfPeopleTalent: (
      byRole[ActorRole.HeadOfPeopleOrTalent] || []
    ).map(collaboratorToSelectOption),
    projectRecruiter: (
      byRole[ActorRole.RecruiterOrTalentAcquisitionPartner] || []
    ).map(collaboratorToSelectOption),
  };
};

export default ProjectOverviewForm;
