import Form from 'components/feature/jobRole/JobRoleForm/JobRoleForm';
import {
  IJobRoleFormSubmitValues,
  TJobRoleFormPredefinedValues,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import ProjectLink from 'components/feature/project/ProjectLink/ProjectLink';
import { ProjectFragment, SoftSkillFragment } from 'generated/graphql';
import { formatDate } from 'helpers/date';
import { FC } from 'react';
import styles from './EditJobRolePresentationsl.module.scss';

type TEditJobRolePresentationalProps = {
  projectPath?: string;
  project?: ProjectFragment | null;
  jobRoleCreatedAt?: string;
  jobRoleUpdatedAt?: string;
  softSkills: SoftSkillFragment[];
  initialValues: TJobRoleFormPredefinedValues;
  onSubmit: (formValues: IJobRoleFormSubmitValues) => void;
  onCancel: () => void;
};

const EditJobRolePresentational: FC<TEditJobRolePresentationalProps> = ({
  project,
  projectPath,
  jobRoleCreatedAt,
  jobRoleUpdatedAt,
  softSkills,
  initialValues,
  onSubmit,
  onCancel,
}) => {
  return (
    <div
      className={styles.container}
      data-role="edit-job-role-view"
      data-testid="edit-job-role-view"
    >
      <div className={styles.nav}>
        {projectPath && project && (
          <ProjectLink project={project} projectPath={projectPath} />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <h1 className={styles.heading}>Edit job role</h1>
          <div className={styles.datesSection}>
            {jobRoleCreatedAt && (
              <p className={styles.dateCard}>
                <span className={styles.dateCardLabel}>Created</span>
                <span className={styles.dateCardValue}>
                  {formatDate(jobRoleCreatedAt, 'dd MMM yyyy')}
                </span>
              </p>
            )}
            {jobRoleUpdatedAt && (
              <p className={styles.dateCard}>
                <span className={styles.dateCardLabel}>Edited</span>
                <span className={styles.dateCardValue}>
                  {formatDate(jobRoleUpdatedAt, 'dd MMM yyyy')}
                </span>
              </p>
            )}
          </div>
        </div>
        <Form
          softSkills={softSkills}
          predefinedValues={initialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

export default EditJobRolePresentational;
