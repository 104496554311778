import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { Button, TMultiselectOption } from '@spotted-zebra-uk/ui-components';
import { TFormValues } from '../CandidateUsage';

interface IExportResult {
  getQueryVariablesFromFormValues: (
    companies: TMultiselectOption[],
    dateFrom: string,
    dateTo: string
  ) => [number[], string, string];
  formValues: TFormValues;
  disabled: boolean;
}

const ExportResult: FC<IExportResult> = ({
  getQueryVariablesFromFormValues,
  formValues,
  disabled,
}) => {
  const handleExportResultClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const { companies, dateFrom, dateTo } = formValues;
    const [
      companyIds,
      startOfDayStartDate,
      endOfDayEndDate,
    ] = getQueryVariablesFromFormValues(companies, dateFrom, dateTo);
    openModal(ModalTypes.CANDIDATE_USAGE_MODAL, {
      companyIds,
      createdAtFrom: startOfDayStartDate,
      createdAtTo: endOfDayEndDate,
    });
  };

  return (
    <Button
      size="large"
      variant="primary"
      disabled={disabled}
      data-testid="company-projects-button-export-result"
      onClick={handleExportResultClick}
      leftIcon={<Icon icon="export_icon" />}
    >
      Export results
    </Button>
  );
};

export default ExportResult;
