import { makeStyles } from '@mui/styles';

const useFormManagerStyle = makeStyles(() => ({
  container: {
    marginTop: 20,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: 48,
    width: 150,
  },
  externalLinkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useFormManagerStyle;
