import Icon from 'components/atoms/Icon';
import {
  BehaviourFromCsvRowsCreateManyArgs,
  useBehaviourFromCsvRowsCreateManyMutation,
  useTraitSoftSkillContextFileUploadMutation,
} from 'generated/graphql';
import { useState } from 'react';
import {
  TNotification,
  useNotification,
  Button,
  FileInputField,
} from '@spotted-zebra-uk/ui-components';
import styles from './BehaviourContentBody.module.scss';
import BehaviourContentTable from './BehaviourContentTable/BehaviourContentTable';
import BehaviourUploadInlineNotification from './BehaviourUploadInlineNotification/BehaviourUploadInlineNotification';
import { ValidationStatus } from './ValidationStatus.enum';

const BehaviourContentBody = () => {
  const [fileUploadValue, setFileUploadValue] = useState<File | null>(null);
  const [customError, setCustomError] = useState<string>();
  const { handleMsgType } = useNotification();

  const [validationStatus, setValidationStatus] = useState<ValidationStatus>(
    ValidationStatus.NOT_STARTED
  );
  const [
    fileUpload,
    fileUploadResponse,
  ] = useTraitSoftSkillContextFileUploadMutation({
    context: {
      isUpload: true,
    },
    onError: error => {
      const parsedErrorMessage = error.message.includes(
        'only file with extension .csv is accepted'
      )
        ? 'Incorrect file format, please upload a csv'
        : error.message;
      setCustomError(parsedErrorMessage);
      setValidationStatus(ValidationStatus.FAILED);
    },
    onCompleted: data => {
      if (data?.TraitSoftSkillContextFileUpload.errorMessages?.length) {
        setValidationStatus(ValidationStatus.FAILED);
      } else if (data.TraitSoftSkillContextFileUpload.warnings.length) {
        setValidationStatus(ValidationStatus.WARNING);
      } else if (data?.TraitSoftSkillContextFileUpload.creates?.length) {
        setValidationStatus(ValidationStatus.SUCCESS);
      }
    },
  });

  const [
    behaviourUpload,
    behaviourUploadResponse,
  ] = useBehaviourFromCsvRowsCreateManyMutation({
    onCompleted: data => {
      setFileUploadValue(null);
      setValidationStatus(ValidationStatus.NOT_STARTED);
      handleMsgType({
        type: TNotification.success,
        message: data.BehaviourFromCsvRowsCreateMany,
      });
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
  });

  const handleValidateClick = () => {
    if (fileUploadValue) {
      fileUpload({
        variables: {
          file: fileUploadValue,
          apply: true,
        },
      });
    }
  };

  const handleConfirmUploadClick = () => {
    const uploadingRows:
      | BehaviourFromCsvRowsCreateManyArgs[]
      | undefined = fileUploadResponse.data?.TraitSoftSkillContextFileUpload?.creates?.map(
      row => ({
        internalName: row.internalName,
        level: row.level,
        negativeSummary: row.negativeSummary,
        positiveSummary: row.positiveSummary,
        softSkillId: row.softSkillId,
        traitId: row.traitId,
      })
    );
    if (uploadingRows) {
      behaviourUpload({
        variables: {
          dto: uploadingRows,
        },
      });
    }
  };

  const softSkillIds = fileUploadResponse.data?.TraitSoftSkillContextFileUpload.creates?.map(
    row => +row.softSkillId
  );
  const traitIds = fileUploadResponse.data?.TraitSoftSkillContextFileUpload.creates?.map(
    row => +row.traitId
  );

  const showBehaviourContentTable =
    validationStatus === ValidationStatus.SUCCESS ||
    validationStatus === ValidationStatus.WARNING;

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header}>Import content</div>
        {(ValidationStatus.NOT_STARTED || ValidationStatus.FAILED) && (
          <>
            <div className={styles.body}>
              Choose a CSV file to upload and validate. If you are happy with
              the validation summary, you can confirm to import the new
              behaviour content.
            </div>
            <div className={styles.upload_container}>
              <FileInputField
                label="Title"
                id="id-1"
                value={fileUploadValue}
                placeholder="Choose file"
                onChange={setFileUploadValue}
              />
              <Button
                disabled={!fileUploadValue || fileUploadResponse.loading}
                size="medium"
                onClick={handleValidateClick}
              >
                {fileUploadResponse.loading ? 'Validating...' : 'Validate'}
              </Button>
            </div>
          </>
        )}
        <BehaviourUploadInlineNotification
          validationStatus={validationStatus}
          fileName={fileUploadValue?.name || ''}
          customError={customError}
        />
      </div>
      <div>
        {showBehaviourContentTable && (
          <>
            <Button
              onClick={handleConfirmUploadClick}
              disabled={behaviourUploadResponse.loading}
              leftIcon={
                <Icon
                  icon={
                    behaviourUploadResponse.loading
                      ? 'upload_grey'
                      : 'upload_white'
                  }
                />
              }
            >
              {behaviourUploadResponse.loading
                ? 'Confirming...'
                : 'Confirm upload'}
            </Button>
            <BehaviourContentTable
              traitIds={traitIds}
              softSkillIds={softSkillIds}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BehaviourContentBody;
