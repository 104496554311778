import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { ModalTypes } from '../../enums/modalTypes';
import { useWidgetsQuery, Widget } from '../../generated/graphql';
import { openModal } from '../../graphql/modals';
import { TTextFromFieldValue } from '../../interfaces/forms/form';
import ReportHeader from '../Reports/ReportList/ReportHeader/ReportHeader';
import { headerData } from './Widgets.constant';
import WidgetsTable from './WidgetsTable/WidgetsTable';

const PWidget: FC = () => {
  const widgetsQueryResponse = useWidgetsQuery();

  const [search, setSearch] = useState<string>('');
  const [isArchivedVisible, setIsArchivedVisible] = useState<boolean>(false);
  const handleChangeSearch = (value: TTextFromFieldValue) => {
    setSearch(value);
  };

  const toggleArchivedProjectsVisibility = (value: boolean) => {
    setIsArchivedVisible(value);
  };

  const handleAddWidgetModal = () => {
    openModal(ModalTypes.ADD_WIDGET_MODAL, {});
  };

  if (widgetsQueryResponse?.data) {
    const Widgets = widgetsQueryResponse?.data.Widgets as Widget[];
    let parsedWidgets = [...Widgets];
    parsedWidgets = parsedWidgets.filter(widget => {
      if (!isArchivedVisible && widget.isArchived) return false;
      if (search)
        return [widget.name].some(name =>
          name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      return true;
    });
    return (
      <Grid container>
        <ReportHeader
          isArchivedVisible={isArchivedVisible}
          toggleArchivedProjectsVisibility={toggleArchivedProjectsVisibility}
          search={search}
          setSearch={handleChangeSearch}
          handleClick={handleAddWidgetModal}
          headerTitle="Widget list"
          buttonText="ADD WIDGET"
        />
        <WidgetsTable headerData={headerData} tableBodyData={parsedWidgets} />
      </Grid>
    );
  }
  return null;
};

export default PWidget;
