import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import { useState } from 'react';
import { RadioButton, Tooltip } from '@spotted-zebra-uk/ui-components';
import styles from './ReportsAccess.module.scss';

const TOOLTIP_CONTENT =
  'How reports will be accessible via project team emails by default when candidates complete their assessments. Note: for multi-match projects, reports will only be accessible through links.';

const MULTIMATCH_TOOLTIP_CONTENT =
  'Reports can only be accessible through links as this project is a multi-match project.';

const dataTestId = 'reports-access__radio-button';

export enum ReportsAccessTypes {
  LINKS = 'Links',
  ATTACHMENTS = 'Attachments',
}

const ReportsAccess = ({
  linksEnabled,
  onSubmit,
  hasModal,
  tooltipEnabled = true,
  disabled = false,
  isMultiMatch = false,
}: {
  linksEnabled: boolean;
  onSubmit: Function;
  hasModal?: boolean;
  handleModal?: (handleChange: (value: boolean) => void) => void;
  tooltipEnabled?: boolean;
  disabled?: boolean;
  isMultiMatch?: boolean;
}) => {
  const [areLinksEnabled, setAreLinksEnabled] = useState(
    // For multiMatch projects, attachments are disabled
    linksEnabled || isMultiMatch
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === ReportsAccessTypes.LINKS;
    if (hasModal) {
      onSubmit(() => setAreLinksEnabled(newValue));
      return;
    }
    onSubmit(newValue);
    setAreLinksEnabled(newValue);
  };

  return (
    <div className={styles.container} data-testid={`${dataTestId}-container`}>
      <div className={clsx(styles.label, disabled && styles.disabledLabel)}>
        Reports accessible via
        {tooltipEnabled && (
          <Tooltip content={TOOLTIP_CONTENT}>
            <Icon icon="info" id="tooltip-trigger" />
          </Tooltip>
        )}
      </div>
      <form className={styles.radioGroup}>
        <ReportsAccessRadioButton
          accessType={ReportsAccessTypes.LINKS}
          checked={areLinksEnabled}
          handleChange={handleChange}
          disabled={disabled}
        />
        {isMultiMatch ? (
          <Tooltip content={MULTIMATCH_TOOLTIP_CONTENT}>
            <div id="tooltip-trigger">
              <ReportsAccessRadioButton
                accessType={ReportsAccessTypes.ATTACHMENTS}
                checked={!areLinksEnabled}
                handleChange={handleChange}
                disabled={disabled}
              />
            </div>
          </Tooltip>
        ) : (
          <ReportsAccessRadioButton
            accessType={ReportsAccessTypes.ATTACHMENTS}
            checked={!areLinksEnabled}
            handleChange={handleChange}
            disabled={disabled}
          />
        )}
      </form>
    </div>
  );
};

export default ReportsAccess;

const ReportsAccessRadioButton = ({
  accessType,
  checked,
  handleChange,
  disabled,
}: {
  accessType: ReportsAccessTypes;
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) => {
  return (
    <RadioButton
      name={accessType}
      id={accessType}
      checked={checked}
      data-testid={`${dataTestId}-${accessType}`}
      onChange={handleChange}
      value={accessType}
      disabled={disabled}
    >
      {accessType}
    </RadioButton>
  );
};
