import { FC } from 'react';

interface IExternalLink {
  url: string;
  children: React.ReactNode;
}

const ExternalLink: FC<IExternalLink> = ({ url, children }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default ExternalLink;
