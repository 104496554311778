import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { Button, Checkbox } from '@spotted-zebra-uk/ui-components';
import { getFiltersCount } from '../../Projects.helpers';
import styles from './ProjectFilterModal.module.scss';
import { emptyProjectFilterFormValues } from './ProjectsFilterModal';

const ProjectsFilterModalForm = ({ onCancel }: { onCancel: () => void }) => {
  const { values, submitForm, setFieldValue } = useFormikContext<
    typeof emptyProjectFilterFormValues
  >();

  const filterCount = useMemo(() => {
    // Return undefined if the filter count is 0
    return getFiltersCount(values) || undefined;
  }, [values]);

  const handleCheck = () => {
    setFieldValue('isArchived', !values.isArchived);
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Checkbox
        label="Include archived projects"
        checked={values.isArchived}
        onChange={handleCheck}
      />

      <div className={styles.actions}>
        <div className={styles.actionsRight}>
          <Button
            type="button"
            size="medium"
            variant="secondary"
            onClick={onCancel}
            aria-label="Cancel and close filter modal"
          >
            Cancel
          </Button>
          <Button
            size="medium"
            type="submit"
            onClick={submitForm}
            aria-label={'Apply filters'}
            counter={filterCount}
            data-testid="projects-page-filter-modal-apply-button"
          >
            Apply
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ProjectsFilterModalForm;
