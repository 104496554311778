import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { TStageIndirectInvitationModal } from 'interfaces/modal';
import { FC } from 'react';
import { Button, Modal } from '@spotted-zebra-uk/ui-components';
import CopyStageIndirectInvitation from './CopyStageIndirectInvitation';

const StageIndirectInvitationModal: FC<TStageIndirectInvitationModal> = ({
  stageId,
  projectId,
}) => {
  const handleCloseModal = () => {
    closeModal(ModalTypes.STAGE_INDIRECT_INVITATION_MODAL);
  };

  return (
    <Modal
      isOpen
      onClose={handleCloseModal}
      header="Stage indirect invitation"
      className="stage-indirect-invitation-modal"
    >
      <CopyStageIndirectInvitation stageId={stageId} projectId={projectId} />
      <div className="stage-indirect-invitation-modal__actions">
        <Button onClick={handleCloseModal} size="medium" variant="secondary">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default StageIndirectInvitationModal;
