import {
  ScheduledTaskStatus,
  useFindOneRoiCsvFileExportScheduledTaskLazyQuery,
} from 'generated/graphql';
import { usePolling } from 'helpers/polling';
import { useState } from 'react';

const POLLING_TIMEOUT_ERROR_MESSAGE = 'Request timed out, please try again.';
const POLLING_FAILED_ERROR_MESSAGE = 'Request failed';

export const useROIExportPolling = (reportSubId?: string) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [findROIReport] = useFindOneRoiCsvFileExportScheduledTaskLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const status = data.FindOneROICsvFileExportScheduledTask.status;
      if (status === ScheduledTaskStatus.Completed) {
        endPolling();
        window.open(data.FindOneROICsvFileExportScheduledTask?.blob?.url);
      } else if (status === ScheduledTaskStatus.Failed) {
        setErrorMessage(
          `${POLLING_FAILED_ERROR_MESSAGE}: ${data.FindOneROICsvFileExportScheduledTask.error}`
        );
        endPolling();
      }
    },
    onError: error => {
      setErrorMessage(`${error}`);
      endPolling();
    },
  });

  const pollingFunction = () => {
    if (reportSubId) {
      findROIReport({
        variables: {
          subId: reportSubId,
        },
      });
    }
  };

  const onPollingTimeOut = () => {
    setErrorMessage(POLLING_TIMEOUT_ERROR_MESSAGE);
  };

  const { startPolling, endPolling, isPolling } = usePolling({
    func: pollingFunction,
    onTimeOut: onPollingTimeOut,
  });

  const onStartPolling = () => {
    startPolling();
    setErrorMessage('');
  };

  return {
    startPolling: onStartPolling,
    isPolling,
    errorMessage,
  };
};
