/* eslint-disable max-len */
import { FC } from 'react';
import { useMutation } from '@apollo/client';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../../components/atoms/Button/Button';
import { FormManagerType, FormType } from '../../../../../../enums/forms/form';
import { ModalTypes } from '../../../../../../enums/modalTypes';
import {
  FIELD_DELETE_MUTATION,
  FIELDS_QUERY,
} from '../../../../../../graphql/form/field';
import { GET_FORM_BUILDER_QUERY } from '../../../../../../graphql/form/formBuilder';
import { closeModal } from '../../../../../../graphql/modals';
import {
  IFieldDeleteMutationInput,
  IFieldDeleteMutationResponse,
  IFieldsQueryInput,
  IFieldsQueryResponse,
  IGetFormBulderQueryInput,
  IGetFormBulderQueryResponse,
  TFormBuilderGQL,
} from '../../../../../../interfaces/forms/formGQL';

interface IDeleteCandidateInfoFieldButton {
  candidateInfoFieldId: number;
  companyId?: number;
}

const DeleteCandidateInfoFieldButton: FC<IDeleteCandidateInfoFieldButton> = ({
  candidateInfoFieldId,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const [deleteCandidateInformationFieldMutation] = useMutation<
    IFieldDeleteMutationResponse,
    IFieldDeleteMutationInput
  >(FIELD_DELETE_MUTATION, {
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      closeModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL);
    },
    update(cache, { data }) {
      if (data) {
        try {
          const fieldsCacheData = cache.readQuery<
            IFieldsQueryResponse,
            IFieldsQueryInput
          >({
            query: FIELDS_QUERY,
          });

          if (fieldsCacheData) {
            cache.writeQuery<IFieldsQueryResponse, IFieldsQueryInput>({
              query: FIELDS_QUERY,
              data: {
                fields: fieldsCacheData.fields.filter(
                  fieldsItem => fieldsItem.id !== candidateInfoFieldId
                ),
              },
            });
          }
        } catch (error) {}

        try {
          if (companyId) {
            const formBuilderCachedData = cache.readQuery<
              IGetFormBulderQueryResponse,
              IGetFormBulderQueryInput
            >({
              query: GET_FORM_BUILDER_QUERY,
              variables: {
                formType: FormType.CI_FORM,
                formManagerType: FormManagerType.CI,
                formOwnerId: companyId,
              },
            });

            if (formBuilderCachedData) {
              const newFormBuilder: TFormBuilderGQL = {
                ...formBuilderCachedData.formBuilder,
                form: {
                  ...formBuilderCachedData.formBuilder.form,
                  formFields: formBuilderCachedData.formBuilder.form.formFields.filter(
                    formField => formField.field.id !== candidateInfoFieldId
                  ),
                },
                availableFields: formBuilderCachedData.formBuilder.availableFields.filter(
                  availableField => availableField.id !== candidateInfoFieldId
                ),
              };

              cache.writeQuery<
                IGetFormBulderQueryResponse,
                IGetFormBulderQueryInput
              >({
                query: GET_FORM_BUILDER_QUERY,
                variables: {
                  formType: FormType.CI_FORM,
                  formManagerType: FormManagerType.CI,
                  formOwnerId: companyId,
                },
                data: {
                  formBuilder: newFormBuilder,
                },
              });
            }
          }
        } catch (error) {}
      }
    },
  });

  const handleDeleteCandidateInformationField = () => {
    deleteCandidateInformationFieldMutation({
      variables: { id: candidateInfoFieldId },
    });
  };

  return (
    <Button onClick={handleDeleteCandidateInformationField} color="secondary">
      Delete
    </Button>
  );
};

export default DeleteCandidateInfoFieldButton;
