import { FC } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ISelectFormFieldOption } from '../../../../interfaces/forms/form';
import CircularLoader from '../../CircularLoader/CircularLoader';
import SelectFormField, { ISelectFormField } from '../SelectFormField';
import useSelectWithQueryStyle from './SelectWithQueryStyle';

type TStagesSelectFormFieldWrapper = {
  query: DocumentNode;
  queryOptions?: QueryHookOptions<any, OperationVariables>;
  getOptionsFromResponse: (response: any) => ISelectFormFieldOption[];
  isEmptySelectVisible?: boolean;
  selectElementProps: Omit<ISelectFormField, 'options'>;
};

type TSelectWithQuery = Omit<
  TStagesSelectFormFieldWrapper,
  'isEmptySelectVisible'
>;

const SelectWithQuery: FC<TSelectWithQuery> = ({
  query,
  queryOptions,
  getOptionsFromResponse,
  selectElementProps,
}) => {
  const { handleMsgType } = useNotification();

  const classes = useSelectWithQueryStyle();
  const queryResult = useQuery(query, {
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    ...queryOptions,
  });

  if (queryResult.loading) {
    return <CircularLoader customStyle={classes.loaderWrapper} size={30} />;
  }

  if (queryResult.error) {
    return <Typography>{queryResult.error.message}</Typography>;
  }

  if (queryResult.data) {
    const options = getOptionsFromResponse(queryResult.data);

    return <SelectFormField options={options} {...selectElementProps} />;
  }

  return null;
};

const SelectWithQueryWrapper: FC<TStagesSelectFormFieldWrapper> = ({
  isEmptySelectVisible = true,
  ...restProps
}) => {
  if (isEmptySelectVisible) {
    return <SelectWithQuery {...restProps} />;
  }

  return (
    <SelectFormField
      {...restProps.selectElementProps}
      options={[]}
      isDisabled
    />
  );
};

export default SelectWithQueryWrapper;
