export enum FormType {
  CI_FORM = 'CI_FORM',
  CD_FORM = 'CD_FORM',
  TR_FORM = 'TR_FORM',
  RS_FORM = 'RS_FORM',
  MS_EMPLOYEE_FORM = 'MS_EMPLOYEE_FORM',
  MS_MANAGER_FORM = 'MS_MANAGER_FORM',
  APPRENTICE_FORM = 'APPRENTICE_FORM',
  UNDERGRAD_FORM = 'UNDERGRAD_FORM',
  GRAD_FORM = 'GRAD_FORM',
}

export enum FormManagerType {
  CI = 'CI',
  TR = 'TALENT_REVIEW',
  RS = 'RS',
  MANAGER_SURVEY = 'MANAGER_SURVEY',
  QUALITY_OF_HIRE = 'QUALITY_OF_HIRE',
  GLOBAL = 'GLOBAL',
  APPRENTICE = 'APPRENTICE',
}

export enum FieldType {
  SINGLE_SELECT_FIELD = 'SINGLE_SELECT_FIELD',
  MULTIPLE_SELECT_FIELD = 'MULTIPLE_SELECT_FIELD',
  SHORT_TEXT_FIELD = 'SHORT_TEXT_FIELD',
  LONG_TEXT_FIELD = 'LONG_TEXT_FIELD',
  DATE_FIELD = 'DATE_FIELD',
  COMPANY_EMPLOYEE_SELECT_FIELD = 'COMPANY_EMPLOYEE_SELECT_FIELD',
}

export enum FieldInstanceType {
  SINGLETON = 'SINGLETON',
  MULTIPLE = 'MULTIPLE',
}

export enum FieldOwnerType {
  SZ_FIELD_OWNER = 'SZ_FIELD_OWNER',
  COMPANY_FIELD_OWNER = 'COMPANY_FIELD_OWNER',
}

export enum ResponseType {
  LONG_TEXT_RESPONSE = 'LONG_TEXT_RESPONSE',
  SHORT_TEXT_RESPONSE = 'SHORT_TEXT_RESPONSE',
  SINGLE_SELECT_RESPONSE = 'SINGLE_SELECT_RESPONSE',
  MULTIPLE_SELECT_RESPONSE = 'MULTIPLE_SELECT_RESPONSE',
  DATE_RESPONSE = 'DATE_RESPONSE',
}
