import { FC } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  ISelectFormFieldOption,
  TMultiSelectFormFieldValue,
  TSelectFormFieldValue,
} from '../../../interfaces/forms/form';

export interface IMultiselectFormField {
  id: string;
  name: string;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  value: TMultiSelectFormFieldValue;
  className?: string;
  isDisabled?: boolean;
  options: ISelectFormFieldOption[];
  isMulti?: boolean;
  inputProps?: InputBaseComponentProps;
}

const MultiselectFormField: FC<IMultiselectFormField> = ({
  id,
  name,
  label,
  onChange,
  onBlur,
  hasError,
  errorText,
  value,
  isDisabled,
  options,
  inputProps,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as TSelectFormFieldValue;
    onChange(newValue, name);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, name);
    }
  };

  const sortedOptions = [...options].sort((a, b) =>
    a.label < b.label ? -1 : 1
  );

  return (
    <FormControl variant="outlined" fullWidth error={hasError}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        //@ts-ignore
        value={value as string[]} //value needs to be string[] for multiple select, but typescript wants string
        style={{
          overflow: 'auto',
        }}
        onChange={handleChange}
        disabled={isDisabled}
        label={label}
        inputProps={{
          ...inputProps,
          id,
          name,
          onBlur: handleBlur,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: 216,
              width: 250,
            },
          },
        }}
      >
        {sortedOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {hasError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default MultiselectFormField;
