import {
  useCompanySignatureFindManyLazyQuery,
  useProjectSetCompanySignatureMutation,
} from 'generated/graphql';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  TNotification,
  useNotification,
  SelectFormField,
  TSelectOption,
  Checkbox,
} from '@spotted-zebra-uk/ui-components';

interface IProjectCompanySignature {
  companyId: number;
  projectId: number;
  projectCompanySignatureId: number;
}

const ProjectCompanySignature: FC<IProjectCompanySignature> = ({
  companyId,
  projectId,
  projectCompanySignatureId,
}) => {
  const { handleMsgType } = useNotification();

  const [companySignatureId, setCompanySignatureId] = useState<TSelectOption>({
    value: `${projectCompanySignatureId}`,
  });
  const [checkValues, setCheckValues] = useState(false);

  const [
    companySignatureQuery,
    companySignatureQueryResponse,
  ] = useCompanySignatureFindManyLazyQuery({
    variables: {
      companyId,
    },
    fetchPolicy: 'no-cache',
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  useEffect(() => {
    companySignatureQuery();
  }, [companySignatureQuery]);

  const options: TSelectOption[] = useMemo(() => {
    if (!companySignatureQueryResponse?.data?.CompanySignature) {
      return [];
    }

    return companySignatureQueryResponse.data.CompanySignature.map(item => ({
      label: `${item.signature.substring(
        0,
        20
      )}...${item.signatureEmail.substring(0, 10)}`,
      value: item.id.toString(),
    }));
  }, [companySignatureQueryResponse.data?.CompanySignature]);

  const defaultValue = useMemo(() => {
    if (!companySignatureQueryResponse.data?.CompanySignature) {
      return { value: '-1' };
    }

    const defaultItem = companySignatureQueryResponse.data.CompanySignature.find(
      item => item.isDefault === true
    );

    return defaultItem
      ? { value: defaultItem?.id.toString() }
      : { value: '-1' };
  }, [companySignatureQueryResponse.data?.CompanySignature]);

  useEffect(() => {
    if (projectCompanySignatureId) {
      setCompanySignatureId({ value: `${projectCompanySignatureId}` });
      setCheckValues(true);
    } else {
      setCompanySignatureId(defaultValue);
    }
  }, [defaultValue, projectCompanySignatureId]);

  const [
    projectSetCompanySignatureMutation,
  ] = useProjectSetCompanySignatureMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () =>
      handleMsgType({
        type: TNotification.success,
        message: "project's company signature updated successfully",
      }),
  });

  const handleCheckChange = useCallback(() => {
    const newCheckValue = !checkValues;

    if (!companySignatureQueryResponse.data?.CompanySignature?.length) {
      setCheckValues(false);
    } else {
      setCheckValues(() => newCheckValue);
      if (!newCheckValue) {
        projectSetCompanySignatureMutation({
          variables: {
            companySignatureId: Number(companySignatureId.value),
            projectId,
          },
        });
      } else {
        projectSetCompanySignatureMutation({
          variables: {
            projectId,
          },
          onCompleted: () => {
            companySignatureQuery();
            setCompanySignatureId(defaultValue);
          },
        });
      }
    }
  }, [
    checkValues,
    companySignatureId.value,
    companySignatureQuery,
    companySignatureQueryResponse.data?.CompanySignature?.length,
    defaultValue,
    projectId,
    projectSetCompanySignatureMutation,
  ]);

  const handleSelectFieldChange = useCallback(
    (value: TSelectOption) => {
      setCompanySignatureId(value);
      projectSetCompanySignatureMutation({
        variables: {
          companySignatureId: Number(value.value),
          projectId,
        },
      });
    },
    [projectId, projectSetCompanySignatureMutation]
  );

  return (
    <div className="project-company-signature">
      <SelectFormField
        id="defaultSignature"
        name="isDefault"
        label="Default signature"
        value={companySignatureId}
        onChange={handleSelectFieldChange}
        options={options}
        isDisabled={!checkValues || companySignatureQueryResponse.loading}
        className="project-company-signature__select"
      />
      <Checkbox
        id="projectCompanyDefaultCheck"
        name="defaultCheck"
        label="Use default"
        onChange={handleCheckChange}
        checked={!checkValues || companySignatureQueryResponse.loading}
      />
    </div>
  );
};

export default ProjectCompanySignature;
