import { CompaniesQuery } from '../../generated/graphql';

export const parseCompanies = (
  companies: CompaniesQuery['Companies'],
  search: string
): CompaniesQuery['Companies'] => {
  if (!search && companies) {
    return [...companies].sort((a, b) =>
      a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
    );
  }

  if (companies) {
    return companies
      .filter(company => {
        return company.name
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      })
      .sort((a, b) =>
        a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      );
  }
};
