import StaticContentNavigation from 'navigation/StaticContentNavigation/StaticContentNavigation';
import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { IStaticContentFiltersFormValues } from '../ModalView/StaticContentFiltersModal/StaticContentFiltersForm/StaticContentFiltersForm';
import { StaticContentContext } from './StaticContentContext/StaticContentContext';

interface IStaticContent {}

const StaticContent: FC<IStaticContent> = () => {
  const [filters, setFilters] = useState<IStaticContentFiltersFormValues>({});

  const updateFilters = (values: IStaticContentFiltersFormValues) => {
    setFilters(values);
  };

  return (
    <StaticContentContext.Provider value={{ filters, updateFilters }}>
      <StaticContentNavigation />
      <Outlet />
    </StaticContentContext.Provider>
  );
};

export default StaticContent;
