import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { Modal } from '@spotted-zebra-uk/ui-components';
import ProjectRequestJobSpecificationForm from './ProjectRequestJobSpecificationForm';

export interface IProjectRequestJobSpecificationModal {
  projectRequestId: number;
  projectRequestCompanyId: number;
}

const ProjectRequestJobSpecificationModal: FC<IProjectRequestJobSpecificationModal> = ({
  projectRequestId,
  projectRequestCompanyId,
}) => {
  const close = () => {
    closeModal(ModalTypes.PROJECT_REQUEST_JOB_SPECIFICATION_MODAL);
  };

  return (
    <Modal
      onClose={close}
      isOpen
      className="project-request-job-specification-modal"
      header="Request Job Specification"
    >
      <ProjectRequestJobSpecificationForm
        projectRequestId={projectRequestId}
        projectRequestCompanyId={projectRequestCompanyId}
        onCancelClick={close}
        onSubmit={close}
      />
    </Modal>
  );
};

export default ProjectRequestJobSpecificationModal;
