import { FC } from 'react';
import Modal from '../../../components/molecules/Modal/Modal';
import { ModalTypes } from '../../../enums/modalTypes';
import { closeModal } from '../../../graphql/modals';
import { TCandidateInfoModal } from '../../../interfaces/modal';
import useCandidateInfoFieldModalStyle from './CandidateInfoFieldModalStyle';
import CreateCandidateInfoField from './CreateCandidateInfoField/CreateCandidateInfoField';
import EditCandidateInfoField from './EditCandidateInfoField/EditCandidateInfoField';

export interface ICandidateEditInfoFieldModal extends TCandidateInfoModal {}

const CandidateEditInfoFieldModal: FC<ICandidateEditInfoFieldModal> = ({
  candidateInfoFieldId,
  companyId,
  formFilter,
}) => {
  const classes = useCandidateInfoFieldModalStyle();
  const handleCloseModal = () => {
    closeModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      {candidateInfoFieldId ? (
        <EditCandidateInfoField
          candidateInfoFieldId={candidateInfoFieldId}
          onCandidateInfoFieldUpdated={handleCloseModal}
          companyId={companyId}
        />
      ) : (
        <CreateCandidateInfoField
          onCandidateInfoFieldCreated={handleCloseModal}
          companyId={companyId}
          formFilter={formFilter}
        />
      )}
    </Modal>
  );
};

export default CandidateEditInfoFieldModal;
