import Button from 'components/atoms/Button/Button';
import { contentRoutes } from 'constants/navigation';
import { ModalTypes } from 'enums/modalTypes';
import { SoftSkillTrait } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { IStaticContentFiltersFormValues } from '../../../../../StaticContentFiltersModal/StaticContentFiltersForm/StaticContentFiltersForm';

interface IConntectedTraitsListItem {
  softSkillTrait: SoftSkillTrait;
  updateFilters: (values: IStaticContentFiltersFormValues) => void;
}

const ConntectedTraitsListItem: FC<IConntectedTraitsListItem> = ({
  softSkillTrait,
  updateFilters,
}) => {
  const navigate = useNavigate();

  const openTraitContent = () => {
    updateFilters({
      softSkills: [softSkillTrait?.softSkillId as number],
      traits: [softSkillTrait?.traitId as number],
      grades: [],
      classifications: [],
      roleLevels: [],
      //sortBy: ['softSkillName', 'traitName'],
    });
    navigate(contentRoutes.traitContent.url());
    handleCloseModal();
  };

  const openIQContent = () => {
    updateFilters({
      softSkills: [softSkillTrait?.softSkillId as number],
      traits: [softSkillTrait?.traitId as number],
      grades: [],
      classifications: [],
      roleLevels: [],
      //sortBy: ['softSkillName', 'traitName'],
    });
    navigate(contentRoutes.interviewQuestionContent.url());
    handleCloseModal();
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_SOFT_SKILL_MODAL);
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      key={softSkillTrait.id}
    >
      <Grid item xs={2}>
        <Typography>{softSkillTrait.traitName}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>{softSkillTrait.weight}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>{softSkillTrait.orientation}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Button onClick={openTraitContent}>Trait Content</Button>
      </Grid>
      <Grid item xs={3}>
        <Button onClick={openIQContent}>IQ Content</Button>
      </Grid>
    </Grid>
  );
};

export default ConntectedTraitsListItem;
