import {
  dataSourcesFieldsArray,
  getEmptyFormSoftSkillValues,
  TDataSourceField,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.helpers';
import {
  IJobRoleFormSubmitSoftSkillTraitValues,
  IJobRoleFormSubmitValues,
  TJobRoleFormPredefinedValues,
  TJobRoleFormSoftSkillTraitValues,
  TJobRoleFormSoftSkillValues,
  TTechnicalSkillValue,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import { orientationOptions } from 'components/feature/jobRole/TraitsFormItem/TraitsFormItem';
import { getValueFromCompany } from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import { appRoutes } from 'constants/navigation';
import Decimal from 'decimal.js';
import {
  CalculationMethod,
  GradingMethod,
  JobRoleFamily,
  JobRoleFragment,
  MeasurementConstructType,
  ProjectCompanyFragment,
  SoftSkillFragment,
  SuccessProfileFragment,
  SuccessProfileSoftSkillFragment,
  SuccessProfileSoftSkillTraitFragment,
  useCreateSuccessProfileMutation,
  useJobRoleFindOneQuery,
  useJobRoleUpdateOneMutation,
  useSoftSkillFindManyQuery,
  useSuccessProfileSoftSkillsOverwriteMutation,
  useSuccessProfileTechnicalSkillsOverwriteMutation,
  useUpdateSuccessProfileMutation,
} from 'generated/graphql';
import { LocationState, useGetProjectFromLocation } from 'helpers/jobRole';
import { TEditJobRolePath } from 'interfaces/routes';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import EditJobRolePresentational from './components/EditJobRolePresentational/EditJobRolePresentational';
import styles from './EditJobRole.module.scss';

const EditJobRole: FC = () => {
  const navigate = useNavigate();
  const params = useParams() as TEditJobRolePath;
  const { handleMsgType } = useNotification();

  const { projectPath, projectQueryResponse } = useGetProjectFromLocation();
  const projectJobRoleSkillsProfileId = useGetProjectJobRoleSkillsProfileFromLocation();
  const projectId = projectQueryResponse?.data?.project?.id;
  const jobRoleId = Number(params.jobRoleId);

  const [saveLoading, setSaveLoading] = useState(false);

  const defaultErrorHandler = (error?: ApolloError) => {
    handleMsgType({
      type: TNotification.error,
      message: (error as ApolloError)?.message || 'Ops, some issue occurred!',
    });

    projectPath ? navigate(projectPath) : navigate(appRoutes.projects.url());
  };

  useEffect(() => {
    if (!params.jobRoleId) defaultErrorHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.jobRoleId]);

  // Getting initial data.
  const jobRoleFindOneQueryResponse = useJobRoleFindOneQuery({
    variables: {
      id: jobRoleId,
    },
    skip: !params.jobRoleId,
    onError: defaultErrorHandler,
  });
  const jobRole = jobRoleFindOneQueryResponse.data?.JobRoleFindOne;
  const skillsProfile = useMemo(
    () =>
      jobRole?.skillsProfiles.find(
        skillsProfile => skillsProfile.id === projectJobRoleSkillsProfileId
      ),
    [jobRole?.skillsProfiles, projectJobRoleSkillsProfileId]
  );

  const softSkillsQueryResult = useSoftSkillFindManyQuery({
    variables: { isArchived: false },
    skip: !params.jobRoleId,
    onError: defaultErrorHandler,
  });

  // Initialize mutations required for submitting form values.
  const [jobRoleUpdateOneMutation] = useJobRoleUpdateOneMutation({
    fetchPolicy: 'no-cache',
  });

  const [updateSuccessProfileMutation] = useUpdateSuccessProfileMutation({
    fetchPolicy: 'no-cache',
  });

  const [createSuccessProfileMutation] = useCreateSuccessProfileMutation({
    fetchPolicy: 'no-cache',
  });

  const [
    updateTechnicalSkillsMutation,
  ] = useSuccessProfileTechnicalSkillsOverwriteMutation();

  const [
    updateSoftSkillsMutation,
  ] = useSuccessProfileSoftSkillsOverwriteMutation();

  // Helpers required for submitting form values.
  const jobRoleUpdateOne = (formValues: IJobRoleFormSubmitValues) => {
    return jobRoleUpdateOneMutation({
      variables: {
        id: Number(jobRoleId),
        qualifier: formValues.additionalInformation,
        name: formValues.jobRoleName,
        roleLevel: formValues.roleLevel,
        family: formValues.family.value as JobRoleFamily,
      },
    });
  };

  const createSkillsProfile = (formValues: IJobRoleFormSubmitValues) => {
    return createSuccessProfileMutation({
      variables: {
        roleLevel: formValues.roleLevel,
        calculationMethod: formValues.calculationMethod as CalculationMethod,
        gradingMethod: formValues.gradingMethod as GradingMethod,
        jobRoleId: jobRoleId,
      },
    });
  };

  const updateSkillsProfile = (formValues: IJobRoleFormSubmitValues) => {
    if (!projectJobRoleSkillsProfileId) return;

    return updateSuccessProfileMutation({
      variables: {
        id: projectJobRoleSkillsProfileId,
        roleLevel: formValues.roleLevel,
        calculationMethod: formValues.calculationMethod as CalculationMethod,
        gradingMethod: formValues.gradingMethod as GradingMethod,
      },
    });
  };

  const parseTraitsFormValues = (
    traits: IJobRoleFormSubmitSoftSkillTraitValues[]
  ) => {
    return traits
      .filter(traitValues => traitValues.weight)
      .map(traitValues => ({
        traitId: traitValues.traitId,
        weight: traitValues.weight,
        orientation: traitValues.orientation,
      }));
  };

  const updateSoftSkills = (
    skillsProfileId: number,
    formValues: IJobRoleFormSubmitValues
  ) => {
    const parseSoftSkillValues = formValues.softSkills.map(softSkillValues => ({
      weight: softSkillValues.weight,
      softSkillId: softSkillValues.softSkillId,
      type: softSkillValues.type,
      dataSources: softSkillValues.dataSourceValues,
      includeInCalibration: softSkillValues.includeInCalibration,
      traits: parseTraitsFormValues(softSkillValues.traitsValues),
    }));

    return updateSoftSkillsMutation({
      variables: {
        id: skillsProfileId,
        softSkills: parseSoftSkillValues,
      },
    });
  };

  const updateTechnicalSkills = (
    skillsProfileId: number,
    formValues: IJobRoleFormSubmitValues
  ) => {
    updateTechnicalSkillsMutation({
      variables: {
        id: skillsProfileId,
        technicalSkills: formValues.technicalSkills.map(
          technicalSkillValue => ({
            technicalSkillId: Number(technicalSkillValue.value),
          })
        ),
      },
    });
  };

  const handleEditJobRole = async (formValues: IJobRoleFormSubmitValues) => {
    setSaveLoading(true);

    // TODO: Remove this constraint after projectId is not required for creating success profile.
    if (!projectId) {
      setSaveLoading(false);
      return handleMsgType({
        type: TNotification.error,
        message: 'Ops, job role requires to be connected to the project!',
      });
    }

    if (!jobRole) return;

    try {
      await jobRoleUpdateOne(formValues);

      const skillsProfileRes = await (skillsProfile
        ? updateSkillsProfile(formValues)
        : createSkillsProfile(formValues));

      if (skillsProfileRes?.data?.successProfile) {
        const skillsProfileId = skillsProfileRes.data.successProfile
          .id as number;

        formValues.softSkills.length &&
          (await updateSoftSkills(skillsProfileId, formValues));

        await updateTechnicalSkills(skillsProfileId, formValues);
      }

      await jobRoleFindOneQueryResponse.refetch();

      handleMsgType({
        type: TNotification.success,
        message: 'Job role updated!',
      });

      if (projectPath) {
        navigate(projectPath);
      } else {
        setSaveLoading(false);
      }
    } catch (error) {
      setSaveLoading(false);
      handleMsgType({
        type: TNotification.error,
        message:
          (error as ApolloError)?.message ||
          'Ops, some issue occurred while creating Job Role!',
      });
    }
  };

  const handleCancel = () => {
    if (projectPath) {
      navigate(projectPath);
    }
  };

  if (
    projectQueryResponse?.loading ||
    softSkillsQueryResult.loading ||
    jobRoleFindOneQueryResponse.loading ||
    saveLoading
  ) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader variant="bubbles" />
      </div>
    );
  }

  if (
    softSkillsQueryResult.data &&
    jobRole &&
    softSkillsQueryResult.data.SoftSkillFindMany
  ) {
    const softSkills = softSkillsQueryResult.data.SoftSkillFindMany;
    const project = projectQueryResponse?.data?.project;

    const initialValues = getInitialValues({
      jobRole,
      projectCompany: project?.company,
      skillsProfile,
      softSkills,
    });
    return (
      <EditJobRolePresentational
        jobRoleCreatedAt={jobRole.createdAt}
        jobRoleUpdatedAt={jobRole.updatedAt}
        softSkills={softSkills}
        initialValues={initialValues}
        onCancel={handleCancel}
        onSubmit={handleEditJobRole}
        project={project}
        projectPath={projectPath}
      />
    );
  }

  return null;
};

function useGetProjectJobRoleSkillsProfileFromLocation() {
  const locationState = useLocation().state as LocationState;

  return locationState.projectJobRoleSkillsProfileId;
}

// Making form initial values from API data helpers. (TODO: To refactor)

function initializeTechnicalSkillsValues(
  successProfile: SuccessProfileFragment
): TTechnicalSkillValue[] {
  const techSkills = successProfile?.successProfileTechnicalSkills?.map(
    ({ technicalSkill }) => ({
      value: `${technicalSkill?.id}` || '0',
      label: technicalSkill?.name || '',
    })
  );

  return techSkills || [];
}

function initializeTraitsValues(spTrait: SuccessProfileSoftSkillTraitFragment) {
  return {
    id: spTrait.id || 0,
    traitId: spTrait.traitId || 0,
    name: spTrait.name || '',
    weight: new Decimal(spTrait.weight).times(100).toString(),
    orientation: orientationOptions[spTrait.orientation || 0],
  };
}

function initializeDataSourceValues(
  dataSourceField: TDataSourceField,
  successProfileSoftSkill: SuccessProfileSoftSkillFragment
) {
  const dataSourcePredefinedData = successProfileSoftSkill.successProfileSoftSkillDataSources?.find(
    predefinedDataSource =>
      predefinedDataSource.dataSourceType === dataSourceField.type
  );

  return dataSourcePredefinedData
    ? {
        isIncluded: true,
        softSkillType: {
          value: dataSourcePredefinedData.softSkillType,
        },
        name: dataSourceField.name,
        type: dataSourceField.type,
      }
    : { ...dataSourceField, name: dataSourceField.name };
}

function initializeSoftSkillsValues(
  successProfile: SuccessProfileFragment,
  softSkills: SoftSkillFragment[]
): TJobRoleFormSoftSkillValues[] {
  const parsedSoftSkills: TJobRoleFormSoftSkillValues[] =
    successProfile.successProfileSoftSkills?.map(spSoftSkill => {
      const softSkill = softSkills.find(
        ss => ss.id === spSoftSkill.softSkillId
      );
      /**
       * we don't want users to edit traits in right for us, right for the role and right for the future soft skills.
       * These traits are picked by backend implicitly
       */

      const traitsValues: TJobRoleFormSoftSkillTraitValues[] =
        softSkill?.measurementConstructType ===
        MeasurementConstructType.CalculatedOnSsSummary
          ? []
          : spSoftSkill.successProfileSoftSkillTraits?.map(spTrait =>
              initializeTraitsValues(spTrait)
            ) || [];

      const dataSourceValues = dataSourcesFieldsArray.map(dataSourceField =>
        initializeDataSourceValues(dataSourceField, spSoftSkill)
      );

      return {
        softSkillId: { value: `${spSoftSkill.softSkillId}` },
        name: spSoftSkill.name,
        type: { value: spSoftSkill.type },
        weight: new Decimal(spSoftSkill.weight).times(100).toString(),
        traitsValues,
        dataSourceValues,
        includeInCalibration: spSoftSkill.includeInCalibration
          ? { value: 'yes' }
          : { value: 'no' },
      };
    }) || [];

  return [...parsedSoftSkills, getEmptyFormSoftSkillValues()];
}

type TGetInitialValuesInput = {
  jobRole: JobRoleFragment;
  projectCompany?: ProjectCompanyFragment | null;
  skillsProfile?: SuccessProfileFragment;
  softSkills: SoftSkillFragment[];
};

function getInitialValues({
  jobRole,
  projectCompany,
  skillsProfile,
  softSkills,
}: TGetInitialValuesInput): TJobRoleFormPredefinedValues {
  return {
    customWeights: true,
    jobRoleName: jobRole.name,
    roleLevel: { value: jobRole.roleLevel },
    company: projectCompany ? getValueFromCompany(projectCompany) : undefined,
    additionalInformation: jobRole.qualifier || '',
    family: { value: jobRole.family || '' },
    softSkills: skillsProfile
      ? initializeSoftSkillsValues(skillsProfile, softSkills)
      : undefined,
    technicalSkills: skillsProfile
      ? initializeTechnicalSkillsValues(skillsProfile)
      : undefined,
  };
}

export default EditJobRole;
