import React from 'react';
import styles from './BehaviourContent.module.scss';
import BehaviourContentHeader from './BehaviourContentHeader/BehaviourContentHeader';
import BehaviourContentBody from './BehaviourContentBody/BehaviourContentBody';

const BehaviourContent = () => {
  return (
    <div className={styles.container}>
      <BehaviourContentHeader />
      <BehaviourContentBody />
    </div>
  );
};

export default BehaviourContent;
