import { FC } from 'react';
import { gradeOptions } from '../../../../constants/grade';
import {
  TFormFieldValue,
  TMultiSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

export interface IGradeFilterMultiselectFormField {
  id: string;
  name: string;
  value: TMultiSelectFormFieldValue;
  label: string;
  onChange: (value: TFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
}

const GradeFilterMultiselectFormField: FC<IGradeFilterMultiselectFormField> = props => {
  return (
    <MultiselectFormField
      {...props}
      inputProps={{
        'data-role': 'grade-filter-select',
      }}
      options={gradeOptions}
    />
  );
};

export default GradeFilterMultiselectFormField;
