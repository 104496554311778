import { makeStyles } from '@mui/styles';

const useFileUploadFormFieldStyle = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  inputButton: {
    pointerEvents: 'none',
  },
  inputWrapper: {
    display: 'flex',
  },
  input: {
    display: 'none !important',
  },
  typography: {
    marginLeft: 5,
    height: 33,
    overflow: 'auto',
    width: '100%',
  },
  clearButton: {
    height: 48,
    width: 48,
    minWidth: 48,
    borderRadius: '50%',
    marginLeft: 8,
  },
  label: {
    marginTop: '-5px',
    flex: 1,
    '& > div': {
      width: '100%',
    },
  },
}));

export default useFileUploadFormFieldStyle;
