import { ModalTypes } from 'enums/modalTypes';
import { FieldArray, Formik, useField } from 'formik';
import {
  ProjectRequestFindOneDocument,
  ProjectRequestUserFindManyDocument,
  RelationDepth,
  useProjectRequestUserApprovalCreateManyMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC, Fragment, useMemo } from 'react';
import { useGetProjectRequestUsers } from 'views/ProjectRequests/ProjectRequest/helpers';
import * as yup from 'yup';
import {
  Loader,
  TNotification,
  useNotification,
  FormikCheckboxFormField,
  ModalButtons,
} from '@spotted-zebra-uk/ui-components';

const validationSchema = yup.object().shape({
  selectedProjectRequestUsers: yup
    .array()
    .compact(value => {
      return !value.isSelected;
    })
    .min(1, 'Please select at least one user.'),
});

interface IProjectRequestApprovalFormValues {
  selectedProjectRequestUsers: {
    projectRequestUserId: number;
    isSelected: boolean;
  }[];
}

interface IProjectRequestApprovalForm {
  projectRequestId: number;
  projectRequestCompanyId: number;
}

const ProjectRequestApprovalForm: FC<IProjectRequestApprovalForm> = ({
  projectRequestCompanyId,
  projectRequestId,
}) => {
  const { handleMsgType } = useNotification();
  const [
    projectRequestUserApprovalCreateManyMutation,
  ] = useProjectRequestUserApprovalCreateManyMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: ProjectRequestFindOneDocument,
        variables: { id: projectRequestId },
      },
      {
        query: ProjectRequestUserFindManyDocument,
        variables: {
          projectRequestId: projectRequestId,
          relationDepth: RelationDepth.Full,
        },
      },
    ],
  });
  const { projectRequestUsers, loading, error } = useGetProjectRequestUsers(
    projectRequestId,
    projectRequestCompanyId
  );

  const initialValues = useMemo(
    () => ({
      selectedProjectRequestUsers: projectRequestUsers.map(
        projectRequestUser => ({
          projectRequestUserId: projectRequestUser.id,
          isSelected: false,
        })
      ),
    }),
    [projectRequestUsers]
  );

  const handleSubmit = async (values: IProjectRequestApprovalFormValues) => {
    const dto = values.selectedProjectRequestUsers
      .filter(prUser => prUser.isSelected)
      .map(prUser => ({ projectRequestUserId: prUser.projectRequestUserId }));

    try {
      await projectRequestUserApprovalCreateManyMutation({
        variables: { dto },
      });
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const close = () => {
    closeModal(ModalTypes.PROJECT_REQUEST_APPROVAL_MODAL);
  };

  if (loading) {
    return <Loader variant="bubbles" />;
  }

  if (error) {
    return null;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <div className="project-request-approval-form">
          <div className="project-request-approval-form__fields">
            <FieldArray name="selectedProjectRequestUsers">
              {() => (
                <>
                  {projectRequestUsers.map((projectRequestUser, index) => (
                    <Fragment key={projectRequestUser.id}>
                      <FormikCheckboxFormField
                        id={`selectedProjectRequestUsers.${index}.isSelected`}
                        label={
                          <div className="project-request-approval-form__checkbox-label">
                            {projectRequestUser.companyEmployee?.firstName}{' '}
                            {projectRequestUser.companyEmployee?.lastName}{' '}
                            <span className="project-request-approval-form__checkbox-label-note">
                              {projectRequestUser.companyEmployee?.email}
                            </span>
                          </div>
                        }
                        useFormikField={useField}
                      />
                    </Fragment>
                  ))}
                  <div className="project-request-approval-form__fields-array-error">
                    {errors.selectedProjectRequestUsers?.toString() &&
                      errors.selectedProjectRequestUsers?.toString()}
                  </div>
                </>
              )}
            </FieldArray>
          </div>
          <ModalButtons
            onCancelButtonClick={close}
            onConfirmButtonClick={() => handleSubmit()}
            confirmButtonChildren="Submit"
            cancelButtonChildren="Close"
            confirmButtonProps={{ type: 'button', disabled: isSubmitting }}
            cancelButtonProps={{ type: 'button' }}
          />
        </div>
      )}
    </Formik>
  );
};

export default ProjectRequestApprovalForm;
