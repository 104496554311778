import GridContainer from 'components/atoms/GridContainer/GridContainer';
import { ICompanyPath } from 'interfaces/routes';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import CompanyApiKeysHeader from './CompanyApiKeysHeader/CompanyApiKeysHeader';
import CompanyApiKeysList from './CompanyApiKeysList/CompanyApiKeysList';

const CompanyApiKeys: FC = () => {
  const { companyId } = useParams() as ICompanyPath;
  const parsedCompanyId = +companyId;

  return (
    <GridContainer>
      <CompanyApiKeysHeader companyId={parsedCompanyId} />
      <CompanyApiKeysList companyId={parsedCompanyId} />
    </GridContainer>
  );
};

export default CompanyApiKeys;
