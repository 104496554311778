import './styles.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { Modal } from '@spotted-zebra-uk/ui-components';
import { ProjectRequestApprovalStatusForm } from './ProjectRequestApprovalStatusForm';

export interface IProjectRequestApprovalStatusModal {
  projectRequestId: number;
  projectRequestCompanyId: number;
}

const ProjectRequestApprovalStatusModal: FC<IProjectRequestApprovalStatusModal> = ({
  projectRequestId,
  projectRequestCompanyId,
}) => {
  const handleClose = () => {
    closeModal(ModalTypes.PROJECT_REQUEST_APPROVAL_STATUS_MODAL);
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      className="project-request-approval-status-modal"
      header="Who approved?"
    >
      <ProjectRequestApprovalStatusForm
        projectRequestId={projectRequestId}
        projectRequestCompanyId={projectRequestCompanyId}
      />
    </Modal>
  );
};

export default ProjectRequestApprovalStatusModal;
