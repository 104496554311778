import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import CompanySelectFormField from '../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IgInterviewGuidesDocument,
  IgInterviewGuidesQuery,
  useIgInterviewGuideCreateMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { TAddInterviewGuideModalProps } from '../../../interfaces/modal';
import { useInterviewGuideModalStyle } from './AddInterviewGuideModalStyle';

interface IAddInterviewGuidePayload {
  interviewGuideName: string;
  ownerId: TSelectFormFieldValue;
  company: string;
}
interface IInterviewGuideError {
  interviewGuideName: string;
  company: string;
}

const AddInterviewGuideModal: FC<TAddInterviewGuideModalProps> = props => {
  const { handleMsgType } = useNotification();
  const classes = useInterviewGuideModalStyle();

  const [createInterviewGuideMutation] = useIgInterviewGuideCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const newInterviewGuide = data?.IgInterviewGuideCreate;
      const interviewGuidesData = cache.readQuery<IgInterviewGuidesQuery>({
        query: IgInterviewGuidesDocument,
      });
      if (newInterviewGuide && interviewGuidesData?.IgInterviewGuides) {
        cache.writeQuery<IgInterviewGuidesQuery>({
          query: IgInterviewGuidesDocument,
          data: {
            IgInterviewGuides: [
              newInterviewGuide,
              ...interviewGuidesData.IgInterviewGuides,
            ],
          },
        });
      }
    },
    onCompleted: () => {
      closeModal(ModalTypes.ADD_INTERVIEW_GUIDE_MODAL);
    },
  });

  const { name, ownerId } = props;
  const [interviewGuideDetails, setInterviewGuideDetails] = useState<
    IAddInterviewGuidePayload
  >({
    interviewGuideName: name,
    ownerId: ownerId,
    company: '',
  });
  const [errors, setErrors] = useState<IInterviewGuideError>({
    interviewGuideName: '',
    company: '',
  });
  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_INTERVIEW_GUIDE_MODAL);
  };

  const isEmptyProps = _.isEmpty(props);

  const getInterviewGuideName = (value: string, name: string) => {
    setInterviewGuideDetails(prev => ({ ...prev, interviewGuideName: value }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, [name]: '' } : prevErrors
    );
  };

  const getCompanyFormSelection = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setInterviewGuideDetails(prev => ({
      ...prev,
      ownerId: value,
      company: name,
    }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, [name]: '' } : prevErrors
    );
  };

  const interviewGuideFormValidation = (
    newValues: IAddInterviewGuidePayload
  ) => {
    return validate(newValues, {
      interviewGuideName: {
        nonEmptyString: true,
      },
      company: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
    });
  };

  const handleSubmitReport = () => {
    const interviewGuideErrors = interviewGuideFormValidation(
      interviewGuideDetails
    );
    if (!_.isEmpty(interviewGuideErrors)) {
      return setErrors(interviewGuideErrors);
    }
    const variables = {
      name: interviewGuideDetails.interviewGuideName,
      ownerId: Number(interviewGuideDetails.ownerId),
    };
    // const duplicateVariables = {
    //   ...variables,
    //   copyReportId: Number(props.copyReportId),
    // };
    if (isEmptyProps) {
      createInterviewGuideMutation({ variables });
    } else {
      //   createDuplicateMutation({ variables: duplicateVariables });
    }
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            {`${isEmptyProps ? 'Create Interview Guide' : 'Duplicate report'}`}
          </Typography>
        </Grid>
        {!isEmptyProps && (
          <Grid>
            <Typography className={classes.textLabel}>Source report</Typography>
            <Typography className={classes.textValue}>{name}</Typography>
          </Grid>
        )}
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="interviewGuide"
            name="interviewGuideName"
            value={interviewGuideDetails.interviewGuideName}
            label="Interview Guide Name"
            onChange={getInterviewGuideName}
            hasError={!!errors?.interviewGuideName}
            errorText={errors?.interviewGuideName}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <CompanySelectFormField
            id="companyFormField"
            name="company"
            label="Company"
            value={interviewGuideDetails.ownerId}
            onChange={getCompanyFormSelection}
            hasError={!!errors?.company}
            errorText={errors?.company}
          />
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmitReport}
          >{`Create${!isEmptyProps ? ' copy' : ''}`}</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddInterviewGuideModal;
