import { capitalizeFirstLetter } from './capitalzieFirstLetter';

export const getSelectElementOptionsFromEnumObject = (
  inputEnum: { [key in string]: string }
): { value: string; label: string }[] =>
  Object.values(inputEnum).map(enumValue => ({
    value: enumValue,
    label: getSelectElementLabelFromEnumValue(enumValue),
  }));

export const getSelectElementLabelFromEnumValue = (enumValue: string) => {
  return capitalizeFirstLetter(
    enumValue.replaceAll('_', ' ').toLocaleLowerCase()
  );
};
