import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Modal from '../../../../components/molecules/Modal/Modal';
import { ModalTypes } from '../../../../enums/modalTypes';
import {
  MeasurementConstructType,
  SoftSkill,
  SoftSkillFindManyDocument,
  SoftSkillFindManyQuery,
  SoftSkillFindManyQueryVariables,
  SoftSkillsDocument,
  SoftSkillsQuery,
  SoftSkillsQueryVariables,
  useAddSoftSkillMutation,
  useSoftSkillUploadIconMutation,
} from '../../../../generated/graphql';
import { closeModal } from '../../../../graphql/modals';
import SoftSkillForm, {
  ISoftSkillFieldFormValues,
} from '../SoftSkillForm/SoftSkillForm';
import useAddSoftSkillModalStyle from './AddSoftSkillModalStyle';

export interface IAddSoftSkillModal {}

const AddSoftSkillModal: FC<IAddSoftSkillModal> = () => {
  const { handleMsgType } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const classes = useAddSoftSkillModalStyle();
  const [addSoftSkillMutation] = useAddSoftSkillMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, response) => {
      if (response.data?.softSkill) {
        try {
          const cachedFindManySoftSkillsResult = cache.readQuery<
            SoftSkillFindManyQuery,
            SoftSkillFindManyQueryVariables
          >({
            query: SoftSkillFindManyDocument,
            variables: {
              isArchived: false,
            },
          });

          if (cachedFindManySoftSkillsResult) {
            cache.writeQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: false,
              },
              data: {
                SoftSkillFindMany: [
                  ...(cachedFindManySoftSkillsResult?.SoftSkillFindMany as SoftSkill[]),
                  response.data?.softSkill,
                ],
              },
            });
          }
        } catch (error) {
          console.log(error);
        }

        try {
          const cachedSoftSkillsResult = cache.readQuery<
            SoftSkillsQuery,
            SoftSkillsQueryVariables
          >({
            query: SoftSkillsDocument,
          })?.SoftSkills as SoftSkill[];

          if (cachedSoftSkillsResult) {
            cache.writeQuery<SoftSkillsQuery, SoftSkillsQueryVariables>({
              query: SoftSkillsDocument,
              data: {
                SoftSkills: [
                  ...cachedSoftSkillsResult,
                  response.data?.softSkill,
                ],
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const [softSkillUploadMutation] = useSoftSkillUploadIconMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleSubmit = async (values: ISoftSkillFieldFormValues) => {
    const name = values.name;
    const category = values.category;
    const companyId = +values.companyId;
    const softSkillId = +values.softSkillId;
    setIsSaving(true);
    try {
      const addSoftSkillMutationResponse = await addSoftSkillMutation({
        variables: {
          name,
          category,
          companyId,
          softSkillId: softSkillId !== 0 ? softSkillId : undefined,
          measurementConstructType: values.measurementConstructType as MeasurementConstructType,
        },
      });

      if (
        addSoftSkillMutationResponse.data?.softSkill?.id &&
        values.softSkillIcon
      ) {
        await softSkillUploadMutation({
          variables: {
            id: addSoftSkillMutationResponse.data.softSkill.id,
            file: values.softSkillIcon,
          },
        });
      }
      setIsSaving(false);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_SOFT_SKILL_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <SoftSkillForm onSubmit={handleSubmit} />
      {isSaving ? <Typography>Copying...</Typography> : null}
    </Modal>
  );
};

export default AddSoftSkillModal;
