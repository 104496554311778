import {
  BasicScoreType,
  DisplayScoreType,
  MeasurementConstructType,
  SpSoftSkillSort,
  StageCsvOptions,
} from 'generated/graphql';

export const StageCsvOptionsEnum = [
  {
    label: 'Custom',
    value: StageCsvOptions.Custom,
  },
  {
    label: 'All Scores All Measures',
    value: StageCsvOptions.AllScoresAllMeasures,
  },
  {
    label: 'Percentile All Measures',
    value: StageCsvOptions.PercentileAllMeasures,
  },
  {
    label: 'Percentile Soft Skills',
    value: StageCsvOptions.PercentileSoftSkills,
  },
  {
    label: 'Percentile Category Cog',
    value: StageCsvOptions.PercentileCategoryCog,
  },
];

export const MeasurementConstructTypeEnum = [
  {
    label: 'Behavior',
    value: MeasurementConstructType.Behavior,
  },
  {
    label: 'Cognitive',
    value: MeasurementConstructType.Cognitive,
  },
  {
    label: 'Values',
    value: MeasurementConstructType.Values,
  },
  {
    label: 'Calculated On SS Summary',
    value: MeasurementConstructType.CalculatedOnSsSummary,
  },
];

export const BasicScoreTypeEnum = [
  {
    label: 'Trait',
    value: BasicScoreType.Trait,
  },
  {
    label: 'Soft Skill',
    value: BasicScoreType.SoftSkill,
  },
  {
    label: 'Success Profile',
    value: BasicScoreType.SuccessProfile,
  },
];

export const SpSoftSkillSortEnum = [
  {
    label: 'Category',
    value: SpSoftSkillSort.Category,
  },
  {
    label: 'Name',
    value: SpSoftSkillSort.Name,
  },
];

export const DisplayScoreTypeEnum = [
  {
    label: 'Z Score',
    value: DisplayScoreType.ZScore,
  },
  {
    label: 'Percentile',
    value: DisplayScoreType.Percentile,
  },
  {
    label: 'Sten Score',
    value: DisplayScoreType.StenScore,
  },
  {
    label: 'Grade',
    value: DisplayScoreType.Grade,
  },
];
