import { makeStyles } from '@mui/styles';

const useProjectCSVFormStyle = makeStyles(() => ({
  container: {
    marginTop: 20,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: 48,
    width: 150,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 205,
  },
  loaderWrapperSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
  },
  externalLinkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useProjectCSVFormStyle;
