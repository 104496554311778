import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  SoftSkillRoleLevelContent,
  useSoftSkillRoleLevelContentFindManyAndCountLazyQuery,
} from '../../../generated/graphql';
import { StaticContentContext } from '../StaticContentContext/StaticContentContext';
import SoftSkillContentHeader from './SoftSkillContentHeader/SoftSkillContentHeader';
import SoftSkillContentList from './SoftSkillContentList/SoftSkillContentList';
import useSoftSkillContentStyle from './SoftSkillContentStyle';

export interface ISoftSkillContent {}

const SoftSkillContent: FC<ISoftSkillContent> = () => {
  const { filters } = useContext(StaticContentContext);
  const classes = useSoftSkillContentStyle();

  const [
    softSkillContentQuery,
    softSkillContentQueryResponse,
  ] = useSoftSkillRoleLevelContentFindManyAndCountLazyQuery();

  useEffect(() => {
    const { softSkills, roleLevels, grades, page, take, sortBy } = filters;
    softSkillContentQuery({
      variables: {
        softSkillIds: softSkills,
        roleLevels,
        grades,
        page: page ? page : 1,
        take: take ? take : 50,
        order: sortBy ? sortBy : [],
      },
    });
  }, [filters, softSkillContentQuery]);

  if (softSkillContentQueryResponse.error) {
    return <Typography className={classes.container}>Error</Typography>;
  }

  if (softSkillContentQueryResponse.loading) {
    return (
      <Typography className={classes.container}>Loading content...</Typography>
    );
  }

  if (softSkillContentQueryResponse.data) {
    const softSkillContent =
      softSkillContentQueryResponse.data.softSkillContent;
    const count = softSkillContent.count;

    if (softSkillContent.softSkillContent) {
      return (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.container}
        >
          <SoftSkillContentHeader />
          <SoftSkillContentList
            softSkillContent={
              softSkillContent.softSkillContent as SoftSkillRoleLevelContent[]
            }
            count={count as number}
          />
        </Grid>
      );
    }
  }

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
      className={classes.container}
    >
      <SoftSkillContentHeader />
      <Typography>No content to display</Typography>
    </Grid>
  );
};

export default SoftSkillContent;
