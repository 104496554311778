import { makeStyles } from '@mui/styles';

export const useInterviewGuideCategoryModalStyle = makeStyles({
  modalPaper: {
    minWidth: 600,
    minHeight: 200,
    padding: 40,
    borderRadius: '16px',
    boxSizing: 'border-box',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  },
  btn: {
    width: 150,
  },
  closeBtn: {
    width: '100%',
  },
  textfieldContainer: {
    width: '600px',
    marginBottom: 27,
  },
  btnContainer: {
    width: '600px',
    display: 'flex',
  },
  h2: {
    fontSize: 24,
    marginBottom: 20,
  },
  textValue: {
    fontSize: 16,
    marginBottom: 20,
    color: '#000',
  },
  textLabel: {
    fontSize: 14,
    color: '#757575',
    marginBottom: 5,
  },
  p: {
    marginBottom: 10,
  },
});
