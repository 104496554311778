export enum ContentRoleLevel {
  INDIVIDUAL_CONTRIBUTOR = 'INDIVIDUAL_CONTRIBUTOR',
  MANAGER = 'MANAGER',
  MANAGER_OF_MANAGERS = 'MANAGER_OF_MANAGERS',
  LEADER = 'LEADER',
  APPRENTICE = 'APPRENTICE',
  TECHNICAL_EXPERT = 'TECHNICAL_EXPERT',
}

export enum ContentClassification {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export enum Grade {
  A_PLUS = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export enum GradeGQLInput {
  'A+' = 'A_PLUS',
  'A' = 'A',
  'B' = 'B',
  'C' = 'C',
  'D' = 'D',
  'E' = 'E',
}

export enum Orientation {
  'STANDARD' = 'STANDARD',
  'INVERTED' = 'INVERTED',
}
