import { FC } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CircularLoader from '../../../../components/molecules/CircularLoader/CircularLoader';
import {
  FIELD_QUERY,
  FIELD_UPDATE_MUTATION,
} from '../../../../graphql/form/field';
import { FieldsFragments } from '../../../../graphql/form/fragments';
import {
  IFieldQueryInput,
  IFieldQueryResponse,
  IFieldUpdateMutationInput,
  IFieldUpdateMutationResponse,
  TFieldGQL,
} from '../../../../interfaces/forms/formGQL';
import CandidateInfoFieldForm, {
  ICandidateInfoFieldFormSubmitValues,
  ICandidateInfoFieldFormValues,
} from '../CandidateInfoFieldForm/CandidateInfoFieldForm';
import EditCandidateInfoFieldHeader from './EditCandidateInfoFieldHeader/EditCandidateInfoFieldHeader';

interface IEditCandidateInfoField {
  candidateInfoFieldId: number;
  onCandidateInfoFieldUpdated: () => void;
  companyId?: number;
}

const EditCandidateInfoField: FC<IEditCandidateInfoField> = ({
  candidateInfoFieldId,
  onCandidateInfoFieldUpdated,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const getCandidateInformationFieldQueryResponse = useQuery<
    IFieldQueryResponse,
    IFieldQueryInput
  >(FIELD_QUERY, {
    variables: {
      id: candidateInfoFieldId,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [updateCandidateInformationFieldMutation] = useMutation<
    IFieldUpdateMutationResponse,
    IFieldUpdateMutationInput
  >(FIELD_UPDATE_MUTATION, {
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      onCandidateInfoFieldUpdated();
    },
    update(cache, { data }) {
      if (data?.field && data.field.__typename) {
        const cacheFieldData = cache.readFragment<TFieldGQL>({
          id: cache.identify(data.field),
          fragment: FieldsFragments.field(data.field),
        });

        if (cacheFieldData) {
          cache.writeFragment<TFieldGQL>({
            id: cache.identify(data.field),
            fragment: FieldsFragments.field(data.field),
            data: {
              ...cacheFieldData,
              ...data.field,
            },
          });
        }
      }
    },
  });

  if (getCandidateInformationFieldQueryResponse.loading) {
    return <CircularLoader />;
  }

  if (getCandidateInformationFieldQueryResponse.data) {
    const candidateInformationField =
      getCandidateInformationFieldQueryResponse.data.field;

    const handleUpdateCandidateInformationField = (
      values: ICandidateInfoFieldFormSubmitValues
    ) => {
      const { allowFreeText, searchable } = values.settings;
      updateCandidateInformationFieldMutation({
        variables: {
          id: candidateInfoFieldId,
          name: values.name,
          question: values.question,
          selectOptions: values.allowedValues
            ? JSON.stringify({
                options: values.allowedValues.split('\n'),
              })
            : undefined,
          settings: {
            allowFreeText,
            searchable,
          },
          hint: values.hint,
        },
      });
    };

    const formInitialValues: ICandidateInfoFieldFormValues = {
      name: candidateInformationField.name,
      question: candidateInformationField.question || '',
      hint: candidateInformationField.hint || '',
      settings: candidateInformationField.settings || {
        searchable: false,
        allowFreeText: false,
      },
      companyId: `${
        candidateInformationField.fieldOwnerId
          ? candidateInformationField.fieldOwnerId
          : ''
      }`,
      type: candidateInformationField.fieldType,
      instanceType: candidateInformationField.fieldInstanceType,
      formManagerType: candidateInformationField.formManagerType,
      allowedValues: candidateInformationField.selectOptions
        ? JSON.parse(candidateInformationField.selectOptions).options.join('\n')
        : '',
    };

    return (
      <Grid container direction="column" justifyContent="flex-start">
        <EditCandidateInfoFieldHeader
          candidateInformationFieldIsArchived={
            candidateInformationField.isArchived
          }
          candidateInfoFieldId={candidateInfoFieldId}
          companyId={companyId}
        />
        <CandidateInfoFieldForm
          onSubmit={handleUpdateCandidateInformationField}
          title="Edit candidate information field"
          initialValues={formInitialValues}
          isEditMode
        />
      </Grid>
    );
  }

  return null;
};

export default EditCandidateInfoField;
