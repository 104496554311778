import './styles.scss';
import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestFragment,
  useProjectRequestDeleteOneMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { StyledConfirmationModalProps } from 'interfaces/modal';
import { FC, MouseEventHandler } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IDeleteProjectRequestButton {
  projectRequestId: number;
  projectRequestName: string;
  onCompleted?: () => void;
}

export const DeleteProjectRequestButton: FC<IDeleteProjectRequestButton> = ({
  projectRequestId,
  projectRequestName,
  onCompleted,
}) => {
  const { handleMsgType } = useNotification();

  const [
    projectRequestDeleteOneMutation,
    { loading },
  ] = useProjectRequestDeleteOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      onCompleted?.();
    },
    update: cache => {
      // Removes project request model from the project requests list on the '/project-requests' page.
      cache.modify({
        fields: {
          ProjectRequestFindMany(existingProjectRequests = [], { readField }) {
            return existingProjectRequests.filter(
              (projectRequestRef: ProjectRequestFragment) =>
                projectRequestId !== readField('id', projectRequestRef)
            );
          },
        },
      });
      cache.gc();
    },
  });

  const handleDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    const confirmationModalProps: StyledConfirmationModalProps = {
      message: `Do you want to delete ${projectRequestName} project request?`,
      saveBtnText: 'Yes, I am Sure',
      cancelBtnText: 'No, Keep It',
      saveBtnColor: 'purple',
      open: true,
      onSave: () => {
        projectRequestDeleteOneMutation({
          variables: { id: projectRequestId },
        });
        closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
      },
    };

    openModal(ModalTypes.STYLED_CONFIRMATION_MODAL, confirmationModalProps);
  };

  return (
    <Button
      disabled={loading}
      variant="text"
      size="small"
      onClick={handleDeleteButtonClick}
      className="delete-project-request-button"
      rightIcon={
        <Icon
          icon="delete_outline"
          className="delete-project-request-button__icon"
        />
      }
    >
      Delete
    </Button>
  );
};
