import Modal from 'components/molecules/Modal/Modal';
import { ModalTypes } from 'enums/modalTypes';
import {
  Trait,
  TraitFragmentDoc,
  TraitsDocument,
  TraitsQuery,
  TraitsQueryVariables,
  useUpdateTraitMutation,
  useUpdateTraitTestTypesMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { TEditTraitModal } from 'interfaces/modal';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TraitForm, { ITraitFieldFormValues } from '../TraitForm/TraitForm';
import useEditTraitModalStyle from './EditTraitModalStyle';

export interface IEditTraitModal extends TEditTraitModal {}

const EditTraitModal: FC<IEditTraitModal> = ({ trait }) => {
  const { handleMsgType } = useNotification();
  const classes = useEditTraitModalStyle();
  const [editTraitMutation] = useUpdateTraitMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      if (data?.Trait) {
        cache.writeFragment<Trait>({
          id: cache.identify(data?.Trait),
          fragment: TraitFragmentDoc,
          fragmentName: 'Trait',
          data: data.Trait,
        });
      }
    },
  });

  const [updateTraitTestTypesMutation] = useUpdateTraitTestTypesMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, { data }) => {
      if (data?.traitTestTypes && data.traitTestTypes.length > 0) {
        const firstTraitTestType = data.traitTestTypes[0];
        const currentTraits = cache.readQuery<
          TraitsQuery,
          TraitsQueryVariables
        >({
          query: TraitsDocument,
          variables: {
            isArchived: false,
          },
        });
        const newTraits = currentTraits?.Traits?.map(trait =>
          trait.id === firstTraitTestType.traitId && data.traitTestTypes
            ? { ...trait, testTypes: data.traitTestTypes }
            : trait
        );
        cache.writeQuery<TraitsQuery, TraitsQueryVariables>({
          query: TraitsDocument,
          variables: {
            isArchived: false,
          },
          data: {
            Traits: newTraits,
          },
        });
      }
    },
  });

  const updateTrait = (values: ITraitFieldFormValues) => {
    const id = trait.id;
    const name = values.name ? values.name : trait.name;
    const description = values.description
      ? values.description
      : trait.description;
    editTraitMutation({
      variables: {
        id,
        isArchived: trait.isArchived,
        name,
        description,
      },
      onCompleted: data => {
        const traitId = data.Trait?.id;
        if (traitId) {
          updateTraitTestTypesMutation({
            variables: {
              dto: values.traitTestTypes.map(traitTestType => ({
                testType: traitTestType.testType,
                testSubType: traitTestType.testSubType,
                traitId,
              })),
            },
          });
        }
        handleCloseModal();
      },
    });
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_TRAIT_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <TraitForm trait={trait} onSubmit={updateTrait} isEditMode={true} />
    </Modal>
  );
};

export default EditTraitModal;
