import { makeStyles } from '@mui/styles';

const useTraitsListStyle = makeStyles(() => ({
  listHeadingGridItemWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useTraitsListStyle;
