import Button from 'components/atoms/Button/Button';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import styles from './DeleteAssessment.module.scss';

export interface IDeleteAssessmentProps {
  candidateName: string;
  projectName: string;
  candidateId: number;
  stageCandidateSubId: string;
}

const DeleteAssessment = ({
  candidateName,
  projectName,
  candidateId,
  stageCandidateSubId,
}: IDeleteAssessmentProps) => {
  const handleDeleteAssessmentModal = () => {
    const modalProps = {
      candidateName,
      projectName,
      candidateId,
      stageCandidateSubId,
    };
    openModal(ModalTypes.DELETE_ASSESSMENT_MODAL, modalProps);
  };

  return (
    <div
      className={styles.container}
      data-testid="user-details--delete-assessment"
    >
      <p>
        Remove user from project and delete associated assessment and results
      </p>
      <Button onClick={handleDeleteAssessmentModal}>Delete Assessment</Button>
    </div>
  );
};

export default DeleteAssessment;
