import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';

interface IProjectRequestActions {
  companyId: number;
  projectRequestId: number;
}

const ProjectRequestActions: FC<IProjectRequestActions> = ({
  companyId,
  projectRequestId,
}) => {
  const openProjectRequestContactModal = () => {
    openModal(ModalTypes.CREATE_PROJECT_REQUEST_CONTACT_MODAL, {
      companyId,
      projectRequestId,
    });
  };

  const openAddProjectRequestDocumentModal = () => {
    openModal(ModalTypes.ADD_PROJECT_REQUEST_DOCUMENT_MODAL, {
      companyId,
      projectRequestId,
    });
  };

  const openProjectRequestJobSpecificationModal = () => {
    openModal(ModalTypes.PROJECT_REQUEST_JOB_SPECIFICATION_MODAL, {
      projectRequestId,
      projectRequestCompanyId: companyId,
    });
  };

  const openProjectRequestManagerSurveyModal = () => {
    openModal(ModalTypes.PROJECT_REQUEST_MANAGER_SURVEY_MODAL, {
      projectRequestId,
      projectRequestCompanyId: companyId,
    });
  };

  const openAddProjectRequestSPDocumentModal = () => {
    openModal(ModalTypes.ADD_PROJECT_REQUEST_DOCUMENT_MODAL, {
      companyId,
      projectRequestId,
      fixedFileName: 'Final Success Profile',
    });
  };

  return (
    <div className="project-request-actions">
      <Button
        className="project-request-actions__add-contact-button"
        size="medium"
        variant="secondary"
        onClick={openProjectRequestContactModal}
        type="button"
        leftIcon={
          <Icon
            icon="add_black"
            className="project-request-actions__add-icon"
          />
        }
        data-testid="admin-projreq--add-contact-button"
      >
        Add contact
      </Button>
      <Button
        className="project-request-actions__add-document-button"
        size="medium"
        variant="secondary"
        onClick={openAddProjectRequestDocumentModal}
        type="button"
        leftIcon={
          <Icon
            icon="add_black"
            className="project-request-actions__add-icon"
          />
        }
        data-testid="admin-projreq--add-document-button"
      >
        Add document
      </Button>
      <Button
        className="project-request-actions__add-document-button"
        size="medium"
        variant="secondary"
        onClick={openProjectRequestJobSpecificationModal}
        type="button"
        leftIcon={
          <Icon icon="email" className="project-request-actions__email-icon" />
        }
        data-testid="admin-projreq--request-job-spec-button"
      >
        Request job specification
      </Button>
      <Button
        className="project-request-actions__manager-survey-button"
        size="medium"
        variant="secondary"
        onClick={openProjectRequestManagerSurveyModal}
        type="button"
        leftIcon={
          <Icon
            icon="add_black"
            className="project-request-actions__email-icon"
          />
        }
        data-testid="admin-projreq--add-survey-button"
      >
        Add survey
      </Button>
      <Button
        className="project-request-actions__add-document-button"
        size="medium"
        variant="secondary"
        onClick={openAddProjectRequestSPDocumentModal}
        type="button"
        leftIcon={
          <Icon
            icon="add_black"
            className="project-request-actions__add-icon"
          />
        }
        data-testid="admin-projreq--add-role-skills-profile-button"
      >
        Add role skills profile
      </Button>
    </div>
  );
};

export default ProjectRequestActions;
