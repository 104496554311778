import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestUserModel,
  ProjectRequestUserType,
  useProjectRequestUserDeleteOneMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { StyledConfirmationModalProps } from 'interfaces/modal';
import { FC, MouseEventHandler } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';
import { ContactsListItemField } from '../ContactsListItemField';
import { TContactData } from '../types';

export const roleTitles = {
  [ProjectRequestUserType.AdditionalContact]: 'Additional contact',
  [ProjectRequestUserType.Manager]: 'Manager',
  [ProjectRequestUserType.Recruiter]: 'Recruiter',
};

interface IContactsListItem {
  contact: TContactData;
}

const ContactsListItem: FC<IContactsListItem> = ({ contact }) => {
  const [
    projectRequestUserDeleteMutation,
    projectRequestUserDeleteMutationResponse,
  ] = useProjectRequestUserDeleteOneMutation({
    update: cache => {
      cache.modify({
        fields: {
          ProjectRequestUserFindMany(projectRequestUsers = [], { readField }) {
            return projectRequestUsers.filter(
              (projectRequestUserRef: ProjectRequestUserModel) =>
                contact.projectRequestUserId !==
                readField('id', projectRequestUserRef)
            );
          },
        },
      });
      cache.gc();
    },
  });

  const deleteContact = async () => {
    try {
      await projectRequestUserDeleteMutation({
        variables: { id: contact.projectRequestUserId },
      });
      closeModal(ModalTypes.STYLED_CONFIRMATION_MODAL);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    const confirmationModalProps: StyledConfirmationModalProps = {
      message: `Do you want to delete ${contact.firstName} ${contact.lastName} as a contact?`,
      saveBtnText: 'Yes, I am Sure',
      cancelBtnText: 'No, Keep It',
      saveBtnColor: 'purple',
      open: true,
      onSave: deleteContact,
    };

    openModal(ModalTypes.STYLED_CONFIRMATION_MODAL, confirmationModalProps);
  };

  return (
    <div className="contacts-list-item">
      <ContactsListItemField
        title="Role"
        className="contacts-list-item__role-select"
        content={roleTitles[contact.type]}
        id={`${contact.email}-role`}
      />
      <ContactsListItemField
        title="Employee"
        className="contacts-list-item__employee-select"
        content={`${contact.firstName} ${contact.lastName}`}
        note={contact.email}
        id={`${contact.email}-email`}
      />
      <Button
        onClick={handleDeleteButtonClick}
        size="small"
        variant="secondary"
        className="contacts-list-item__delete-button"
        disabled={projectRequestUserDeleteMutationResponse.loading}
        leftIcon={
          <Icon
            icon="delete_outline"
            className="contacts-list-item__delete-icon"
          />
        }
      ></Button>
    </div>
  );
};

export default ContactsListItem;
