import { contentRoutes } from 'constants/navigation';
import { ContentValidatiorPieceModel } from 'generated/graphql';
import {
  maxNumOfIqContent,
  maxNumOfSpContent,
  maxNumOfSsContent,
  maxNumOfTraitContent,
} from 'helpers/staticContent';
import { FC, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { StaticContentContext } from '../../../StaticContentContext/StaticContentContext';

interface IValidateListItem {
  row: ContentValidatiorPieceModel;
}

const ValidateListItem: FC<IValidateListItem> = ({ row }) => {
  const navigate = useNavigate();
  const { updateFilters } = useContext(StaticContentContext);

  const onSoftSkillContentClick = () => {
    updateFilters({
      softSkills: [row.softSkillId ? row.softSkillId : 0],
      traits: [],
      grades: [],
      classifications: [],
      roleLevels: [],
      sortBy: [],
    });

    navigate(contentRoutes.softSkillContent.url());
  };

  const onSuccessProfileContentClick = () => {
    updateFilters({
      softSkills: [row.softSkillId ? row.softSkillId : 0],
      traits: [],
      grades: [],
      classifications: [],
      roleLevels: [],
      sortBy: [],
    });

    navigate(contentRoutes.softSkillSuccessProfileContent.url());
  };

  const onTraitContentClick = () => {
    updateFilters({
      softSkills: [row.softSkillId ? row.softSkillId : 0],
      traits: [row.traitId ? row.traitId : 0],
      grades: [],
      classifications: [],
      roleLevels: [],
      //sortBy: ['softSkillName', 'traitName'],
    });

    navigate(contentRoutes.traitContent.url());
  };

  const onIQContentClick = () => {
    updateFilters({
      softSkills: [row.softSkillId ? row.softSkillId : 0],
      traits: [row.traitId ? row.traitId : 0],
      grades: [],
      classifications: [],
      roleLevels: [],
      //sortBy: ['softSkillName', 'traitName'],
    });

    navigate(contentRoutes.interviewQuestionContent.url());
  };

  /**
   * Responsible for calculating the color of interview question validate content
   * If the trait is cognitive(has 'cognitive' in it's name), then it shouldn't have any
   * IQContent, therefore we expect all zeros to be the correct value.
   * Else, we expect all rows and columns to have content in them. Ex. '30 / 30 / 30 / 30 / 30'
   */
  const getIqContentColor = (): 'primary' | 'secondary' => {
    if (
      row.traitName &&
      row.traitName.toLowerCase().indexOf('cognitive') !== -1
    ) {
      if (row.numOfIqContent === `0 / 0 / 0 / 0 / 0`) return 'primary';
    }
    if (
      row.numOfIqContent ===
      `${maxNumOfIqContent} / ${maxNumOfIqContent} / ${maxNumOfIqContent} / ${maxNumOfIqContent} / ${maxNumOfIqContent}`
    )
      return 'primary';
    return 'secondary';
  };

  // Color calculations
  const colorOfSSContentButton =
    row.numOfSsContent ===
    `${maxNumOfSsContent} / ${maxNumOfSsContent} / ${maxNumOfSsContent}`
      ? 'primary'
      : 'secondary';
  const colorOfSPContentButton =
    row.numOfSsSpContent === `${maxNumOfSpContent} / ${maxNumOfSpContent}`
      ? 'primary'
      : 'secondary';
  const colorOfTraitContentButton =
    row.numOfTraitContent ===
    `${maxNumOfTraitContent} / ${maxNumOfTraitContent}`
      ? 'primary'
      : 'secondary';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colorOfIQContentButton = useMemo(() => getIqContentColor(), [
    maxNumOfIqContent,
    row,
  ]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      pt={2}
    >
      <Grid xs={2}>
        <Typography>{row.softSkillName}</Typography>
      </Grid>
      <Grid xs={2}>
        <Typography>{row.traitName}</Typography>
      </Grid>
      <Grid xs={2}>
        <Button
          onClick={onSoftSkillContentClick}
          color={colorOfSSContentButton}
        >
          {row.numOfSsContent}
        </Button>
      </Grid>
      <Grid xs={2}>
        <Button
          onClick={onSuccessProfileContentClick}
          color={colorOfSPContentButton}
        >
          {row.numOfSsSpContent}
        </Button>
      </Grid>
      <Grid xs={2}>
        <Button onClick={onTraitContentClick} color={colorOfTraitContentButton}>
          {row.numOfTraitContent}
        </Button>
      </Grid>
      <Grid xs={2}>
        <Button onClick={onIQContentClick} color={colorOfIQContentButton}>
          {row.numOfIqContent}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ValidateListItem;
