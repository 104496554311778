import { ContentRoleLevel, JobRoleFamily } from '../generated/graphql';

export const roleLevelOptions = [
  {
    value: ContentRoleLevel.Apprentice,
    label: 'Apprentice',
  },
  {
    value: ContentRoleLevel.IndividualContributor,
    label: 'Individual contributor',
  },
  {
    value: ContentRoleLevel.Leader,
    label: 'Leader',
  },
  {
    value: ContentRoleLevel.Manager,
    label: 'Manager',
  },
  {
    value: ContentRoleLevel.ManagerOfManagers,
    label: 'Manager of managers',
  },
  {
    value: ContentRoleLevel.TechnicalExpert,
    label: 'Technical expert',
  },
];

export const jobFamilyOptions = Object.keys(JobRoleFamily).map(key => ({
  value: JobRoleFamily[key as keyof typeof JobRoleFamily],
  label: key.split(/(?=[A-Z])/).join(' '),
}));

export const includeInCalibrationOption: { label: string; value: string }[] = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];
