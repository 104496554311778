import { useField } from 'formik';
import { FC } from 'react';
import {
  FormikSelectFormField,
  FormikTextInputField,
} from '@spotted-zebra-uk/ui-components';
import styles from './TraitsFormItem.module.scss';

interface ITraitsFormItem {
  index: number;
  traitName: string;
  softSkillIndex: number;
}

export const orientationOptions = [
  { value: '0', label: 'Negative' },
  { value: '1', label: 'Positive' },
];

const TraitsFormItem: FC<ITraitsFormItem> = ({
  traitName,
  index,
  softSkillIndex,
}) => (
  <div className={styles.container}>
    <div className={styles.label}>{traitName}</div>
    <FormikTextInputField
      id={`softSkills.${softSkillIndex}.traitsValues.${index}.weight`}
      label="Weight"
      className={styles.weightInput}
      useFormikField={useField}
    />
    <FormikSelectFormField
      id={`softSkills.${softSkillIndex}.traitsValues.${index}.orientation`}
      options={[...orientationOptions].reverse()}
      label="Orientation"
      className={styles.orientationSelect}
      useFormikField={useField}
      hasClearIndicator={false}
    />
  </div>
);

export default TraitsFormItem;
