import * as yup from 'yup';

export const emailDomainsSchema = yup
  .string()
  .required('This is a required field.')
  .matches(
    /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
    `Please enter the email domain in the correct format 'name.com' without the '@' symbol.`
  );

export function validateExisting(existingEmailDomains: Array<string>) {
  return yup.object().shape({
    emailDomains: yup
      .array()
      .of(
        emailDomainsSchema.notOneOf(
          existingEmailDomains,
          'This email domain already exists.'
        )
      ),
  });
}
