import Button from 'components/atoms/Button/Button';
import SelectFormField from 'components/molecules/SelectFormField/SelectFormField';
import { useResultDeleteManyMutation } from 'generated/graphql';
import {
  ISelectFormFieldOption,
  TSelectFormFieldValue,
} from 'interfaces/forms/form';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IResetCalibration {
  doneFor: number;
  doneBy: number;
}

const options: ISelectFormFieldOption[] = [
  {
    label: 'Signed off',
    value: 'signed off',
  },
  {
    label: 'HR + Signed off',
    value: 'hr + signed off',
  },
  {
    label: 'All',
    value: 'all',
  },
];

const ResetCalibration: FC<IResetCalibration> = ({ doneFor, doneBy }) => {
  const { handleMsgType } = useNotification();

  const [label, setLabel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteResults] = useResultDeleteManyMutation({
    onCompleted: () => {
      setIsLoading(false);
      handleMsgType({
        type: TNotification.success,
        message: 'The Calibration has been reset successfully!',
      });
    },
    onError: () => {
      setIsLoading(false);
      handleMsgType({
        type: TNotification.error,
        message: 'Error while resetting the calibration!',
      });
    },
  });
  const resetCalibration = () => {
    setIsLoading(true);
    let labels = [];
    if (label === 'signed off') {
      labels = ['signed off'];
    } else if (label === 'hr + signed off') {
      labels = ['signed off', 'talent team decision', 'hr decision'];
    } else {
      labels = [
        'signed off',
        'talent team decision',
        'hr decision',
        'manager decision',
      ];
    }
    deleteResults({
      variables: {
        doneFor,
        doneBy,
        labels,
      },
    });
  };

  const onChangeHandler = (value: TSelectFormFieldValue, name: string) => {
    setLabel(value as string);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <p>
        <strong>Reset Calibration</strong>
      </p>
      <div style={{ width: 550, display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ marginLeft: 10, width: 200 }}>
          <SelectFormField
            id="decision-select"
            name={'name'}
            label={'Select Decision'}
            value={label}
            onChange={onChangeHandler}
            options={options}
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <Button
            isDisabled={label === '' || isLoading}
            onClick={resetCalibration}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetCalibration;
