import { resultsRoutes } from 'constants/navigation';

export const resultsSidebarConfig = [
  {
    title: 'Candidate Results',
    path: resultsRoutes.candidateResults.path,
  },
  {
    title: 'Candidate Usage',
    path: resultsRoutes.candidateUsage.path,
  },
  {
    title: 'Skills Validated',
    path: resultsRoutes.skillsValidated.path,
  },
  {
    title: 'Users',
    path: resultsRoutes.users.path,
  },
  {
    title: 'QBR data',
    path: resultsRoutes.QBRData.path,
  },
  {
    title: 'ATS requisitions data',
    path: resultsRoutes.atsRequisitions.path,
  },
];
