import { FC } from 'react';
import { Drawer } from '@mui/material';
import { useSidebarStyles } from './SidebarStyle';

interface ISidebar {
  children: React.ReactNode;
}

const Sidebar: FC<ISidebar> = ({ children }) => {
  const classes = useSidebarStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>{children}</div>
    </Drawer>
  );
};

export default Sidebar;
