import { FC, useState } from 'react';
import {
  Table,
  TPaginationOnChangeArgs,
} from '@spotted-zebra-uk/ui-components';
import styles from './BehaviourTraitTable.module.scss';

interface IBehaviourTraitTable {
  traits: {
    id: string;
    name: string;
  }[];
}
const BehaviourTraitTable: FC<IBehaviourTraitTable> = ({ traits }) => {
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const handlePaginationChange = ({
    page,
    pageSize,
  }: TPaginationOnChangeArgs) => {
    if (pageSize !== currPageSize) {
      setCurrPageSize(pageSize);
    }
    setFirstRowIndex(pageSize * (page - 1));
    setPage(page);
  };

  const paginatedRows = traits?.slice(
    firstRowIndex,
    firstRowIndex + currPageSize
  );

  return (
    <Table
      headerData={[
        { key: 'id', header: 'ID' },
        { key: 'name', header: 'Name' },
      ]}
      hasPagination
      rowData={paginatedRows}
      title="Traits"
      pagination={{
        currentPageSize: currPageSize,
        onChange: handlePaginationChange,
        totalNumberOfItems: traits.length,
        page,
      }}
      className={styles.traitTable}
    />
  );
};

export default BehaviourTraitTable;
