import { gql } from '@apollo/client';
import { FormBuilderFragments } from './fragments';

export const FORM_BUILDER_QUERY = gql`
  query FormBuilder($id: Int!) {
    formBuilder: FormBuilder(id: $id) {
      ...FormBuilder
    }
  }
  ${FormBuilderFragments.formBuilder}
`;

export const GET_FORM_BUILDER_QUERY = gql`
  query FormBuilder(
    $formType: FormType
    $formManagerType: FormManagerType
    $formOwnerId: Int
  ) {
    formBuilder: FormBuilderFindByOwnerAndType(
      formType: $formType
      formManagerType: $formManagerType
      formOwnerId: $formOwnerId
    ) {
      ...FormBuilder
    }
  }
  ${FormBuilderFragments.formBuilder}
`;

export const FORM_BUILDER_CREATE_MUTATION = gql`
  mutation FormBuilderCreate(
    $name: String!
    $formType: FormType!
    $formManagerType: FormManagerType!
    $formOwnerId: Int!
    $addFields: [CreateFormFieldByFieldIdArgs!]
  ) {
    formBuilder: FormBuilderCreate(
      name: $name
      formType: $formType
      formManagerType: $formManagerType
      formOwnerId: $formOwnerId
      addFields: $addFields
    ) {
      ...FormBuilder
    }
  }
  ${FormBuilderFragments.formBuilder}
`;

export const FORM_BUILDER_UPDATE_MUTATION = gql`
  mutation FormBuilderUpdate(
    $id: Int!
    $name: String
    $addFields: [CreateFormFieldByFieldIdArgs!]
    $removeFields: [DeleteFormFieldByFieldIdArgs!]
    $updateFields: [UpdateFormFieldByFieldIdArgs!]
  ) {
    formBuilder: FormBuilderUpdate(
      id: $id
      name: $name
      addFields: $addFields
      removeFields: $removeFields
      updateFields: $updateFields
    ) {
      ...FormBuilder
    }
  }
  ${FormBuilderFragments.formBuilder}
`;
