import { createContext, FC, useState } from 'react';

export interface IProjectRequestsViewContext {
  displayArchivedFilter: boolean;
  setDisplayArchivedFilter: (newValue: boolean) => void;
}

const initialValues: IProjectRequestsViewContext = {
  displayArchivedFilter: false,
  setDisplayArchivedFilter: () => {},
};

export const ProjectRequestsViewContext = createContext<
  IProjectRequestsViewContext
>(initialValues);

export const ProjectRequestsViewContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [displayArchivedFilter, setDisplayArchivedFilter] = useState(
    initialValues.displayArchivedFilter
  );

  const value = { displayArchivedFilter, setDisplayArchivedFilter };

  return (
    <ProjectRequestsViewContext.Provider value={value}>
      {children}
    </ProjectRequestsViewContext.Provider>
  );
};
