import { FC, useState } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';
import CandidateListModal from './DirectInvitationModal';

interface ICandidateListButton {
  stageId: number;
  projectId: number;
}

const CandidateListButton: FC<ICandidateListButton> = ({
  stageId,
  projectId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpenModal} size="small" variant="tertiary">
        Direct invitation
      </Button>
      {isModalOpen ? (
        <CandidateListModal
          onClose={handleCloseModal}
          stageId={stageId}
          projectId={projectId}
        />
      ) : null}
    </>
  );
};

export default CandidateListButton;
