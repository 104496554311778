import { FieldArray, useFormikContext } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { Modal, ModalButtons } from '@spotted-zebra-uk/ui-components';
import {
  TJobRoleFormSoftSkillDataSourceValues,
  TJobRoleFormValues,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import DataSourcesFormItem from 'components/feature/jobRole/DataSourcesFormItem/DataSourcesFormItem';
import styles from './DataSourcesModal.module.scss';

interface IDataSourcesModal {
  softSkillIndex: number;
  onClose: () => void;
}

const DataSourcesModal: FC<IDataSourcesModal> = ({
  softSkillIndex,
  onClose,
}) => {
  const { values, setFieldValue } = useFormikContext<TJobRoleFormValues>();
  const initialValues = useRef<TJobRoleFormSoftSkillDataSourceValues[]>();

  useEffect(() => {
    initialValues.current = values.softSkills[softSkillIndex].dataSourceValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    onClose();
  };

  const handleCancel = () => {
    setFieldValue(
      `softSkills.${softSkillIndex}.dataSourceValues`,
      initialValues.current || []
    );

    onClose();
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      header={values.softSkills[softSkillIndex].name}
      className={styles.modal}
    >
      <div className={styles.form}>
        <div className={styles.title}>Data Source</div>
        <FieldArray
          name={`softSkills.${softSkillIndex}.dataSourceValues`}
          render={() => (
            <>
              {values.softSkills[softSkillIndex].dataSourceValues.map(
                (dataSourceValue, index) => (
                  <DataSourcesFormItem
                    index={index}
                    softSkillIndex={softSkillIndex}
                    key={dataSourceValue.type}
                  />
                )
              )}
            </>
          )}
        />
        <ModalButtons
          onConfirmButtonClick={handleSave}
          onCancelButtonClick={handleCancel}
          confirmButtonChildren="Save Data Source"
          confirmButtonProps={{
            size: 'medium',
          }}
          cancelButtonProps={{
            size: 'medium',
            variant: 'secondary',
          }}
          buttonsPosition="center"
        />
      </div>
    </Modal>
  );
};

export default DataSourcesModal;
