import Icon from 'components/atoms/Icon';
import { Button, Tooltip } from '@spotted-zebra-uk/ui-components';

const TOOLTIP_CONTENT =
  'Project unavailable in the company app: no stage configured.';

const OpenCompanyAppButton = ({
  onClick,
  disabled,
  loading,
}: {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
}) => {
  if (!loading && disabled) {
    return (
      <div>
        <Tooltip content={TOOLTIP_CONTENT}>
          <ButtonBase
            onClick={onClick}
            disabled={disabled}
            id="tooltip-trigger"
            loading={loading}
          />
        </Tooltip>
      </div>
    );
  }

  return <ButtonBase onClick={onClick} loading={loading} />;
};

const ButtonBase = ({
  onClick,
  disabled,
  loading,
  id,
}: {
  onClick: () => void;
  disabled?: boolean;
  loading: boolean;
  id?: string;
}) => {
  return (
    <Button
      onClick={onClick}
      size="medium"
      variant="tertiary"
      rightIcon={<Icon icon="openTab" />}
      disabled={loading || disabled}
      id={id}
      data-testid="open-project-in-company-app"
    >
      Open in company app
    </Button>
  );
};

export default OpenCompanyAppButton;
