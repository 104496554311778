import Button from 'components/atoms/Button/Button';
import { ReportSubType, ReportType } from 'generated/graphql';
import { formatDate } from 'helpers/date';
import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import useReportsTableRowStyle from './ReportsTableRowStyle';

interface IReportsTableRow {
  name: string;
  type: ReportType;
  subType?: ReportSubType | null;
  company: string;
  updatedAt: string;
  isArchived?: boolean;
  onDuplicateBtnClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onViewReport?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

const ReportsTableRow: FC<IReportsTableRow> = ({
  name,
  company,
  type,
  subType,
  updatedAt,
  isArchived,
  onDuplicateBtnClick,
  onViewReport,
}) => {
  const classes = useReportsTableRowStyle();
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{name}</TableCell>
      <TableCell className={classes.tableCell}>{company}</TableCell>
      <TableCell className={classes.tableCell}>{type}</TableCell>
      <TableCell className={classes.tableCell}>{subType}</TableCell>
      <TableCell className={classes.tableCell}>
        {formatDate(updatedAt, 'dd MMM yyyy')}{' '}
        {isArchived && <span className={classes.archivedText}>Archived</span>}
      </TableCell>
      <TableCell
        align="right"
        style={{ width: '230px' }}
        className={classes.btnCell}
      >
        <Button
          color="inherit"
          className={classes.duplicateBtn}
          onClick={onDuplicateBtnClick}
        >
          DUPLICATE
        </Button>
        <Button onClick={onViewReport} className={classes.btn}>
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ReportsTableRow;
