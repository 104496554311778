import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import UserNavigation from '../../navigation/UserNavigation/UserNavigation';
import useUserStyle from './UserStyle';

const User: FC = () => {
  const classes = useUserStyle();
  return (
    <>
      <UserNavigation />
      <div className={classes.container}>
        <Outlet />
      </div>
    </>
  );
};

export default User;
