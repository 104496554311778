import SidebarNavigation from 'components/organisms/SidebarNavigation/SidebarNavigation';
import { stageActionsRoutes } from 'constants/navigation';
import { Outlet } from 'react-router-dom';
import styles from './StageActions.module.scss';

// sidebar navig
const sidebarRoutes = [
  {
    title: 'Coding Assessments',
    path: stageActionsRoutes.codingAssessments.path,
  },
];

function StageActionsNavigation() {
  return <SidebarNavigation routes={sidebarRoutes} />;
}

function StageActionsView() {
  return (
    <>
      <StageActionsNavigation />
      <div className={styles.container}>
        <Outlet />
      </div>
    </>
  );
}

export default StageActionsView;
