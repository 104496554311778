import { makeStyles } from '@mui/styles';

const useCandidateInfoFieldsHeaderStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
}));

export default useCandidateInfoFieldsHeaderStyle;
