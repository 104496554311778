import { useCompanyFindUsersPaginatedQuery } from 'generated/graphql';
import { FC, useState } from 'react';
import CompanyEmployeesListPresentational from './CompanyEmployeesListPresentational/CompanyEmployeesListPresentational';

interface ICompanyEmployeesList {
  companyId: number;
}

const employeesPerPage = 100;

const CompanyEmployeesList: FC<ICompanyEmployeesList> = ({ companyId }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const companyEmployeesQuery = useCompanyFindUsersPaginatedQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: companyId,
      skip: 0,
      take: employeesPerPage,
    },
    onCompleted: data => {
      if (data.companyUsers.pageInfo?.itemsTotal !== totalEmployees) {
        setTotalEmployees(data.companyUsers.pageInfo?.itemsTotal || 0);
      }
    },
  });

  if (companyEmployeesQuery.error) {
    return <div>Error</div>;
  }

  const handlePageChange = (_event: unknown, value: number) => {
    setSelectedPage(value);
    companyEmployeesQuery.refetch({
      id: companyId,
      skip: (value - 1) * employeesPerPage,
      take: employeesPerPage,
    });
  };
  const companyUsers = companyEmployeesQuery.data?.companyUsers;
  if (companyUsers?.data) {
    return (
      <CompanyEmployeesListPresentational
        companyId={companyId}
        employees={companyUsers?.data}
        handlePageChange={handlePageChange}
        selectedPage={selectedPage}
        totalEmployees={totalEmployees}
        loading={companyEmployeesQuery.loading}
        employeesPerPage={employeesPerPage}
      />
    );
  }

  return null;
};

export default CompanyEmployeesList;
