import { FC } from 'react';
import SelectWithQuery from '../../../../../components/molecules/SelectFormField/SelectWithQuery/SelectWithQuery';
import {
  FormManagerType,
  FormOwnersDocument,
  FormOwnersQuery,
  FormType,
} from '../../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../../interfaces/forms/form';

export interface IFormOwnersSelectFormFieldWrapper {
  id: string;
  name: string;
  label: string;
  hasError?: boolean;
  errorText?: string;
  value: TSelectFormFieldValue;
  selectedFormType?: FormType;
  selectedFormManagerType?: FormManagerType;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
}

const FormOwnersSelectFormFieldWrapper: FC<IFormOwnersSelectFormFieldWrapper> = ({
  selectedFormManagerType,
  selectedFormType,
  ...restProps
}) => {
  const getOptionsFromResponse = (response: FormOwnersQuery | undefined) => {
    return response?.FormReportFindFormOwners
      ? response.FormReportFindFormOwners.map(formOwner => ({
          label: formOwner.name as string,
          value: formOwner.id as number,
        }))
      : [];
  };

  const isEmptySelectVisible = Boolean(
    selectedFormManagerType && selectedFormType
  );

  return (
    <SelectWithQuery
      query={FormOwnersDocument}
      queryOptions={{
        variables: {
          formManagerType: selectedFormManagerType,
          formType: selectedFormType,
        },
      }}
      getOptionsFromResponse={getOptionsFromResponse}
      isEmptySelectVisible={isEmptySelectVisible}
      selectElementProps={restProps}
    />
  );
};

export default FormOwnersSelectFormFieldWrapper;
