import { getAccessToken } from './auth';

function getUrl(endpoint: string) {
  return `${process.env.REACT_APP_REST_GATEWAY_API_URL}/${endpoint}`;
}

function getAuthHeaders() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

function getFilename(headers: Headers): string | null {
  try {
    const header = headers.get('Content-Disposition');
    const parts = header!.split(';');
    return parts[1].split('=')[1];
  } catch {
    return null;
  }
}

async function download(
  endpoint: string,
  defaultFileName: string = 'report.csv',
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  body?: unknown
): Promise<UploadResponse> {
  try {
    const options = {
      method: !!body ? 'POST' : 'GET',
      headers: {
        ...getAuthHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body) ?? undefined,
    };

    const response = await fetch(getUrl(endpoint), options);

    // cover the errors
    if (!response.ok) {
      const responseBody = await response.json();
      if (responseBody.error) {
        return {
          error: {
            error: responseBody.error,
            message: responseBody.message,
          },
        };
      }
    }

    const filename = getFilename(response.headers) || defaultFileName;
    const blob = await response.blob();
    const aElement = document.createElement('a');
    aElement.setAttribute('download', filename);

    const href = URL.createObjectURL(blob);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    //Initiate download
    aElement.click();

    URL.revokeObjectURL(href);

    return { error: null };
  } catch (error) {
    return { error: String(error) };
  }
}

async function upload(endpoint: string, file: File): Promise<UploadResponse> {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await fetch(getUrl(endpoint), {
      method: 'POST',
      headers: getAuthHeaders(),
      body: formData,
    });

    const responseJson = await response.json();

    return response.ok ? { error: null } : { error: responseJson };
  } catch (error) {
    return { error: String(error) };
  }
}

export type UploadResponse = {
  error: null | { error: string; message: string } | any;
};

export const uploadService = {
  download,
  upload,
};
