import { FC } from 'react';
import { Grid } from '@mui/material';
import { SoftSkillTrait } from '../../../../../../generated/graphql';
import { IStaticContentFiltersFormValues } from '../../../../StaticContentFiltersModal/StaticContentFiltersForm/StaticContentFiltersForm';
import ConnectedTraitsListItem from './ConnectedTraitsListItem/ConnectedTraitsListItem';

export interface IConnectedTraitsList {
  softSkillTraits: SoftSkillTrait[];
  updateFilters: (values: IStaticContentFiltersFormValues) => void;
  className: string;
}

const ConnectedTraitsList: FC<IConnectedTraitsList> = ({
  softSkillTraits,
  updateFilters,
  className,
}) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={className}
      sx={{ maxHeight: 'unset' }}
    >
      {softSkillTraits.map(softSkillTrait => (
        <ConnectedTraitsListItem
          softSkillTrait={softSkillTrait}
          updateFilters={updateFilters}
          key={softSkillTrait.id}
        />
      ))}
    </Grid>
  );
};

export default ConnectedTraitsList;
