import { FC } from 'react';
import {
  RoleType,
  useRoleFindManyQuery,
  UserRole,
} from '../../../../generated/graphql';
import {
  TMultiSelectFormFieldValue,
  TSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

// import SelectFormField from '../SelectFormField';

interface IRoleMultiSelectFormField {
  className?: string;
  name: string;
  value: TMultiSelectFormFieldValue;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  existingRoles?: UserRole[] | null;
  isSuperAdmin?: boolean;
}

interface IOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

const RoleMultiSelectFormField: FC<IRoleMultiSelectFormField> = ({
  value,
  name,
  onChange,
  hasError,
  errorText,
}) => {
  const { data } = useRoleFindManyQuery({
    variables: {
      filterForIdentity: true,
    },
  });

  let adminRoleArray: IOptions[] | undefined;
  if (data?.RoleFindMany) {
    adminRoleArray = data?.RoleFindMany.filter(
      r => r.type === RoleType.Company || r.type === RoleType.ModuleAccess
    ).map(role => ({
      label: role.name,
      value: role.name.toUpperCase(),
    }));
  }

  if (data?.RoleFindMany.length) {
    return (
      <MultiselectFormField
        id="roleFormField"
        name={name}
        label="Role"
        value={value}
        onChange={onChange}
        options={adminRoleArray as IOptions[]}
        hasError={hasError}
        errorText={errorText}
      />
    );
  }
  return null;
};

export default RoleMultiSelectFormField;
