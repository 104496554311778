import {
  RssAssessmentCreateOneArgs,
  RssAssessmentType,
  StageActionAvailability,
  StageActionSubType,
  StageActionType,
  useRssAssessmentCreateManyMutation,
  useRssAssessmentDeleteManyMutation,
  useRssAssessmentFindManyQuery,
  useStageQuery,
} from 'generated/graphql';
import { isEmpty } from 'lodash';
import { FC, useState } from 'react';
import {
  Loader,
  Modal,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  rssNotAvailableTooltipHtml,
  updateCacheAfterRSSAssessmentCreateMany,
  updateCacheAfterRSSAssessmentDeleteMany,
} from './helpers';
import SkillsSelfAssessmentActionForm, {
  SkillsSelfAssessmentActionFormValues,
} from './SkillsSelfAssessmentActionForm';

const getTooltipNote = (
  assessmentType: 'Soft skill' | 'Technical skill',
  availability?: StageActionAvailability
) => {
  if (availability === StageActionAvailability.NotAvailable)
    return rssNotAvailableTooltipHtml(assessmentType);

  return '';
};

interface IEditSkillsSelfAssessmentActionModal {
  stageId: number;
  onClose: () => void;
}

const EditSkillsSelfAssessmentActionModal: FC<IEditSkillsSelfAssessmentActionModal> = ({
  stageId,
  onClose,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { handleMsgType } = useNotification();
  const stageQueryResponse = useStageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: stageId,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const rssAssessmentsQueryResponse = useRssAssessmentFindManyQuery({
    variables: { stageId },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [
    rssAssessmentDeleteManyMutation,
  ] = useRssAssessmentDeleteManyMutation();
  const [rssAssessmentCreateManyMutation] = useRssAssessmentCreateManyMutation({
    update: (cache, { data }) =>
      updateCacheAfterRSSAssessmentCreateMany(cache, stageId, data),
  });
  const [error, setError] = useState('');

  const cachedSoftSkillAssessment = rssAssessmentsQueryResponse.data?.RssAssessmentFindMany.find(
    rssAssessment => rssAssessment.type === RssAssessmentType.SoftSkill
  );
  const softSkillAssessmentPredefined = Boolean(cachedSoftSkillAssessment);

  const cachedTechnicalSkillAssessment = rssAssessmentsQueryResponse.data?.RssAssessmentFindMany.find(
    rssAssessment => rssAssessment.type === RssAssessmentType.TechnicalSkill
  );
  const technicalSkillAssessmentPredefined = Boolean(
    cachedTechnicalSkillAssessment
  );

  const handleSubmit = async ({
    softSkillsChecked,
    technicalSkillsChecked,
  }: SkillsSelfAssessmentActionFormValues) => {
    if (!softSkillsChecked && !technicalSkillsChecked) {
      return setError('At least one option must be selected.');
    }

    const dtoDelete: number[] = [];
    const dtoCreate: RssAssessmentCreateOneArgs[] = [];

    if (!softSkillAssessmentPredefined && softSkillsChecked) {
      dtoCreate.push({
        stageId,
        type: RssAssessmentType.SoftSkill,
      });
    }

    if (!technicalSkillAssessmentPredefined && technicalSkillsChecked) {
      dtoCreate.push({
        stageId,
        type: RssAssessmentType.TechnicalSkill,
      });
    }

    if (
      softSkillAssessmentPredefined &&
      !softSkillsChecked &&
      cachedSoftSkillAssessment
    ) {
      dtoDelete.push(cachedSoftSkillAssessment.id);
    }

    if (
      technicalSkillAssessmentPredefined &&
      !technicalSkillsChecked &&
      cachedTechnicalSkillAssessment
    ) {
      dtoDelete.push(cachedTechnicalSkillAssessment.id);
    }

    setSubmitting(true);
    try {
      if (!isEmpty(dtoDelete)) {
        await rssAssessmentDeleteManyMutation({
          variables: {
            ids: dtoDelete,
          },
          update: (cache, { data }) =>
            updateCacheAfterRSSAssessmentDeleteMany(
              cache,
              stageId,
              dtoDelete,
              data
            ),
        });
      }
      if (!isEmpty(dtoCreate)) {
        await rssAssessmentCreateManyMutation({
          variables: {
            dto: dtoCreate,
          },
        });
      }
      if (!isEmpty(dtoCreate) || !isEmpty(dtoDelete)) {
        await stageQueryResponse.refetch();
        handleMsgType({
          type: TNotification.success,
          message: 'Skills self-assessment action created!',
        });
        onClose();
      }
    } catch (_error) {
      handleMsgType({
        type: TNotification.error,
        message:
          'Ops, some error is ocurred during editing Skills self-assessment action!',
      });
      setSubmitting(false);
    }
  };

  const softSkillAssessmentAvailability = stageQueryResponse.data?.Stage?.availableActions.find(
    actionAvailability =>
      actionAvailability.type === StageActionType.RssAssessment &&
      actionAvailability.subType === StageActionSubType.RssAssessmentSoft
  );

  const technicalSkillAssessmentAvailability = stageQueryResponse.data?.Stage?.availableActions.find(
    actionAvailability =>
      actionAvailability.type === StageActionType.RssAssessment &&
      actionAvailability.subType === StageActionSubType.RssAssessmentTechnical
  );

  const softSkillTooltipNote = getTooltipNote(
    'Soft skill',
    softSkillAssessmentAvailability?.availability
  );

  const technicalSkillTooltipNote = getTooltipNote(
    'Technical skill',
    technicalSkillAssessmentAvailability?.availability
  );

  const loading = !stageQueryResponse.data;

  const softSkillsAssessmentAvailable =
    softSkillAssessmentAvailability?.availability !==
    StageActionAvailability.NotAvailable;

  const technicalSkillsAssessmentAvailable =
    technicalSkillAssessmentAvailability?.availability !==
    StageActionAvailability.NotAvailable;

  return (
    <Modal
      onClose={onClose}
      isOpen
      className="edit-skills-self-assessment-action-modal"
      header="Edit action"
    >
      {loading ? (
        <Loader variant="bubbles" />
      ) : (
        <SkillsSelfAssessmentActionForm
          initialValues={{
            softSkillsChecked: softSkillAssessmentPredefined,
            technicalSkillsChecked: technicalSkillAssessmentPredefined,
          }}
          softSkillsAssessmentAvailable={softSkillsAssessmentAvailable}
          softSkillTooltipNote={softSkillTooltipNote}
          technicalSkillsAssessmentAvailable={
            technicalSkillsAssessmentAvailable
          }
          technicalSkillTooltipNote={technicalSkillTooltipNote}
          submitDisabled={submitting}
          error={error}
          title="Skill self-assessment"
          onSoftSkillsCheckboxChange={() => setError('')}
          onTechnicalSkillsCheckboxChange={() => setError('')}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default EditSkillsSelfAssessmentActionModal;
