import './styles.scss';
import { CompanyFragment, CompanyFragmentDoc } from 'generated/graphql';
import { FC } from 'react';
import { useApolloClient } from '@apollo/client';

const CompanySign: FC<{ companyId: number }> = ({ companyId }) => {
  const client = useApolloClient();
  const company = client.readFragment<CompanyFragment>({
    id: `Company:${companyId}`,
    fragment: CompanyFragmentDoc,
  });

  if (!company) {
    return null;
  }

  if (!company.logoUrl) {
    return (
      <div className="project-request-company-logo__company-name">
        {company.name}
      </div>
    );
  }

  return (
    <img
      src={company.logoUrl}
      alt="company logo"
      className="project-request-company-logo"
    />
  );
};

export default CompanySign;
