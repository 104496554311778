import { UserFragment } from 'generated/graphql';
import { FC } from 'react';
import { ContactsListItemField } from '../ContactsListItemField';

interface ICreatorContactsListItem {
  creator: UserFragment;
}

export const CreatorContactsListItem: FC<ICreatorContactsListItem> = ({
  creator,
}) => {
  return (
    <div className="contacts-list-item">
      <ContactsListItemField
        title="Role"
        className="contacts-list-item__role-select"
        content={'Requestor'}
        id={`${creator.email}-role`}
      />
      <ContactsListItemField
        title="Employee"
        className="contacts-list-item__employee-select"
        content={`${creator.firstName} ${creator.lastName}`}
        note={creator.email}
        id={`${creator.email}-email`}
      />
    </div>
  );
};
