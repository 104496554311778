import { FC } from 'react';
import Modal from '../../../components/molecules/Modal/Modal';
import { ModalTypes } from '../../../enums/modalTypes';
import { closeModal } from '../../../graphql/modals';
import { TValidateContentModal } from '../../../interfaces/modal';
import ValidateContentForm from './ValidateContentForm/ValidateContentForm';
import useValidateContentModalStyle from './ValidateContentModalStyle';

export interface IValidateContentModal extends TValidateContentModal {}

const ValidateContentModal: FC<IValidateContentModal> = () => {
  const classes = useValidateContentModalStyle();

  const handleCloseModal = () => {
    closeModal(ModalTypes.VALIDATE_CONTENT_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <ValidateContentForm />
    </Modal>
  );
};

export default ValidateContentModal;
