import { FC } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { Grid, Typography } from '@mui/material';
import {
  IgCategory,
  IgInterviewGuideCategory,
} from '../../../generated/graphql';
import Category from './Category/Category';
import InterviewGuideCategory from './InterviewGuideCategory/InterviewGuideCategory';
import useInterviewGuideDndStyle from './InterviewGuideDndStyle';

interface IInterviewGuideDnD {
  categories: IgCategory[];
  interviewGuideCategories: IgInterviewGuideCategory[];
  interviewGuideId: number;
  reOrderInterviewGuideCategories: (list: IgInterviewGuideCategory[]) => void;
}

const InterviewGuideDnD: FC<IInterviewGuideDnD> = ({
  categories,
  interviewGuideCategories,
  interviewGuideId,
  reOrderInterviewGuideCategories,
}) => {
  const classes = useInterviewGuideDndStyle();

  const reorder = (
    list: IgInterviewGuideCategory[],
    startIndex: number,
    endIndex: number
  ): IgInterviewGuideCategory[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source && result.destination) {
      const temp = reorder(
        interviewGuideCategories,
        result.source.index,
        result.destination?.index
      );
      reOrderInterviewGuideCategories(temp);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.container}>
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.title}>
              Categories used
            </Typography>
          </Grid>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <div
                className={classes.droppableContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {interviewGuideCategories?.map(
                  (item: IgInterviewGuideCategory, index: number) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={index}
                      >
                        {(providedDraggable: DraggableProvided) => (
                          <div>
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              {item && (
                                <InterviewGuideCategory
                                  interviewGuideCategory={
                                    item as IgInterviewGuideCategory
                                  }
                                  interviewGuideId={interviewGuideId}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <div>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            All Categories
          </Typography>
        </Grid>
        <div>
          {categories?.map(
            (item: IgCategory, index) =>
              item && (
                <Category
                  key={item.id}
                  category={item}
                  interviewGuideLastItem={
                    interviewGuideCategories[
                      interviewGuideCategories.length - 1
                    ] as IgInterviewGuideCategory
                  }
                  interviewGuideId={interviewGuideId}
                />
              )
          )}
        </div>
      </div>
    </>
  );
};

export default InterviewGuideDnD;
