import { makeStyles } from '@mui/styles';

const useReportsHeaderStyle = makeStyles(() => ({
  container: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '22px !important',
  },
  searchInput: {
    marginRight: '15px',
  },
  button: {
    fontSize: '16px',
  },
}));

export default useReportsHeaderStyle;
