import './styles.scss';
import ResultsNavigation from 'navigation/ResultsNavigation/ResultsNavigation';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useResultsStyle = makeStyles(() => ({
  container: {
    padding: '30px 30px 30px 270px',
  },
}));

const Results: FC = () => {
  const classes = useResultsStyle();

  return (
    <>
      <ResultsNavigation />
      <div className={classes.container}>
        <Outlet />
      </div>
    </>
  );
};

export default Results;
