import { ActorRole, ProjectCollaborator } from '../generated/graphql';
import { capitalizeFirstLetter } from './capitalzieFirstLetter';
import { formatDate, formatISO } from './date';

export function formatProjectDate(date: string) {
  return isNaN(Number(date))
    ? formatDate(formatISO(date), 'dd MMM yyyy')
    : formatDate(new Date(Number(date)).toISOString(), 'dd MMM yyyy');
}

export function formatCamelCase(moduleType?: string | null): string {
  return moduleType
    ? capitalizeFirstLetter(
        moduleType
          .split('_')
          .filter(part => !!part)
          .join(' ')
          .toLocaleLowerCase()
      )
    : '-';
}

export function collaboratorsByRole(collaborators: Array<ProjectCollaborator>) {
  return (
    collaborators?.reduce<
      Partial<Record<ActorRole, Array<ProjectCollaborator>>>
    >((acc, collaborator) => {
      if (!collaborator.actorRole) return acc;
      acc[collaborator.actorRole] = [
        ...(acc[collaborator.actorRole] || []),
        collaborator,
      ];
      return acc;
    }, {}) || {}
  );
}

const HUMAN_FRIENDLY_ACTOR_ROLE = {
  [ActorRole.AssessorOrInterviewer]: 'Interviewer',
  [ActorRole.EarlyCareerTeamMember]: 'Early career team member',
  [ActorRole.HeadOfPeopleOrTalent]: 'Head of People / Talent',
  [ActorRole.HiringManager]: 'Hiring manager',
  [ActorRole.Interviewer]: 'Interviewer',
  [ActorRole.LineManager]: 'Line manager',
  [ActorRole.RecruiterOrTalentAcquisitionPartner]: 'Recruiter',
  [ActorRole.ReskillingTeamMember]: 'Reskilling team member',
};

export const humanizeActorRole = (role: ActorRole) =>
  role ? HUMAN_FRIENDLY_ACTOR_ROLE[role] : '';
