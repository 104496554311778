import { stageModes } from 'views/Project/components/Stages/helpers';
import { StageModes } from 'views/Project/components/Stages/types';
import { Tag, TagSize, Tooltip } from '@spotted-zebra-uk/ui-components';
import { MODE_TAG_COLOR, TOOLTIP_CONTENT } from './ProjectModeTag.helpers';
import styles from './ProjectModeTag.module.scss';

const ProjectModeTag = ({ mode }: { mode: StageModes }) => {
  return (
    <Tooltip content={TOOLTIP_CONTENT[mode]} className={styles.container}>
      <div>
        <Tag
          size={TagSize.LARGE}
          tagColor={MODE_TAG_COLOR[mode]}
          isDismissible={false}
          isPresentational={true}
          containerClass={styles.tagContainer}
        >
          {stageModes[mode].label}{' '}
          {`${mode !== StageModes.DISABLED ? 'emails' : ''}`}
        </Tag>
      </div>
    </Tooltip>
  );
};

export default ProjectModeTag;
