import { ApolloCache } from '@apollo/client';
import {
  Widget,
  WidgetDocument,
  WidgetOption,
  WidgetQuery,
  WidgetsDocument,
  WidgetsQuery,
} from '../generated/graphql';

export const addWidgetOptionToCache = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  WidgetOption: WidgetOption
) => {
  const data = cache.readQuery<WidgetQuery>({
    query: WidgetDocument,
  });
  if (data?.Widget?.widgetOptions && WidgetOption) {
    cache.writeQuery({
      query: WidgetDocument,
      data: {
        Widget: {
          WidgetOptions: [WidgetOption, ...data?.Widget.widgetOptions],
        },
      },
    });
  }
};

export const deleteWidgetFromCache = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  parsedId: number
) => {
  let WidgetsData = cache.readQuery<WidgetsQuery>({
    query: WidgetsDocument,
  });
  if (WidgetsData?.Widgets) {
    const changedData = WidgetsData.Widgets?.filter(
      item => item.id !== parsedId
    ) as Widget[];
    cache.writeQuery<WidgetsQuery>({
      query: WidgetsDocument,
      data: {
        Widgets: changedData,
      },
    });
  }
};

export const addWidgetToCache = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  Widget: Widget
) => {
  const data = cache.readQuery<WidgetsQuery>({
    query: WidgetsDocument,
  });
  Widget.reportConfigs = [];
  Widget.widgetOptions = [];
  if (data?.Widgets && Widget) {
    cache.writeQuery<WidgetsQuery>({
      query: WidgetsDocument,
      data: { Widgets: [Widget, ...data?.Widgets] },
    });
  }
};
