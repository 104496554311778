import { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import useGridContainerStyle from './GridContainerStyle';

interface IGridContainer {
  gridProps?: GridProps;
  children: React.ReactNode;
}

const GridContainer: FC<IGridContainer> = ({ children, gridProps }) => {
  const classes = useGridContainerStyle();

  return (
    <Grid container className={classes.container} {...gridProps}>
      {children}
    </Grid>
  );
};

export default GridContainer;
