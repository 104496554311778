import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import GenerateUserApiKeyButton from './GenerateUserApiKeyButton/GenerateUserApiKeyButton';

interface IUserApiKeysHeader {
  userId: number;
}

const UserApiKeysHeader: FC<IUserApiKeysHeader> = ({ userId }) => {
  return (
    <Grid
      style={{ paddingBottom: '35px' }}
      container
      alignContent="center"
      justifyContent="space-between"
    >
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontSize: 24 }}>User api keys</Typography>
      </Grid>
      <Grid>
        <GenerateUserApiKeyButton userId={userId} />
      </Grid>
    </Grid>
  );
};

export default UserApiKeysHeader;
