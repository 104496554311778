import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Snackbar from '../../../components/atoms/Snackbar/Snackbar';
import Modal from '../../../components/molecules/Modal/Modal';
import ErrorMessage from '../../../components/organisms/ErrorMessage/ErrorMessage';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  StageCandidateFindManyDocument,
  TestCandidateFindManyByCandidateDocument,
  useRetrieveTpCandidatesMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import { ISnackBar } from '../../../interfaces/snackBar';
import RetriveTpCandidatesForm, {
  TRetrieveTpCandidatesFormValues,
} from '../../User/RetrieveTpCandidates/RetrieveTpCandidatesForm/RetrieveTpCandidatesForm';
import { IUserResponse } from '../../User/UserDetails/UserRoles/UserRoles';

interface IRetrieveTpDetailsModal {
  userInfo: IUserResponse;
  projectId: number;
  stageId: number;
}

const useReportModalStyle = makeStyles({
  modalPaper: {
    minWidth: 600,
    height: 600,
    padding: 40,
    borderRadius: '16px',
    boxSizing: 'border-box',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    overflowY: 'scroll',
  },
});

const RetrieveTpDetailsModal: FC<IRetrieveTpDetailsModal> = ({
  userInfo,
  projectId,
  stageId,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useReportModalStyle();
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const handleCloseModal = () => {
    closeModal(ModalTypes.RETRIEVE_TP_DETAILS_MODAL);
  };

  const [
    retrieveTpCandidatesMutation,
    retrieveTpCandidatesMutationResponse,
  ] = useRetrieveTpCandidatesMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({
        ...snackbar,
        show: true,
        msg: 'Success! Tp candidate retrieved!',
      });
      handleCloseModal();
    },
    refetchQueries: [
      {
        query: StageCandidateFindManyDocument,
        variables: { candidateId: userInfo?.id },
      },
      {
        query: TestCandidateFindManyByCandidateDocument,
        variables: { candidateId: Number(userInfo?.id) || 0 },
      },
    ],
  });

  const handleRetriveTpCandidates = (
    values: TRetrieveTpCandidatesFormValues
  ) => {
    retrieveTpCandidatesMutation({
      variables: {
        candidateEmail: values.candidateEmail,
        firstName: values.firstName,
        lastName: values.lastName,
        stageId: values.stageId ? parseInt(values.stageId) : 0,
        personalityTpAssessmentId: parseInt(values.personalityTpAssessmentId),
        inductiveTpAssessmentId: parseInt(values.inductiveTpAssessmentId),
        numericalTpAssessmentId: parseInt(values.numericalTpAssessmentId),
        verbalTpAssessmentId: parseInt(values.verbalTpAssessmentId),
      },
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <div style={{ paddingTop: 120, marginTop: 60 }}>
        <Grid item>
          <Typography variant="h4">Retieve tp candidates</Typography>
        </Grid>
        <div style={{ marginTop: 10 }}>
          <RetriveTpCandidatesForm
            projectId={projectId}
            stageId={stageId}
            userInfo={userInfo}
            onSubmit={handleRetriveTpCandidates}
          />
          {retrieveTpCandidatesMutationResponse.error && (
            <ErrorMessage error={retrieveTpCandidatesMutationResponse.error} />
          )}
        </div>
      </div>
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </Modal>
  );
};

export default RetrieveTpDetailsModal;
