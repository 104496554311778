import { FC, useMemo } from 'react';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import { ReportSubType, ReportType } from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

const optionsObject: {
  [key in ReportSubType]: {
    value: ReportSubType;
    label: string;
    reportType: ReportType;
    order: number;
  };
} = {
  [ReportSubType.StageResultsCandidate]: {
    value: ReportSubType.StageResultsCandidate,
    label: 'Stage results candidate',
    reportType: ReportType.StageResults,
    order: 1,
  },
  [ReportSubType.StageResultsManager]: {
    value: ReportSubType.StageResultsManager,
    label: 'Stage results manager',
    reportType: ReportType.StageResults,
    order: 2,
  },
  [ReportSubType.SuccessProfileFull]: {
    value: ReportSubType.SuccessProfileFull,
    label: 'Sp report full',
    reportType: ReportType.SuccessProfile,
    order: 3,
  },
  [ReportSubType.SuccessProfilePartial]: {
    value: ReportSubType.SuccessProfilePartial,
    label: 'Sp report partial',
    reportType: ReportType.SuccessProfile,
    order: 4,
  },
};

interface IReportSubTypeFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  isDisabled?: boolean;
  selectedReportType: ReportType | null;
}

const ReportSubTypeFormField: FC<IReportSubTypeFormField> = ({
  selectedReportType,
  ...selectElementProps
}) => {
  const options = useMemo(
    () =>
      Object.values(optionsObject)
        .filter(option => option.reportType === selectedReportType)
        .sort((a, b) => (a.order > b.order ? 1 : 1))
        .map(option => ({ label: option.label, value: option.value })),
    [selectedReportType]
  );

  return <SelectFormField options={options} {...selectElementProps} />;
};

export default ReportSubTypeFormField;
