import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';

interface IIndirectInvitationButton {
  projectId: number;
  stageId: number;
}

const IndirectInvitationButton: FC<IIndirectInvitationButton> = ({
  projectId,
  stageId,
}) => {
  const handleIndirectInvitationButtonClick = () => {
    openModal(ModalTypes.STAGE_INDIRECT_INVITATION_MODAL, {
      projectId,
      stageId,
    });
  };

  return (
    <Button
      onClick={handleIndirectInvitationButtonClick}
      size="small"
      variant="tertiary"
    >
      Indirect invitation
    </Button>
  );
};

export default IndirectInvitationButton;
