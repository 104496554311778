import { FC } from 'react';
import styles from '../BehaviourUploadInlineNotification.module.scss';

interface ISuccessValidationStatus {
  fileName: string;
}

const SuccessValidationStatus: FC<ISuccessValidationStatus> = ({
  fileName,
}) => {
  return (
    <div className={styles.info_container}>
      <div className={styles.info_title}>
        ‘{fileName}‘ has passed validation
      </div>
      <div className={styles.info_paragraph}>
        Confirming upload of this content will affect the following soft skills
        and traits.
      </div>
    </div>
  );
};

export default SuccessValidationStatus;
