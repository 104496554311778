import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import { ApiKeyOwnerType, useApiKeyFindAllQuery } from 'generated/graphql';
import { FC } from 'react';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import UserApiKeysListItem from './UserApiKeysListItem/UserApiKeysListItem';

interface IUserApiKeysList {
  userId: number;
}

const UserApiKeysList: FC<IUserApiKeysList> = ({ userId }) => {
  const { handleMsgType } = useNotification();

  const apiKeysQueryResponse = useApiKeyFindAllQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  if (apiKeysQueryResponse.error) {
    return <div>Error: {apiKeysQueryResponse.error.message}</div>;
  }

  if (apiKeysQueryResponse.data) {
    const userApiKeys = apiKeysQueryResponse.data.ApiKeyFindAll.filter(
      apiKey =>
        apiKey.ownerType === ApiKeyOwnerType.User && apiKey.ownerId === userId
    );

    return (
      <Grid container item>
        {userApiKeys.map(apiKey => (
          <UserApiKeysListItem
            key={apiKey.id}
            apiKey={{
              id: apiKey.id,
              key: apiKey.key,
              createdAt: apiKey.createdAt,
            }}
          />
        ))}
      </Grid>
    );
  }

  return <CircularLoader />;
};

export default UserApiKeysList;
