import { FC, useMemo } from 'react';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import { ReportType } from '../../../../enums/report';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

const optionsObject: {
  [key in ReportType]: { value: ReportType; label: string; order: number };
} = {
  [ReportType.STAGE_RESULTS]: {
    value: ReportType.STAGE_RESULTS,
    label: 'Stage results',
    order: 1,
  },
  [ReportType.SUCCESS_PROFILE]: {
    value: ReportType.SUCCESS_PROFILE,
    label: 'Success profile results',
    order: 2,
  },
};

interface IReportTypeFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
}

const ReportTypeFormField: FC<IReportTypeFormField> = props => {
  const options = useMemo(
    () =>
      Object.values(optionsObject)
        .sort((a, b) => (a.order > b.order ? 1 : 1))
        .map(option => ({ label: option.label, value: option.value })),
    []
  );

  return <SelectFormField options={options} {...props} />;
};

export default ReportTypeFormField;
