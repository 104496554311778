import Button from 'components/atoms/Button/Button';
import Modal from 'components/molecules/Modal/Modal';
import TextFormField from 'components/molecules/TextFormField/TextFormField';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { TTextFormFieldModal } from 'interfaces/modal';
import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import useTextFieldModalStyle from './TextFormFieldModalStyle';

export interface ITextFieldModal extends TTextFormFieldModal {}

const TextFieldModal: FC<ITextFieldModal> = ({
  id,
  name,
  label,
  onChange,
  initialValue,
  showActionButton = false,
  onActionButtonClick,
  actionButtonLabel,
  showCancelButton,
  cancelButtonLabel = 'Cancel',
  changeValueOnClose = true,
}) => {
  const classes = useTextFieldModalStyle();
  const handleClose = () => {
    if (changeValueOnClose) {
      onChange(value);
    }
    closeModal(ModalTypes.TEXT_FIELD_MODAL);
  };
  const handleCancelButton = () => {
    closeModal(ModalTypes.TEXT_FIELD_MODAL);
  };
  const [value, setValue] = useState<string>(initialValue ? initialValue : '');

  const handleChange = (value: string) => {
    setValue(value);
  };
  const handleActionButton = () => {
    if (onActionButtonClick) {
      onActionButtonClick(value);
    }
  };
  return (
    <Modal className={classes.modalPaper} onClose={handleClose}>
      <Typography variant="h6">{label}</Typography>
      <TextFormField
        id={id}
        name={name}
        onChange={handleChange}
        value={value}
        rows={6}
        multiline={true}
      ></TextFormField>
      <div className={classes.actions}>
        {showActionButton && (
          <Button onClick={handleActionButton}>{actionButtonLabel}</Button>
        )}
        {showCancelButton && (
          <Button onClick={handleCancelButton}>{cancelButtonLabel}</Button>
        )}
      </div>
    </Modal>
  );
};

export default TextFieldModal;
