import { makeStyles } from '@mui/styles';

const useCandidateInformationFieldRequirementsFormItemStyle = makeStyles(
  () => ({
    container: {
      borderRadius: '7px',
      border: '1px solid black',
      backgroundColor: 'white',
      margin: '3px !important',
      width: 'calc(100% - 10px)',
      padding: 0,
    },
    ownedBySelectedConpanyFieldWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
);

export default useCandidateInformationFieldRequirementsFormItemStyle;
