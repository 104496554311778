import styles from './CompanyEmailDomainsDrawer.module.scss';
import {
  InlineNotification,
  NotificationType,
  Button,
  FormikTextInputField,
  Loader,
} from '@spotted-zebra-uk/ui-components';
import { FieldArray, useFormikContext, Form, useField } from 'formik';
import Icon from 'components/atoms/Icon';
import { Grid } from '@mui/material';
import { EmailDomainsFormValues } from './CompanyEmailDomainsDrawer.types';

export function CompanyEmailDomainsForm() {
  const { values, submitForm, isSubmitting } = useFormikContext<
    EmailDomainsFormValues
  >();

  return (
    <Form
      className={styles.container}
      onSubmit={e => e.preventDefault()}
      autoComplete="false"
    >
      <Grid
        className={styles.hero}
        container
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <InlineNotification
          notificationType={NotificationType.NEUTRAL}
          className={styles.info}
        >
          Please enter email domains in the format 'companyname.com' without the
          '@' symbol.
        </InlineNotification>
        <Button
          rightIcon={
            isSubmitting && (
              <Loader color="white" variant="spinner" size="small" />
            )
          }
          onClick={() => {
            if (isSubmitting) return;
            submitForm();
          }}
          data-testid="emailDomains--submit"
        >
          Add
        </Button>
      </Grid>

      <FieldArray
        name="emailDomains"
        render={({ push, remove, replace }) => (
          <>
            {values.emailDomains.map((_, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.ampersand}>@</div>
                <FormikTextInputField
                  className={styles.input}
                  id={`emailDomains.${index}`}
                  name={`emailDomains.${index}`}
                  data-testid={`emailDomains.${index}--input`}
                  placeholder="Email domain"
                  auto
                  useFormikField={useField}
                />
                <Button
                  leftIcon={<Icon icon="ic_close_gray" />}
                  variant="text"
                  size="small"
                  color="gray"
                  disabled={values.emailDomains.length === 1}
                  onClick={() =>
                    values.emailDomains.length > 1
                      ? remove(index)
                      : replace(index, '')
                  }
                  aria-label="Remove domain"
                  data-testid={`emailDomains.${index}--remove`}
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button
              variant={'secondary'}
              size="small"
              leftIcon={<Icon icon="plus" />}
              onClick={() => push('')}
              data-testid="emailDomains--add-domain"
            >
              Add another domain
            </Button>
          </>
        )}
      />
    </Form>
  );
}
