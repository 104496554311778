import { makeStyles } from '@mui/styles';

const useInterviewGuideDndStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px',
  },
  droppableContainer: {
    padding: 10,
    marginTop: 10,
    border: 'solid 1px #3f51b5',
    backgroundColor: 'rgba(63, 81, 181, 0.1)',
  },
  droppableContainer2: {
    padding: 10,
    marginTop: 10,
    border: 'solid 1px #3f51b5',
    backgroundColor: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  w100: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'between',
    alignItem: 'center',
  },
  title: {
    fontSize: '16px',
    margin: '20px 0',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  widgetOption: {
    borderRadius: '4px',
    border: 'solid 1px #757575',
    padding: '8px 8px 8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  widgetOption2: {
    borderRadius: '4px',
    border: 'solid 1px #757575',
    padding: '15px 15px 15px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btn: {
    width: 96,
  },
  firstBtn: {
    width: 96,
    marginRight: 10,
  },
  mb10: {
    marginBottom: 10,
  },
  archivedText: {
    color: '#f50057',
  },
  configBtn: {
    width: 96,
    marginRight: 10,
  },
  viewBtn: {
    width: 96,
  },
  addBtn: {
    color: 'blue',
    width: 36,
    marginRight: 10,
    cursor: 'pointer',
  },
  removeBtn: {
    color: 'red',
    width: 36,
    marginRight: 10,
    cursor: 'pointer',
  },
}));

export default useInterviewGuideDndStyle;
