import Modal from 'components/molecules/Modal/Modal';
import { ModalTypes } from 'enums/modalTypes';
import {
  SoftSkillFragment,
  SoftSkillFragmentDoc,
  UpdateSoftSkillMutationVariables,
  useSoftSkillUploadIconMutation,
  useUpdateSoftSkillMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { TEditSoftSkillModal } from 'interfaces/modal';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import EditSoftSkillModalPresentational from './EditSoftSkillModalPresentational/EditSoftSkillModalPresentational';
import useEditSoftSkillModalStyle from './EditSoftSkillModalStyle';

export interface IEditSoftSkillModal extends TEditSoftSkillModal {}

const EditSoftSkillModal: FC<IEditSoftSkillModal> = ({
  softSkill,
  updateFilters,
}) => {
  const { handleMsgType } = useNotification();

  const classes = useEditSoftSkillModalStyle();
  const [editSoftSkillMutation] = useUpdateSoftSkillMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, response) => {
      if (response.data?.softSkill) {
        const cachedSoftSkill = cache.readFragment<SoftSkillFragment>({
          id: `SoftSkill:${response.data.softSkill.id}`,
          fragment: SoftSkillFragmentDoc,
          fragmentName: 'SoftSkill',
        });

        if (cachedSoftSkill) {
          cache.writeFragment<SoftSkillFragment>({
            id: `SoftSkill:${response.data.softSkill.id}`,
            fragment: SoftSkillFragmentDoc,
            fragmentName: 'SoftSkill',
            data: {
              ...cachedSoftSkill,
              ...response.data?.softSkill,
            },
          });
        }
      }
    },
  });

  const [softSkillUploadMutation] = useSoftSkillUploadIconMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
  });

  const handleSubmit = async (
    variables: UpdateSoftSkillMutationVariables,
    softSkillIcon: File | null
  ) => {
    try {
      const editSoftSkillMutationResponse = await editSoftSkillMutation({
        variables: variables,
      });

      if (editSoftSkillMutationResponse.data?.softSkill && softSkillIcon) {
        await softSkillUploadMutation({
          variables: {
            id: editSoftSkillMutationResponse.data.softSkill.id,
            file: softSkillIcon,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_SOFT_SKILL_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <EditSoftSkillModalPresentational
        softSkill={softSkill}
        updateFilters={updateFilters}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default EditSoftSkillModal;
