import TextWithTooltip from 'components/ui/TextWithTooltip/TextWithTooltip';
import { ProjectCompanyOrderField, ProjectFragment } from 'generated/graphql';
import { formatProjectDate } from 'helpers/project';
import { Tag, TagColor } from '@spotted-zebra-uk/ui-components';
import { emptyProjectFilterFormValues } from './components/ProjectsFilterModal/ProjectsFilterModal';
import styles from './Projects.module.scss';

export const DEFAULT_TABLE_PAGE_SIZE = 20;

export const tableHeaders = [
  {
    key: ProjectCompanyOrderField.ProjectName,
    header: 'Project name',
  },
  {
    key: ProjectCompanyOrderField.CompanyName,
    header: 'Company name',
  },
  {
    key: ProjectCompanyOrderField.ProjectCreatedAt,
    header: 'Created',
  },
  {
    key: ProjectCompanyOrderField.ProjectEditedAt,
    header: 'Last edited',
  },
  {
    key: ProjectCompanyOrderField.ProjectStatus,
    header: 'Status',
  },
  {
    key: 'archived',
    header: '',
  },
];

export function parseTableData(project: ProjectFragment) {
  return {
    id: project.id.toString(),
    [ProjectCompanyOrderField.ProjectName]: (
      <TextWithTooltip text={project.name} />
    ),
    [ProjectCompanyOrderField.CompanyName]: (
      <TextWithTooltip text={project.company?.name || ''} />
    ),
    [ProjectCompanyOrderField.ProjectCreatedAt]: formatProjectDate(
      project.createdAt
    ),
    [ProjectCompanyOrderField.ProjectEditedAt]: formatProjectDate(
      project.updatedAt
    ),
    [ProjectCompanyOrderField.ProjectStatus]: (
      <div className={styles.tagCellWrapper}>
        <Tag
          isDismissible={false}
          isPresentational
          tagColor={project.isStopped ? TagColor.BLUE : TagColor.GREEN}
        >
          {project.isStopped ? 'Closed' : 'Open'}
        </Tag>
      </div>
    ),
    archived: project.isArchived && (
      <div className={styles.archivedTag}>
        <Tag isDismissible={false} isPresentational>
          Archived
        </Tag>
      </div>
    ),
  };
}

export function getFiltersCount(filters: typeof emptyProjectFilterFormValues) {
  let count = 0;
  if (filters.isArchived) {
    count += 1;
  }
  return count;
}

export function getFiltersButtonAriaLabel(
  filters: typeof emptyProjectFilterFormValues
) {
  const filtersCount = getFiltersCount(filters);

  if (!filtersCount) {
    return 'Configure filter options';
  }

  if (filtersCount === 1) {
    return 'One filter applied, modify filters';
  }

  return `${filtersCount} filters applied, modify filters`;
}
