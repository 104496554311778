import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal } from '@spotted-zebra-uk/ui-components';
import styles from './ProjectFilterModal.module.scss';
import ProjectsFilterModalForm from './ProjectsFilterModalForm';

type FiltersFormValues = {
  isArchived: boolean;
};

type FiltersModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialValues: FiltersFormValues;
  onSubmit: (values: FiltersFormValues) => void;
};

export const emptyProjectFilterFormValues = { isArchived: false };

const validationSchema = yup.object({ isArchived: yup.boolean() });

const ProjectsFilterModal = ({
  isOpen,
  onClose,
  initialValues,
  onSubmit,
}: FiltersModalProps) => {
  const handleSubmit = (formValues: FiltersFormValues) => {
    onSubmit(formValues);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Filter"
      className={styles.projectsFilterModal}
      data-testid="projects-page-filter-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <ProjectsFilterModalForm onCancel={onClose} />
      </Formik>
    </Modal>
  );
};

export default ProjectsFilterModal;
