import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import { useField, useFormikContext } from 'formik';
import {
  CompanyEmployeeModel,
  ProjectRequestUserType,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { EmployeeModalMode } from 'views/ModalView/CompanyEmployeeModal/CompanyEmployeeModal.enums';
import {
  Button,
  TSelectOption,
  FormikSelectFormField,
  FormikSelectWithMenuButton,
  TOption,
} from '@spotted-zebra-uk/ui-components';

export const roleOptions: TSelectOption[] = [
  {
    label: 'Additional contact',
    value: ProjectRequestUserType.AdditionalContact,
  },
  {
    label: 'Manager',
    value: ProjectRequestUserType.Manager,
  },
  {
    label: 'Recruiter',
    value: ProjectRequestUserType.Recruiter,
  },
];

interface CreateProjectRequestContactsFormRowProps {
  index: number;
  employeesOptions: TOption[];
  remove: (index: number) => void;
  hasDeleteButton: boolean;
  companyId: number;
}

export const CreateProjectRequestContactsFormRow: FC<CreateProjectRequestContactsFormRowProps> = ({
  index,
  employeesOptions,
  remove,
  hasDeleteButton,
  companyId,
}) => {
  const { setFieldValue } = useFormikContext();

  const handleDelete = () => {
    remove(index);
  };

  const handleEmployeeCreated = (employee: CompanyEmployeeModel) => {
    setFieldValue(`contacts.${index}.employee`, {
      label: `${employee.firstName} ${employee.lastName}`,
      value: employee.email,
      labelNote: employee.email,
    });
  };

  const handleCreateEmployeeButtonClick = () => {
    openModal(ModalTypes.COMPANY_EMPLOYEE_MODAL, {
      employee: {
        companyId,
      },
      onEmployeeCreated: handleEmployeeCreated,
      mode: EmployeeModalMode.CREATE,
    });
  };

  return (
    <div className="create-project-request-contacts-form-row" key={index}>
      <FormikSelectFormField
        options={roleOptions}
        id={`contacts.${index}.role`}
        placeholder="Role"
        label="Role"
        className="create-project-request-contacts-form-row__role-select"
        useFormikField={useField}
      />
      <FormikSelectWithMenuButton
        options={employeesOptions}
        id={`contacts.${index}.employee`}
        placeholder="Employee"
        label="Employee"
        className="create-project-request-contacts-form-row__employee-select"
        hasMenuButton={!!companyId}
        menuButtonProps={{
          children: '+ Create New Employee',
          onClick: handleCreateEmployeeButtonClick,
        }}
        useFormikField={useField}
      />
      {hasDeleteButton && (
        <Button
          onClick={handleDelete}
          size="small"
          variant="secondary"
          className="create-project-request-contacts-form-row__delete-button"
          leftIcon={
            <Icon
              icon="delete_outline"
              className="create-project-request-contacts-form-row__delete-icon"
            />
          }
        />
      )}
    </div>
  );
};
