import {
  apiKeysRoutes,
  appRoutes,
  companyRoutes,
  contentRoutes,
  editCompanyRoutes,
  formManagerRoutes,
  interviewGuidesRoutes,
  jobRolesRoutes,
  projectRequestsRoutes,
  projectsRoutes,
  reportsRoutes,
  resultsRoutes,
  stageActionsRoutes,
  technicalSkillsRoutes,
  usersRoutes,
} from 'constants/navigation';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ApiKeys from 'views/ApiKeys/ApiKeys';
import ApiKeysCompany from 'views/ApiKeys/ApiKeysCompany/ApiKeysCompany';
import ApiKeysUsers from 'views/ApiKeys/ApiKeysUsers/ApiKeysUsers';
import CandidateInfo from 'views/CandidateInfo/CandidateInfo';
import CandidateInfoFields from 'views/CandidateInfo/CandidateInfoFields/CandidateInfoFields';
import CandidateRequirements from 'views/CandidateInfo/CompanyRequirements/CompanyRequirements';
import FormManager from 'views/CandidateInfo/FormManagerView/FormManager';
import Companies from 'views/Companies/Companies';
import CompanyApiKeys from 'views/Company/CompanyView/CompanyApiKeys/CompanyApiKeys';
import CompanyRoles from 'views/Company/CompanyView/CompanyRoles/CompanyRoles';
import CompanyView from 'views/Company/CompanyView/CompanyView';
import EditCompany from 'views/Company/CompanyView/EditCompany/EditCompany';
import CreateCompany from 'views/Company/CreateCompany/CreateCompany';
import CreateJobRole from 'views/CreateJobRole';
import EditJobRole from 'views/EditJobRole';
import InterviewGuide from 'views/InterviewGuide/InterviewGuide';
import InterviewGuideCategory from 'views/InterviewGuideCategory/InterviewGuideCategory';
import InterviewGuideList from 'views/InterviewGuides/InterviewGuideList/InterviewGuideList';
import InterviewGuides from 'views/InterviewGuides/InterviewGuides';
import InterviewGuidesCategories from 'views/InterviewGuidesCategories/InterviewGuidesCategories';
import CreateProjectView from 'views/Project/ProjectCreate';
import EditProjectView from 'views/Project/ProjectEdit';
import ProjectRequests from 'views/ProjectRequests';
import ProjectRequest from 'views/ProjectRequests/ProjectRequest';
import ProjectRequestCreate from 'views/ProjectRequests/ProjectRequestCreate';
import ProjectRequestsView from 'views/ProjectRequests/ProjectRequestsView';
import Projects from 'views/Projects/Projects';
import Report from 'views/Report/Report';
import ReportList from 'views/Reports/ReportList/ReportList';
import Reports from 'views/Reports/Reports';
import CandidateUsage from 'views/Results/CandidateUsage/CandidateUsage';
import QBRData from 'views/Results/QBR/QBR';
import Results from 'views/Results/Results';
import SkillsValidated from 'views/Results/SkillsValidated/SkillsValidated';
import StageCSV from 'views/Results/StageCSV/StageCSV';
import Users from 'views/Results/Users/Users';
import CodingAssessmentView from 'views/StageActions/CodingAssessments/CodingAssessments';
import StageActionsView from 'views/StageActions/StageActions';
import BehaviourContent from 'views/StaticContent/BehaviourContent/BehaviourContent';
import InterviewQuestionContent from 'views/StaticContent/InterviewQuestionContent/InterviewQuestionContent';
import SoftSkillContent from 'views/StaticContent/SoftSkillContent/SoftSkillContent';
import SoftSkills from 'views/StaticContent/SoftSkills/SoftSkills';
import SoftSkillSuccessProfileContent from 'views/StaticContent/SoftSkillSuccessProfileContent/SoftSkillSuccessProfileContent';
import StaticContent from 'views/StaticContent/StaticContent';
import AddTechnicalSkillsView from 'views/StaticContent/TechnicalSkills/AddTechnicalSkills/AddTechnicalSkills.view';
import TechnicalSkills from 'views/StaticContent/TechnicalSkills/TechnicalSkills';
import TraitContent from 'views/StaticContent/TraitContent/TraitContent';
import Traits from 'views/StaticContent/Traits/Traits';
import Validate from 'views/StaticContent/Validate/Validate';
import RetriveTpCandidates from 'views/User/RetrieveTpCandidates/RetrieveTpCandidates';
import User from 'views/User/User';
import UserDetails from 'views/User/UserDetails/UserDetails';
import UserMain from 'views/User/UserMain/UserMain';
import Widget from 'views/Widget/Widget';
import Widgets from 'views/Widgets/Widgets';
import AtsRequisitions from '../views/Results/AtsRequisitions/AtsRequisitions';

const RootRedirection = () => <Navigate to={appRoutes.projects.path} />;

const RootSwitch: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<RootRedirection />} />

      {/* Projects routes */}
      <Route path={`${appRoutes.projects.path}`} element={<Projects />} />
      <Route
        path={projectsRoutes.createProject.path}
        element={<CreateProjectView />}
      />
      <Route
        path={projectsRoutes.createProjectWithProjectRequest.path}
        element={<CreateProjectView />}
      />
      <Route
        path={projectsRoutes.editProjectView.path}
        element={<EditProjectView />}
      />
      <Route
        path={projectsRoutes.editProjectViewWithProjectRequest.path}
        element={<EditProjectView />}
      />

      {/* Project request routes */}
      <Route element={<ProjectRequests />}>
        <Route
          path={appRoutes.projectRequests.path}
          element={<ProjectRequestsView />}
        />
        <Route
          path={projectRequestsRoutes.createProjectRequest.path}
          element={<ProjectRequestCreate />}
        />
        <Route
          path={projectRequestsRoutes.projectRequest.path}
          element={<ProjectRequest />}
        />
      </Route>

      {/* Results routes */}
      <Route element={<Results />}>
        <Route
          path={resultsRoutes.candidateResults.path}
          element={<StageCSV />}
        />
        <Route
          path={resultsRoutes.candidateUsage.path}
          element={<CandidateUsage />}
        />
        <Route
          path={resultsRoutes.skillsValidated.path}
          element={<SkillsValidated />}
        />
        <Route path={resultsRoutes.users.path} element={<Users />} />
        <Route path={resultsRoutes.QBRData.path} element={<QBRData />} />
        <Route
          path={resultsRoutes.atsRequisitions.path}
          element={<AtsRequisitions />}
        />
        <Route
          path={appRoutes.results.path}
          element={<Navigate to={resultsRoutes.candidateResults.path} />}
        />
      </Route>

      {/* Company routes */}
      <Route path={appRoutes.companies.path} element={<Companies />} />

      <Route
        path={companyRoutes.createCompany.path}
        element={<CreateCompany />}
      />

      <Route element={<CompanyView />}>
        <Route
          path={companyRoutes.editCompany.path}
          element={<EditCompany />}
        />
        <Route
          path={editCompanyRoutes.editCompanyApiKeys.path}
          element={<CompanyApiKeys />}
        />
        <Route
          path={editCompanyRoutes.editCompanyRoles.path}
          element={<CompanyRoles />}
        />
      </Route>

      {/* Form manager routes */}
      <Route path={appRoutes.formManager.path} element={<CandidateInfo />}>
        <Route
          path={appRoutes.formManager.path}
          element={<Navigate to={formManagerRoutes.fields.path} />}
        />
        <Route
          path={formManagerRoutes.fields.path}
          element={<CandidateInfoFields />}
        />
        <Route
          path={formManagerRoutes.companyForms.path}
          element={<CandidateRequirements />}
        />
        <Route
          path={formManagerRoutes.downloadSubmittedForms.path}
          element={<FormManager />}
        />
      </Route>

      {/** Stage actions routes */}
      <Route element={<StageActionsView />}>
        <Route
          path={appRoutes.stageActions.path}
          element={<CodingAssessmentView />}
        />
        <Route
          path={stageActionsRoutes.codingAssessments.path}
          element={<CodingAssessmentView />}
        />
      </Route>

      {/** Static content routes */}
      <Route element={<StaticContent />}>
        <Route
          path={appRoutes.content.path}
          element={<Navigate to={contentRoutes.softSkills.path} />}
        />
        <Route path={contentRoutes.softSkills.path} element={<SoftSkills />} />
        <Route path={contentRoutes.traits.path} element={<Traits />} />
        <Route
          path={contentRoutes.softSkillContent.path}
          element={<SoftSkillContent />}
        />
        <Route
          path={contentRoutes.softSkillSuccessProfileContent.path}
          element={<SoftSkillSuccessProfileContent />}
        />
        <Route
          path={contentRoutes.traitContent.path}
          element={<TraitContent />}
        />
        <Route
          path={contentRoutes.interviewQuestionContent.path}
          element={<InterviewQuestionContent />}
        />
        <Route path={contentRoutes.validate.path} element={<Validate />} />
        <Route
          path={contentRoutes.technicalSkills.path}
          element={<TechnicalSkills />}
        />
        <Route
          path={technicalSkillsRoutes.addNew.path}
          element={<AddTechnicalSkillsView />}
        />
        <Route
          path={contentRoutes.behaviourContent.path}
          element={<BehaviourContent />}
        />
      </Route>

      {/** Reports routes */}
      <Route path={appRoutes.reports.path} element={<Reports />}>
        <Route
          path={appRoutes.reports.path}
          element={<Navigate to={reportsRoutes.reports.path} />}
        />
        <Route path={reportsRoutes.report.path} element={<Report />} />
        <Route path={reportsRoutes.reports.path} element={<ReportList />} />
        <Route path={reportsRoutes.widget.path} element={<Widget />} />
        <Route path={reportsRoutes.widgets.path} element={<Widgets />} />
      </Route>

      <Route path={appRoutes.users.path} element={<User />}>
        <Route path={appRoutes.users.path} element={<UserMain />} />
        <Route
          path={usersRoutes.retrieveTpDetails.path}
          element={<RetriveTpCandidates />}
        />
        <Route path={usersRoutes.user.path} element={<UserDetails />} />
      </Route>

      {/** Api Keys routes */}
      <Route path={appRoutes.apiKeys.path} element={<ApiKeys />}>
        <Route
          path={appRoutes.apiKeys.path}
          element={<Navigate to={apiKeysRoutes.companies.path} />}
        />
        <Route
          path={apiKeysRoutes.companies.path}
          element={<ApiKeysCompany />}
        />
        <Route path={apiKeysRoutes.users.path} element={<ApiKeysUsers />} />
      </Route>

      {/** Interview Guides routes */}
      <Route
        path={appRoutes.interviewGuides.path}
        element={<InterviewGuides />}
      >
        <Route
          path={appRoutes.interviewGuides.path}
          element={<Navigate to={interviewGuidesRoutes.categories.path} />}
        />
        <Route
          path={interviewGuidesRoutes.category.path}
          element={<InterviewGuideCategory />}
        />
        <Route
          path={interviewGuidesRoutes.categories.path}
          element={<InterviewGuidesCategories />}
        />
        <Route
          path={interviewGuidesRoutes.interviewGuide.path}
          element={<InterviewGuide />}
        />
        <Route
          path={interviewGuidesRoutes.interviewGuides.path}
          element={<InterviewGuideList />}
        />
      </Route>

      {/** Job Role routes */}
      <Route
        path={jobRolesRoutes.createJobRole.path}
        element={<CreateJobRole />}
      />
      <Route path={jobRolesRoutes.editJobRole.path} element={<EditJobRole />} />
    </Routes>
  );
};

export default RootSwitch;
