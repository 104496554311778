import CandidateInfoNavigation from 'navigation/CandidateInfoNavigation/CandidateInfoNavigation';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import useCandidateInfoStyle from './CandidateInfoStyle';

interface ICandidateInfo {}

const CandidateInfo: FC<ICandidateInfo> = () => {
  const classess = useCandidateInfoStyle();
  return (
    <>
      <CandidateInfoNavigation />
      <div className={classess.container}>
        <Outlet />
      </div>
    </>
  );
};

export default CandidateInfo;
