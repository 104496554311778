import Icon from 'components/atoms/Icon';
import { ProjectFragment } from 'generated/graphql';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './ProjectLink.module.scss';

type Props = {
  project: ProjectFragment;
  projectPath: string;
};

const ProjectLink: FC<Props> = ({ projectPath, project }) => (
  <Link to={projectPath}>
    <div className={styles['content']} data-testid="project-link">
      <Icon icon="left_arrow" className={styles['icon']} />
      Projects / {project.company?.name || ''} / {project.name}
    </div>
  </Link>
);

export default ProjectLink;
