import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import { FormManagerType, FormType } from 'enums/forms/form';
import { FIELDS_QUERY } from 'graphql/form/field';
import { GET_FORM_BUILDER_QUERY } from 'graphql/form/formBuilder';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import {
  IFieldsQueryInput,
  IFieldsQueryResponse,
  IGetFormBulderQueryInput,
  IGetFormBulderQueryResponse,
} from 'interfaces/forms/formGQL';
import { FC, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CompanyRequriementsContent from './CompanyRequirementsContent/CompanyRequriementsContent';
import CompanyRequirementsCreateFormButton from './CompanyRequirementsCreateFormButton/CompanyRequirementsCreateFormButton';
import CompanyRequirementsHeader from './CompanyRequirementsHeader/CompanyRequirementsHeader';

const CompanyRequirements: FC = () => {
  const { handleMsgType } = useNotification();

  const [isArchivedVisible, setIsArchivedVisible] = useState<boolean>(false);
  const [companyIdFilter, setCompanyIdFilter] = useState<string>('');
  const [formFilter, setFormFilter] = useState<FormType>(FormType.CI_FORM);
  const [isRefetchLoading, setIsRefetchLoading] = useState(false);

  const [
    testFunction,
    getCompanyRequirementsFormBuilderQueryResponse,
  ] = useLazyQuery<IGetFormBulderQueryResponse, IGetFormBulderQueryInput>(
    GET_FORM_BUILDER_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });

        getCompanyRequirementsFormBuilderQueryResponse.client.cache.restore({});
      },
    }
  );

  useEffect(() => {
    testFunction({
      variables: {
        formType: formFilter,
        formManagerType: getFormManagerType(formFilter),
        formOwnerId: 0,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fields data needed for checking if selected company owns specific fields.
  // Check CompanyRequirementsFormBuilder.tsx.
  const fieldsQueryResponse = useQuery<IFieldsQueryResponse, IFieldsQueryInput>(
    FIELDS_QUERY,
    {
      // TODO: Currently backend breaks if archived fields are queried,
      // this is added as temporarily solution. Remove after it is fixed on backend.
      variables: {
        isArchived: false,
      },
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
    }
  );

  const getFormManagerType = (formType: FormType): FormManagerType => {
    switch (formType) {
      case FormType.TR_FORM:
        return FormManagerType.TR;
      case FormType.RS_FORM:
        return FormManagerType.RS;
      case FormType.APPRENTICE_FORM:
      case FormType.GRAD_FORM:
      case FormType.UNDERGRAD_FORM:
        return FormManagerType.APPRENTICE;
      default:
        return FormManagerType.CI;
    }
  };
  const refechCompanyRequirementsFormBuilderQuery = async (
    companyIdFilter: number,
    formFilter: FormType
  ) => {
    const formManagerType: FormManagerType = getFormManagerType(formFilter);
    setIsRefetchLoading(true);
    try {
      await getCompanyRequirementsFormBuilderQueryResponse.refetch({
        formType: formFilter,
        formManagerType,
        formOwnerId: companyIdFilter,
      });
    } catch (error) {
      console.log(error);
    }

    setIsRefetchLoading(false);
  };

  const handleChangeCompanyIdFilter = async (
    newCompanyId: TSelectFormFieldValue,
    name: string
  ) => {
    if (newCompanyId) {
      setCompanyIdFilter(newCompanyId as string);
      refechCompanyRequirementsFormBuilderQuery(
        newCompanyId ? +newCompanyId : 0,
        formFilter
      );
    }
  };

  const handleChangeFormFilter = async (
    newForm: TSelectFormFieldValue,
    name: string
  ) => {
    setFormFilter(newForm as FormType);
    refechCompanyRequirementsFormBuilderQuery(
      companyIdFilter ? +companyIdFilter : 0,
      newForm as FormType
    );
  };

  const handleToggleArchivedProjectsVisibility = () => {
    setIsArchivedVisible(prevIsArchivedVisible => !prevIsArchivedVisible);
  };

  const renderContent = () => {
    if (
      getCompanyRequirementsFormBuilderQueryResponse.loading ||
      fieldsQueryResponse.loading ||
      isRefetchLoading
    ) {
      return <CircularLoader />;
    }

    if (fieldsQueryResponse.data && companyIdFilter) {
      if (
        !getCompanyRequirementsFormBuilderQueryResponse.data ||
        !getCompanyRequirementsFormBuilderQueryResponse.data?.formBuilder ||
        !getCompanyRequirementsFormBuilderQueryResponse.data.formBuilder.form
      ) {
        return (
          <CompanyRequirementsCreateFormButton
            companyId={+companyIdFilter}
            formFilter={formFilter}
            onCreateCompleted={() => {
              refechCompanyRequirementsFormBuilderQuery(
                companyIdFilter ? +companyIdFilter : 0,
                formFilter
              );
            }}
          />
        );
      } else {
        /**
         * Company requirements form builder should be rerendered when:
         * - New field available to that form builder created.
         * - Field owned by form builder or availabel to from builder updated, archived or deleted.
         * - Form builder has been updated.
         * - User change state of filter that determines are archived fields visible in form builder.
         */
        const key = JSON.stringify({
          formBuilder:
            getCompanyRequirementsFormBuilderQueryResponse.data.formBuilder,
          isArchivedVisible,
        });

        return (
          <CompanyRequriementsContent
            key={key}
            companyId={+companyIdFilter}
            isArchivedVisible={isArchivedVisible}
            companyRequirementsFormBuilder={
              getCompanyRequirementsFormBuilderQueryResponse.data.formBuilder
            }
            formFilter={formFilter}
          />
        );
      }
    }
    return null;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <CompanyRequirementsHeader
        isArchivedVisible={isArchivedVisible}
        companyIdFilter={companyIdFilter}
        formFilter={formFilter}
        onToggleIsArchivedVisibility={handleToggleArchivedProjectsVisibility}
        onChangeCompanyIdFilter={handleChangeCompanyIdFilter}
        onFormFilterChange={handleChangeFormFilter}
      />
      {renderContent()}
    </Grid>
  );
};

export default CompanyRequirements;
