import './styles.scss';
import { appRoutes, projectsRoutes } from 'constants/navigation';
import {
  Project,
  ProjectRequestStatus,
  ProjectsDocument,
  ProjectsQuery,
  ProjectsQueryVariables,
  useCreateProjectMutation,
  useProjectCollaboratorsCreateManyMutation,
  useProjectRequestFindOneQuery,
  useProjectRequestUpdateOneMutation,
} from 'generated/graphql';
import { ICreateProjectPath } from 'interfaces/routes';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ProjectOverviewForm, {
  IProjectOverviewFormSubmitValues,
  ProjectFormMode,
} from './components/ProjectOverviewForm';
import ProjectBar from './ProjectBar';

const ProjectCreate: FC = () => {
  const { handleMsgType } = useNotification();

  const navigate = useNavigate();
  const params = useParams() as ICreateProjectPath;
  const parsedProjectRequestId = Number(params.projectRequestId);
  const projectRequestQueryResponse = useProjectRequestFindOneQuery({
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        title: error?.message,
      }),
    skip: !parsedProjectRequestId,
    variables: {
      id: parsedProjectRequestId || 0,
    },
  });

  const [createProjectCollaborators] =
    useProjectCollaboratorsCreateManyMutation({
      onError: error =>
        handleMsgType({
          type: TNotification.error,
          title: 'Something went wrong while saving project collaborators',
        }),
    });

  const [projectRequestUpdateMutation] = useProjectRequestUpdateOneMutation({
    onCompleted: ({ ProjectRequestUpdateOne }) => {
      if (ProjectRequestUpdateOne) {
        navigate(
          projectsRoutes.editProjectViewWithProjectRequest.url(
            ProjectRequestUpdateOne.projectId || 0,
            parsedProjectRequestId
          )
        );
      }
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: 'Error happened during updating project request!',
      });
    },
  });
  const [createProjectMutation] = useCreateProjectMutation({
    update(cache, { data }) {
      const project = data?.project as Project;
      if (project) {
        const data = cache.readQuery<ProjectsQuery, ProjectsQueryVariables>({
          query: ProjectsDocument,
        });
        if (data?.projects && project) {
          cache.writeQuery({
            query: ProjectsDocument,
            data: { projects: [project, ...data.projects] },
          });
        }
      }
    },
    onCompleted: async ({ project }) => {
      if (project) {
        if (parsedProjectRequestId) {
          projectRequestUpdateMutation({
            variables: {
              status: ProjectRequestStatus.ProjectCreated,
              id: parsedProjectRequestId,
              projectId: project.id,
            },
          });
        } else {
          handleMsgType({
            type: TNotification.success,
            title: 'Project successfully created',
          });
        }
      }
    },
  });

  const handleCreateProject = async ({
    name,
    collaborators,
    companyId,
    projectModuleType,
    requireSheetsExport,
    productSolution,
  }: IProjectOverviewFormSubmitValues) => {
    const result = await createProjectMutation({
      variables: {
        name,
        companyId: Number(companyId),
        moduleType: projectModuleType,
        requireSheetsExport,
        ...(productSolution && { productSolution }),
      },
    });
    const projectId = result.data?.project.id;
    if (projectId) {
      if (collaborators.length) {
        await createProjectCollaborators({
          variables: {
            collaborators: collaborators.map(collaborator => ({
              actorRole: collaborator.role,
              userId: collaborator.userId,
              groupId: collaborator.groupId,
              projectId: Number(projectId),
            })),
          },
        });
      }
      navigate(projectsRoutes.editProjectView.url(projectId));
    } else {
      handleMsgType({
        type: TNotification.error,
        title: 'Something has gone wrong while creating this project',
      });
    }
  };

  const loading = projectRequestQueryResponse.loading;

  if (loading) {
    return (
      <div className="project-loader-wrapper">
        <Loader variant="bubbles" />
      </div>
    );
  }

  const predefinedCreateProjectData = {
    companyId:
      projectRequestQueryResponse.data?.ProjectRequestFindOne?.companyId,
    projectName:
      projectRequestQueryResponse.data?.ProjectRequestFindOne?.projectName,
  };

  const handleCancel = () => {
    navigate(appRoutes.projects.url());
  };

  return (
    <div className="project">
      <div className="project-create">
        <ProjectBar projectRequestId={parsedProjectRequestId} />
        <div className="project-create__title">New Project</div>
        <ProjectOverviewForm
          onProjectSave={handleCreateProject}
          onCancel={handleCancel}
          predefinedCreateProjectData={predefinedCreateProjectData}
          mode={ProjectFormMode.CREATE}
        />
      </div>
    </div>
  );
};

export default ProjectCreate;
