import { FC } from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../components/atoms/Button/Button';
import { interviewGuidesRoutes } from '../../../../constants/navigation';
import {
  IgCategory,
  IgCategoryColour,
  IgInterviewGuideCategory,
  IgInterviewGuideCategoryFindManyDocument,
  IgInterviewGuideCategoryFindManyQuery,
  IgInterviewGuideCategoryFindManyQueryVariables,
  useIgInterviewGuideCategoryCreateMutation,
} from '../../../../generated/graphql';
import useInterviewGuideDndStyle from '../InterviewGuideDndStyle';

interface ICategoryItem {
  category: IgCategory;
  interviewGuideId: number;
  interviewGuideLastItem: IgInterviewGuideCategory;
}

const CategoryItem: FC<ICategoryItem> = ({
  category,
  interviewGuideId,
  interviewGuideLastItem,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useInterviewGuideDndStyle();

  const [addInterviewGuideCategory] = useIgInterviewGuideCategoryCreateMutation(
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      update: (cache, { data }) => {
        const newInterviewGuideCategory = data?.IgInterviewGuideCategoryCreate as IgInterviewGuideCategory;
        if (newInterviewGuideCategory) {
          const interviewGuideCategoriesData = cache.readQuery<
            IgInterviewGuideCategoryFindManyQuery,
            IgInterviewGuideCategoryFindManyQueryVariables
          >({
            query: IgInterviewGuideCategoryFindManyDocument,
            variables: {
              interviewGuideId,
            },
          });
          if (
            newInterviewGuideCategory &&
            interviewGuideCategoriesData?.IgInterviewGuideCategoryFindMany
          ) {
            cache.writeQuery<
              IgInterviewGuideCategoryFindManyQuery,
              IgInterviewGuideCategoryFindManyQueryVariables
            >({
              query: IgInterviewGuideCategoryFindManyDocument,
              variables: {
                interviewGuideId,
              },
              data: {
                IgInterviewGuideCategoryFindMany: [
                  ...interviewGuideCategoriesData.IgInterviewGuideCategoryFindMany,
                  newInterviewGuideCategory,
                ],
              },
            });
          }
        }
      },
    }
  );
  const handleAddCategory = (category: IgCategory) => {
    let variables;
    if (interviewGuideLastItem?.order) {
      variables = {
        categoryId: category.id,
        interviewGuideId,
        order: interviewGuideLastItem.order + 1,
        colour: IgCategoryColour.Colour1,
      };
    } else {
      variables = {
        categoryId: category.id,
        interviewGuideId,
        order: 0,
        colour: IgCategoryColour.Colour1,
      };
    }
    addInterviewGuideCategory({ variables });
  };

  return (
    <>
      <div
        style={{
          border: 'solid 1px #757575',
          backgroundColor: '#fff',
          marginBottom: 10,
        }}
        className={classes.widgetOption}
      >
        <p className={classes.text}>
          <AddCircleOutlineIcon
            className={classes.addBtn}
            onClick={() => handleAddCategory(category)}
          />

          {category.name}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '420px',
            }}
          >
            <Link to={interviewGuidesRoutes.category.url(category.id || 0)}>
              <Button className={classes.viewBtn} color="inherit">
                VIEW
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryItem;
