import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { useIgCategoryUpdateMutation } from '../../../generated/graphql';
import validate from '../../../helpers/validate';
import useInterviewGuideCategoryStyles from '../InterviewGuideCategoryStyle';

interface IAddCategoryPayload {
  categoryName: string;
  categoryInternalName: string;
}

interface IEditCategory {
  id: number;
  name: string;
  internalName: string;
}

interface IEditCategoryError {
  categoryName: string;
  categoryInternalName: string;
}

const EditCategory: FC<IEditCategory> = ({ id, name, internalName }) => {
  const { handleMsgType } = useNotification();

  const classes = useInterviewGuideCategoryStyles();
  const initialValue = {
    categoryName: name,
    categoryInternalName: internalName,
  };
  const [categoryInfo, setCategoryInfo] = useState<IAddCategoryPayload>(
    initialValue
  );

  const [errors, setErrors] = useState<IEditCategoryError>({
    categoryName: '',
    categoryInternalName: '',
  });

  const [updateCategoryMutation] = useIgCategoryUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const getCategoryName = (value: string) => {
    setCategoryInfo(prev => ({ ...prev, categoryName: value }));
    if (errors.categoryName) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, categoryName: '' } : prevErrors
      );
    }
  };
  const getCategoryInternalName = (value: string) => {
    setCategoryInfo(prev => ({ ...prev, categoryInternalName: value }));
    if (errors.categoryInternalName) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, categoryInternalName: '' } : prevErrors
      );
    }
  };

  const addCategoryFormValidation = (newValues: IAddCategoryPayload) =>
    validate(newValues, {
      categoryName: {
        nonEmptyString: true,
      },
      categoryInternalName: {
        nonEmptyString: true,
      },
    });

  const handleSubmitCategory = () => {
    const reportErrors = addCategoryFormValidation(categoryInfo);
    if (!_.isEmpty(reportErrors)) return setErrors(reportErrors);
    const variables = {
      id,
      name: categoryInfo.categoryName,
      internalName: categoryInfo.categoryInternalName,
    };
    updateCategoryMutation({ variables });
  };

  return (
    <>
      <Grid item container direction="row" xs={12}>
        <Grid xs={6} item>
          <TextFormField
            id="categoryName"
            name="categoryName"
            value={categoryInfo.categoryName}
            label="Category Name"
            onChange={getCategoryName}
            hasError={!!errors?.categoryName}
            errorText={errors?.categoryName}
          />
        </Grid>
        <Grid xs={6} item>
          <TextFormField
            id="categoryInternalName"
            name="categoryInternalName"
            value={categoryInfo.categoryInternalName}
            label="Category Internal Name"
            onChange={getCategoryInternalName}
            hasError={!!errors?.categoryInternalName}
            errorText={errors?.categoryInternalName}
          />
        </Grid>
      </Grid>
      <Grid>
        <div style={{ marginTop: '10px' }}>
          <Button
            className={classes.btn}
            onClick={handleSubmitCategory}
            color="inherit"
          >
            SAVE
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default EditCategory;
