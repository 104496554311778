import { apiKeysRoutes } from 'constants/navigation';

export const apiKeysSidebarConfig = [
  {
    title: 'Companies',
    path: apiKeysRoutes.companies.path,
  },
  {
    title: 'Users',
    path: apiKeysRoutes.users.path,
  },
];
