import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  ApiKeyFindAllDocument,
  ApiKeyFindAllQuery,
  useApiKeyDisableMutation,
} from '../../../generated/graphql';
import { closeModal, openModal } from '../../../graphql/modals';
import Button from '../../atoms/Button/Button';

interface IDeleteApiKeyButton {
  apiKeyId: number;
  apiKey: string;
}

const confirmationModalMessage =
  'Are you sure you want to disable this API key?  If the company is continuing to use the API you will need to update the external system to use a new API key';

const DeleteApiKeyButton: FC<IDeleteApiKeyButton> = ({ apiKey, apiKeyId }) => {
  const { handleMsgType } = useNotification();

  const [
    apiKeyDisableMutation,
    apiKeyDisableMutationResponse,
  ] = useApiKeyDisableMutation({
    onCompleted: () => {
      closeModal(ModalTypes.CONFIRMATION_MODAL);
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: cache => {
      const apiKeysFromCache = cache.readQuery<ApiKeyFindAllQuery>({
        query: ApiKeyFindAllDocument,
      });

      if (apiKeysFromCache) {
        cache.writeQuery<ApiKeyFindAllQuery>({
          query: ApiKeyFindAllDocument,
          data: {
            ApiKeyFindAll: apiKeysFromCache.ApiKeyFindAll.filter(
              apiKey => apiKey.id !== apiKeyId
            ),
          },
        });
      }
    },
  });

  const handleDisableButtonClick = () => {
    openModal(ModalTypes.CONFIRMATION_MODAL, {
      open: true,
      message: confirmationModalMessage,
      onSave: () => {
        apiKeyDisableMutation({
          variables: {
            key: apiKey,
          },
        });
      },
      onClose: () => {
        closeModal(ModalTypes.CONFIRMATION_MODAL);
      },
      saveBtnText: 'Disable',
    });
  };

  if (apiKeyDisableMutationResponse.loading) {
    return <CircularProgress />;
  }

  return (
    <Button onClick={handleDisableButtonClick} color="secondary">
      Disable
    </Button>
  );
};

export default DeleteApiKeyButton;
