import Icon from 'components/atoms/Icon';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormikSelectFormField,
  FormikTextInputField,
} from '@spotted-zebra-uk/ui-components';
import ContactsSubForm, {
  initialValues as contactsInitialValues,
} from '../ContactsSubForm';
import { ProjectRequestCreateFormValues } from '../types';

interface FromFieldsProps {
  companiesOptions: { label: string; value: string }[];
}

const validationSchema = yup.object().shape({
  projectName: yup.string().required('Field is required'),
  company: yup.object().shape({
    label: yup.string(),
    value: yup.string().required('Field is required'),
  }),
});

const FormFields: FC<FromFieldsProps> = ({ companiesOptions }) => {
  const { handleSubmit } = useFormikContext();

  const handleSubmitClick = () => {
    handleSubmit();
  };

  return (
    <Form className="project-request-create-form">
      <div className="project-request-create-form__title">Project Request</div>
      <div className="project-request-create-form__fields">
        <div className="project-request-create-form__fields-row">
          <FormikSelectFormField
            id="company"
            options={companiesOptions}
            className="project-request-create-form__company-select"
            label="Company"
            placeholder="Company"
            useFormikField={useField}
          />
          <FormikTextInputField
            id="projectName"
            className="project-request-create-form__project-name-input"
            label="Project Name"
            placeholder="Project Name"
            useFormikField={useField}
          />
        </div>
        <ContactsSubForm />
      </div>
      <div>
        <Button
          type="button"
          onClick={handleSubmitClick}
          rightIcon={
            <Icon
              icon="left_arrow"
              className="project-request-create-form__save-button-icon"
            />
          }
        >
          Save Project Request
        </Button>
      </div>
    </Form>
  );
};

const initialValues: ProjectRequestCreateFormValues = {
  projectName: '',
  company: { label: '', value: '' },
  contacts: contactsInitialValues,
};

interface ProjectRequestCreateFormPureProps {
  companiesOptions: { label: string; value: string }[];
  onSubmit: (values: ProjectRequestCreateFormValues) => void | Promise<void>;
}

const ProjectRequestCreateFormPure: FC<ProjectRequestCreateFormPureProps> = ({
  companiesOptions,
  onSubmit,
}) => {
  return (
    <Formik<ProjectRequestCreateFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormFields companiesOptions={companiesOptions} />
    </Formik>
  );
};

export default ProjectRequestCreateFormPure;
