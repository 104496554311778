import InterviewGuidesNavigation from 'navigation/InterviewGuidesNavigation/InterviewGuidesNavigation';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import useInterviewGuideStyle from './InterviewGuidesStyle';

const InterviewGuides: FC = () => {
  const classes = useInterviewGuideStyle();
  return (
    <>
      <InterviewGuidesNavigation />
      <div className={classes.container}>
        <Outlet />
      </div>
    </>
  );
};

export default InterviewGuides;
