import { FC } from 'react';
import { Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CircularLoader from '../../../../../components/molecules/CircularLoader/CircularLoader';
import {
  ApiKeyOwnerType,
  useApiKeyFindAllQuery,
} from '../../../../../generated/graphql';
import ApiKeyListItem from './ApiKeyListItem/ApiKeyListItem';

interface ICompanyApiKeysList {
  companyId: number;
}

const CompanyApiKeysList: FC<ICompanyApiKeysList> = ({ companyId }) => {
  const { handleMsgType } = useNotification();

  const apiKeysQueryResponse = useApiKeyFindAllQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  if (apiKeysQueryResponse.error) {
    return <div>Error: {apiKeysQueryResponse.error.message}</div>;
  }

  if (apiKeysQueryResponse.data) {
    const companyApiKeys = apiKeysQueryResponse.data.ApiKeyFindAll.filter(
      apiKey =>
        apiKey.ownerType === ApiKeyOwnerType.Company &&
        apiKey.ownerId === companyId
    );

    return (
      <Grid container item>
        {companyApiKeys.map(apiKey => (
          <ApiKeyListItem
            key={apiKey.id}
            apiKey={{
              id: apiKey.id,
              key: apiKey.key,
              createdAt: apiKey.createdAt,
              role: apiKey.role,
            }}
          />
        ))}
      </Grid>
    );
  }

  return <CircularLoader />;
};

export default CompanyApiKeysList;
