import { FC, useState } from 'react';
import { Button, Grid } from '@mui/material';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import validate from '../../../../helpers/validate';
import { TTextFromFieldValue } from '../../../../interfaces/forms/form';
import useCompanyNameFormStyle from './CompanyNameFormStyle';

interface ICompanyNameForm {
  onSubmit: (name: string) => void;
}

interface ICompanyNameFormErrors {
  name: string;
}

interface ICompanyNameFormValues {
  name: string;
}

const CompanyNameForm: FC<ICompanyNameForm> = props => {
  const classes = useCompanyNameFormStyle();
  const [values, setValues] = useState<ICompanyNameFormValues>({
    name: '',
  });
  const [errors, setErrors] = useState<ICompanyNameFormErrors | undefined>(
    undefined
  );

  const handleNameChange = (value: TTextFromFieldValue) => {
    setValues({ name: value });
    if (errors?.name) {
      setErrors({ name: '' });
    }
  };

  const validateCompanyNameForm = () => {
    return validate(values, {
      name: {
        nonEmptyString: {
          message: '^Must not be empty.',
        },
      },
    });
  };

  const handleSubmit = () => {
    const newErrors = validateCompanyNameForm();
    if (!newErrors) {
      props.onSubmit(values.name);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item className={classes.inputWrapper}>
        <TextFormField
          id="company-name"
          name="name"
          value={values.name}
          onChange={handleNameChange}
          label="Company name"
        />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={handleSubmit}
          className={classes.button}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default CompanyNameForm;
