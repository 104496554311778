import { createStyles, makeStyles } from '@mui/styles';
import isDevelopmentEnvironment from '../../../helpers/isDevelopmentEnvironment';

const drawerWidth = 240;

export const useSidebarStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
      marginTop: isDevelopmentEnvironment() ? 145 : 81,
      position: 'absolute',
      height: `calc(100% - ${isDevelopmentEnvironment() ? 145 : 81}px)`,
      zIndex: 1,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);
