import { FC } from 'react';
import { useMutation } from '@apollo/client';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../../components/atoms/Button/Button';
import {
  FIELD_UPDATE_MUTATION,
  FIELDS_QUERY,
} from '../../../../../../graphql/form/field';
import { FieldsFragments } from '../../../../../../graphql/form/fragments';
import {
  IFieldsQueryInput,
  IFieldsQueryResponse,
  IFieldUpdateMutationInput,
  IFieldUpdateMutationResponse,
  TFieldGQL,
} from '../../../../../../interfaces/forms/formGQL';

interface IArchiveCandidateInfoFieldButton {
  candidateInformationFieldId: number;
  companyId?: number;
}

const ArchiveCandidateInfoFieldButton: FC<IArchiveCandidateInfoFieldButton> = ({
  candidateInformationFieldId,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const [updateCandidateInformationFieldMutation] = useMutation<
    IFieldUpdateMutationResponse,
    IFieldUpdateMutationInput
  >(FIELD_UPDATE_MUTATION, {
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      if (data) {
        const { field } = data;
        try {
          const fieldsNotArchivedCacheData = cache.readQuery<
            IFieldsQueryResponse,
            IFieldsQueryInput
          >({
            query: FIELDS_QUERY,
            variables: {
              isArchived: false,
            },
          });
          if (fieldsNotArchivedCacheData) {
            cache.writeQuery<IFieldsQueryResponse, IFieldsQueryInput>({
              query: FIELDS_QUERY,
              data: {
                fields: fieldsNotArchivedCacheData.fields.filter(
                  fieldsItem => fieldsItem.id !== field.id
                ),
              },
              variables: {
                isArchived: false,
              },
            });
          }
        } catch (error) {}

        try {
          const cacheFieldData = cache.readFragment<TFieldGQL>({
            id: cache.identify(data.field),
            fragment: FieldsFragments.field(data.field),
          });

          if (cacheFieldData) {
            cache.writeFragment<TFieldGQL>({
              id: cache.identify(data.field),
              fragment: FieldsFragments.field(data.field),
              data: {
                ...cacheFieldData,
                ...data.field,
              },
            });
          }
        } catch (error) {}
      }
    },
  });

  const handleArchiveCandidateInfoField = () => {
    updateCandidateInformationFieldMutation({
      variables: {
        id: candidateInformationFieldId,
        isArchived: true,
      },
    });
  };

  return <Button onClick={handleArchiveCandidateInfoField}>Archive</Button>;
};

export default ArchiveCandidateInfoFieldButton;
