export enum ModalTypes {
  CANDIDATE_INFO_FIELD_MODAL,
  ADD_SOFT_SKILL_MODAL,
  EDIT_SOFT_SKILL_MODAL,
  ADD_TRAIT_MODAL,
  EDIT_TRAIT_MODAL,
  STATIC_CONTENT_FILTERS_MODAL,
  VALIDATE_CONTENT_MODAL,
  TEXT_FIELD_MODAL,
  WARNING_MODAL,
  REPORTS_PAGE_MODAL,
  ADD_WIDGET_OPTION_MODAL,
  ADD_WIDGET_MODAL,
  WIDGET_CONFIG_MODAL,
  ADD_NEW_USER_ROLE_MODAL,
  ADD_NEW_USER_MODAL,
  EDIT_USER_MODAL,
  RETRIEVE_TP_DETAILS_MODAL,
  ADD_COMPANY_SIGNATURE_MODAL,
  CONFIRMATION_MODAL,
  LANGUAGE_MODAL,
  STAGE_INDIRECT_INVITATION_MODAL,
  ADD_CATEGORY_MODAL,
  ADD_INTERVIEW_GUIDE_MODAL,
  INTERVIEW_GUIDE_CATEGORY_CONFIG_MODAL,
  IG_STEP_CONFIG_MODAL,
  MITIGATE_CIRCUMSTANCE_MODAL,
  STYLED_CONFIRMATION_MODAL,
  NOTIFICATION_MODAL,
  CREATE_PROJECT_REQUEST_CONTACT_MODAL,
  ADD_PROJECT_REQUEST_DOCUMENT_MODAL,
  PROJECT_REQUEST_JOB_SPECIFICATION_MODAL,
  PROJECT_REQUEST_MANAGER_SURVEY_MODAL,
  PROJECT_REQUEST_APPROVAL_MODAL,
  PROJECT_REQUEST_APPROVAL_STATUS_MODAL,
  COMPANY_EMPLOYEE_MODAL,
  EDIT_HR_MANAGER_EMPLOYEE_ID_MODAL,
  BEHAVIOUR_CONTENT_MODAL,
  CANDIDATE_USAGE_MODAL,
  DELETE_ASSESSMENT_MODAL,
  EDIT_DEI_ACTION,
  REPORTS_ACCESS,
}
