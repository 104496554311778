import { useState } from 'react';
import {
  ReportRenderStatus,
  useReportRenderLazyQuery,
} from '../generated/graphql';
import { usePolling } from './polling';

const POLLING_TIMEOUT_ERROR_MESSAGE = 'Request timed out, please try again.';
const POLLING_FAILED_ERROR_MESSAGE = 'Request failed.';

export const usePollingReportRenderQuery = (
  reportRenderSubId: string | null,
  doNothing?: boolean
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [
    reportRenderQuery,
    reportRenderQueryResponse,
  ] = useReportRenderLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (
        data.ReportRender?.reportRenderStatus === ReportRenderStatus.Complete ||
        data.ReportRender?.reportRenderStatus ===
          ReportRenderStatus.CompleteEmailed ||
        data.ReportRender?.reportRenderStatus ===
          ReportRenderStatus.CompleteEmailing
      ) {
        if (data.ReportRender.blob?.url) {
          if (doNothing) {
            endPolling();
            return;
          }
          window.open(data.ReportRender.blob?.url);
        }
        endPolling();
        return;
      }

      if (data.ReportRender?.reportRenderStatus === ReportRenderStatus.Failed) {
        setErrorMessage(POLLING_FAILED_ERROR_MESSAGE);
        endPolling();
      }
    },
    onError: error => {
      setErrorMessage(POLLING_FAILED_ERROR_MESSAGE);
      endPolling();
    },
  });

  const pollingFunction = () => {
    if (reportRenderSubId) {
      reportRenderQuery({
        variables: { subId: reportRenderSubId },
      });
    }
  };

  const onPollingTimeOut = () => {
    setErrorMessage(POLLING_TIMEOUT_ERROR_MESSAGE);
  };

  const { startPolling, endPolling, isPolling } = usePolling({
    func: pollingFunction,
    onTimeOut: onPollingTimeOut,
  });

  const onStartPolling = () => {
    startPolling();
    setErrorMessage('');
  };

  return {
    startPolling: onStartPolling,
    reportRenderQueryResponse,
    isPolling,
    errorMessage,
  };
};
