import { ModalTypes } from 'enums/modalTypes';
import {
  SoftSkillFindManyDocument,
  SoftSkillFindManyQuery,
  SoftSkillFindManyQueryVariables,
  SoftSkillFragment,
  SoftSkillsDocument,
  SoftSkillsQuery,
  SoftSkillsQueryVariables,
  useArchiveSoftSkillMutation,
  useSoftSkillDeleteMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import SoftSkillFormHeaderPresentational from './SoftSkillFormHeaderPresentational/SoftSkillFormHeaderPresentational';

export interface ISoftSkillFormHeader {
  softSkill?: SoftSkillFragment;
  isEditMode: boolean;
}

const SoftSkillFormHeader: FC<ISoftSkillFormHeader> = ({
  softSkill,
  isEditMode,
}) => {
  const { handleMsgType } = useNotification();

  const [archiveSoftSkillMutation] = useArchiveSoftSkillMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, response) => {
      if (response.data?.softSkill) {
        if (response.data.softSkill.isArchived) {
          // If soft skill is archived, soft skill should be removed
          // from list where are visible soft skill that are not archived
          // and added to the list where are archived soft skills.
          try {
            const cachedFindManySoftSkillsResult = cache.readQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: false,
              },
            })?.SoftSkillFindMany as SoftSkillFragment[];

            if (cachedFindManySoftSkillsResult) {
              cache.writeQuery<
                SoftSkillFindManyQuery,
                SoftSkillFindManyQueryVariables
              >({
                query: SoftSkillFindManyDocument,
                variables: {
                  isArchived: false,
                },
                data: {
                  SoftSkillFindMany: cachedFindManySoftSkillsResult.filter(
                    cachedSoftSkill => cachedSoftSkill.id !== softSkill?.id
                  ),
                },
              });
            }
          } catch (error) {
            console.log(error);
          }

          try {
            const cachedFindManySoftSkillsResult = cache.readQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: true,
              },
            })?.SoftSkillFindMany as SoftSkillFragment[];

            if (cachedFindManySoftSkillsResult) {
              cache.writeQuery<
                SoftSkillFindManyQuery,
                SoftSkillFindManyQueryVariables
              >({
                query: SoftSkillFindManyDocument,
                variables: {
                  isArchived: true,
                },
                data: {
                  SoftSkillFindMany: [
                    ...cachedFindManySoftSkillsResult,
                    response.data.softSkill,
                  ],
                },
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          // If soft skill is unarchived, soft skill should be added to the list
          // of not archived soft skills and removed from the list of archived soft skills.
          try {
            const cachedFindManySoftSkillsResult = cache.readQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: false,
              },
            })?.SoftSkillFindMany as SoftSkillFragment[];

            if (cachedFindManySoftSkillsResult) {
              cache.writeQuery<
                SoftSkillFindManyQuery,
                SoftSkillFindManyQueryVariables
              >({
                query: SoftSkillFindManyDocument,
                variables: {
                  isArchived: false,
                },
                data: {
                  SoftSkillFindMany: [
                    ...cachedFindManySoftSkillsResult,
                    response.data.softSkill,
                  ],
                },
              });
            }
          } catch (error) {
            console.log(error);
          }

          try {
            const cachedFindManySoftSkillsResult = cache.readQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: true,
              },
            })?.SoftSkillFindMany as SoftSkillFragment[];

            if (cachedFindManySoftSkillsResult) {
              cache.writeQuery<
                SoftSkillFindManyQuery,
                SoftSkillFindManyQueryVariables
              >({
                query: SoftSkillFindManyDocument,
                variables: {
                  isArchived: true,
                },
                data: {
                  SoftSkillFindMany: cachedFindManySoftSkillsResult.filter(
                    cachedSoftSkill => cachedSoftSkill.id !== softSkill?.id
                  ),
                },
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  });

  const [deleteSoftSkillMutation] = useSoftSkillDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, response) => {
      try {
        if (response.data?.affected) {
          const cachedFindManySoftSkillsResult = cache.readQuery<
            SoftSkillFindManyQuery,
            SoftSkillFindManyQueryVariables
          >({
            query: SoftSkillFindManyDocument,
            variables: {
              isArchived: true,
            },
          })?.SoftSkillFindMany as SoftSkillFragment[];

          if (cachedFindManySoftSkillsResult) {
            cache.writeQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: true,
              },
              data: {
                SoftSkillFindMany: cachedFindManySoftSkillsResult.filter(
                  cachedSoftSkill => cachedSoftSkill.id !== softSkill?.id
                ),
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }

      try {
        if (response.data?.affected) {
          const cachedFindManySoftSkillsResult = cache.readQuery<
            SoftSkillFindManyQuery,
            SoftSkillFindManyQueryVariables
          >({
            query: SoftSkillFindManyDocument,
            variables: {
              isArchived: false,
            },
          })?.SoftSkillFindMany as SoftSkillFragment[];

          if (cachedFindManySoftSkillsResult) {
            cache.writeQuery<
              SoftSkillFindManyQuery,
              SoftSkillFindManyQueryVariables
            >({
              query: SoftSkillFindManyDocument,
              variables: {
                isArchived: false,
              },
              data: {
                SoftSkillFindMany: cachedFindManySoftSkillsResult.filter(
                  cachedSoftSkill => cachedSoftSkill.id !== softSkill?.id
                ),
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const cachedSoftSkillsResult = cache.readQuery<
          SoftSkillsQuery,
          SoftSkillsQueryVariables
        >({
          query: SoftSkillsDocument,
        })?.SoftSkills as SoftSkillFragment[];

        if (cachedSoftSkillsResult) {
          cache.writeQuery<SoftSkillsQuery, SoftSkillsQueryVariables>({
            query: SoftSkillsDocument,
            data: {
              SoftSkills: cachedSoftSkillsResult.filter(
                cachedSoftSkill => cachedSoftSkill.id !== softSkill?.id
              ),
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onCompleted: () => {
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_SOFT_SKILL_MODAL);
  };

  const handleArchive = () => {
    archiveSoftSkillMutation({
      variables: {
        id: softSkill?.id as number,
        isArchived: !(softSkill?.isArchived as boolean),
      },
    });
  };

  const handleDelete = () => {
    deleteSoftSkillMutation({
      variables: {
        id: softSkill?.id as number,
      },
    });
  };

  return (
    <SoftSkillFormHeaderPresentational
      softSkill={softSkill}
      isEditMode={isEditMode}
      handleArchive={handleArchive}
      handleDelete={handleDelete}
    />
  );
};

export default SoftSkillFormHeader;
