import { ReactComponent as AddIcon } from 'icons/add.svg';
import { ReactComponent as AddBlackIcon } from 'icons/add_black.svg';
import { ReactComponent as BluePencilIcon } from 'icons/blue_pencil.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import { ReactComponent as CheckCircleIcon } from 'icons/check_circle.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
import { ReactComponent as CopyIcon } from 'icons/copy.svg';
import { ReactComponent as DeiIcon } from 'icons/dei.svg';
import { ReactComponent as DeleteOutlineIcon } from 'icons/delete_outline.svg';
import { ReactComponent as DoneAllIcon } from 'icons/done_all.svg';
import { ReactComponent as EmailIcon } from 'icons/email.svg';
import { ReactComponent as ExclamationMarkInCircle } from 'icons/exclamation_mark_in_circle.svg';
import { ReactComponent as EyeIcon } from 'icons/eye.svg';
import { ReactComponent as UploadIcon } from 'icons/file_upload.svg';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import { ReactComponent as GroupsIcon } from 'icons/groups.svg';
import { ReactComponent as HelpOutlineIcon } from 'icons/help_outline.svg';
import { ReactComponent as BarsInCircleIcon } from 'icons/ic_bars_in_circle.svg';
import { ReactComponent as CloseGrayIcon } from 'icons/ic_close_gray.svg';
import { ReactComponent as ExportIcon } from 'icons/ic_export.svg';
import { ReactComponent as InductiveIconInCircleColored } from 'icons/ic_inductive.svg';
import { ReactComponent as LogoutIcon } from 'icons/ic_logout.svg';
import { ReactComponent as NoteIcon } from 'icons/ic_note.svg';
import { ReactComponent as NumericalIconInCircleColored } from 'icons/ic_numerical.svg';
import { ReactComponent as PersonalityIconInCircleColored } from 'icons/ic_personality.svg';
import { ReactComponent as SearchIcon } from 'icons/ic_search.svg';
import { ReactComponent as VerbalIconInCircleColored } from 'icons/ic_verbal.svg';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { ReactComponent as LeftArrowIcon } from 'icons/left_arrow.svg';
import { ReactComponent as OpenTabIcon } from 'icons/open_tab.svg';
import { ReactComponent as PencilIcon } from 'icons/pencil.svg';
import { ReactComponent as PlaylistAddCheckIcon } from 'icons/playlist_add_check.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as ReplayIcon } from 'icons/replay.svg';
import { ReactComponent as SZLogoIcon } from 'icons/SpottedZebraLogo.svg';
import { ReactComponent as TagIcon } from 'icons/tag.svg';
import { ReactComponent as TimelapseIcon } from 'icons/timelapse.svg';
import { ReactComponent as UploadGreyIcon } from 'icons/upload_grey.svg';
import { ReactComponent as UploadWhiteIcon } from 'icons/upload_white.svg';
import { FC } from 'react';

const iconsObj = {
  add: AddIcon,
  check_circle: CheckCircleIcon,
  delete_outline: DeleteOutlineIcon,
  done_all: DoneAllIcon,
  logout: LogoutIcon,
  playlist_add_check: PlaylistAddCheckIcon,
  replay: ReplayIcon,
  sz_logo: SZLogoIcon,
  timelapse: TimelapseIcon,
  add_black: AddBlackIcon,
  left_arrow: LeftArrowIcon,
  check: CheckIcon,
  pencil: PencilIcon,
  bluePencil: BluePencilIcon,
  upload: UploadIcon,
  upload_white: UploadWhiteIcon,
  upload_grey: UploadGreyIcon,
  note: NoteIcon,
  email: EmailIcon,
  search: SearchIcon,
  chevron: ChevronIcon,
  verbalInCircleColored: VerbalIconInCircleColored,
  numericalInCircleColored: NumericalIconInCircleColored,
  inductiveInCircleColored: InductiveIconInCircleColored,
  personalityInCircleColored: PersonalityIconInCircleColored,
  bars_in_circle: BarsInCircleIcon,
  dei: DeiIcon,
  eye: EyeIcon,
  help_outline: HelpOutlineIcon,
  exclamation_mark_in_circle: ExclamationMarkInCircle,
  export_icon: ExportIcon,
  tag: TagIcon,
  filter: FilterIcon,
  copy: CopyIcon,
  info: InfoIcon,
  ic_close_gray: CloseGrayIcon,
  plus: PlusIcon,
  openTab: OpenTabIcon,
  groups: GroupsIcon,
};

export type IconType = keyof typeof iconsObj;

interface IconProps {
  icon: IconType;
  className?: string;
  id?: string;
}

const Icon: FC<IconProps> = ({ icon, className, id }) => {
  const Component = iconsObj[icon];

  return iconsObj[icon] ? <Component className={className} id={id} /> : null;
};

export default Icon;
