import { IdentityPermissionRole } from '../../../../../../generated/graphql';
import { isUserSuperAdmin } from '../../../../../../helpers/roles';
import { TMeQueryUser } from '../../../../../../interfaces/user';

const notSuperAdminOptions = {
  [IdentityPermissionRole.Candidate]: {
    label: 'Candidate',
    value: IdentityPermissionRole.Candidate,
  },
  [IdentityPermissionRole.CompanyAdmin]: {
    label: 'Company admin',
    value: IdentityPermissionRole.CompanyAdmin,
  },
  [IdentityPermissionRole.CompanyHiringManager]: {
    label: 'Company hiring manager',
    value: IdentityPermissionRole.CompanyHiringManager,
  },
  [IdentityPermissionRole.CompanyMember]: {
    label: 'Company member',
    value: IdentityPermissionRole.CompanyMember,
  },
};

const superAdminOptions = {
  ...notSuperAdminOptions,
  [IdentityPermissionRole.SzSuperAdmin]: {
    label: 'Sz super admin',
    value: IdentityPermissionRole.SzSuperAdmin,
  },
  [IdentityPermissionRole.Admin]: {
    label: 'Admin',
    value: IdentityPermissionRole.Admin,
  },
  [IdentityPermissionRole.SzApi]: {
    label: 'Sz api',
    value: IdentityPermissionRole.SzApi,
  },
};

export const getIdentityPermissionRoleOptionsObj = (user: TMeQueryUser) =>
  isUserSuperAdmin(user) ? superAdminOptions : notSuperAdminOptions;

export const getIdentityPermissionRoleOptions = (user: TMeQueryUser) =>
  Object.values(getIdentityPermissionRoleOptionsObj(user));
