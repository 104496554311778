export enum SoftSkillType {
  DIFFERENTIATOR = 'DIFFERENTIATOR',
  CORE = 'CORE',
  DIVERSITY = 'DIVERSITY',
}

export enum MeasurementConstructType {
  BEHAVIOR = 'BEHAVIOR',
  COGNITIVE = 'COGNITIVE',
  VALUE = 'VALUE',
  CALCULATED_ON_SS_SUMMARY = 'CALCULATED_ON_SS_SUMMARY',
  NONE = 'NONE',
}
