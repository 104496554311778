import { ProjectRequestsViewContextProvider } from 'contexts/ProjectRequestContext';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const ProjectRequests: FC = () => (
  <ProjectRequestsViewContextProvider>
    <Outlet />
  </ProjectRequestsViewContextProvider>
);

export default ProjectRequests;
