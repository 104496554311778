import { gql } from '@apollo/client';
import { ModalTypes } from '../enums/modalTypes';
import { TModal, TModalProps } from '../interfaces/modal';
import { modalsVar } from './cache';

export const GET_MODALS_QUERY = gql`
  query GetModals {
    modals @client {
      modalType
      modalProps
    }
  }
`;

export const openModal = (modalType: ModalTypes, modalProps: TModalProps) => {
  const modals = modalsVar();
  if (!modals.find(modal => modal.modalType === modalType)) {
    const newModal: TModal = { modalType, modalProps };
    modalsVar([...modals, newModal]);
  }
};

export const closeModal = (modalType: ModalTypes) => {
  const modals = modalsVar();
  modalsVar(modals.filter(modal => modal.modalType !== modalType));
};
