import clsx from 'clsx';
import {
  TJobRoleFormSoftSkillTraitValues,
  TJobRoleFormValues,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import TraitsFormItem from 'components/feature/jobRole/TraitsFormItem/TraitsFormItem';
import { FieldArray, useFormikContext } from 'formik';
import { sumOfArrayField } from 'helpers/calculations';
import { FC, useEffect, useRef, useState } from 'react';
import { Button, Modal } from '@spotted-zebra-uk/ui-components';
import styles from './TraitsModal.module.scss';

const getWeightsSum = (traits?: TJobRoleFormSoftSkillTraitValues[]) => {
  if (!traits) {
    return 0;
  }

  return sumOfArrayField(traits, 'weight');
};

interface ITraitsModal {
  softSkillIndex: number;
  onClose: () => void;
}

const TraitsModal: FC<ITraitsModal> = ({ onClose, softSkillIndex }) => {
  const initialValues = useRef<TJobRoleFormSoftSkillTraitValues[]>();
  const { values, setFieldValue } = useFormikContext<TJobRoleFormValues>();
  const [error, setError] = useState('');

  const traits = values.softSkills[softSkillIndex].traitsValues;
  const weightsSum = getWeightsSum(traits);

  useEffect(() => {
    initialValues.current = traits;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    if (weightsSum !== 100) {
      setError('Sum of sub skill weights must be 100!');
    } else {
      onClose();
    }
  };

  const handleClose = () => {
    setFieldValue(
      `softSkills.${softSkillIndex}.traitsValues`,
      initialValues.current || []
    );
    onClose();
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      header={values.softSkills[softSkillIndex].name}
      className={styles.modal}
    >
      <div className={styles.title}>Sub Skills</div>
      <div
        className={clsx(styles.note, weightsSum !== 100 && styles.noteError)}
      >
        Weights sum: {weightsSum}
      </div>
      <div>
        <FieldArray
          name={`softSkills.${softSkillIndex}.traitsValues`}
          render={() => (
            <div>
              {traits.map((trait, index) => (
                <TraitsFormItem
                  index={index}
                  key={index}
                  traitName={trait.name}
                  softSkillIndex={softSkillIndex}
                />
              ))}
            </div>
          )}
        />
        <div className={styles.error}>{error}</div>
      </div>
      <div className={styles.actions}>
        <Button
          onClick={handleClose}
          data-role="cancel-traits"
          size="medium"
          variant="secondary"
          type="button"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          data-role="save-traits"
          size="medium"
          variant="primary"
          type="button"
        >
          Save Sub Skills
        </Button>
      </div>
    </Modal>
  );
};

export default TraitsModal;
