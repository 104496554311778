import Icon from 'components/atoms/Icon';
import { RssAssessmentFragment, RssAssessmentType } from 'generated/graphql';
import { uniqBy } from 'lodash';
import { FC } from 'react';
import {
  Button,
  MultiselectFormField,
  TextInputField,
} from '@spotted-zebra-uk/ui-components';
import RemoveSkillsSelfAssessmentActionButton from './RemoveSkillsSelfAssessmentActionButton';

const skillsTypesOptions = [
  {
    label: 'Soft skills',
    value: RssAssessmentType.SoftSkill,
  },
  {
    label: 'Technical skills',
    value: RssAssessmentType.TechnicalSkill,
  },
];

interface IStageRoleSkillsSelfAssessmentAction {
  onEditButtonClick: () => void;
  rssAssessments: RssAssessmentFragment[];
  stageId: number;
}

const StageRoleSkillsSelfAssessmentAction: FC<IStageRoleSkillsSelfAssessmentAction> = ({
  rssAssessments,
  stageId,
  onEditButtonClick,
}) => {
  const skillTypesValues = uniqBy(
    rssAssessments
      .map(rssAssessment => ({
        value: rssAssessment.type,
        label:
          skillsTypesOptions.find(option => option.value === rssAssessment.type)
            ?.label || '',
      }))
      .sort((a, b) => {
        const valueA = a.value.toLowerCase();
        const valueB = b.value.toLowerCase();

        return valueA < valueB ? -1 : 1;
      }),
    'value'
  );

  return (
    <div className="stage-role-skills-self-assessment-action">
      <Icon
        icon="bars_in_circle"
        className="stage-role-skills-self-assessment-action__bars-icon"
      />
      <TextInputField
        id={`stage-${stageId}-rss-assessment-action-name`}
        disabled
        onChange={() => {}}
        value="Skill self-assessment"
        className="stage-role-skills-self-assessment-action__action-name-label"
      />
      <MultiselectFormField
        label="Skill types"
        id={`stage-${stageId}-rss-assessment-skill-types`}
        isDisabled
        onChange={() => {}}
        options={skillsTypesOptions}
        value={skillTypesValues}
        hideMultiValueRemove
        className="stage-role-skills-self-assessment-action__skill-types-select"
      />
      <Button
        variant="secondary"
        size="medium"
        className="stage-role-skills-self-assessment-action__edit-button"
        onClick={onEditButtonClick}
      >
        <Icon
          icon="bluePencil"
          className="stage-role-skills-self-assessment-action__edit-button-icon"
        />
      </Button>
      <RemoveSkillsSelfAssessmentActionButton
        rssAssessments={rssAssessments}
        stageId={stageId}
      />
    </div>
  );
};

export default StageRoleSkillsSelfAssessmentAction;
