import { FC } from 'react';
import { Grid } from '@mui/material';
import ArchiveCandidateInfoFieldButton from './ArchiveCandidateInfoFieldButton/ArchiveCandidateInfoFieldButton';
import DeleteCandidateInfoFieldButton from './DeleteCandidateInfoFieldButton/DeleteCandidateInfoFieldButton';
import UnarchiveCandidateInfoFieldButton from './UnarchiveCandidateInfoFieldButton/UnarchiveCandidateInfoFieldButton';

interface IEditCandidateInfoFieldHeader {
  candidateInformationFieldIsArchived: boolean;
  candidateInfoFieldId: number;
  companyId?: number;
}

const EditCandidateInfoFieldHeader: FC<IEditCandidateInfoFieldHeader> = ({
  candidateInformationFieldIsArchived,
  candidateInfoFieldId,
  companyId,
}) => {
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {candidateInformationFieldIsArchived ? (
        <>
          <Grid item>
            <UnarchiveCandidateInfoFieldButton
              candidateInformationFieldId={candidateInfoFieldId}
              companyId={companyId}
            />
          </Grid>
          <Grid item>
            <DeleteCandidateInfoFieldButton
              candidateInfoFieldId={candidateInfoFieldId}
              companyId={companyId}
            />
          </Grid>
        </>
      ) : (
        <Grid item>
          <ArchiveCandidateInfoFieldButton
            candidateInformationFieldId={candidateInfoFieldId}
            companyId={companyId}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EditCandidateInfoFieldHeader;
