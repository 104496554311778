import {
  RssAssessmentCreateOneArgs,
  RssAssessmentType,
  StageActionAvailability,
  StageAvailableActionFragment,
  StageDocument,
  useRssAssessmentCreateManyMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  rssAlreadyExistsTooltipHtml,
  rssNotAvailableTooltipHtml,
  updateCacheAfterRSSAssessmentCreateMany,
} from './helpers';
import SkillsSelfAssessmentActionForm, {
  SkillsSelfAssessmentActionFormValues,
} from './SkillsSelfAssessmentActionForm';

interface IAddSkillsSelfAssessmentAction {
  softSkillAssessmentAvailability?: StageAvailableActionFragment;
  technicalSkillAssessmentAvailability?: StageAvailableActionFragment;
  stageId: number;
  onClose: () => void;
}

const getTooltipNote = (
  assessmentType: 'Soft skill' | 'Technical skill',
  availability?: StageActionAvailability
) => {
  if (availability === StageActionAvailability.AlreadyExists)
    return rssAlreadyExistsTooltipHtml(assessmentType);

  if (availability === StageActionAvailability.NotAvailable)
    return rssNotAvailableTooltipHtml(assessmentType);

  return '';
};

const AddSkillsSelfAssessmentAction: FC<IAddSkillsSelfAssessmentAction> = ({
  softSkillAssessmentAvailability,
  technicalSkillAssessmentAvailability,
  stageId,
  onClose,
}) => {
  const { handleMsgType } = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const [rssAssessmentCreateManyMutation] = useRssAssessmentCreateManyMutation({
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        message:
          'Ops, some error is ocurred during creating Skills self-assessment action!',
      });
      setSubmitting(false);
    },
    refetchQueries: [{ query: StageDocument, variables: { id: stageId } }],
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Skills self-assessment action created!',
      });
      onClose();
    },
    update: (cache, { data }) =>
      updateCacheAfterRSSAssessmentCreateMany(cache, stageId, data),
  });

  const handleSubmit = ({
    softSkillsChecked,
    technicalSkillsChecked,
  }: SkillsSelfAssessmentActionFormValues) => {
    const dto: RssAssessmentCreateOneArgs[] = [];

    if (softSkillsChecked) {
      dto.push({ stageId, type: RssAssessmentType.SoftSkill });
    }

    if (technicalSkillsChecked) {
      dto.push({ stageId, type: RssAssessmentType.TechnicalSkill });
    }

    if (dto.length) {
      setSubmitting(true);
      rssAssessmentCreateManyMutation({
        variables: {
          dto,
        },
      });
    }
  };

  const softSkillTooltipNote = getTooltipNote(
    'Soft skill',
    softSkillAssessmentAvailability?.availability
  );

  const technicalSkillTooltipNote = getTooltipNote(
    'Technical skill',
    technicalSkillAssessmentAvailability?.availability
  );

  return (
    <SkillsSelfAssessmentActionForm
      initialValues={{
        softSkillsChecked: false,
        technicalSkillsChecked: false,
      }}
      softSkillsAssessmentAvailable={
        softSkillAssessmentAvailability?.availability ===
        StageActionAvailability.Available
      }
      softSkillTooltipNote={softSkillTooltipNote}
      technicalSkillsAssessmentAvailable={
        technicalSkillAssessmentAvailability?.availability ===
        StageActionAvailability.Available
      }
      technicalSkillTooltipNote={technicalSkillTooltipNote}
      submitDisabled={submitting}
      submitButtonText="Add action"
      onCancel={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default AddSkillsSelfAssessmentAction;
