import {
  endOfDay,
  format,
  formatISO as formatISOFns,
  isBefore as isBeforeFns,
  parseISO,
  startOfDay,
  sub,
} from 'date-fns';

const formatDate = (dateInput: string, dateOutputFormat: string) =>
  format(parseISO(dateInput), dateOutputFormat);

const formatISO = (dateInput: string | number) =>
  formatISOFns(new Date(dateInput));

const isBefore = (date: string, dateToCompate: string) =>
  isBeforeFns(new Date(date), new Date(dateToCompate));

const getUtcBoundaryOfDay = (date: Date, boundary: 'start' | 'end'): string => {
  let localStartOfDay: Date;
  if (boundary === 'start') {
    localStartOfDay = startOfDay(date);
  } else {
    localStartOfDay = endOfDay(date);
  }
  const timezoneOffsetMinutes = localStartOfDay.getTimezoneOffset();
  const utcTimeMilliseconds =
    localStartOfDay.getTime() - timezoneOffsetMinutes * 60 * 1000;
  // Create a new Date object with the calculated UTC time
  const utcDate = new Date(utcTimeMilliseconds);
  return utcDate.toISOString();
};

export { sub, formatDate, formatISO, isBefore, getUtcBoundaryOfDay };
