import Icon from 'components/atoms/Icon';
import { jobRolesRoutes } from 'constants/navigation';
import {
  ProjectJobRoleFindManyDocument,
  ProjectJobRoleFindManyQuery,
  ProjectJobRoleFindManyQueryVariables,
  ProjectJobRoleFragment,
  useProjectJobRoleDeleteOneMutation,
} from 'generated/graphql';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './ProjectJobRoleRow.module.scss';

type TProjectJobRoleRowProps = {
  projectJobRole: ProjectJobRoleFragment;
  projectId: number;
};

const ProjectJobRoleRow: FC<TProjectJobRoleRowProps> = ({
  projectJobRole: {
    id,
    jobRole: { name, id: jobRoleId },
    skillsProfileId,
  },
  projectId,
}) => {
  const location = useLocation();
  const { handleMsgType } = useNotification();
  const [
    deleteProjectJobRoleMutation,
    { loading },
  ] = useProjectJobRoleDeleteOneMutation({
    variables: {
      id,
    },
    onCompleted: () =>
      handleMsgType({
        message: `Successfully deleted ${name} job role`,
        type: TNotification.success,
      }),
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        message:
          error?.message ||
          `Ops, some error happened while deleting ${name} job role`,
      }),
    update: (cache, { data }) => {
      if (!data) return;

      const cachedProjectJobRoles = cache.readQuery<
        ProjectJobRoleFindManyQuery,
        ProjectJobRoleFindManyQueryVariables
      >({
        query: ProjectJobRoleFindManyDocument,
        variables: { id: projectId },
      });

      if (!cachedProjectJobRoles?.ProjectJobRoleFindMany.length) return;

      const updatedProjectJobRoles = cachedProjectJobRoles?.ProjectJobRoleFindMany?.filter(
        projectJobRole => projectJobRole.id !== id
      );

      cache.writeQuery({
        query: ProjectJobRoleFindManyDocument,
        variables: { id: projectId },
        data: {
          ProjectJobRoleFindMany: updatedProjectJobRoles,
        },
      });
    },
  });

  const handleDeleteProjectJobRole = () => {
    deleteProjectJobRoleMutation();
  };

  const jobRoleRoute = jobRolesRoutes.editJobRole.url(jobRoleId);
  const linkState = {
    from: location,
    projectId,
    projectJobRoleSkillsProfileId: skillsProfileId,
  };

  return (
    <div className={styles.container} data-testid="project-job-role-row">
      <p className={styles.name} data-testid="project-job-role-row-name">
        {name}
      </p>
      <div className={styles.btnsContainer}>
        <Link
          to={jobRoleRoute}
          state={linkState}
          data-testid="edit-project-job-role-link"
        >
          <Button
            size="small"
            className={styles.btn}
            variant="secondary"
            leftIcon={<Icon icon="pencil" className={styles.icon} />}
          >
            Edit
          </Button>
        </Link>
        <Button
          size="small"
          className={styles.btn}
          variant="secondary"
          data-testid="remove-project-job-role-button"
          onClick={handleDeleteProjectJobRole}
          disabled={loading}
          leftIcon={<Icon icon="delete_outline" className={styles.icon} />}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export default ProjectJobRoleRow;
