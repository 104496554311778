import { useState } from 'react';
import useInterval from './useInterval';
import useTimeout from './useTimeout';

// time (in milliseconds) between two calls of report render api.
const DEFAULT_POLLING_INTERVAL = 1000;
// time (in milliseconds) after that polling of report render api will be stoped if not completed.
const DEFAULT_POLLING_TIMEOUT = 15000;

type TUsePollingInput = {
  func: () => void;
  onTimeOut?: () => void;
  pollingInterval?: number | undefined;
  pollingTimeoutInterval?: number | undefined;
};

export const usePolling = ({
  func,
  onTimeOut,
  pollingInterval = DEFAULT_POLLING_INTERVAL,
  pollingTimeoutInterval = DEFAULT_POLLING_TIMEOUT,
}: TUsePollingInput) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);

  const startPolling = () => {
    setIsPolling(true);
    setIsTimeout(false);
  };

  const endPolling = () => {
    setIsPolling(false);
  };

  useInterval(
    () => {
      if (isPolling) {
        func();
      } else {
        if (isPolling) {
          endPolling();
        }
      }
    },
    isPolling ? pollingInterval : null
  );

  useTimeout(
    () => {
      if (isPolling) {
        endPolling();
        setIsTimeout(true);
        if (onTimeOut) {
          onTimeOut();
        }
      }
    },
    isPolling ? pollingTimeoutInterval : null
  );

  return { startPolling, endPolling, isPolling, isTimeout };
};
