import {
  DeiStageActionFieldsInput,
  DeiStageActionFieldsModel,
  DeiStageActionModel,
  StageFindManyDocument,
  StageFindManyQuery,
  StageFindManyQueryVariables,
} from 'generated/graphql';
import { capitalize, cloneDeep, findIndex } from 'lodash';
import { ApolloCache } from '@apollo/client';
import { IconType } from '@spotted-zebra-uk/ui-components';

export const deiCheckboxOptions = [
  {
    label: 'Gender*',
    value: 'hasGenderIdentityField',
  },
  {
    label: 'Sexual orientation',
    value: 'hasSexualOrientationField',
  },
  {
    label: 'Ethnicity group*',
    value: 'hasEthnicityField',
  },
  {
    label: 'Religion or beliefs',
    value: 'hasReligiousBeliefsField',
  },
  {
    label: 'Disability or long-term condition*',
    value: 'hasDisabilityField',
  },
  {
    label: 'Neurodiversity* ',
    value: 'hasNeurodiversityField',
  },
  {
    label: 'Age range*',
    value: 'hasAgeRangeField',
  },
];

export const getDeiFields = (values: string[]) => {
  const fields: Record<string, boolean> = {};
  deiCheckboxOptions.forEach(option => {
    fields[option.value] = values.includes(option.value);
  });
  return fields as DeiStageActionFieldsInput;
};

type DeiInputFields = Omit<DeiStageActionFieldsModel, '__typename'>;
const deiTooltipLabels: Record<keyof DeiInputFields, string> = {
  hasGenderIdentityField: 'gender',
  hasSexualOrientationField: 'sexual orientation',
  hasEthnicityField: 'ethnicity group',
  hasReligiousBeliefsField: 'religious beliefs',
  hasDisabilityField: 'disability or long-term condition',
  hasNeurodiversityField: 'neurodiversity',
  hasAgeRangeField: 'age-range',
};

export const getDeiTooltipContent = (fields: DeiStageActionFieldsModel) => {
  const content =
    Object.entries(deiTooltipLabels)
      .filter(elem => fields[elem[0] as keyof DeiStageActionFieldsModel])
      .map(elem => deiTooltipLabels[elem[0] as keyof DeiInputFields])
      .join(', ') + '.';

  return capitalize(content);
};

export const deiInitialValues = {
  deiValues: [
    'hasGenderIdentityField',
    'hasEthnicityField',
    'hasDisabilityField',
    'hasNeurodiversityField',
    'hasAgeRangeField',
  ],
};

export const getPrefilledDeiFields = (fields: DeiStageActionFieldsModel) => {
  return Object.entries(fields)
    .filter(field => field[0] !== '__typename' && field[1])
    .map(field => field[0]);
};

export const removeModalContent = {
  className: 'remove-dei-action-modal',
  type: IconType.WARNING,
  title: 'Are you sure you want to remove this stage action?',
  okButtonChildren: 'Remove',
  message:
    'If the project has already started collecting data, the information gathered will no longer be accessible to clients via the company app. However, internal users can still access the data through the admin app.',
};

export const updateCacheHelper = (
  cache: ApolloCache<DeiStageActionModel>,
  data: DeiStageActionModel,
  projectId: number,
  stageId: number
) => {
  const existingStages = cache.readQuery<
    StageFindManyQuery,
    StageFindManyQueryVariables
  >({
    query: StageFindManyDocument,
    variables: { projectId },
  });

  if (existingStages?.Stages) {
    const newStages = cloneDeep(existingStages);

    const stageIndex = findIndex(newStages.Stages, { id: stageId });

    if (newStages?.Stages && stageIndex !== -1) {
      newStages.Stages[stageIndex].deiStageAction = {
        ...data,
      };
    }

    cache.writeQuery({
      query: StageFindManyDocument,
      variables: { projectId },
      data: newStages,
    });
  }
};
