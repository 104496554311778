import { ModalTypes } from 'enums/modalTypes';
import { useField, useFormikContext } from 'formik';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import {
  FormikTextInputField,
  ModalButtons,
} from '@spotted-zebra-uk/ui-components';

export const EditHRManagerEmployeeIDFormFields: FC = () => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <div className="edit-hr-manager-employee-id-form">
      <div className="edit-hr-manager-employee-id-form__fields">
        <div className="edit-hr-manager-employee-id-form__flex-container">
          <FormikTextInputField
            label="Given Name"
            id="firstName"
            placeholder="Given Name"
            disabled
            useFormikField={useField}
          />
          <FormikTextInputField
            label="Family Name"
            id="lastName"
            placeholder="Family Name"
            disabled
            useFormikField={useField}
          />
        </div>
        <FormikTextInputField
          label="Email"
          id="email"
          placeholder="Email"
          disabled
          useFormikField={useField}
        />
        <FormikTextInputField
          label="Employee Id"
          id="employeeId"
          placeholder="Employee Id"
          useFormikField={useField}
        />
      </div>
      <div className="edit-hr-manager-employee-id-form__actions">
        <ModalButtons
          onCancelButtonClick={() =>
            closeModal(ModalTypes.EDIT_HR_MANAGER_EMPLOYEE_ID_MODAL)
          }
          onConfirmButtonClick={() => handleSubmit()}
          cancelButtonChildren="Cancel"
          confirmButtonChildren="Submit"
          confirmButtonProps={{
            type: 'button',
            disabled: isSubmitting,
          }}
        />
      </div>
    </div>
  );
};
