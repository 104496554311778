import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from '../../../../../../components/atoms/Button/Button';
import SwitchFormField from '../../../../../../components/molecules/SwitchFormField/SwitchFormField';
import { reportsRoutes } from '../../../../../../constants/navigation';
import { ModalTypes } from '../../../../../../enums/modalTypes';
import { Report } from '../../../../../../generated/graphql';
import { openModal } from '../../../../../../graphql/modals';
import { TCheckboxFormFieldValue } from '../../../../../../interfaces/forms/form';
import { IReportWidget } from '../../../../../../interfaces/report';
import { TWidgetOptions } from '../../../../../../interfaces/widget';
import UsedReportWidgetStyles from './UsedReportWidgetStyles';

interface IUsedReportWidget {
  item: IReportWidget;
  index: number;
  isUsedWidget?: boolean;
  reportId?: number;
  report?: Report;
  usedWidgetItems?: IReportWidget[];
  getModalWidgetInfo: (
    updatedWidgetOption: TWidgetOptions[],
    dndId?: number
  ) => void;
  getNewReportConfig: (reportConfig: IReportWidget) => void;
  getNewPageBeforeValue: (
    value: boolean,
    reportConfig: IReportWidget,
    index: number
  ) => void;
  removeReportConfig: (reportConfig: IReportWidget, index: number) => void;
}

const UsedReportWidget: FC<IUsedReportWidget> = ({
  item,
  index,
  isUsedWidget = true,
  report,
  reportId,
  getNewReportConfig,
  removeReportConfig,
  getNewPageBeforeValue,
  usedWidgetItems,
  getModalWidgetInfo,
}) => {
  const classes = UsedReportWidgetStyles();
  const [showSwitch, setShowSwitch] = useState<boolean>(
    item?.newPageBefore || false
  );
  const handleOpenConfigModal = (
    reportConfig: IReportWidget,
    index: number,
    isUsedWidget: boolean
  ) => {
    if (isUsedWidget) {
      openModal(ModalTypes.WIDGET_CONFIG_MODAL, {
        reportConfig,
        report,
        reportId,
        getModalWidgetInfo,
        index,
      });
    } else {
      openModal(ModalTypes.WIDGET_CONFIG_MODAL, {
        reportConfig,
        isAvailableWidget: true,
      });
    }
  };

  useEffect(() => {
    if (item.newPageBefore) {
      setShowSwitch(item.newPageBefore);
    } else {
      setShowSwitch(false);
    }
  }, [item.newPageBefore]);

  const handleSwitch = (
    value: TCheckboxFormFieldValue,
    reportConfig: IReportWidget,
    index: number
  ) => {
    setShowSwitch(value);
    getNewPageBeforeValue(value, reportConfig, index);
  };

  const handleAddWidget = (reportConfig: IReportWidget) => {
    getNewReportConfig(reportConfig);
  };

  const handleDeleteWidget = (reportConfig: IReportWidget, index: number) => {
    removeReportConfig(reportConfig, index);
  };

  return (
    <>
      <div
        style={{
          border: 'solid 1px #757575',
          backgroundColor: '#fff',
          marginBottom: 10,
        }}
        className={classes.widgetOption}
      >
        <p className={classes.text}>
          {!isUsedWidget ? (
            <AddCircleOutlineIcon
              className={classes.addBtn}
              onClick={() => handleAddWidget(item)}
            />
          ) : (
            <RemoveCircleOutlineIcon
              className={classes.removeBtn}
              onClick={() => handleDeleteWidget(item, index)}
            />
          )}
          {item.name}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '420px',
            }}
          >
            {isUsedWidget && item && (
              <SwitchFormField
                id="reportSwitch"
                name="startPage"
                color="primary"
                label="Start from new page"
                onChange={value => handleSwitch(value, item, index)}
                value={showSwitch}
              />
            )}
            {item && item.widgetOptions && item?.widgetOptions?.length > 0 && (
              <div>
                <Button
                  color={isUsedWidget ? 'primary' : 'inherit'}
                  className={classes.configBtn}
                  onClick={() =>
                    handleOpenConfigModal(item, index, isUsedWidget)
                  }
                >
                  CONFIG
                </Button>
              </div>
            )}
            <Link to={reportsRoutes.widget.url(item.id || 0)}>
              <Button className={classes.viewBtn} color="inherit">
                VIEW
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsedReportWidget;
