import { makeStyles } from '@mui/styles';

const useTextFormFieldWithActionIconStyle = makeStyles(() => ({
  formField: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  disabled: {},
}));

export default useTextFormFieldWithActionIconStyle;
