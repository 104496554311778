import {
  useProjectJobRoleFindManyQuery,
  useStageFindManyQuery,
} from 'generated/graphql';
import { useMemo, useState } from 'react';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './Stage.module.scss';
import StageActions from './StageActions';
import StageForm from './StageForm/StageForm';
import { initializeValues, initialValues } from './StageForm/StageForm.helper';
import StageHeader from './StageHeader/StageHeader';
import StageOverview from './StageOverview/StageOverview';

type StageProps = {
  projectId: number;
  reportsAccess: boolean;
};

const Stage = ({ projectId, reportsAccess }: StageProps) => {
  const { handleMsgType } = useNotification();
  const [isEditing, setIsEditing] = useState(false);

  const { data: stageData, loading: loadingStage } = useStageFindManyQuery({
    variables: { projectId },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const { data: projectsData } = useProjectJobRoleFindManyQuery({
    variables: { id: projectId },
  });

  const projectJobRoles = useMemo(
    () => projectsData?.ProjectJobRoleFindMany || [],
    [projectsData?.ProjectJobRoleFindMany]
  );

  const isMultiMatch = projectJobRoles.length > 1;

  const stage = useMemo(() => {
    return stageData?.Stages?.[0];
  }, [stageData]);

  const prefillValues = useMemo(
    () => (stage ? initializeValues(stage) : initialValues),
    [stage]
  );

  // If no stage exists, use company's default reports access type. Otherwise use the type set on existing stage
  const stageReportAccess = stage
    ? stage.managerReportEmailLinks
    : reportsAccess;

  const handleEditStage = () => {
    setIsEditing(true);
  };

  const handleCancelForm = () => {
    setIsEditing(false);
  };

  if (loadingStage) {
    return <Loader variant="bubbles" />;
  }

  if (!isEditing && !stage) {
    return (
      <div className={styles.container}>
        <StageHeader
          primaryButton={{ label: 'Insert stage', onClick: handleEditStage }}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {stage && !isEditing ? (
        <>
          <StageHeader
            primaryButton={{ label: 'Edit', onClick: handleEditStage }}
            deleteButton={{ projectId, stageId: stage.id }}
          />
          <StageOverview stage={stage} projectId={projectId} />
          <StageActions stageId={stage.id} projectId={projectId} />
        </>
      ) : (
        <StageForm
          stageId={stage?.id}
          projectId={projectId}
          reportsAccess={stageReportAccess}
          isMultiMatch={isMultiMatch}
          onCancel={handleCancelForm}
          isLoading={false}
          onSubmit={handleCancelForm}
          initialValues={prefillValues}
        />
      )}
    </div>
  );
};
export default Stage;
