import { FC } from 'react';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import SelectFormField from '../SelectFormField/SelectFormField';

interface ISwitchSelectFormField {
  id: string;
  name: string;
  onChange: (value: boolean, name: string) => void;
  value: boolean;
  isDisabled?: boolean;
  hasNullValue?: boolean;
  labels?: { 1: string; 0: string };
}

const defaultSwitchOptions = [
  {
    label: 'On',
    value: 1,
  },
  {
    label: 'Off',
    value: 0,
  },
];

const SwitchSelectFormField: FC<ISwitchSelectFormField> = ({
  value,
  onChange,
  labels,
  ...props
}) => {
  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    onChange(!!parseInt(value as string, 10), name);
  };

  const options = labels
    ? [
        { label: labels[1], value: 1 },
        { label: labels[0], value: 0 },
      ]
    : defaultSwitchOptions;

  return (
    <SelectFormField
      onChange={handleChange}
      value={value ? 1 : 0}
      options={options}
      {...props}
    />
  );
};

export default SwitchSelectFormField;
