import { endOfDay } from 'date-fns';
import { useFormik } from 'formik';
import {
  useCompaniesQuery,
  useProjectFindManysByCompanyWithStageCandidateRangeLazyQuery,
} from 'generated/graphql';
import { getUtcBoundaryOfDay, sub } from 'helpers/date';
import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';
import {
  Button,
  InlineNotification,
  NotificationType,
  Loader,
  TMultiselectOption,
  TNotification,
  useNotification,
  Datepicker,
  MultiselectFormField,
} from '@spotted-zebra-uk/ui-components';
import CandidateUsageTable from './CandidateUsageTable/CandidateUsageTable';
import ExportResult from './ExportResult/ExportResult';

export const errorMessage =
  'Oops, something went wrong during the fetch of new stage candidates';

export const INITIAL_START_DATE = getUtcBoundaryOfDay(
  sub(new Date(), { months: 1 }),
  'start'
);
export const INITIAL_END_DATE = endOfDay(
  sub(new Date(), { days: 1 })
).toISOString();

const initialFormValues: {
  companies: TMultiselectOption[];
  dateFrom: string;
  dateTo: string;
} = {
  companies: [],
  dateFrom: INITIAL_START_DATE,
  dateTo: INITIAL_END_DATE,
};
export type TFormValues = {
  companies: TMultiselectOption[];
  dateFrom: string;
  dateTo: string;
};

const CandidateUsage: FC = () => {
  const { handleMsgType } = useNotification();

  const formik = useFormik<TFormValues>({
    initialValues: initialFormValues,
    onSubmit: values => handleFormSubmit(values),
  });

  const companiesQueryResponse = useCompaniesQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${errorMessage}: ${error.message}`,
      });
    },
  });

  const [
    getCompanyProjectsQuery,
    {
      data: companyProjectsResponse,
      loading: companyProjectsLoading,
      called: queryWasCalled,
    },
  ] = useProjectFindManysByCompanyWithStageCandidateRangeLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${errorMessage}: ${error.message}`,
      });
    },
  });

  const companyMultiselectOptions = useMemo(
    () =>
      companiesQueryResponse.data?.Companies?.map(c => ({
        label: c.name,
        value: String(c.id),
      })) || [],
    [companiesQueryResponse.data?.Companies]
  );

  const getQueryVariablesFromFormValues = (
    companies: TMultiselectOption[],
    dateFrom: string,
    dateTo: string
  ): [number[], string, string] => {
    const companyIds: number[] = [];
    companies.forEach(c => {
      if (c.value) companyIds.push(+c.value);
    });

    const startOfDayStartDate =
      dateFrom && getUtcBoundaryOfDay(new Date(dateFrom), 'start');
    const endOfDayEndDate =
      dateTo && getUtcBoundaryOfDay(new Date(dateTo), 'end');
    return [companyIds, startOfDayStartDate, endOfDayEndDate];
  };

  const handleFormSubmit = async (formValues: TFormValues) => {
    const { companies, dateFrom, dateTo } = formValues;
    const [
      companyIds,
      startOfDayStartDate,
      endOfDayEndDate,
    ] = getQueryVariablesFromFormValues(companies, dateFrom, dateTo);
    await getCompanyProjectsQuery({
      variables: {
        companyIds,
        createdAtFrom: startOfDayStartDate,
        createdAtTo: endOfDayEndDate,
      },
    });
  };

  const renderResultsView = () => {
    const companyProjects = companyProjectsResponse?.projects;
    if (companyProjectsLoading) {
      return (
        <div data-testid="company-projects-loader">
          <Loader variant="bubbles" />
        </div>
      );
    }

    if (!queryWasCalled) {
      return (
        <div className="candidate-usage__error-message">
          <InlineNotification
            title={'Apply filters to see the summary'}
            notificationType={NotificationType.INFORMATION}
          />
        </div>
      );
    }

    if (
      companyProjects?.some(companyProject => !isEmpty(companyProject.projects))
    ) {
      return <CandidateUsageTable companyProjects={companyProjects} />;
    }

    return (
      <div className="candidate-usage__error-message">
        <InlineNotification
          title={'No results found'}
          notificationType={NotificationType.WARNING}
        />
      </div>
    );
  };

  return (
    <div className="candidate-usage">
      <form
        className="candidate-usage__input-container"
        onSubmit={formik.handleSubmit}
      >
        <div
          className="candidate-usage__input-item"
          data-testid="companies-dropdown"
        >
          <MultiselectFormField
            id="company-dropdown"
            name="companies"
            placeholder="Companies"
            label="Companies"
            onChange={(value: TMultiselectOption[]) =>
              formik.setFieldValue('companies', value)
            }
            options={companyMultiselectOptions}
            value={formik.values.companies}
          />
        </div>
        <div
          className="candidate-usage__input-item"
          data-testid="input-date-from"
        >
          <Datepicker
            onDateChange={value => formik.setFieldValue('dateFrom', value)}
            date={formik.values.dateFrom}
            id="dateFrom"
            placeholder="Date from"
            name="dateFrom"
            label="Date from"
            inputType="input"
          />
        </div>
        <div
          className="candidate-usage__input-item"
          data-testid="input-date-to"
        >
          <Datepicker
            onDateChange={value => formik.setFieldValue('dateTo', value)}
            date={formik.values.dateTo}
            id="dateTo"
            placeholder="Date to"
            name="dateTo"
            label="Date to"
            inputType="input"
          />
        </div>
        <div className="candidate-usage__button-container">
          <div className="candidate-usage__input-item">
            <Button
              type="submit"
              size="large"
              variant="primary"
              disabled={companyProjectsLoading}
              data-testid="company-projects-button"
            >
              View usage summary
            </Button>
          </div>
          <ExportResult
            getQueryVariablesFromFormValues={getQueryVariablesFromFormValues}
            formValues={formik.values}
            disabled={companyProjectsLoading}
          />
        </div>
      </form>
      {renderResultsView()}
    </div>
  );
};

export default CandidateUsage;
