import Button from 'components/atoms/Button/Button';
import ConfirmationModal from 'components/organisms/ConfirmationModal/ConfirmationModal';
import { ModalTypes } from 'enums/modalTypes';
import {
  StageCandidateFindManyDocument,
  StageCandidateStatus,
  TestCandidateFindManyByCandidateDocument,
  useStageCandidateReprocessOneMutation,
  useStageCandidateResetOneMutation,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { IModalStatus } from '../../../../UserDetails.interface';
import { IRecalculateStage } from '../../UserResets.interface';

const RecalculateStage: FC<IRecalculateStage> = ({
  userInfo,
  stageId,
  projectId,
  displaySnackbar,
  stageStatus,
  stageCandidateId,
}) => {
  const { handleMsgType } = useNotification();

  const [modalStatus, setModalStatus] = useState<IModalStatus>({
    isOpen: false,
    msg: '',
    type: '',
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [resetOneCandidateStageMutation] = useStageCandidateResetOneMutation({
    onError: error => {
      setErrorMsg(error.message);
    },
    onCompleted: () => {
      displaySnackbar('stage reset successful');
      setErrorMsg('');
      closeModal();
    },
    refetchQueries: [
      {
        query: StageCandidateFindManyDocument,
        variables: { candidateId: userInfo?.id },
      },
      {
        query: TestCandidateFindManyByCandidateDocument,
        variables: { candidateId: Number(userInfo?.id) || 0 },
      },
    ],
  });

  const onResetStage = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: true,
    });
  };

  const closeModal = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: false,
    });
    setErrorMsg('');
  };

  const handleResetStage = () => {
    const variables = {
      id: stageCandidateId,
    };
    resetOneCandidateStageMutation({ variables });
  };

  const [
    stageCandidateReprocessOneMutation,
  ] = useStageCandidateReprocessOneMutation({
    onCompleted: data => {
      if (data.StageCandidateReprocessOne) {
        displaySnackbar('Recalculated soft skill scores successfully');
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const onReCalculateSoftSkill = () => {
    const variables = {
      id: stageCandidateId,
    };
    stageCandidateReprocessOneMutation({ variables });
  };

  const handleTpDetails = () => {
    openModal(ModalTypes.RETRIEVE_TP_DETAILS_MODAL, {
      userInfo,
      projectId,
      stageId,
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#f2f2f2',
          padding: '20px',
        }}
      >
        <p>
          <strong>
            Reset stage and all the tests in the stage, including any soft skill
            calculations
          </strong>
        </p>
        <Button onClick={onResetStage}>Reset Stage</Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <p>
          <strong>Import new tests from TP</strong>
        </p>
        <Button onClick={handleTpDetails}>Retrieve TP Details</Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#f2f2f2',
          padding: '20px',
        }}
      >
        <p>
          <strong>
            Recalculate soft skill scores then create and email reports
          </strong>
        </p>
        <Button
          isDisabled={stageStatus !== StageCandidateStatus.Completed}
          onClick={onReCalculateSoftSkill}
        >
          Recalculate
        </Button>
      </div>
      <ConfirmationModal
        open={modalStatus.isOpen}
        errorMsg={errorMsg}
        message={
          'Please confirm you wish to delete the current results for this candidate'
        }
        onSave={handleResetStage}
        onClose={closeModal}
        saveBtnText="RESET"
      />
    </>
  );
};

export default RecalculateStage;
