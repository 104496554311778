import SidebarNavigation from 'components/organisms/SidebarNavigation/SidebarNavigation';
import { FC } from 'react';
import { contentSidebarConfig } from './StaticContentNavigation.constants';

interface IStaticContentNavigation {}

const StaticContentNavigation: FC<IStaticContentNavigation> = () => {
  return <SidebarNavigation routes={contentSidebarConfig} />;
};

export default StaticContentNavigation;
