import { FC } from 'react';
import Button from '../../../../components/atoms/Button/Button';
import { Report } from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import { IReportWidget } from '../../../../interfaces/report';
import { TWidgetOptions } from '../../../../interfaces/widget';
import EditReport from './EditReport/EditReport';
import ReportDnD from './ReportDnD/ReportDnD';
import ReportFormStyles from './ReportFormStyles';

export interface IReportPath {
  reportId: string;
}

interface IEditReportPayload {
  reportName: string;
  company: string;
  reportType: TSelectFormFieldValue;
  reportSubType?: TSelectFormFieldValue;
}

interface IReportForm {
  reportName: string | undefined;
  company: string | undefined;
  reportType: TSelectFormFieldValue;
  reportSubType?: TSelectFormFieldValue;
  report: Report | undefined;
  reportId: number;
  availableWidget: IReportWidget[] | undefined;
  usedWidget: IReportWidget[] | undefined;
  getNewPageBeforeValue: (
    value: boolean,
    reportConfig: IReportWidget,
    index: number
  ) => void;
  reOrderWidget: (item: IReportWidget[]) => void;
  getNewReportConfig: (reportConfig: IReportWidget) => void;
  removeReportConfig: (reportConfig: IReportWidget, index: number) => void;
  getReportDetails: (reportDetails: IEditReportPayload) => void;
  onSaveReport: () => void;
  getModalWidgetInfo: (
    updatedWidgetOption: TWidgetOptions[],
    dndId?: number
  ) => void;
}

const ReportForm: FC<IReportForm> = ({
  report,
  reportName,
  reportId,
  reportSubType,
  company,
  reportType,
  availableWidget,
  usedWidget,
  reOrderWidget,
  getNewReportConfig,
  removeReportConfig,
  getReportDetails,
  onSaveReport,
  getNewPageBeforeValue,
  getModalWidgetInfo,
}) => {
  const classes = ReportFormStyles();

  return (
    <div>
      <Button
        color="primary"
        className={classes.saveBtn}
        onClick={onSaveReport}
      >
        save
      </Button>
      <EditReport
        reportName={reportName}
        reportId={reportId}
        company={company}
        reportType={reportType}
        reportSubType={reportSubType}
        getReportDetails={getReportDetails}
      />
      {usedWidget && (
        <ReportDnD
          availableWidget={availableWidget}
          usedWidgetItems={usedWidget}
          report={report}
          reportId={reportId}
          getModalWidgetInfo={getModalWidgetInfo}
          reOrderWidget={reOrderWidget}
          getNewReportConfig={getNewReportConfig}
          removeReportConfig={removeReportConfig}
          getNewPageBeforeValue={getNewPageBeforeValue}
        />
      )}
    </div>
  );
};

export default ReportForm;
