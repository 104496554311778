import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import {
  CompanyFragment,
  CompanyFragmentDoc,
  useUploadCompanyLogoMutation,
} from 'generated/graphql';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import UploadCompanyLogoForm from './UploadCompanyLogoForm/UploadCompanyLogoForm';

interface IUploadCompanyLogo {
  companyId: number;
}

const UploadCompanyLogo: FC<IUploadCompanyLogo> = ({ companyId }) => {
  const { handleMsgType } = useNotification();

  const [
    uploadCompanyLogoMutation,
    uploadCompanyLogoMutationResponse,
  ] = useUploadCompanyLogoMutation({
    context: {
      isUpload: true,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      const companyLogoUrl = data?.uploadCompanyLogo;
      if (companyLogoUrl) {
        const cacheCompanyData = cache.readFragment<CompanyFragment>({
          id: `Company:${companyId}`,
          fragment: CompanyFragmentDoc,
        });
        if (cacheCompanyData) {
          cache.writeFragment<CompanyFragment>({
            id: `Company:${companyId}`,
            fragment: CompanyFragmentDoc,
            data: {
              ...cacheCompanyData,
              logoUrl: companyLogoUrl,
            },
          });
        }
      }
    },
  });

  const handleUploadCompanyLogoMutation = (file: File) => {
    uploadCompanyLogoMutation({
      variables: {
        file,
        id: companyId,
      },
    });
  };

  if (uploadCompanyLogoMutationResponse.loading) {
    return <CircularLoader />;
  }

  return <UploadCompanyLogoForm onSubmit={handleUploadCompanyLogoMutation} />;
};

export default UploadCompanyLogo;
