import { appRoutes } from 'constants/navigation';
import { useGetProjectByIdQuery } from 'generated/graphql';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export type LocationState = {
  from?: Location;
  projectId?: number;
  projectJobRoleSkillsProfileId?: number;
};

export const useGetProjectFromLocation = () => {
  const locationState = useLocation().state as LocationState;
  const { handleMsgType } = useNotification();

  const projectPage = locationState?.from?.pathname.includes(
    appRoutes.projects.path
  );

  const projectId = locationState?.projectId;
  const projectQueryResponse = useGetProjectByIdQuery({
    variables: { id: projectId as number },
    skip: !projectId,
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  return {
    projectPath: projectPage ? locationState?.from?.pathname : undefined,
    projectQueryResponse: projectPage ? projectQueryResponse : undefined,
  };
};
