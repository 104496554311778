import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestFragment,
  ProjectRequestUserApprovalStatus,
  ProjectRequestUserModelFragment,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { formatDate } from 'helpers/date';
import { FC } from 'react';

interface IProjectRequestApprovalsPreview {
  projectRequest: ProjectRequestFragment;
  projectRequestUsers: ProjectRequestUserModelFragment[];
}

export const ProjectRequestApprovalsPreview: FC<IProjectRequestApprovalsPreview> = ({
  projectRequest,
  projectRequestUsers,
}) => {
  const handleApprovalStatusClick = () => {
    openModal(ModalTypes.PROJECT_REQUEST_APPROVAL_STATUS_MODAL, {
      projectRequestId: projectRequest.id,
      projectRequestCompanyId: projectRequest.companyId,
    });
  };

  const formattedApprovalRequestDate = formatDate(
    projectRequest.approvalRequestDate,
    'dd/MM/yyyy'
  );
  const numberOfRequestedApprovals = projectRequestUsers.filter(
    projectRequestUsers => projectRequestUsers.approval
  ).length;
  const numberOfApproved = projectRequestUsers.filter(
    projectRequestUsers =>
      projectRequestUsers.approval?.approvalStatus ===
      ProjectRequestUserApprovalStatus.Approved
  ).length;

  return (
    <div className="project-request-approvals-preview">
      <div className="project-request-approvals-preview__text">
        Approval request set {formattedApprovalRequestDate}
      </div>
      <div
        className="project-request-approvals-preview__link"
        onClick={handleApprovalStatusClick}
      >
        Who Approved? ({numberOfApproved}/{numberOfRequestedApprovals})
      </div>
    </div>
  );
};
