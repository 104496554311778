import { formManagerRoutes } from 'constants/navigation';

export const candidateSidebarConfig = [
  {
    title: 'Fields',
    path: formManagerRoutes.fields.path,
  },
  {
    title: 'Company forms',
    path: formManagerRoutes.companyForms.path,
  },
  {
    title: 'Download submitted forms',
    path: formManagerRoutes.downloadSubmittedForms.path,
  },
];
