import { useFormikContext } from 'formik';
import { IProjectFormValues } from 'views/Project/components/ProjectOverviewForm/index';
import { useLocales } from '@spotted-zebra-uk/ui-components';

import {
  Multiselect,
  TMultiselectOption,
} from '@spotted-zebra-uk/ui-components';
import styles from './LanguageForm.module.scss';

type LanguageFormProps = {
  title: string;
};

const LanguageForm = ({ title }: LanguageFormProps) => {
  const { setFieldValue, values } = useFormikContext<IProjectFormValues>();
  const { getSupportedLocaleMetadata, SupportedLocale } = useLocales();

  const handleChange = (values: TMultiselectOption[]) => {
    setFieldValue(
      'projectLocales',
      values.map(value => value.value)
    );
  };

  return (
    <div className={styles.container} data-testid="language-form__container">
      {title && <h2 className={styles.title}>{title}</h2>}
      <Multiselect
        id="project-languages-multiselect"
        name="Languages"
        value={values.projectLocales.map(value => ({
          label: getSupportedLocaleMetadata(SupportedLocale[value]).name
            .english,
          value: value,
        }))}
        options={Object.entries(SupportedLocale).map(
          ([language, SupportedLocale]) => ({
            id: language,
            label: getSupportedLocaleMetadata(SupportedLocale).name.english,
            value: language,
          })
        )}
        onChange={handleChange}
      />
    </div>
  );
};

export default LanguageForm;
