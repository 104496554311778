import { FC } from 'react';
import { Typography } from '@mui/material';
import { IStatusMsg } from '../../../UserDetails.interface';

const StatusMsg: FC<IStatusMsg> = ({
  reportRenderStatus,
  blob,
  statusMessage,
}) => {
  return (
    <>
      <Typography>
        <strong>STATUS: </strong>
        {reportRenderStatus}
      </Typography>
      {blob ? (
        <Typography>
          <strong>URL: </strong>
          <a href={blob} target="_blank" rel="noreferrer noopener">
            {blob}
          </a>
        </Typography>
      ) : (
        <Typography>
          <strong>Message: </strong>
          {statusMessage}
        </Typography>
      )}
    </>
  );
};

export default StatusMsg;
