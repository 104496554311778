import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../components/atoms/Button/Button';
import TextFormFieldWithActionIcon from '../../../../../components/organisms/TextFormFieldWithActionIcon/TextFormFieldWithActionIcon';
import { roleLevelOptions } from '../../../../../constants/roleLevel';
import { ModalTypes } from '../../../../../enums/modalTypes';
import {
  SoftSkillRoleLevelContent,
  useSaveSoftSkillContentMutation,
} from '../../../../../generated/graphql';
import { openModal } from '../../../../../graphql/modals';
import { TFormFieldValue } from '../../../../../interfaces/forms/form';
import useSoftSkillContentListItemStyle from './SoftSkillContentListItemStyle';

interface ISoftSkillRoleLevelContentListItem {
  contentPiece: SoftSkillRoleLevelContent;
}

interface ISoftSkillRoleLevelContentFieldValues {
  description: string;
  hiringManagerContent: string;
  candidateContent: string;
  internalCandidateContent: string;
}

interface ISoftSkillRoleLevelContentFieldDisabledValues {
  description: boolean;
  hiringManagerContent: boolean;
  candidateContent: boolean;
  internalCandidateContent: boolean;
  saveButton: boolean;
}
const SoftSkillContentListItem: FC<ISoftSkillRoleLevelContentListItem> = ({
  contentPiece,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useSoftSkillContentListItemStyle();
  const [values, setValues] = useState<ISoftSkillRoleLevelContentFieldValues>({
    description: contentPiece.description ? contentPiece.description : '',
    hiringManagerContent: contentPiece.hiringManagerContent
      ? contentPiece.hiringManagerContent
      : '',
    candidateContent: contentPiece.candidateContent
      ? contentPiece.candidateContent
      : '',
    internalCandidateContent: contentPiece.internalCandidateContent
      ? contentPiece.internalCandidateContent
      : '',
  });
  const [isDisabled, setIsDisabled] = useState<
    ISoftSkillRoleLevelContentFieldDisabledValues
  >({
    description: false,
    hiringManagerContent: false,
    candidateContent: false,
    internalCandidateContent: false,
    saveButton: true,
  });
  const [saveSoftSkillContentMutation] = useSaveSoftSkillContentMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setIsDisabled({
        description: false,
        hiringManagerContent: false,
        candidateContent: false,
        internalCandidateContent: false,
        saveButton: true,
      });
    },
  });

  const handleChangeDescription = (value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, description: value as string }));
    checkIfEdited({ ...values, description: value as string });
  };

  const handleChangeHMContent = (value: TFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      hiringManagerContent: value as string,
    }));
    checkIfEdited({ ...values, hiringManagerContent: value as string });
  };

  const handleChangeCandidateContent = (value: TFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      candidateContent: value as string,
    }));
    checkIfEdited({ ...values, candidateContent: value as string });
  };

  const handleChangeInternalCandidateContent = (value: TFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      internalCandidateContent: value as string,
    }));
    checkIfEdited({ ...values, internalCandidateContent: value as string });
  };

  const handleSubmit = () => {
    setIsDisabled({
      description: true,
      hiringManagerContent: true,
      candidateContent: true,
      internalCandidateContent: true,
      saveButton: true,
    });
    saveSoftSkillContent(values);
  };

  const saveSoftSkillContent = (
    values: ISoftSkillRoleLevelContentFieldValues
  ) => {
    const id = contentPiece.id;
    const description = values.description
      ? values.description
      : contentPiece.description;
    const hiringManagerContent = values.hiringManagerContent
      ? values.hiringManagerContent
      : contentPiece.hiringManagerContent;
    const candidateContent = values.candidateContent
      ? values.candidateContent
      : contentPiece.candidateContent;
    const internalCandidateContent = values.internalCandidateContent
      ? values.internalCandidateContent
      : contentPiece.internalCandidateContent;

    saveSoftSkillContentMutation({
      variables: {
        id,
        description,
        hiringManagerContent,
        candidateContent,
        internalCandidateContent,
      },
    });
  };

  // Disables button if no edit is made
  const checkIfEdited = (
    values: ISoftSkillRoleLevelContentFieldValues
  ): void => {
    if (
      values.description === '' ||
      values.hiringManagerContent === '' ||
      values.candidateContent === '' ||
      values.internalCandidateContent === ''
    ) {
      if (!isDisabled.saveButton)
        setIsDisabled(prevValues => ({ ...prevValues, saveButton: true }));
      return;
    }
    if (
      contentPiece.description === values.description &&
      contentPiece.hiringManagerContent === values.hiringManagerContent &&
      contentPiece.candidateContent === values.candidateContent &&
      contentPiece.internalCandidateContent === values.internalCandidateContent
    ) {
      if (!isDisabled.saveButton)
        setIsDisabled(prevValues => ({ ...prevValues, saveButton: true }));
      return;
    }

    if (isDisabled.saveButton)
      setIsDisabled(prevValues => ({ ...prevValues, saveButton: false }));
  };

  const handleOnClickDescription = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-description-${contentPiece.id}`,
      name: 'description',
      label: 'Description',
      onChange: handleChangeDescription,
      initialValue: values.description,
    });
  };

  const handleOnClickHMContent = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-hmcontent-${contentPiece.id}`,
      name: 'hiringManagerContent',
      label: 'Hiring manager content',
      onChange: handleChangeHMContent,
      initialValue: values.hiringManagerContent,
    });
  };

  const handleOnClickCandidateContent = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-candidate-content-${contentPiece.id}`,
      name: 'candidateContent',
      label: 'Candidate content',
      onChange: handleChangeCandidateContent,
      initialValue: values.candidateContent,
    });
  };

  const handleOnClickInternalCandidateContent = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-internal-candidate-content-${contentPiece.id}`,
      name: 'internalCandidateContent',
      label: 'Development feedback',
      onChange: handleChangeInternalCandidateContent,
      initialValue: values.internalCandidateContent,
    });
  };
  const key = 'sscli';
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.softSkill?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.grade}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>
          {
            roleLevelOptions.filter(
              roleLevel => roleLevel.value === contentPiece.roleLevel
            )[0].label
          }
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-description-${contentPiece.id}`}
          name="description"
          label="Description"
          onChange={handleChangeDescription}
          value={values.description}
          isDisabled={isDisabled.description}
          onClick={handleOnClickDescription}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-hmContent-${contentPiece.id}`}
          name="hmContent"
          label="Hiring Manager feedback"
          onClick={handleOnClickHMContent}
          onChange={handleChangeHMContent}
          value={values.hiringManagerContent}
          isDisabled={isDisabled.hiringManagerContent}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-candidateContent-${contentPiece.id}`}
          name="candidateContent"
          label="Candidate feedback"
          onChange={handleChangeCandidateContent}
          onClick={handleOnClickCandidateContent}
          value={values.candidateContent}
          isDisabled={isDisabled.candidateContent}
          rows={2}
          multiline={true}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-internalCandidateContent-${contentPiece.id}`}
          name="internalCandidateContent"
          label="Development feedback"
          onChange={handleChangeInternalCandidateContent}
          onClick={handleOnClickInternalCandidateContent}
          value={values.internalCandidateContent}
          isDisabled={isDisabled.internalCandidateContent}
          rows={2}
          multiline={true}
        />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={handleSubmit} isDisabled={isDisabled.saveButton}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default SoftSkillContentListItem;
