import { companyRoutes, projectsRoutes } from 'constants/navigation';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IStageResult } from '../UserResets.interface';

const StageResult: FC<IStageResult> = ({
  companyName,
  companyId,
  projectName,
  stageId,
  stageCandidateId,
  stageCandidateSubId,
  stageName,
  stageStatus,
  projectId,
  lastDateOfCandidateAction,
  scores,
}) => {
  return (
    <>
      <p style={{ fontSize: 24, marginBottom: 10 }}>
        <Link to={companyId ? companyRoutes.editCompany.url(companyId) : ''}>
          {companyName}({companyId})
        </Link>
        |
        <Link
          to={projectId ? projectsRoutes.editProjectView.url(projectId) : ''}
        >
          {projectName}({projectId})
        </Link>
      </p>
      <p style={{ marginBottom: 10 }}>
        {stageName}({stageId})
      </p>
      <p style={{ marginBottom: 10 }}>
        Stage Candidate ID: {stageCandidateId}; UUID: ({stageCandidateSubId})
      </p>
      <p style={{ marginBottom: 10 }}>Status: {stageStatus}</p>
      {lastDateOfCandidateAction && (
        <p style={{ marginBottom: 10 }}>
          Updated:{' '}
          {format(new Date(lastDateOfCandidateAction), 'dd/MM/yyyy HH:mm:ss')}
        </p>
      )}
    </>
  );
};

export default StageResult;
