import { FC } from 'react';
import { Button, Modal, Typography } from '@mui/material';
import CircularLoader from '../../molecules/CircularLoader/CircularLoader';
import useConfirmationModalStyle from './ConfirmationModalStyle';

export interface TConfirmationModal {
  open: boolean;
  message?: string;
  isLoading?: boolean;
  onSave: () => void;
  onClose: () => void;
  saveBtnText?: string;
  errorMsg?: string;
}

const ConfirmationModal: FC<TConfirmationModal> = ({
  open,
  message,
  errorMsg = '',
  onSave,
  onClose,
  isLoading = false,
  saveBtnText = 'Save',
}) => {
  const classes = useConfirmationModalStyle();
  const handleSave = () => {
    onSave();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal onClose={onClose} open={open} className={classes.modal}>
      {isLoading ? (
        <CircularLoader customStyle={classes.loaderWrapper} />
      ) : (
        <div className={classes.paper}>
          {errorMsg.length > 0 ? (
            <Typography color="error" className={classes.title}>
              {errorMsg}
            </Typography>
          ) : (
            <Typography className={classes.title}>{message}</Typography>
          )}
          <div className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              {saveBtnText}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="inherit"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
