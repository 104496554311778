import { FC } from 'react';
import styles from '../BehaviourUploadInlineNotification.module.scss';

interface IFailedValidationStatus {
  sheetTemplate: string;
  customError?: string;
}

const FailedValidationStatus: FC<IFailedValidationStatus> = ({
  sheetTemplate,
  customError,
}) => {
  return (
    <div className={styles.info_container}>
      <div className={styles.info_title}>Validation failed</div>
      {customError ? (
        <div className={styles.info_paragraph}>{customError}</div>
      ) : (
        <>
          <div className={styles.info_paragraph}>
            The uploaded content didn’t pass the validation due to one or more
            errors.
          </div>
          <div className={styles.info_paragraph}>
            Please ensure your CSV file uses the same structure as the{' '}
            <a href={sheetTemplate} className={styles.link} download>
              template
            </a>
            .
          </div>
        </>
      )}
    </div>
  );
};

export default FailedValidationStatus;
