import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { TFieldGQL } from '../../../../interfaces/forms/formGQL';
import CandidateInfoFieldsListItem from './CandidateInfoFieldsListItem/CandidateInfoFieldsListItem';
import useCandidateInfoFieldsListStyle from './CandidateInfoFieldsListStyle';

interface ICandidateInfoFieldsList {
  candidateInfoFileds: TFieldGQL[];
}

const CandidateInfoFieldsList: FC<ICandidateInfoFieldsList> = ({
  candidateInfoFileds,
}) => {
  const classes = useCandidateInfoFieldsListStyle();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Name</Typography>
        </Grid>
        <Grid xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Owned by Company</Typography>
        </Grid>
        <Grid xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Used by</Typography>
        </Grid>
      </Grid>
      {candidateInfoFileds.map(candidateInfoField => (
        <CandidateInfoFieldsListItem
          candidateInfoField={candidateInfoField}
          key={candidateInfoField.id}
        />
      ))}
    </Grid>
  );
};

export default CandidateInfoFieldsList;
