import { ItemsCount } from 'components/ui/ItemsCount/ItemsCount';
import {
  ActorRole,
  useProjectUserDeleteOneMutation,
  useProjectUserFindManyQuery,
  useProjectUserUpdateOneMutation,
} from 'generated/graphql';
import { useMemo, useState } from 'react';
import {
  Table,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import DeleteModal from './Modals/DeleteModal/DeleteModal';
import EditModal from './Modals/EditModal/EditModal';
import {
  getRowData,
  headers,
  ProjectUser,
  sortByCompanyAndProjectName,
} from './UserProjectRoles.helpers';
import styles from './UserProjectRoles.module.scss';

export function UserProjectRoles({ userId }: { userId: number }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [
    selectedUserProjectForModification,
    setSelectedUserProjectForModification,
  ] = useState<ProjectUser>(null!);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [firstRowIndex, setFirstRowIndex] = useState(0);

  const { handleMsgType } = useNotification();

  const { data: projectUserData, refetch } = useProjectUserFindManyQuery({
    variables: {
      userIds: [userId],
    },
  });

  const [updateProjectUser] = useProjectUserUpdateOneMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'User project role successfully updated',
      });
      refetch();
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: 'User project role could not be updated.',
        message: 'Something went wrong. Please try again.',
      });
    },
  });

  const [deleteProjectUser] = useProjectUserDeleteOneMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'User project role successfully deleted',
      });
      refetch();
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: 'User project role could not be deleted.',
        message: 'Something went wrong. Please try again.',
      });
    },
  });

  const sortedData = useMemo(
    () => sortByCompanyAndProjectName(projectUserData?.ProjectUserFindMany),
    [projectUserData]
  );

  const paginatedData = useMemo(
    () =>
      sortedData
        ? sortedData.slice(firstRowIndex, firstRowIndex + currentPageSize)
        : [],
    [currentPageSize, firstRowIndex, sortedData]
  );

  const rowData = useMemo(
    () => getRowData(paginatedData, handleOpenEditModal, handleOpenDeleteModal),
    [paginatedData]
  );

  const totalCount = projectUserData?.ProjectUserFindMany?.length || 0;

  function handlePaginationChange({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) {
    if (pageSize !== currentPageSize) {
      setCurrentPageSize(pageSize);
    }
    setFirstRowIndex(pageSize * (page - 1));
    setPage(page);
  }

  function handleOpenEditModal(projectUser: ProjectUser) {
    setSelectedUserProjectForModification(projectUser);
    setEditModalOpen(true);
  }

  function handleOpenDeleteModal(projectUser: ProjectUser) {
    setSelectedUserProjectForModification(projectUser);
    setDeleteModalOpen(true);
  }

  function handleEditProjectRole(newActorRole: ActorRole) {
    updateProjectUser({
      variables: {
        actorRole: newActorRole,
        projectId: selectedUserProjectForModification.project.id,
        userId: selectedUserProjectForModification.user.id,
      },
    });
    setEditModalOpen(false);
  }

  function handleDeleteProjectRole() {
    deleteProjectUser({
      variables: {
        projectId: selectedUserProjectForModification.project.id,
        userId: selectedUserProjectForModification.user.id,
      },
    });
    setDeleteModalOpen(false);
  }

  return (
    <>
      <section className={styles.container}>
        <p className={styles.sectionTitle}>User Project Roles</p>
        <Table
          headerData={headers}
          rowData={rowData}
          dataTestId="user-project-roles__table"
          isSortable={false}
          className={styles.table}
          hasToolbar
          hasHoverableActions
          hasPagination
          pagination={{
            onChange: handlePaginationChange,
            page: page,
            currentPageSize: currentPageSize,
            totalNumberOfItems: totalCount,
          }}
          toolbarConf={{
            componentListLeftStart: (
              <ItemsCount count={totalCount} ariaLabelSuffix="Total items" />
            ),
          }}
          noItemsConf={{
            noItemsText: 'The user is not a member of any project teams.',
          }}
        />
      </section>

      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteProjectRole}
        selectedUserProject={selectedUserProjectForModification}
      />

      <EditModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onConfirm={handleEditProjectRole}
        selectedUserProject={selectedUserProjectForModification}
      />
    </>
  );
}
