import { FC } from 'react';
import Modal from '../../../components/molecules/Modal/Modal';
import { ModalTypes } from '../../../enums/modalTypes';
import { closeModal } from '../../../graphql/modals';
import { TStaticContentFiltersModal } from '../../../interfaces/modal';
import StaticContentFiltersForm from './StaticContentFiltersForm/StaticContentFiltersForm';
import useStaticContentFiltersModalStyle from './StaticContentFiltersModalStyle';

export interface IStaticContentFiltersModal
  extends TStaticContentFiltersModal {}

const StaticContentFiltersModal: FC<IStaticContentFiltersModal> = ({
  isSoftSkillFilterIncluded = false,
  isGradeFilterIncluded = false,
  isRoleLevelFilterIncluded = false,
  isClassificationFilterIncluded = false,
  isTraitFilterIncluded = false,
  filters,
  onSubmit,
}) => {
  const classes = useStaticContentFiltersModalStyle();

  const handleCloseModal = () => {
    closeModal(ModalTypes.STATIC_CONTENT_FILTERS_MODAL);
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <StaticContentFiltersForm
        isSoftSkillFilterIncluded={isSoftSkillFilterIncluded}
        isGradeFilterIncluded={isGradeFilterIncluded}
        isRoleLevelFilterIncluded={isRoleLevelFilterIncluded}
        isClassificationFilterIncluded={isClassificationFilterIncluded}
        isTraitFilterIncluded={isTraitFilterIncluded}
        filters={filters}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default StaticContentFiltersModal;
