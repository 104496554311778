import { useProjectRequestUpdateOneMutation } from 'generated/graphql';
import { FC, useState } from 'react';
import {
  TextInputField,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface ProjectNameFieldProps {
  projectRequestId: number;
  projectName: string;
}

const ProjectNameField: FC<ProjectNameFieldProps> = ({
  projectRequestId,
  projectName,
}) => {
  const { handleMsgType } = useNotification();

  const [value, setValue] = useState(projectName);
  const [error, setError] = useState('');
  const [projectRequestUpdateOne] = useProjectRequestUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const save = () => {
    if ('value') {
      projectRequestUpdateOne({
        variables: { projectName: value, id: projectRequestId },
      });
    }
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);

    if (!newValue) {
      setError('Project Name is required field!');
    } else {
      setError('');
    }
  };

  return (
    <div className="project-name-field">
      <TextInputField
        id="test"
        value={value}
        onChange={handleChange}
        onBlur={save}
        hasError={!!error}
        bottomText={error}
        placeholder="Project Name"
        label="Project Name"
      />
    </div>
  );
};

export default ProjectNameField;
