import { ActorRole } from 'generated/graphql';
import { actorRoleLabels, actorRolesForSelect } from 'helpers/actorRoles';
import { useEffect, useState } from 'react';
import {
  Modal,
  ModalSize,
  SelectFormField,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import { ProjectUser } from '../../UserProjectRoles.helpers';
import { ModalLabel } from '../ModalFieldLabel';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  selectedUserProject: ProjectUser;
  onConfirm: (newActorRole: ActorRole) => void;
};

const initialActorRole = actorRolesForSelect[0];

export const EditModal = ({
  open,
  onClose,
  selectedUserProject,
  onConfirm,
}: ModalProps) => {
  const [selectedActorRole, setSelectedActorRole] = useState<TSelectOption>(
    initialActorRole
  );

  useEffect(() => {
    if (selectedUserProject?.actorRole) {
      setSelectedActorRole({
        label: String(actorRoleLabels[selectedUserProject.actorRole]),
        value: String(selectedUserProject.actorRole),
      });
    }
  }, [selectedUserProject]);

  function handleConfirmActorRole() {
    onConfirm(selectedActorRole?.value as ActorRole);
  }

  return (
    <Modal
      header="Edit project role"
      isOpen={open}
      onClose={onClose}
      modalSize={ModalSize.SMALL}
      buttonsProps={{
        onConfirmButtonClick: handleConfirmActorRole,
        confirmButtonChildren: 'Save',
      }}
    >
      <ModalLabel
        label="Company"
        labelValue={selectedUserProject?.project?.company?.name || ''}
      />

      <ModalLabel
        label="Project"
        labelValue={selectedUserProject?.project?.name}
      />

      <SelectFormField
        id="edit-project-role-select"
        test-id="edit-project-role__select"
        options={actorRolesForSelect}
        label="Project role"
        placeholder="Select project role"
        onChange={(selectedRole: TSelectOption) =>
          setSelectedActorRole(selectedRole)
        }
        value={selectedActorRole}
      />
    </Modal>
  );
};

export default EditModal;
