import { ContentClassification } from '../generated/graphql';

export const classificationOptions = [
  {
    value: ContentClassification.Positive,
    label: 'Positive',
  },
  {
    value: ContentClassification.Neutral,
    label: 'Neutral',
  },
  {
    value: ContentClassification.Negative,
    label: 'Negative',
  },
];
