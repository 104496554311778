import Snackbar from 'components/atoms/Snackbar/Snackbar';
import {
  RAssignedType,
  ReportSubType,
  ReportType,
  useReportAssignmentCreateMutation,
  useReportAssignmentDeleteMutation,
  useReportAssignmentFindStageReportLazyQuery,
  useReportAssignmentUpdateMutation,
  useReportsFindByCompanyLazyQuery,
} from 'generated/graphql';
import {
  ISelectFormFieldOption,
  TSelectFormFieldValue,
} from 'interfaces/forms/form';
import { ISnackBar } from 'interfaces/snackBar';
import { FC, useEffect, useState } from 'react';
import { IModalStatus } from 'views/User/UserDetails/UserDetails.interface';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import CandidateReportAssignmentPresentational from './CandidateReportAssignmentPresentational';
import stageAssignmentOption from './stageAssignmentOption';

interface ICandidateReportAssignment {
  companyId?: number;
  projectId?: number;
  stageId?: number;
  reportAssignedType: RAssignedType;
  subType: ReportSubType;
  label?: string;
  name: string;
  resetStageData?: boolean;
  checkBoxId?: string;
  selectFormId?: string;
}

const CandidateReportAssignment: FC<ICandidateReportAssignment> = ({
  companyId,
  projectId,
  stageId,
  reportAssignedType,
  subType,
  label,
  name,
  checkBoxId,
  selectFormId,
}) => {
  const { handleMsgType } = useNotification();

  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
    alertType: 'success',
  });
  const [modalStatusCandidate, setModalStatusCandidate] = useState<
    IModalStatus
  >({
    isOpen: false,
    msg: '',
    type: '',
  });
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [candidateCheckValues, setCandidateCheckValues] = useState<boolean>(
    false
  );

  const [candidateReport, setCandidateReport] = useState<
    TSelectFormFieldValue
  >();

  const [
    candidateReportsFindByCompany,
    candidateReportsFindByCompanyResponse,
  ] = useReportsFindByCompanyLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      type: ReportType.StageResults,
      subType,
      ownerId: Number(companyId),
      includeSharedReports: true,
    },
  });

  const [
    candidateReportAssignment,
    candidateReportAssignmentFindStageReportResponse,
  ] = useReportAssignmentFindStageReportLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      type: ReportType.StageResults,
      subType,
      projectId,
      companyId,
      stageId,
    },
  });

  let candidateStageAssignmentOption: ISelectFormFieldOption[] = [];
  if (
    candidateReportAssignmentFindStageReportResponse?.data &&
    candidateReportsFindByCompanyResponse?.data
  ) {
    candidateStageAssignmentOption = stageAssignmentOption(
      candidateReportAssignmentFindStageReportResponse.data,
      candidateReportsFindByCompanyResponse.data
    );
  }

  const [reportAssignmentCreateMutation] = useReportAssignmentCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      candidateReportAssignment();
      candidateReportsFindByCompany();
      setSnackbar({ show: true, msg: 'report assignment creation successful' });
      setModalStatusCandidate({
        ...modalStatusCandidate,
        isOpen: false,
      });
    },
  });

  const [reportAssignmentUpdateMutation] = useReportAssignmentUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      candidateReportAssignment();
      candidateReportsFindByCompany();
      setSnackbar({ show: true, msg: 'report assignment update successful' });
    },
  });

  const [reportAssignmentDeleteMutation] = useReportAssignmentDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      candidateReportAssignment();
      candidateReportsFindByCompany();
      setSnackbar({ show: true, msg: 'report assignment deletion successful' });
      setModalStatusCandidate({
        ...modalStatusCandidate,
        isOpen: false,
      });
    },
  });

  useEffect(() => {
    if (candidateReportAssignmentFindStageReportResponse?.data?.reports) {
      setCandidateReport(
        candidateReportAssignmentFindStageReportResponse?.data?.reports?.id
      );
    }
  }, [candidateReportAssignmentFindStageReportResponse?.data?.reports]);

  useEffect(() => {
    candidateReportAssignment();
    candidateReportsFindByCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      candidateReportAssignmentFindStageReportResponse?.data?.reports
        ?.inheritsSetting
    ) {
      setCandidateCheckValues(
        candidateReportAssignmentFindStageReportResponse.data.reports
          .inheritsSetting
      );
    }
  }, [candidateReportAssignmentFindStageReportResponse?.data?.reports]);

  const handleCandidateChange = (value: TSelectFormFieldValue) => {
    reportAssignmentUpdateMutation({
      variables: {
        assignedId:
          reportAssignedType === 'COMPANY'
            ? Number(companyId)
            : reportAssignedType === 'PROJECT'
            ? Number(projectId)
            : Number(stageId),
        assignedType: reportAssignedType,
        reportId: Number(value),
        id: Number(
          candidateReportAssignmentFindStageReportResponse?.data?.reports?.id
        ),
      },
    });
    setCandidateReport(value);
  };

  const showCandidateComfirmationModal = () => {
    setModalStatusCandidate({
      ...modalStatusCandidate,
      isOpen: true,
    });
  };
  const handleCandidateCheckChange = (value: boolean) => {
    setCandidateCheckValues(value);
    showCandidateComfirmationModal();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  const handleCandidateReportAssignment = () => {
    if (candidateCheckValues) {
      reportAssignmentDeleteMutation({
        variables: { id: Number(candidateReport) },
      });
    }
    if (!candidateCheckValues) {
      reportAssignmentCreateMutation({
        variables: {
          assignedId:
            reportAssignedType === 'COMPANY'
              ? Number(companyId)
              : reportAssignedType === 'PROJECT'
              ? Number(projectId)
              : Number(stageId),
          assignedType: reportAssignedType,
          reportId: Number(
            candidateReportAssignmentFindStageReportResponse?.data?.reports
              ?.reportId
          ),
        },
      });
    }
  };

  const closeCandidateModal = () => {
    setModalStatusCandidate({
      ...modalStatusCandidate,
      isOpen: false,
    });
    setErrorMsg('');
    setCandidateCheckValues(!candidateCheckValues);
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <CandidateReportAssignmentPresentational
          candidateStageAssignmentOption={candidateStageAssignmentOption}
          handleCandidateChange={handleCandidateChange}
          candidateReport={candidateReport}
          candidateCheckValues={candidateCheckValues}
          handleCandidateCheckChange={handleCandidateCheckChange}
          label={label}
          name={name}
          checkBoxId={checkBoxId}
          selectFormId={selectFormId}
        />
      </div>
      <ConfirmationModal
        open={modalStatusCandidate.isOpen}
        errorMsg={errorMsg}
        message={'Please confirm you wish to assign report'}
        onSave={handleCandidateReportAssignment}
        onClose={closeCandidateModal}
        saveBtnText="Assign"
      />
      <Snackbar
        open={snackbar.show}
        alertType={snackbar?.alertType || 'success'}
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default CandidateReportAssignment;
