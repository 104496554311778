import { FC } from 'react';
import SelectWithQuery from '../../../../../components/molecules/SelectFormField/SelectWithQuery/SelectWithQuery';
import {
  FormAssociatesDocument,
  FormAssociatesQuery,
  FormManagerType,
  FormType,
} from '../../../../../generated/graphql';
import { IFormOwner } from '../../../../../interfaces/formReport';
import { TSelectFormFieldValue } from '../../../../../interfaces/forms/form';

interface IFormAssociatesSelectFormField {
  id: string;
  name: string;
  label: string;
  hasError?: boolean;
  errorText?: string;
  value: TSelectFormFieldValue;
  formOwner?: IFormOwner;
  selectedFormType?: FormType;
  selectedFormManagerType?: FormManagerType;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
}

const FormAssociatesSelectFormField: FC<IFormAssociatesSelectFormField> = props => {
  const {
    selectedFormManagerType,
    selectedFormType,
    formOwner,
    ...selectElementProps
  } = props;

  const getOptionsFromResponse = (response: FormAssociatesQuery) => {
    const formAssociates = response?.FormReportFindAssociatedIds?.associatedIds;

    return formAssociates
      ? formAssociates.map(formAssociate => ({
          label: formAssociate.toString(),
          value: formAssociate,
        }))
      : [];
  };

  const isEmptySelectVisible = Boolean(
    selectedFormManagerType && selectedFormType && formOwner
  );

  return (
    <SelectWithQuery
      query={FormAssociatesDocument}
      queryOptions={{
        variables: {
          formManagerType: selectedFormManagerType,
          formType: selectedFormType,
          formOwnerId: formOwner ? +formOwner : 0,
        },
      }}
      isEmptySelectVisible={isEmptySelectVisible}
      getOptionsFromResponse={getOptionsFromResponse}
      selectElementProps={selectElementProps}
    />
  );
};

export default FormAssociatesSelectFormField;
