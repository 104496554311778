import Icon from 'components/atoms/Icon';
import { getEmptyFormSoftSkillValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.helpers';
import { TJobRoleFormValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import FormSoftSkillField from 'components/feature/jobRole/JobRoleFormSoftSkillField/JobRoleFormSoftSkillField';
import { FieldArray, useFormikContext } from 'formik';
import { FC } from 'react';
import { Button, TSelectOption } from '@spotted-zebra-uk/ui-components';
import styles from './JobRoleFormSoftSkillFields.module.scss';

interface IJobRoleFormSoftSkillFields {
  openTraitsModal: (softSkillIndex: number) => void;
  traitsModalSoftSkillIndex?: number;
  onCloseTraitsModal: () => void;
  openDataSourcesModal: (softSkillIndex: number) => void;
  dataSourceModalSoftSkillIndex?: number;
  onCloseDataSourcesModal: () => void;
  onChangeSoftSkill: (value: TSelectOption, index: number) => void;
}

const JobRoleFormSoftSkillFields: FC<IJobRoleFormSoftSkillFields> = props => {
  const { values } = useFormikContext<TJobRoleFormValues>();

  return (
    <div className={styles.container}>
      <FieldArray
        name="softSkills"
        render={fieldArrayHepleres => (
          <>
            <div className={styles.skillsList}>
              {values.softSkills.map((_softSkillValues, index) => (
                <FormSoftSkillField
                  key={index}
                  index={index}
                  remove={fieldArrayHepleres.remove}
                  {...props}
                />
              ))}
            </div>
            <div className={styles.actions}>
              <Button
                onClick={() =>
                  fieldArrayHepleres.push(getEmptyFormSoftSkillValues())
                }
                size="medium"
                variant="secondary"
                type="button"
                data-role="add-soft-skill"
                leftIcon={<Icon icon="add_black" className={styles.icon} />}
              >
                Add soft skill
              </Button>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default JobRoleFormSoftSkillFields;
