import Button from 'components/atoms/Button/Button';
import Modal from 'components/molecules/Modal/Modal';
import TextFormField from 'components/molecules/TextFormField/TextFormField';
import { ModalTypes } from 'enums/modalTypes';
import {
  IgCategoriesDocument,
  IgCategoriesQuery,
  IgCategory,
  useIgCategoryCreateMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import validate from 'helpers/validate';
import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';

interface IAddCategoryPayload {
  categoryName: string;
  categoryInternalName: string;
}

const AddCategoryModal: FC = () => {
  const classes = useReportModalStyle();
  const { handleMsgType } = useNotification();
  const initialValue = {
    categoryName: '',
    categoryInternalName: '',
  };
  const [createCategoryMutation] = useIgCategoryCreateMutation({
    onCompleted: () => {
      closeModal(ModalTypes.ADD_CATEGORY_MODAL);
    },
    update: (cache, { data }) => {
      let newCategory = { ...(data?.IgCategoryCreate as IgCategory) };
      newCategory.interviewGuideCategories = [];
      const categoriesData = cache.readQuery<IgCategoriesQuery>({
        query: IgCategoriesDocument,
      });
      if (newCategory && categoriesData?.IgCategories) {
        cache.writeQuery<IgCategoriesQuery>({
          query: IgCategoriesDocument,
          data: { IgCategories: [newCategory, ...categoriesData.IgCategories] },
        });
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [categoryInfo, setCategoryInfo] = useState<IAddCategoryPayload>(
    initialValue
  );

  const [errors, setErrors] = useState<IAddCategoryPayload>(initialValue);
  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_CATEGORY_MODAL);
  };

  const getCategoryName = (value: string) => {
    setCategoryInfo(prev => ({ ...prev, categoryName: value }));
    if (errors.categoryName) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, categoryName: '' } : prevErrors
      );
    }
  };
  const getCategoryInternalName = (value: string) => {
    setCategoryInfo(prev => ({ ...prev, categoryInternalName: value }));
    if (errors.categoryInternalName) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, categoryInternalName: '' } : prevErrors
      );
    }
  };

  const addCategoryFormValidation = (newValues: IAddCategoryPayload) =>
    validate(newValues, {
      categoryName: {
        nonEmptyString: true,
      },
      categoryInternalName: {
        nonEmptyString: true,
      },
    });

  const handleSubmitCategory = () => {
    const reportErrors = addCategoryFormValidation(categoryInfo);
    if (!_.isEmpty(reportErrors)) return setErrors(reportErrors);
    const variables = {
      name: categoryInfo.categoryName,
      internalName: categoryInfo.categoryInternalName,
    };
    createCategoryMutation({ variables });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            New Category
          </Typography>
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="categoryName"
            name="categoryName"
            value={categoryInfo.categoryName}
            label="Category Name"
            onChange={getCategoryName}
            hasError={!!errors?.categoryName}
            errorText={errors?.categoryName}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="categoryInternalName"
            name="categoryInternalName"
            value={categoryInfo.categoryInternalName}
            label="Category Internal Name"
            onChange={getCategoryInternalName}
            hasError={!!errors?.categoryInternalName}
            errorText={errors?.categoryInternalName}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmitCategory}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddCategoryModal;
