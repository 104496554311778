import styles from '../UserProjectRoles.module.scss';

type ModalLabelProps = {
  label: string;
  labelValue: string;
};

export function ModalLabel({ label, labelValue }: ModalLabelProps) {
  return (
    <div className={styles.modalLabelField}>
      <div className={styles.modalLabel}>{label}</div>
      <div className={styles.modalLabelValue}>{labelValue}</div>
    </div>
  );
}
