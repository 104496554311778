import { ProductSolution } from 'generated/graphql';

export const productSolutionLabels = {
  [ProductSolution.EarlyCareerHiring]: 'Early career hiring',
  [ProductSolution.Learning]: 'Learning',
  [ProductSolution.ProfessionalHiring]: 'Professional hiring',
  [ProductSolution.Reskilling]: 'Reskilling',
  [ProductSolution.SuccessionPlanning]: 'Succession planning',
  [ProductSolution.VolumeHiring]: 'Volume hiring',
};
