import styles from './styles.module.scss';

const TechnicalSkillsList = () => {
  return (
    <div className={styles.container}>
      List of Technical skills will appear here...
    </div>
  );
};

export default TechnicalSkillsList;
