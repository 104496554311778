import { ActorRole } from 'generated/graphql';
import { TSelectOption } from '@spotted-zebra-uk/ui-components';

export const actorRoleLabels = {
  [ActorRole.HiringManager]: 'Hiring manager',
  [ActorRole.HeadOfPeopleOrTalent]: 'Head of People',
  [ActorRole.RecruiterOrTalentAcquisitionPartner]: 'Recruiter',
  [ActorRole.Interviewer]: 'Interviewer',
  [ActorRole.ReskillingTeamMember]: 'Reskilling team member',
  [ActorRole.LineManager]: 'Line manager',
  [ActorRole.EarlyCareerTeamMember]: 'Early Careers team member',
  [ActorRole.AssessorOrInterviewer]: 'Assessor',
};

export const actorRolesForSelect: TSelectOption[] = Object.entries(
  actorRoleLabels
).map(([key, value]) => ({
  label: value,
  value: key,
}));
