import { FC } from 'react';
import { Typography } from '@mui/material';

interface ISuccessMessage {
  message?: string | undefined;
}

const SuccessMessage: FC<ISuccessMessage> = ({ message }) => {
  return message ? (
    <Typography className="SuccessMessage" color="primary">
      {message}
    </Typography>
  ) : null;
};

export default SuccessMessage;
