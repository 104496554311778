import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Grid, Typography } from '@mui/material';
import { companyRequirementsFormBuilderFormColumns } from '../../../../../../enums/forms/CandidateInformationFieldRequirementsForm';
import {
  ICompanyRequirementsFormBuilderFormFieldValue,
  TCompanyRequirementsFormBuilderFormOrderValuesColumn,
} from '../../../../../../interfaces/forms/companyRequirementsFormBuilderForm';
import CandidateInformationFieldRequirementsFormItem from '../CandidateInformationFieldRequirementsFormItem/CompanyRequirementsFormBuilderFormItem';
import useCompanyRequirementsFormBuilderFormOrderColumnStyle from './CompanyRequirementsFormBuilderFormOrderColumnStyle';

interface ICompanyRequirementsFormBuilderFormOrderColumn {
  title: string;
  id: companyRequirementsFormBuilderFormColumns;
  orderValues: TCompanyRequirementsFormBuilderFormOrderValuesColumn;
  fieldValues: {
    [key in number]: ICompanyRequirementsFormBuilderFormFieldValue;
  };
  companyId: number;
  formId: string;
  onChangeFieldRequirement: (value: boolean, fieldId: number) => void;
}

const CompanyRequirementsFormBuilderFormOrderColumn: FC<ICompanyRequirementsFormBuilderFormOrderColumn> = ({
  formId,
  companyId,
  id,
  title,
  orderValues,
  fieldValues,
  onChangeFieldRequirement,
}) => {
  const classes = useCompanyRequirementsFormBuilderFormOrderColumnStyle();
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.titleRow}
      >
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
      </Grid>
      <Droppable droppableId={id}>
        {provided => {
          return (
            <Grid
              className={classes.droppable}
              item
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {orderValues.map((fieldId, orderIndex) => (
                <CandidateInformationFieldRequirementsFormItem
                  isUsed={id === companyRequirementsFormBuilderFormColumns.USED}
                  companyId={companyId}
                  key={fieldId}
                  orderIndex={orderIndex}
                  formFieldValue={fieldValues[fieldId]}
                  formId={formId}
                  onChangeFieldRequirement={onChangeFieldRequirement}
                />
              ))}
              {provided.placeholder}
            </Grid>
          );
        }}
      </Droppable>
    </Grid>
  );
};

export default CompanyRequirementsFormBuilderFormOrderColumn;
