import EditCompanyNavigation from 'navigation/EditCompanyNavigation/EditCompanyNavigation';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const CompanyView: FC = () => {
  return (
    <>
      <EditCompanyNavigation />
      <div style={{ paddingLeft: 241 }}>
        <Outlet />
      </div>
    </>
  );
};

export default CompanyView;
