import { FC } from 'react';
import { useMutation } from '@apollo/client';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { SZ_FORM_OWNER_ID } from '../../../../constants/formGQL';
import {
  FieldInstanceType,
  FieldOwnerType,
  FieldType,
  FormManagerType,
  FormType,
} from '../../../../enums/forms/form';
import {
  FIELD_CREATE_MUTATION,
  FIELDS_QUERY,
} from '../../../../graphql/form/field';
import { GET_FORM_BUILDER_QUERY } from '../../../../graphql/form/formBuilder';
import {
  IFieldCreateMutationInput,
  IFieldCreateMutationResponse,
  IFieldsQueryInput,
  IFieldsQueryResponse,
  IGetFormBulderQueryInput,
  IGetFormBulderQueryResponse,
} from '../../../../interfaces/forms/formGQL';
import CandidateInfoFieldForm, {
  ICandidateInfoFieldFormSubmitValues,
} from '../CandidateInfoFieldForm/CandidateInfoFieldForm';
import CandidateInfoFieldMessage from '../CandidateInfoFieldMessage/CandidateInfoFieldMessage';

interface ICreateCandidateInfoField {
  onCandidateInfoFieldCreated?: () => void;
  companyId?: number;
  formFilter?: FormType;
}

const CreateCandidateInfoField: FC<ICreateCandidateInfoField> = ({
  onCandidateInfoFieldCreated,
  companyId,
  formFilter,
}) => {
  const { handleMsgType } = useNotification();

  const [
    createCandidateInformationFieldMutation,
    createCandidateInformationFieldMutationResponse,
  ] = useMutation<IFieldCreateMutationResponse, IFieldCreateMutationInput>(
    FIELD_CREATE_MUTATION,
    {
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
      onCompleted: () => {
        if (onCandidateInfoFieldCreated) {
          onCandidateInfoFieldCreated();
        }
      },
      update(cache, { data }) {
        if (data?.field) {
          const { field } = data;

          // Company fields should be updated when only active (not archived) fields displayed.
          try {
            const fieldsCacheData = cache.readQuery<
              IFieldsQueryResponse,
              IFieldsQueryInput
            >({
              query: FIELDS_QUERY,
              variables: {
                isArchived: false,
              },
            });
            if (fieldsCacheData) {
              cache.writeQuery<IFieldsQueryResponse, IFieldsQueryInput>({
                query: FIELDS_QUERY,
                data: { fields: [...fieldsCacheData.fields, field] },
                variables: {
                  isArchived: false,
                },
              });
            }
          } catch (error) {}

          // Company fields should be updated when all fields are visible (archived fields displayed).
          try {
            const fieldsAllCacheData = cache.readQuery<
              IFieldsQueryResponse,
              IFieldsQueryInput
            >({
              query: FIELDS_QUERY,
            });
            if (fieldsAllCacheData) {
              cache.writeQuery<IFieldsQueryResponse, IFieldsQueryInput>({
                query: FIELDS_QUERY,
                data: { fields: [...fieldsAllCacheData.fields, field] },
              });
            }
          } catch (error) {}

          // Company requirements form builder should be updated.
          // New field will be added to form builder available fields column.
          try {
            let formOwnerId;

            if (data.field.fieldOwnerId) {
              if (data.field.fieldOwnerId === SZ_FORM_OWNER_ID) {
                formOwnerId = companyId || data.field.fieldOwnerId;
              } else {
                formOwnerId = data.field.fieldOwnerId;
              }
            } else {
              formOwnerId = SZ_FORM_OWNER_ID;
            }

            const formBuilderCachedData = cache.readQuery<
              IGetFormBulderQueryResponse,
              IGetFormBulderQueryInput
            >({
              query: GET_FORM_BUILDER_QUERY,
              variables: {
                formType: formFilter ? formFilter : FormType.CI_FORM,
                formManagerType:
                  formFilter === FormType.TR_FORM
                    ? FormManagerType.TR
                    : FormManagerType.CI,
                formOwnerId,
              },
            });

            if (formBuilderCachedData) {
              cache.writeQuery<
                IGetFormBulderQueryResponse,
                IGetFormBulderQueryInput
              >({
                query: GET_FORM_BUILDER_QUERY,
                variables: {
                  formType: formFilter ? formFilter : FormType.CI_FORM,
                  formManagerType:
                    formFilter === FormType.TR_FORM
                      ? FormManagerType.TR
                      : FormManagerType.CI,
                  formOwnerId,
                },
                data: {
                  formBuilder: {
                    ...formBuilderCachedData.formBuilder,
                    availableFields: [
                      ...formBuilderCachedData.formBuilder.availableFields,
                      field,
                    ],
                  },
                },
              });
            }
          } catch (error) {}
        }
      },
    }
  );

  const handleCreateCandidateInfoField = (
    createCandidateInfoField: ICandidateInfoFieldFormSubmitValues
  ) => {
    createCandidateInformationFieldMutation({
      variables: {
        fieldType: createCandidateInfoField.type as FieldType,
        name: createCandidateInfoField.name,
        question: createCandidateInfoField.question,
        hint: createCandidateInfoField.hint,
        formManagerType: createCandidateInfoField.formManagerType,
        fieldInstanceType: createCandidateInfoField.instanceType as FieldInstanceType,
        settings: {
          searchable: createCandidateInfoField.settings.searchable,
          allowFreeText: createCandidateInfoField.settings.allowFreeText,
        },
        fieldOwnerId: createCandidateInfoField.companyId
          ? parseInt(createCandidateInfoField.companyId, 10)
          : 1,
        fieldOwnerType: createCandidateInfoField.companyId
          ? FieldOwnerType.COMPANY_FIELD_OWNER
          : FieldOwnerType.SZ_FIELD_OWNER,
        selectOptions: createCandidateInfoField.allowedValues
          ? JSON.stringify({
              options: createCandidateInfoField.allowedValues.split('\n'),
            })
          : undefined,
      },
    });
  };

  return (
    <>
      <CandidateInfoFieldForm
        title="Create candidate information field"
        onSubmit={handleCreateCandidateInfoField}
        initialValues={{ companyId: companyId ? `${companyId}` : '' }}
      />
      <CandidateInfoFieldMessage
        error={createCandidateInformationFieldMutationResponse.error}
        isSuccess={!!createCandidateInformationFieldMutationResponse.data}
        successMessage="Candidate information field created!"
      />
    </>
  );
};

export default CreateCandidateInfoField;
