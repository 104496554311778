import { ModalTypes } from 'enums/modalTypes';
import { SmStageModel, StageFindManyDocument } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { Modal, ModalSize } from '@spotted-zebra-uk/ui-components';
import { getPrefilledDeiFields } from './DeiActionForm/dei.helpers';
import DeiActionForm from './DeiActionForm/DeiActionForm';

export interface IEditDeiActionModal {
  projectId: number;
  stageId: number;
  completedCount: number;
}

const EditDeiActionModal = ({
  projectId,
  stageId,
  completedCount,
}: IEditDeiActionModal) => {
  const client = useApolloClient();
  const stageData = client.readQuery({
    query: StageFindManyDocument,
    variables: { projectId: projectId },
  });

  const deiStageAction = stageData?.Stages.find(
    (stage: SmStageModel) => stage.id === stageId
  ).deiStageAction;

  const prefilledData = useMemo(() => {
    return getPrefilledDeiFields(deiStageAction?.fields || []);
  }, [deiStageAction]);

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_DEI_ACTION);
  };

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen
      modalSize={ModalSize.MEDIUM}
      className="edit-dei-action-modal"
      header="Edit Diversity, equity & inclusion"
    >
      <DeiActionForm
        stageId={stageId}
        onClose={handleCloseModal}
        type="edit"
        initialValues={{ deiValues: prefilledData }}
        completedCount={completedCount}
        projectId={projectId}
      />
    </Modal>
  );
};

export default EditDeiActionModal;
