import {
  ReportAssignmentFindStageReportQuery,
  ReportsFindByCompanyQuery,
} from '../../../generated/graphql';
import { ISelectFormFieldOption } from '../../../interfaces/forms/form';

const stageAssignmentOption = (
  reportAssignment: ReportAssignmentFindStageReportQuery,
  companyReport: ReportsFindByCompanyQuery
) => {
  const reportAssigned = {
    value: reportAssignment?.reports?.id || -1,
    label: `**${reportAssignment?.reports?.report.name}`,
  };

  const sZReport = companyReport?.ReportsFindByCompany?.filter(
    item =>
      item.ownerId === 1 && item.id !== reportAssignment?.reports?.reportId
  ).map(item => ({ value: item.id, label: item.name }));

  const companyReportArr = companyReport?.ReportsFindByCompany?.filter(
    item =>
      item.ownerId !== 1 && item.id !== reportAssignment?.reports?.reportId
  ).map(item => ({ value: item.id, label: item.name }));

  let mergedReport: ISelectFormFieldOption[] = [];
  if (sZReport?.length) {
    mergedReport = [reportAssigned, ...sZReport];
  }

  if (sZReport?.length && companyReportArr) {
    mergedReport = [reportAssigned, ...companyReportArr, ...sZReport];
  }

  return mergedReport;
};

export default stageAssignmentOption;
