import { ExternalAssessmentModel } from 'generated/graphql';
import { useMemo } from 'react';
import { Tooltip } from '@spotted-zebra-uk/ui-components';
import TimeLimitIcon from '../icons/TimeLimit.icon';
import styles from './TimeExtension.module.scss';

export default function TimeExtension({
  codingAssessment,
}: {
  codingAssessment: ExternalAssessmentModel;
}) {
  const timeExtensions = useMemo(
    () => extractExtensionTimes(codingAssessment),
    [codingAssessment]
  );

  const hasTimeExtensions = !!timeExtensions && timeExtensions.length > 0;

  const timeExtensionTooltipText = `Time extensions: ${timeExtensions}`;

  return (
    <div className={styles.container}>
      {hasTimeExtensions ? (
        <Tooltip content={timeExtensionTooltipText}>
          <div>
            <TimeLimitIcon />
          </div>
        </Tooltip>
      ) : (
        <TimeLimitIcon color={'var(--color-disabled-front'} />
      )}
      {codingAssessment.timeLimitInMinutes} minutes
    </div>
  );
}

function extractExtensionTimes(codingAssessment: ExternalAssessmentModel) {
  return codingAssessment.eaPlatformAssessments
    ?.filter(assessment => assessment.modifier !== 'DEFAULT')
    .map(assessment => {
      const assessmentModifier = assessment.modifier;
      // extracting number from string (e.g. DURATION_PLUS_25)
      const extensionNumber =
        assessmentModifier.substring(
          'DURATION_PLUS_'.length,
          assessmentModifier.length
        ) + '% ';
      return extensionNumber;
    });
}
