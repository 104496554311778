import { format, toZonedTime } from 'date-fns-tz';
import { ActorRole } from 'generated/graphql';
import { actorRoleLabels } from 'helpers/actorRoles';

export const getYesOrNo = (value: boolean): string => (value ? 'Yes' : 'No');

export const getActorRoles = (rolesArray: ActorRole[]): string =>
  rolesArray.length
    ? rolesArray.map(role => actorRoleLabels[role]).join(', ')
    : 'N/A';

export const getStageDate = (dateString: string): string => {
  const date = new Date(dateString);
  const utcDate = toZonedTime(date, 'UTC');
  return `${format(utcDate, 'dd/MM/yyyy HH:mm')} UTC`;
};
