import style from './NoSearchResultsTableContent.module.scss';

export const NO_SEARCH_RESULTS_MESSAGE_TOP = 'No results found.';
export const NO_SEARCH_RESULTS_MESSAGE_BOTTOM =
  "No results found. Please try adjusting your search to find what you're looking for.";

const NoSearchResultsTableContent = () => (
  <div className={style.noSearchResultsContent}>
    <p className={style.noSearchResultsText}>{NO_SEARCH_RESULTS_MESSAGE_TOP}</p>
    <p className={style.noSearchResultsText}>
      {NO_SEARCH_RESULTS_MESSAGE_BOTTOM}
    </p>
  </div>
);

export default NoSearchResultsTableContent;
