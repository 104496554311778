import { ModalTypes } from 'enums/modalTypes';
import { FieldArray, Formik, useField } from 'formik';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { TProjectRequestUserWithCompanyMaybeEmployee } from 'views/ProjectRequests/ProjectRequest/helpers';
import {
  FormikCheckboxFormField,
  ModalButtons,
  FormikDatepicker,
} from '@spotted-zebra-uk/ui-components';
import { IProjectRequestApprovalStatusFormValues } from './ProjectRequestApprovalStatusModal.interfaces';

interface IProjectRequestApprovalStatusFormPresentational {
  onSubmit: (
    values: IProjectRequestApprovalStatusFormValues
  ) => void | Promise<void>;
  initialValues: IProjectRequestApprovalStatusFormValues;
  projectRequestUsers: TProjectRequestUserWithCompanyMaybeEmployee[];
}

export const ProjectRequestApprovalStatusFormPresentational: FC<IProjectRequestApprovalStatusFormPresentational> = ({
  onSubmit,
  initialValues,
  projectRequestUsers,
}) => (
  <Formik onSubmit={onSubmit} initialValues={initialValues}>
    {({ handleSubmit, isSubmitting, values }) => (
      <div className="project-request-approval-status-form">
        <div className="project-request-approval-status-form__fields">
          <FieldArray name="projectRequestUsersApprovals">
            {() =>
              projectRequestUsers.map((projectRequestUser, index) => (
                <div
                  key={projectRequestUser.id}
                  className="project-request-approval-status-form__fields-row"
                >
                  <FormikCheckboxFormField
                    id={`projectRequestUsersApprovals.${index}.hasApproved`}
                    label={
                      <div className="project-request-approval-status-form__checkbox-label">
                        {projectRequestUser.companyEmployee?.firstName}{' '}
                        {projectRequestUser.companyEmployee?.lastName}{' '}
                        <span className="project-request-approval-status-form__checkbox-label-note">
                          {projectRequestUser.companyEmployee?.email}
                        </span>
                      </div>
                    }
                    useFormikField={useField}
                  />
                  <FormikDatepicker
                    id={`projectRequestUsersApprovals.${index}.approvalRequestDate`}
                    name={`projectRequestUsersApprovals.${index}.approvalRequestDate`}
                    useFormikField={useField}
                  />
                </div>
              ))
            }
          </FieldArray>
        </div>
        <ModalButtons
          onCancelButtonClick={() =>
            closeModal(ModalTypes.PROJECT_REQUEST_APPROVAL_STATUS_MODAL)
          }
          onConfirmButtonClick={() => handleSubmit()}
          confirmButtonChildren="Submit"
          confirmButtonProps={{ type: 'button', disabled: isSubmitting }}
          cancelButtonProps={{ type: 'button' }}
        />
      </div>
    )}
  </Formik>
);
