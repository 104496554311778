import { clsx } from 'clsx';
import { ItemsCount } from 'components/ui/ItemsCount/ItemsCount';
import { ReactComponent as DeleteOutlineIcon } from 'icons/delete_outline.svg';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  DropdownMenu,
  Table,
  TPaginationOnChangeArgs,
  useDrawer,
} from '@spotted-zebra-uk/ui-components';
import { CompanyEmailDomainsDrawer } from '../CompanyEmailDomainsDrawer/CompanyEmailDomainsDrawer';
import { DeleteDomainModal } from '../DeleteDomainModal/DeleteDomainModal';
import styles from './CompanyEmailDomains.module.scss';

interface ICompanyEmailDomains {
  companyId: number;
  allowedEmailDomains: Array<string>;
}

const headerData = [
  { key: '@', header: 'Domain' },
  { key: 'domain', header: '' },
  { key: 'actions', header: '' },
];

export const CompanyEmailDomains: FC<ICompanyEmailDomains> = ({
  allowedEmailDomains,
  companyId,
}) => {
  const [selectedDomainForDelete, setSelectedDomainForDelete] = useState('');
  const [modalOpen, setIsModalOpen] = useState(false);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [page, setPage] = useState(1);

  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const {
    isDrawerOpen: isEmailDomainsDrawerOpen,
    openDrawer: openEmailDomainsDrawer,
    closeDrawer: closeEmailDomainsDrawer,
  } = useDrawer({
    domTrigger: drawerTriggerRef,
  });

  const handlePaginationChange = ({
    page,
    pageSize,
  }: TPaginationOnChangeArgs) => {
    if (pageSize !== currentPageSize) {
      setCurrentPageSize(pageSize);
    }
    setFirstRowIndex(pageSize * (page - 1));
    setPage(page);
  };

  const paginatedRows = allowedEmailDomains?.slice(
    firstRowIndex,
    firstRowIndex + currentPageSize
  );

  const rowData = useMemo(
    () =>
      paginatedRows.map(domain => ({
        '@': '@',
        id: domain,
        domain,
        actions: (
          <HoverableAction
            onDeleteBtnClick={() => {
              setIsModalOpen(true);
              setSelectedDomainForDelete(domain);
            }}
          />
        ),
      })),
    [paginatedRows]
  );

  const count = allowedEmailDomains.length || 0;

  // Auto-switch active page
  // Edge case: when the user deletes the last item on the last page
  useEffect(() => {
    if (count > 0 && rowData.length === 0) {
      const newPage = page - 1;
      setFirstRowIndex(currentPageSize * (newPage - 1));
      setPage(newPage);
    }
  }, [count, rowData, page, currentPageSize]);

  return (
    <div className={styles.container}>
      <Table
        headerData={headerData}
        rowData={rowData}
        hasHoverableActions
        dataTestId="company-email-domains--table"
        isSortable={false}
        hasToolbar={true}
        toolbarConf={{
          componentListLeftStart: (
            <ItemsCount
              count={count}
              ariaLabelSuffix="Total count of email domains"
            />
          ),
          componentListRight: (
            <Button onClick={openEmailDomainsDrawer} ref={drawerTriggerRef}>
              Add
            </Button>
          ),
          containerClass: styles.toolbar,
          rightContainerClass: styles.toolbarRight,
          leftContainerClass: styles.toobarLeft,
        }}
        hasPagination={count > currentPageSize}
        pagination={{
          onChange: handlePaginationChange,
          page,
          currentPageSize,
          totalNumberOfItems: allowedEmailDomains.length,
        }}
        className={clsx({
          [styles.table]: true,
          [styles.empty]: count === 0,
        })}
      />
      <CompanyEmailDomainsDrawer
        isOpen={isEmailDomainsDrawerOpen}
        onClose={closeEmailDomainsDrawer}
        companyId={companyId}
        existingEmailDomains={allowedEmailDomains || []}
      />

      <DeleteDomainModal
        isOpen={modalOpen}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}
        domain={selectedDomainForDelete}
        existingEmailDomains={allowedEmailDomains || []}
        companyId={companyId}
      />
    </div>
  );
};

function HoverableAction({
  onDeleteBtnClick,
}: {
  onDeleteBtnClick: () => void;
}) {
  return (
    <DropdownMenu
      renderButton={(_, __, handleTriggerDropdown) => (
        <Button
          variant="secondary"
          size="small"
          onClick={handleTriggerDropdown}
          className={styles.hoverActionButton}
        >
          Manage
        </Button>
      )}
      options={[
        {
          label: 'Delete',
          icon: (
            <div className={styles.hoverActionIcon}>
              <DeleteOutlineIcon />
            </div>
          ),
          onClick: onDeleteBtnClick,
        },
      ]}
    />
  );
}

export default CompanyEmailDomains;
