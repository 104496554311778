import Button from 'components/atoms/Button/Button';
import FileUploadFormField from 'components/molecules/FileUploadFormField/FileUploadFormField';
import { useWidgetUploadScreenShotMutation } from 'generated/graphql';
import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useWidgetStyles from '../WidgetStyles';

interface IWidgetScreenShot {
  screenShotUrl: string;
  id: number;
}

const WidgetScreenShot: FC<IWidgetScreenShot> = ({ screenShotUrl, id }) => {
  const { handleMsgType } = useNotification();
  const classes = useWidgetStyles();

  const [uploadWidgetImageMutation] = useWidgetUploadScreenShotMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [values, setValues] = useState<{ widgetUpload: File | null }>({
    widgetUpload: null,
  });

  const handleUploadWidgetImage = (file: File) => {
    uploadWidgetImageMutation({
      variables: {
        file,
        id,
      },
    });
  };

  const handleChangeFile = (value: File) => {
    setValues({
      widgetUpload: value,
    });
    if (value) handleUploadWidgetImage(value);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Widget ScreenShot</Typography>
        <Grid xs={5}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: '10px' }}>
              <FileUploadFormField
                id="widgetUpload"
                name="widgetUpload"
                value={values.widgetUpload}
                onChange={handleChangeFile}
                isFileNameHidden
                label="UPLOAD NEW"
              />
            </div>
            <Button className={classes.deleteBtn} color="inherit">
              DELETE UPLOAD
            </Button>
          </div>
        </Grid>
      </Grid>
      {screenShotUrl && (
        <Grid>
          <img src={screenShotUrl} alt="widget" />
        </Grid>
      )}
    </>
  );
};

export default WidgetScreenShot;
