import { FC } from 'react';
import {
  StageFindManyDocument,
  StageFindManyQuery,
} from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import SelectWithQuery from '../SelectWithQuery/SelectWithQuery';

interface IStagesSelectFormFieldWrapper {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label: string;
  selectedProjectId?: number;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  hasError?: boolean | undefined;
  errorText?: string | undefined;
}

const StagesSelectFormFieldWrapper: FC<IStagesSelectFormFieldWrapper> = ({
  selectedProjectId,
  ...restProps
}) => {
  const getOptionsFromResponse = (response: StageFindManyQuery | undefined) => {
    if (response?.Stages) {
      return [...response.Stages]
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(project => ({
          label: project.name,
          value: project.id,
        }));
    }
    return [];
  };

  const isEmptySelectVisible = Boolean(selectedProjectId);

  return (
    <SelectWithQuery
      query={StageFindManyDocument}
      queryOptions={{
        variables: {
          projectId: selectedProjectId,
        },
      }}
      getOptionsFromResponse={getOptionsFromResponse}
      isEmptySelectVisible={isEmptySelectVisible}
      selectElementProps={restProps}
    />
  );
};

export default StagesSelectFormFieldWrapper;
