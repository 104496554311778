import { FC } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReportConfig, Widget } from '../../../generated/graphql';
import WidgetsTableRow from './WidgetsTableRow/WidgetsTableRow';
import useWidgetsTableStyle from './WidgetsTableStyle';

interface IWidgetsTable {
  headerData: string[];
  tableBodyData: Widget[] | undefined;
}

const WidgetsTable: FC<IWidgetsTable> = ({ headerData, tableBodyData }) => {
  const classes = useWidgetsTableStyle();
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell className={classes.tableHeader} key={`a${index}`}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyData?.map(row => (
            <WidgetsTableRow
              key={row.id}
              id={row.id}
              name={row.name as string}
              type={row.type}
              reportConfigs={row.reportConfigs as ReportConfig[]}
              isArchived={row.isArchived}
              updatedAt={row.updatedAt}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WidgetsTable;
