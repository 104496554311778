import {
  TestOrigin,
  TmTestModel,
  useTmTestUpdateOneMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import StageActionForm, {
  IStageTestActionFormValues,
} from './StageTestActionForm';

interface IStageTestAction {
  stageTest: TmTestModel;
}

const StageTestAction: FC<IStageTestAction> = ({ stageTest }) => {
  const { handleMsgType } = useNotification();

  const [stageTestData, setStageTestData] = useState<TmTestModel>(stageTest);

  const [addTestToStage] = useTmTestUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: data => {
      setStageTestData(data.TmTestUpdateOne as TmTestModel);
      handleMsgType({
        type: TNotification.success,
        message: 'Stage test updated!',
      });
    },
  });

  const handleAddTestToStage = (values: IStageTestActionFormValues) => {
    addTestToStage({
      variables: {
        tpProjectId: values.tpProjectId,
        testId: stageTestData.id,
        origin: values.origin.value as TestOrigin,
        nQuestionsPerTrait: Number(values.questionNumbers),
        type: stageTestData?.type,
        subType: stageTestData.subType,
      },
    });
  };

  return (
    <StageActionForm
      tpProjectId={stageTestData.tpProjectId}
      testId={stageTestData.id}
      type={stageTestData.type}
      subType={stageTestData.subType}
      testOrigin={stageTestData.origin}
      testQuestionNumbers={stageTestData.nQuestionsPerTrait}
      onSave={handleAddTestToStage}
    />
  );
};

export default StageTestAction;
