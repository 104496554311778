import TextFormFieldModal from '../../components/organisms/TextFormFieldModal/TextFormFieldModal';
import { ModalTypes } from '../../enums/modalTypes';
import CandidateEditInfoFieldModal from './CandidateInfoFieldModal/CandidateInfoFieldModal';
import AddSoftSkillModal from './SoftSkillModals/AddSoftSkillModal/AddSoftSkillModal';
import EditSoftSkillModal from './SoftSkillModals/EditSoftSkillModal/EditSoftSkillModal';
import StaticContentFiltersModal from './StaticContentFiltersModal/StaticContentFiltersModal';
import AddTraitModal from './TraitModals/AddTraitModal/AddTraitModal';
import EditTraitModal from './TraitModals/EditSoftSkillModal/EditTraitModal';
import ValidateContentModal from './ValidateContentModal/ValidateContentModal';

export const renderModalObject = {
  [ModalTypes.CANDIDATE_INFO_FIELD_MODAL]: CandidateEditInfoFieldModal,
  [ModalTypes.ADD_SOFT_SKILL_MODAL]: AddSoftSkillModal,
  [ModalTypes.EDIT_SOFT_SKILL_MODAL]: EditSoftSkillModal,
  [ModalTypes.TEXT_FIELD_MODAL]: TextFormFieldModal,
  [ModalTypes.ADD_TRAIT_MODAL]: AddTraitModal,
  [ModalTypes.EDIT_TRAIT_MODAL]: EditTraitModal,
  [ModalTypes.STATIC_CONTENT_FILTERS_MODAL]: StaticContentFiltersModal,
  [ModalTypes.VALIDATE_CONTENT_MODAL]: ValidateContentModal,
};
