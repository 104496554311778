import { FieldArray, Formik, useField } from 'formik';
import { useProjectRequestRequestJobSpecificationMutation } from 'generated/graphql';
import { FC, Fragment, useMemo } from 'react';
import {
  TProjectRequestUserWithCompanyMaybeEmployee,
  useGetProjectRequestUsers,
} from 'views/ProjectRequests/ProjectRequest/helpers';
import * as yup from 'yup';
import {
  Loader,
  FormikCheckboxFormField,
  ModalButtons,
} from '@spotted-zebra-uk/ui-components';

const validationSchema = yup.object().shape({
  selectedProjectRequestUsers: yup
    .array()
    .compact(value => {
      return !value.isSelected;
    })
    .min(1, 'Please select at least one user.'),
});

interface IProjectRequestJobSpecificationFormPresentational {
  projectRequestUsers: TProjectRequestUserWithCompanyMaybeEmployee[];
  onSubmit: (
    values: IProjectRequestJobSpecificationFormValues
  ) => void | Promise<void>;
  onCancelClick: () => void;
}

const ProjectRequestJobSpecificationFormPresentational: FC<IProjectRequestJobSpecificationFormPresentational> = ({
  projectRequestUsers,
  onSubmit,
  onCancelClick,
}) => {
  const initialValues = useMemo(
    () => ({
      selectedProjectRequestUsers: projectRequestUsers.map(
        projectRequestUser => ({
          projectRequestUserId: projectRequestUser.id,
          isSelected: false,
        })
      ),
    }),
    [projectRequestUsers]
  );

  return (
    <Formik<IProjectRequestJobSpecificationFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <div className="project-request-job-specification-form">
          <div className="project-request-job-specification-form__fields">
            <FieldArray name="selectedProjectRequestUsers">
              {() => (
                <>
                  {projectRequestUsers.map((projectRequestUser, index) => (
                    <Fragment key={projectRequestUser.id}>
                      <FormikCheckboxFormField
                        id={`selectedProjectRequestUsers.${index}.isSelected`}
                        label={
                          <div className="project-request-job-specification-form__checkbox-label">
                            {projectRequestUser.companyEmployee?.firstName}{' '}
                            {projectRequestUser.companyEmployee?.lastName}{' '}
                            <span className="project-request-job-specification-form__checkbox-label-note">
                              {projectRequestUser.companyEmployee?.email}
                            </span>
                          </div>
                        }
                        useFormikField={useField}
                      />
                    </Fragment>
                  ))}
                  <div className="project-request-job-specification-form__fields-array-error">
                    {errors.selectedProjectRequestUsers?.toString() &&
                      errors.selectedProjectRequestUsers?.toString()}
                  </div>
                </>
              )}
            </FieldArray>
          </div>
          <ModalButtons
            onCancelButtonClick={onCancelClick}
            onConfirmButtonClick={() => handleSubmit()}
            confirmButtonChildren="Send Request"
            cancelButtonProps={{
              size: 'medium',
              type: 'button',
            }}
            confirmButtonProps={{
              size: 'medium',
              disabled: isSubmitting,
              type: 'button',
            }}
          />
        </div>
      )}
    </Formik>
  );
};

interface IProjectRequestJobSpecificationFormValues {
  selectedProjectRequestUsers: {
    projectRequestUserId: number;
    isSelected: boolean;
  }[];
}

interface IProjectRequestJobSpecificationForm {
  projectRequestId: number;
  projectRequestCompanyId: number;
  onCancelClick: () => void;
  onSubmit: () => void;
}

const ProjectRequestJobSpecificationForm: FC<IProjectRequestJobSpecificationForm> = ({
  projectRequestId,
  projectRequestCompanyId,
  onCancelClick,
  onSubmit,
}) => {
  const [
    requestJobSpecificationMutation,
  ] = useProjectRequestRequestJobSpecificationMutation();
  const { projectRequestUsers, loading } = useGetProjectRequestUsers(
    projectRequestId,
    projectRequestCompanyId
  );

  const handleFormSubmit = async (
    values: IProjectRequestJobSpecificationFormValues
  ) => {
    try {
      const selectedUsersIds = values.selectedProjectRequestUsers
        .filter(selectedUsersIds => selectedUsersIds.isSelected)
        .map(
          selectedProjectRequestUsers =>
            selectedProjectRequestUsers.projectRequestUserId
        );
      await requestJobSpecificationMutation({
        variables: {
          id: projectRequestId,
          projectRequestUserIds: selectedUsersIds,
        },
      });
      onSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader variant="bubbles" />;
  }

  return (
    <ProjectRequestJobSpecificationFormPresentational
      onSubmit={handleFormSubmit}
      projectRequestUsers={projectRequestUsers}
      onCancelClick={onCancelClick}
    />
  );
};

export default ProjectRequestJobSpecificationForm;
