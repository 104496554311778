import { FC, useEffect, useState } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  ReportSubType,
  ReportType,
  useReportAssignmentFindStageReportQuery,
  useReportFindManyQuery,
} from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';

export interface ISelectFormField {
  id: string;
  name: string;
  label?: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean | undefined;
  errorText?: string | undefined;
  value: TSelectFormFieldValue;
  className?: string;
  isDisabled?: boolean;
  hasNullValue?: boolean;
  inputProps?: InputBaseComponentProps;
  stageId: number;
  companyId: number;
  projectId: number;
}

interface IOptions {
  label: string;
  value: number;
}

const ReportNameSelectFormField: FC<ISelectFormField> = ({
  id,
  name,
  label,
  onChange,
  onBlur,
  hasError = false,
  errorText = '',
  value,
  isDisabled = false,
  className = '',
  hasNullValue = true,
  inputProps,
  stageId,
  companyId,
  projectId,
}) => {
  const { handleMsgType } = useNotification();

  const [result, setResult] = useState<IOptions[]>([]);
  const queryResult1 = useReportFindManyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      type: ReportType.StageResults,
    },
  });

  const queryResult2 = useReportAssignmentFindStageReportQuery({
    skip: !(stageId && projectId && companyId),
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      type: ReportType.StageResults,
      subType: ReportSubType.StageResultsManager,
      stageId,
      projectId,
      companyId,
    },
  });

  const queryResult3 = useReportAssignmentFindStageReportQuery({
    skip: !(stageId && projectId && companyId),
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      type: ReportType.StageResults,
      subType: ReportSubType.StageResultsCandidate,
      stageId,
      projectId,
      companyId,
    },
  });

  const updatedQueryResult1 = queryResult1?.data?.report?.map(report => ({
    label: report.name,
    value: report.id,
  }));

  useEffect(() => {
    if (updatedQueryResult1 && queryResult2?.data && queryResult3?.data) {
      const updatedQueryResult2 = [
        {
          label: `*${queryResult2?.data?.reports?.report.name}`,
          value: queryResult2?.data?.reports?.report?.id || 0,
        },
        {
          label: `*${queryResult3?.data?.reports?.report.name}`,
          value: queryResult3?.data?.reports?.report?.id || 0,
        },
      ];
      const newResult = [...updatedQueryResult2, ...updatedQueryResult1];
      setResult(newResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult1.data, queryResult2.data, queryResult3.data]);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as TSelectFormFieldValue;
    onChange(newValue, name);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, name);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth error={hasError}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        native
        value={String(value)}
        onChange={handleChange}
        disabled={isDisabled}
        label={label}
        className={className}
        inputProps={{
          ...inputProps,
          id,
          name,
          onBlur: handleBlur,
        }}
      >
        {hasNullValue ? <option value=""> </option> : null}
        {result
          .sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
          )
          .map((option, index) => (
            <option key={`a${index}`} value={option.value || 0}>
              {option.label}
            </option>
          ))}
      </Select>
      {hasError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default ReportNameSelectFormField;
